
export enum ButtonType
{
    Save="Save",
    Edit = "Edit",
    SaveAndAddAnother="Save and add another",
    SaveAndEdit = "Save and edit",  
    Export = "Export",
    AddNew = "Add New",
    AddFilter = "Filter",
    Close = "Close",
    Cancel = "Cancel",
    Clear = "Clear",
    Filter = "Filter",
    Regenerate = "Regenerate",
    RegenerateTable = "Regenerate Table",
    Delete = "Yes, I'm sure"

}