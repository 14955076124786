import React, { Component, Fragment } from 'react'
import { SchemasService } from './services';
import { HandleNotFoundResponse, RedirectBySourceType } from '../../../shared/helpers';
import { NotFoundResponseArea, SchemasType, SourceType } from '../../../shared/enums';
import { HttpResponse } from '../../../core';
import { NavLink } from "react-router-dom";

import { SchemasDetailModel, SchemaOwnerModel } from './models';
import { Loader } from '../../../shared/loaders';

interface IState {
    result?: SchemasDetailModel;
    parsedData?: string;
    type?:string;
    isLoading?: boolean;
}
export class SchemasDetail extends Component<any, IState> {
    private service: SchemasService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new SchemasService();
    }

    initialState: Partial<IState> = {
        result: {             
            id:0,
            type:0,
            dependent_reports:[],
            schema_owner:{
                text:"",
                value:""
            },
            name:"",
            scopes:[]
        },
        type:"",
        isLoading: false,
    }

    componentDidMount() { 
        this.loadData();
    }
    private setLoading(loading: boolean) {
      this.setState({ isLoading: loading });
  }
    loadData() {
      this.setLoading(true);  
        this.service.getSchemasById(this.props.match.params.id)
            .then((res: HttpResponse<SchemasDetailModel>) => {
              this.setLoading(false);
                if (res && res.result) {                           
                    this.setState({
                        result: res.result                        
                    },()=>{
                      if(this.state.result?.type==SchemasType.CUSTOM_CONNECTION)
                      {
                        this.setState({type:"Custom Connection"})
                      }
                      if(this.state.result?.type==SchemasType.EXTRACTOR)
                      {
                        this.setState({type:"Extractor"})
                      }
                      if(this.state.result?.type==SchemasType.REPORT)
                      {
                        this.setState({type:"Report"})
                      }
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.Schemas,this.props)
            });
    }
    render() {
        return (
            <Fragment>               
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Details for Schema &nbsp;'{this.state.type+" "+this.state.result?.id+" "+this.state.result?.schema_owner.text}'</h5>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                        {!this.state.isLoading &&  <>                            
                            <h5 className="mb-3">Basic Info</h5>
                            <div className="row">                            
                                {this.state.result?.type!=0 && <div className="col-6">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Type</b>
                                            </div>
                                            <div className="card-body">
                                              {this.state.type}                                                
                                            </div>
                                        </div>
                                    </div>}

                                    
                               <div className="col-6">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Schema Owner</b>
                                        </div>
                                         <div className="card-body">
                                         {RedirectBySourceType(this.state.result?.name,this.state.result?.schema_owner.text,this.state.result?.schema_owner.value)}
                                        </div>
                                    </div>
                                </div>                              
                                   
                               
                            </div>

                            <div className="row">    
                            {this.state.result?.dependent_reports.length!=0 && (
                    <div className="col-12">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header font-weight-bold">
                          Dependent Reports
                        </div>
                        <div className="card-body">
                          {this.state.result?.dependent_reports.map(
                            (item: SchemaOwnerModel, i, arr) => {
                              return (
                                <span key={item.value}>
                                  <NavLink
                                    className="badge badge-light m-1"
                                    to={"/report/detail/" + item.value}
                                  >
                                    <strong>{i + 1}:</strong> {item.text}
                                    {i != arr.length - 1 ? ", " : ""}
                                  </NavLink>
                                </span>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  </div>
                  <div className="row"> 
{this.state.result?.scopes.length!=0 && (
                    <div className="col-12">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header font-weight-bold">
                        Scopes
                        </div>
                        <div className="card-body">
                          {this.state.result?.scopes.map(
                            (item: SchemaOwnerModel, i, arr) => {
                              return (
                                <span key={item.value}>
                                  <NavLink
                                    className="badge badge-light m-1"
                                    to={"/scope/detail/" + item.value}
                                  >
                                    <strong>{i + 1}:</strong> {item.text}
                                    {i != arr.length - 1 ? ", " : ""}
                                  </NavLink>
                                </span>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                            </div>

                            </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />} 
                            
                        </div>
                    </div>
                </div>
            </div>            
        </Fragment>
        )
    }
}
 