import { HttpWrapper, AppConfig } from "../../../../../core";
import { PostExportModel } from "../../../../../shared/export/post.export.model";
import { SourceReportListResponseModel, SourceReportDetailModel, EditSourceReportModel } from "../models";

export class SourceReportService {
    private wrapper: HttpWrapper;
    constructor() {
        this.wrapper = new HttpWrapper();
    }

    getSourceReportsList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
        return this.wrapper.post<SourceReportListResponseModel>(AppConfig.apiEndpoint + '/v1/source_report/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }   

    getSourceReportsById(id: number) {
        return this.wrapper.get<SourceReportDetailModel>(AppConfig.apiEndpoint + '/v1/source_report/' + id);
    }

    
    exportSourceReports(exportData?: PostExportModel) {
        return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/source_report/export', exportData);
    }

    editSourceReport(id?: number) {
        return this.wrapper.get<EditSourceReportModel>(AppConfig.apiEndpoint + '/v1/source_report/edit/' + id);
    }

    updateSourceReport(sourceReport: EditSourceReportModel) {
        return this.wrapper.put<EditSourceReportModel>(AppConfig.apiEndpoint + '/v1/source_report/' + sourceReport.id, sourceReport);
    }

} 