
const iState = {
    currentRoute: "/",    
    currentPage: 1,
    filters: []
}

const reducer = (state = iState, action) => {
    switch (action.type) {
        case "change_route":
            return { ...state, currentRoute: action.payload }
        case "change_page":
            return { ...state, currentPage: action.payload }
        case "change_filters":
            return { ...state, filters: action.payload,currentPage:1 }
        default:
            return state
    }
};

export default reducer;