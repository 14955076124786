import { HttpWrapper, AppConfig } from "../../../../core";
import { PostExportModel } from "../../../../shared/export/post.export.model";
import { DeleteResponseModel, RerunCollectionMissionPostModel, RerunCollectionMissionResponseModel } from "../../../../shared/models";
import {ConnectionPostModel, EditConnectionModel, ConnectionListResponseModel,LinkResponseConnectionMaintain, ConnectionDetailModel, CollectionMissionsResponseListModel, BackupsResponseModel, DataEngineJobsListModel,LinkResourcesResponseModel, CollectionMissionPostModel, CancelCollectionMission, CollectionMissionResponseModel, VerifyCollectionMissionPostModel, VerifyCollectionMissionResponseModel, DeleteConnectionModel } from "../models";

export class ConnectionsService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getConnectionList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
    return this.wrapper.post<ConnectionListResponseModel>(AppConfig.apiEndpoint + '/v1/connections/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
  }

  getConnectionById(id: number) {
    return this.wrapper.get<ConnectionDetailModel>(AppConfig.apiEndpoint + '/v1/connections/' + id);
  }

  
  getCollectionMissionList(id: number, currentPage: number, pageSize: number) {
    return this.wrapper.get<CollectionMissionsResponseListModel>(AppConfig.apiEndpoint + '/v1/connections/' + id + '/collection_missions?current_page=' + currentPage + '&page_size=' + pageSize);
  }

    postConnection(Connection: ConnectionPostModel) {
      return this.wrapper.post<ConnectionPostModel>(AppConfig.apiEndpoint + '/v1/connections', Connection);
    }
  getDataEngineJobsById(id: number) {
    return this.wrapper.get<Array<DataEngineJobsListModel>>(AppConfig.apiEndpoint + '/v1/connections/' + id+'/data_engine_jobs');
  }

  geBackupsById(id: number, currentPage: number, pageSize: number) {
    return this.wrapper.get<BackupsResponseModel>(AppConfig.apiEndpoint + '/v1/connections/' + id+'/connection_archives?current_page=' + currentPage + '&page_size=' + pageSize);
  }

  //   postConnection(Connection: ConnectionPostModel) {
  //     return this.wrapper.post<ConnectionPostModel>(AppConfig.apiEndpoint + '/v1/connection', Connection);
  //   }

    editConnection(id?: number) {
      return this.wrapper.get<EditConnectionModel>(AppConfig.apiEndpoint + '/v1/connections/edit/' + id);
    }

    updateConnection(Connection: ConnectionPostModel) {
      return this.wrapper.put<ConnectionPostModel>(AppConfig.apiEndpoint + '/v1/connections/' + Connection.id, Connection);
    }

    getLinkedResourcesById(id: number) {
      return this.wrapper.get<LinkResourcesResponseModel>(AppConfig.apiEndpoint + '/v1/connections/' + id + '/linked_resources');
    }
    getLinkConnectionMaintainById(id: number) {
      return this.wrapper.get<LinkResponseConnectionMaintain>(AppConfig.apiEndpoint + '/v1/connections/' + id + '/maintain');
    }
    
    cancelCollectionMissionById(id: number, missionId?:number) {
      return this.wrapper.put<CancelCollectionMission>(AppConfig.apiEndpoint + '/v1/connections/'+ id +'/cancel_collection_missions?mission_id='+missionId,{});
    }

    deleteRecordById(data:DeleteConnectionModel, id:number) {
      return this.wrapper.delete<DeleteResponseModel>(AppConfig.apiEndpoint + '/v1/connections/'+ id,data);   
    }
 
    verifyConnectionData(data: VerifyCollectionMissionPostModel) { 
      return this.wrapper.post<VerifyCollectionMissionResponseModel>(AppConfig.apiEndpoint + '/v1/collection_missions/verify', data);
    }

    postCollectionMission(data: CollectionMissionPostModel) {
      return this.wrapper.post<CollectionMissionResponseModel>(AppConfig.apiEndpoint + '/v1/collection_missions/create', data);
    }

    rerunCollectionMissionById(data:RerunCollectionMissionPostModel) {
      return this.wrapper.post<RerunCollectionMissionResponseModel>(AppConfig.apiEndpoint + '/v1/collection_missions/rerun', data);
    }

  exportConnection(exportData?: PostExportModel) {
    return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/connection/export', exportData);
  }

}