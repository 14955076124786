import { HttpWrapper, AppConfig } from "../../../../../core";
import { PostExportModel } from "../../../../../shared/export/post.export.model";
import { PlanCategoriesListResponseModel, PlanCategoriesDetailModel, AddUpdatePlanCategoriesModel, PlanCategoriesDeleteResponseModel, PlanCategoriesDeleteModel } from "../models";



export class PlanCategoriesService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getPlanCategoriesList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
    return this.wrapper.post<PlanCategoriesListResponseModel>(AppConfig.apiEndpoint + '/v1/plan_categories/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
  }

  getPlanCategoriesById(id: number) {
    return this.wrapper.get<PlanCategoriesDetailModel>(AppConfig.apiEndpoint + '/v1/plan_categories/' + id);
  }  

  postPlanCategories(PlanCategories: AddUpdatePlanCategoriesModel) {
    return this.wrapper.post<AddUpdatePlanCategoriesModel>(AppConfig.apiEndpoint + '/v1/plan_categories', PlanCategories);
}

editPlanCategories(id?: number) {
    return this.wrapper.get<AddUpdatePlanCategoriesModel>(AppConfig.apiEndpoint + '/v1/plan_categories/edit/' + id);
}

updatePlanCategories(PlanCategories: AddUpdatePlanCategoriesModel) {
    return this.wrapper.put<AddUpdatePlanCategoriesModel>(AppConfig.apiEndpoint + '/v1/plan_categories/' + PlanCategories.id, PlanCategories);
}

getLinkedResourcesById(id: number) {
  return this.wrapper.get<PlanCategoriesDeleteModel>(AppConfig.apiEndpoint + '/v1/plan_categories/' + id+'/linked_resource');
}

deleteRecordById(id: number) {
  return this.wrapper.delete<PlanCategoriesDeleteResponseModel>(AppConfig.apiEndpoint + '/v1/plan_categories/' + id);
}

  exportPlanCategories(exportData?: PostExportModel) {
    return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/plan_categories/export', exportData);
  }


} 