import React, { Component, Fragment } from 'react'
import { InboundEmailAddressesPostModel, InboundEmailAddressesEditModel } from './models'
import { InboundEmailAddressesService } from './services/inbound.email.addresses.service';
import { HttpResponse } from '../../../../core';
import { Link, Route } from "react-router-dom";
import { ShowSuccessMessage, ShowException, HandleNotFoundResponse } from '../../../../shared/helpers';
import { ButtonType } from '../../../../shared/enums/button.enum'
import { Loader } from '../../../../shared/loaders';
import { InboundEmailAddressesTabs } from './InboundEmailAddressesTabs'
import { NotFoundResponseArea } from '../../../../shared/enums';
import InboundEmailAddressesList from './InboundEmailAddressesList';
import { CommonService } from '../../../../shared/services/common.service';
import { DropdownItemModel } from '../../../../shared/models';


interface IState {
    postInboundEmailAddresses?: InboundEmailAddressesPostModel,
    editInboudEmailAddresses?: InboundEmailAddressesEditModel,
    id?: number;
    to_address?: string;
    connection_id?: number;
    connection_type?: string;
    connection_name?: string;
    connectionError?: string,
    to_addressError?: string,
    isShowtext?: string;
    isSave?: string;
    isSaving?: boolean;
    searchData?: DropdownItemModel[]
}
export class InboundEmailAddressesForm extends Component<any, IState> {
    //declaring service type
    private InboundEmailAddressesService: InboundEmailAddressesService;
    private commonService: CommonService;
    constructor(props) {
        super(props)
        this.InboundEmailAddressesService = new InboundEmailAddressesService();
        this.state = this.initialState;
        this.commonService = new CommonService();
    }


    initialState: IState = {
        postInboundEmailAddresses:{
            id: 0,
            to_address: "",
            connection_id: 0,
            connection_type: ""
        },
        id: 0,
        to_address: "",
        connection_id: 0,
        connection_type: "",
        connection_name: "",
        connectionError: "",
        to_addressError:"",
        isShowtext: "New",
        isSave: "",
        isSaving: false,
        searchData:[]
    }
 
    componentDidMount() {
        if (this.props.match.params.id > 0) {
            this.setState({ id: this.props.match.params.id, isShowtext: "Edit" }, () => {
                this.loadData();
            });
        }
        document.addEventListener("mousedown", this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
      }

      handleClickOutside = (event) => {
        if (event.path[0].id !== "connection_name" && event.path[0].id !== "searchData" && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight) {
          this.setState(
            {
              searchData: [],
            },
            () => { }
          );
        }
       
      };

    getAllConnection(data:string) {
        if(this.state.connection_type == "CustomConnection"){
        this.commonService.getCustomConnectionsByName(data)
            .then((res: HttpResponse<DropdownItemModel[]>) => {
                if (res) {
                    this.setState({
                        searchData: res.result
                    });                    
                }               
            });
        }
        else{
            this.commonService.getConnectionsByName(data)
            .then((res: HttpResponse<DropdownItemModel[]>) => {
                if (res) {
                    this.setState({
                        searchData: res.result
                    });
                }
            });
        }
    }
  


    loadData() {
        this.InboundEmailAddressesService.editInboudEmailAddresses(this.props.match.params.id)
            .then((res: HttpResponse<InboundEmailAddressesEditModel>) => {
                if (res && res.result) {
                    this.setState({
                        id: res.result.id,
                        to_address: res.result.to_address,
                        connection_id: res.result.connection_id,
                        connection_type: res.result.connection_type,
                        connection_name: res.result.connection_name
                    },()=>{
                       
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.InboundEmailAddresses,this.props)
            });
    }

    postData(iea: InboundEmailAddressesPostModel) {
        this.setSavingFlag(true);
        this.InboundEmailAddressesService.postInboudEmailAddresses(iea)
            .then((res: HttpResponse<InboundEmailAddressesPostModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        postInboundEmailAddresses: res.result,
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Inboud Email Addresses successfully created.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/inbound-email-address/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                            this.setState(this.initialState);
                            this.props.history.push('/inbound-email-address/inbound-email-address-form/0');
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/inbound-email-address/inbound-email-address-form/' + this.state.postInboundEmailAddresses?.id);
                            this.setState({ isShowtext: "Edit" })
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });;
    }

    updateData(iea: InboundEmailAddressesPostModel) {
        this.setSavingFlag(true);
        this.InboundEmailAddressesService.updateInboudEmailAddresses(iea)
            .then((res: HttpResponse<InboundEmailAddressesPostModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        postInboundEmailAddresses: res.result
                    }, () => {
                        ShowSuccessMessage("Inboud Email Addresses Successfully Updated.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/inbound-email-address/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                            this.setState(this.initialState);
                            this.props.history.push('/inbound-email-address/inbound-email-address-form/0');
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/inbound-email-address/inbound-email-address-form/' + this.state.postInboundEmailAddresses?.id);
                            this.setState({ isShowtext: "Edit" })
                        }
                    });
                }
            }, () => {
                this.setSavingFlag(false);
            });
    }


  
 
    handleChange = (event: any) => {
       
        if (event.target.name == "connection_name") {
                this.getAllConnection(event.target.value);
          } 

          
        const isCheckbox = event.target.type === "checkbox";
        this.setState({
          [event.target.name]: isCheckbox
            ? event.target.checked
            : event.target.value,
        });

    if(event.target.name == "connection_type"){
        this.setState({
            searchData:[],
            connection_id:0,
            connection_name:""
        })
    }
      };
    
      

      selectedRoles(item) {
        this.setState({
            connection_id: item.value,
          connection_name: item.text,
          searchData: [],
        });
      }
    
      renderRoles = () => {
        if (this.state.searchData?.length === 0) {
          return null;
        }
        return (
          <ul className="list-unstyled auto-suggest">
            {this.state.searchData?.map((item) => (
              <li
                id="searchData"
                key={item.value}
                onClick={() => this.selectedRoles(item)}
              >
                {item.text}
              </li>
            ))}
          </ul>
        );
      };


    validate = () => {
        let connectionError = "";
        let to_addressError = "";
        if (!this.state.connection_name) {
            connectionError = "Connection can't be blank";
        }
        
        if (!this.state.to_address) {
            to_addressError = "To address is invalid and must be the domain (or subdomain of staqdata.com)";
        }

        if (connectionError || to_addressError) {
            this.setState({ 
                connectionError: connectionError,
                to_addressError:to_addressError
            })
            return false;
        }

        else {
            connectionError = "";
            to_addressError = "";
            this.setState({ connectionError: connectionError, 
                to_addressError:to_addressError
            })
            return true;
        }
    }

    handleSubmit = event => {
        event.preventDefault();
        const isValid = this.validate();
        let InboundEmailAddressesData: InboundEmailAddressesPostModel = {
            id: this.state.id,
            to_address:this.state.to_address,
            connection_id:this.state.connection_id,
            connection_type:this.state.connection_type
        };
        if (isValid) {
            if (InboundEmailAddressesData.id === 0) {
                this.postData(InboundEmailAddressesData);
            }
            else {
                this.updateData(InboundEmailAddressesData)
            }
        }
    }

    handleClick = (event) => {
        this.setState({ isSave: event.target.value })
    }
    private setSavingFlag(saving: boolean) {
        this.setState({ isSaving: saving });
    }
    // getConnectionId = (item) =>{
    //     this.setState({
    //         connection_id: item.value,
    //         searchData:[]
    //       });
          
    // }


    render() {
        
        return (
            <Fragment>
                <form onSubmit={this.handleSubmit}>
                    <input type="hidden" value={this.state.id} />
                    <div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h4>{this.state.isShowtext} Inbound Email Address<span> '{this.state.to_address}'</span></h4>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        {this.props.match.params.id != 0 && <InboundEmailAddressesTabs id={this.props.match.params.id} url="/inbound-email-address/inbound-email-address-form/" />}
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group p-3 shadow-sm">
                                                    <label>Connection<span className=" text-danger">*</span> </label>
                                                    <div className="row">
                                                        <div className="col">
                                                            <select className="form-control" name="connection_type" value={this.state.connection_type} onChange={this.handleChange} >
                                                                <option value="">Select</option>
                                                                <option value="Connection">Connection</option>
                                                                <option value="CustomConnection">Custom Connection</option>
                                                            </select>
                                                        </div>
                                                        <div className="col">
                                                        <input autoComplete="off" id="connection_name" type="text"    name="connection_name" value={this.state.connection_name} onChange={this.handleChange} disabled={this.state.connection_type ?  false : true} className={!this.state.connectionError ? 'form-control' : 'form-control  is-invalid'} />
                                                        
                                                         {this.renderRoles()}

                                                            {/* <input list="connection_name" autoComplete="off"   name="connection_name" value={this.state.connection_name} onChange={this.handleChange} disabled={this.state.connection_type ?  false : true} className={!this.state.connectionError ? 'form-control' : 'form-control  is-invalid'} />
                                                            {this.state.connection_type == "CustomConnection" && 
                                                            <datalist id="connection_name" >
                                                                {this.state.searchData?.map((item:any, index) =>{
                                                                   return <option key={index} value={item.text} onSelect={() => this.getConnectionId(item)} />
                                                                })}
                                                            </datalist>
                                                            }
                                                         {this.state.connection_type == "Connection" && 
                                                            <datalist id="connection_name" >
                                                                {this.state.searchData?.map((item:any, index) =>{
                                                                   return <option key={index} value={item.text} onSelect={() => this.getConnectionId(item)} />
                                                                })}
                                                            </datalist>
                                                            } */}
                                                             
                                                                <div className="invalid-feedback">
                                                            {this.state.connectionError}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <small>You should not normally change this. It gets set by the developer the first time the application code is pushed.</small>
                                                </div>
                                               
                                            </div>
                                       
                                            <div className="col-md-6">
                                                <div className="form-group p-3 shadow-sm">
                                                    <label>To Address<span className=" text-danger">*</span></label>
                                                    <input type="text" maxLength={255} name="to_address" pattern="[a-z0-9._%+-]+@[a-z0-9._%+-]+[staqdata]+\.[com]{2,3}"  value={this.state.to_address} onChange={this.handleChange} className={!this.state.to_addressError ? 'form-control' : 'form-control  is-invalid'} />
                                                    <div className="invalid-feedback">
                                                        {this.state.to_addressError}
                                                    </div>
                                                    <small>Email id Must be unique and Domain name must be staqdata.com</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="text-center mt-3 mb-4">
                            <Link type="button" className="btn btn-lg btn-default" to="/inbound-email-address/list">{ButtonType.Cancel}</Link>
                            {!this.state.isSaving && <Fragment>
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" onClick={this.handleClick} value={ButtonType.Save} />
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndAddAnother} onClick={this.handleClick} />
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndEdit} onClick={this.handleClick} />
                            </Fragment>
                            }
                            <Loader loading={this.state.isSaving} marginBottom="0px" marginTop="8px" ></Loader>
                        </div>
                    </div>
                </form>
                <Route path="/inbound-email-address/list" component={InboundEmailAddressesList} />
            </Fragment>
        )
    }
}



























