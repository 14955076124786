import React, { Component, Fragment } from 'react'
import { HttpResponse } from '../../../core';
import { CollectionMissionTaskResponseModel, RawaDataFiles } from './models'
import { CollectionMissionService } from './services/collection.mission.service';
import { NavLink } from "react-router-dom";
import { ConvertDateTime } from '../../../shared/helpers';
import { Loader } from '../../../shared/loaders';
import { FileDownloadService } from '../../../shared/services/file-download.service';

interface IState {
    result?: CollectionMissionTaskResponseModel;
    isLoading?: boolean;

}
export default class CollectionMissionTask extends Component<any, IState> {
    private service: CollectionMissionService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new CollectionMissionService();
    }
    initialState: Partial<IState> = {
        result: {
            task: {
                collection_mission_id: 0,
                created_at: null,
                datapoint_count: 0,
                duration: 0,
                earliest_data_at: null,
                exception_count: 0,
                id: 0,
                latest_data_at: null,
                message: "",
                name: "",
                num_attempts: 0,
                options: "",
                perform_in_seconds: 0,
                run_location: "",
                scheduled_at: null,
                success: false,
                auth_failure: false,
                job_id: "",
                load_keys: "",
                task_finished_at: null,
                task_started_at: null
            },
            files: []

        },
        isLoading: false,
    }
    componentDidMount() {
        this.loadData();
    }
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getCollectionMissionTasksDetail(this.props.match.params.id)
            .then((res: HttpResponse<CollectionMissionTaskResponseModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            });

    }
    // download(url: any, filename: any) {
    //     new FileDownloadService().downloadFileByStream(url, filename)
    // }
    render() {
        return (
            <Fragment>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5>Details for Collection mission task '{this.state.result?.task.name}&nbsp;{this.state.result?.task.id}&nbsp;(CCCollector)'</h5>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                {!this.state.isLoading && <>
                                    <h5 className="mb-3">Basic Info</h5>

                                    <div className="row">
                                        {this.state.result?.task.name && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Name</b>
                                                </div>
                                                <div className="card-body">
                                                {this.state.result?.task.name}&nbsp;{this.state.result?.task.id}&nbsp;(CCCollector)
                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.result?.task.collection_mission_id && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Collection Mission</b>
                                                </div>
                                                <div className="card-body">
                                                    <NavLink to={"/connection/collection-mission/" + this.state.result.task.collection_mission_id}>{this.state.result?.task.collection_mission_id}</NavLink>

                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.result?.task.job_id && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Sidekiq Job</b>
                                                </div>
                                                <div className="card-body">
                                                    <a href={"https://papertrailapp.com/groups/8012632/events?q=" + this.state.result?.task.job_id}>
                                                        Log for&nbsp;{this.state.result?.task.job_id}</a><br />
                                                    
                                                </div>
                                            </div>
                                        </div>}
                                        <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Perform In Seconds</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.task.perform_in_seconds}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        {this.state.result?.task.created_at && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Created At</b>
                                                </div>
                                                <div className="card-body">
                                                    {ConvertDateTime(this.state.result?.task.created_at, 'LLL')}
                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.result?.task.scheduled_at && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Scheduled  At</b>
                                                </div>
                                                <div className="card-body">
                                                    {ConvertDateTime(this.state.result?.task.scheduled_at, 'LLL')}
                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.result?.task.earliest_data_at && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Earliest Data   At</b>
                                                </div>
                                                <div className="card-body">
                                                    {ConvertDateTime(this.state.result?.task.earliest_data_at, 'LLL')}
                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.result?.task.latest_data_at && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Latest data   At</b>
                                                </div>
                                                <div className="card-body">
                                                    {ConvertDateTime(this.state.result?.task.latest_data_at, 'LLL')}
                                                </div>
                                            </div>
                                        </div>}

                                    </div>

                                    <div className="row">
                                        {this.state.result?.task.success && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Success</b>
                                                </div>
                                                <div className="card-body"> <i className="fas fa-check text-success"></i>   Yes</div>
                                            </div>
                                        </div>}

                                        {this.state.result?.task.message && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Message</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.task.message}
                                                </div>
                                            </div>
                                        </div>}

                                        <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Datapoint Count</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.task.datapoint_count}
                                                </div>
                                            </div>
                                        </div>

                                         <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Exception Count</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.task.exception_count}
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row">
                                         <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Duration</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.task.duration}
                                                </div>
                                            </div>
                                        </div>

                                        {this.state.result?.task.num_attempts && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Num Attempts</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.task.num_attempts}
                                                </div>
                                            </div>
                                        </div>}

                                        {this.state.result?.task.run_location && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Run Location</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.task.run_location}
                                                </div>
                                            </div>
                                        </div>}

                                        {this.state.result?.task.options && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Options</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.task.options}
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                </>}
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
