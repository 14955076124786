import React, { Component, Fragment } from 'react'
import { NavLink } from "react-router-dom";
import { IsAuthorize } from '../../../shared/authorization/check-access';
import { Role } from '../../../shared/authorization/enums';


interface IProps {
    id: number,
    url: string
}
export  class ConfigurationsTabs extends Component<IProps> {
    render() {
        return (
            <Fragment>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
            {IsAuthorize([Role.Dev]) &&<li className="nav-item">
                <NavLink to={"/configuration/configuration-form/" + this.props.id} className={this.props.url=="/configuration/configuration-form/"?"nav-link active":"nav-link"} id="show-tab" data-toggle="tab" href="#edit" role="tab"
                    aria-controls="edit" aria-selected={this.props.url=="/configuration/configuration-form/"?"true":"false"}><i className="far fa-edit"></i>&nbsp;Edit</NavLink>
            </li>}
            {IsAuthorize([Role.Dev]) && <li className="nav-item">
                    <NavLink to={"/configuration/delete/" + this.props.id} className={this.props.url == "/configuration/delete/" ? "nav-link active" : "nav-link"} id="show-tab" data-toggle="tab" href="#delete" role="tab"
                        aria-controls="delete" aria-selected={this.props.url == "/configuration/delete/" ? "true" : "false"}><i className="far fa-trash-alt"></i>&nbsp;Delete</NavLink>
                </li>}
                {IsAuthorize([Role.TAM, Role.Dev]) && <li className="nav-item">
                    <NavLink to={"/configuration/detail/" + this.props.id} className={this.props.url == "/configuration/detail/" ? "nav-link active" : "nav-link"} id="detail-tab" data-toggle="tab" href="#edit" role="tab"
                        aria-controls="detail" aria-selected={this.props.url == "/configuration/detail/" ? "true" : "false"}><i className="fas fa-info-circle"></i>&nbsp;Show</NavLink>
                </li>}
            </ul>
            <br />
        </Fragment>
        )
    }
}
