import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";

interface IProps {
  id: number;
  url: string;
  name?: string;
}
export class RoleTabs extends Component<IProps> {
  render() {
    return (
      <Fragment>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item">
            <NavLink
              to={"/role/role-form/" + this.props.id}
              className={
                this.props.url === "/role/role-form/"
                  ? "nav-link active"
                  : "nav-link"
              }
              id="edit-tab"
              data-toggle="tab"
              href="#edit"
              role="tab"
              aria-controls="edit"
              aria-selected={
                this.props.url === "/role/role-form/" ? "true" : "false"
              }
            >
              <i className="far fa-edit"></i>&nbsp;Edit
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
              to={"/role/detail/" + this.props.id}
              className={
                this.props.url === "/role/detail/"
                  ? "nav-link active"
                  : "nav-link"
              }
              id="show-tab"
              data-toggle="tab"
              href="#show"
              role="tab"
              aria-controls="show"
              aria-selected={
                this.props.url === "/role/detail/" ? "true" : "false"
              }
            >
              <i className="fas fa-info-circle"></i>&nbsp;Show
            </NavLink>
          </li>
        </ul>
        <br />
      </Fragment>
    );
  }
}
