import { HttpWrapper } from '../../../../core'
import { AppConfig } from '../../../../core/app.config'
import { ReportListResponseModel, ReportModel, EditReportModel, ReportDetailModel, ReportDefinitionModel, ViewReportDataResponseModel, ReportChangeHistoryListResponseModel, TableStorageSettingResponseModel, ReportTableStorageModel, ReportDataEngineJobsListeponseModel, ReportReadinessResponseModel, ReportDataEngineReportJobsListeponseModel, ReportBackupsResponseModel, DataEngineJobPostModel, ViewReportDataDisplayModel } from '../models';
import { PostExportModel } from '../../../../shared/export/post.export.model';
import { FileUploadResponseModel } from '../../../../shared/models';


export class UserReportsService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getUserReportsList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
    return this.wrapper.post<ReportListResponseModel>(AppConfig.apiEndpoint + '/v1/reports/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
  }

  editUserReport(id?: number) {
    return this.wrapper.get<EditReportModel>(AppConfig.apiEndpoint + '/v1/reports/edit/' + id);
  }

  updateUserReport(report: ReportModel) {
    return this.wrapper.put<ReportModel>(AppConfig.apiEndpoint + '/v1/reports/' + report.id, report);
  }

  getUserReportById(id: number) {
    return this.wrapper.get<ReportDetailModel>(AppConfig.apiEndpoint + '/v1/reports/' + id);
  }

  geReportDefinitionById(id: number) {
    return this.wrapper.get<ReportDefinitionModel>(AppConfig.apiEndpoint + '/v1/reports/report_definition/' + id);
  }

  geReportViewDataById(id: number, currentPage: number, pageSize: number, filters: any) {
    return this.wrapper.post<ViewReportDataResponseModel>(AppConfig.apiEndpoint + '/v1/reports/' + id+'/report_data?current_page=' + currentPage + '&page_size=' + pageSize, { filters: filters });
  }

  getReportChangeHistoryList(id: number, currentPage: number, pageSize: number) {
    return this.wrapper.get<ReportChangeHistoryListResponseModel>(AppConfig.apiEndpoint + '/v1/reports/' + id + '/report_change_history?current_page=' + currentPage + '&page_size=' + pageSize);
  }

  getReportDataEngineJobsList(id: number, currentPage: number, pageSize: number) {
    return this.wrapper.get<ReportDataEngineJobsListeponseModel>(AppConfig.apiEndpoint + '/v1/reports/' + id + '/data_engine_jobs?current_page=' + currentPage + '&page_size=' + pageSize);
  }
  getLatestReportDataEngineJobsList(id: number) {
    return this.wrapper.get<ViewReportDataDisplayModel>(AppConfig.apiEndpoint + '/v1/reports/' + id + '/data_engine_jobs/latest');
  }

  getReportDataEngineReportJobsList(id: number, currentPage: number, pageSize: number) {
    return this.wrapper.get<ReportDataEngineReportJobsListeponseModel>(AppConfig.apiEndpoint + '/v1/reports/' + id + '/data_engine_report_jobs?current_page=' + currentPage + '&page_size=' + pageSize);
  }

  geReportTableStorageSettingsById(id: number) {
    return this.wrapper.get<TableStorageSettingResponseModel>(AppConfig.apiEndpoint + '/v1/reports/' + id + '/report_table_storage');
  }

  geReportBackupsById(id: number, currentPage: number, pageSize: number) {
    return this.wrapper.get<ReportBackupsResponseModel>(AppConfig.apiEndpoint + '/v1/reports/report_archive/' + id + '?current_page=' + currentPage + '&page_size=' + pageSize);
  }

  updateReportTableStorageSettings(id: number, reportTableStorage: ReportTableStorageModel) {
    return this.wrapper.put<ReportTableStorageModel>(AppConfig.apiEndpoint + '/v1/reports/' + id + '/report_table_storage', reportTableStorage);
  }

  exportUserReport(exportData?: PostExportModel) {
    return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/reports/export', exportData);
  }


  getReportReadinessById(id: number) { 
    return this.wrapper.get<ReportReadinessResponseModel>('https://data-engine-api-staging.staqdata.com/report/'+id+'/readiness');
  }

  createDataEngineJob(data:DataEngineJobPostModel) {
    return this.wrapper.post<FileUploadResponseModel>(AppConfig.apiEndpoint + '/v1/reports/data_engine_job',data);
  }
}