import React, { Component } from 'react'
import { IFilterItemProps, OperatorList, FilterItemInputRenderType } from './models'
import { JobType } from '../enums';


interface IState {
    id: string,
    op: string,
    value: string
}
export class FilterItemJobType extends Component<IFilterItemProps, IState> {
    constructor(props) {
        super(props);
    }

    oprators = OperatorList.jobType;
    inputRenderType: any = FilterItemInputRenderType.NoBox;

    selectedOptionValue = this.oprators[0].operator;
    selectedValue = '';

    handleDropDownChange = (event: any) => {
        let selectedObject = this.oprators.find(x => x.name == event.target.value);
        this.selectedOptionValue = selectedObject != null ? selectedObject.operator : "";
        this.inputRenderType = selectedObject?.renderType;
        if (this.selectedOptionValue == 'is' || this.selectedOptionValue == 'isnot') {
            this.selectedValue = 'null';
        }
        if (this.selectedOptionValue === 'eq') {
            switch (selectedObject?.name) {
                case JobType.analyze:
                    this.selectedValue = JobType.analyze
                    break;
                case JobType.data_source:
                    this.selectedValue = JobType.data_source
                    break;
                case JobType.new_collection:
                    this.selectedValue = JobType.new_collection
                    break;
                case JobType.preview:
                    this.selectedValue = JobType.preview
                    break;
                    case JobType.scope_rebuild:
                    this.selectedValue = JobType.scope_rebuild
                    break;
            }

        }
        this.brodCastValue();        
    }

    brodCastValue = () => {
        this.setState({ id: this.props.column.columnName, op: this.selectedOptionValue, value: this.selectedValue });
        this.props.onValueChange({ column: this.props.column.columnName, op: this.selectedOptionValue, value: this.selectedValue });
    }
    render() {
        return (
            <div className="form-group mb-3">
                <label>{this.props.column.displayText}</label>
                <div className="row">
                    <div className="col-5">
                        <select className="form-control form-control-sm mb-1" name={this.props.column.columnName} onChange={this.handleDropDownChange}>
                            {this.oprators.map((num, index) => {
                                return (
                                    <option key={index} value={num.name} className='form-control'>{num.name}</option>
                                );
                            })}

                        </select>
                    </div>
                </div>
            </div>
        )
    }
}
