import React, { Component, Fragment } from 'react'
import Breadcrumbs from '../../shared/Breadcrumbs'
import { EmailLayoutsRoute } from '../../routing'

export  class EmailLayoutsPage extends Component {
    render() {
        return (
            <Fragment>
        <Breadcrumbs /> 
        <EmailLayoutsRoute />
      </Fragment>
        )
    }
}
