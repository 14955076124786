import React, { Component, Fragment } from 'react'
import { AccountSourceOverridesService } from '../account-source-overrides/services'
import { AccountSourceOverridesDetailModel } from './models';
import { HttpResponse } from '../../../core';
import { AccountSourceOverridesTabs } from './AccountSourceOverridesTabs';
import { NavLink } from "react-router-dom";
import { HandleNotFoundResponse } from '../../../shared/helpers';
import { NotFoundResponseArea } from '../../../shared/enums';
import { Loader } from '../../../shared/loaders';


interface IState {
    result?: AccountSourceOverridesDetailModel;
    isLoading?: boolean;
} 
export class AccountSourceOverridesDetail extends Component<any, IState> {
    private service: AccountSourceOverridesService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new AccountSourceOverridesService();
    }
    initialState: Partial<IState> = {
        result: {
            id: 0,
            account: {
                text: "",
                value: 0
            },
            source_report: {
                text: "",
                value: 0
            },
            connection: {
                text: "",
                value: 0
            },
            scope: {
                text: "",
                value: 0
            }
        },
        isLoading: false,
    }

    componentDidMount() {
        this.loadData();
    }
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getAccountSourceOverridesById(this.props.match.params.id)
            .then((res: HttpResponse<AccountSourceOverridesDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.AccountSourceOverrides,this.props)
            });
    }
    render() {
        return (
            <Fragment>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5>Details for Account Source Override 'AccountSourceOverride #{this.state.result?.id}'</h5>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <AccountSourceOverridesTabs id={this.props.match.params.id} url="/account-source-override/detail/" />
                                {!this.state.isLoading && <>  
                                <h5 className="mb-3">Basic Info</h5>
                                <div className="row">
                                    {this.state.result?.account && <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Account</b>
                                            </div>
                                            <div className="card-body">
                                                <NavLink to={"/account/detail/" + this.state.result?.account.value}>{this.state.result?.account.text}</NavLink>
                                            </div>
                                        </div>
                                    </div>}
                                    {this.state.result?.connection && <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Connection</b>
                                            </div>
                                            <div className="card-body">
                                            <NavLink to={"/connection/detail/" + this.state.result?.connection.value}>{this.state.result?.connection.text}</NavLink>
                                            </div>
                                        </div>
                                    </div>}
                                    {this.state.result?.scope && <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Scope</b>
                                            </div>
                                            <div className="card-body">
                                            <NavLink to={"/scope/detail/" + this.state.result?.scope.value}>{this.state.result?.scope.text}</NavLink>
                                            </div>
                                        </div>
                                    </div>}
                                    {this.state.result?.source_report && <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Source Report</b>
                                            </div>
                                            <div className="card-body">
                                               <NavLink to={"/source-report/detail/" + this.state.result?.source_report.value}>{this.state.result?.source_report.text}</NavLink>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                                </>}                 
                        {this.state.isLoading && <Loader loading={this.state.isLoading} />}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
