import React, { Component, Fragment } from 'react'
import { NavLink } from "react-router-dom";
import { IsAuthorize } from '../../shared/authorization/check-access';
import { Role } from '../../shared/authorization/enums';

interface IProps {
    id: number,
    url: string
}
export class UserTabs extends Component<IProps> {
    render() {
        return (
            <Fragment>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    {IsAuthorize([Role.TAM, Role.Dev, Role.AM, Role.Support]) && <li className="nav-item">
                        <NavLink to={"/user/user-form/" + this.props.id} className={this.props.url == "/user/user-form/" ? "nav-link active" : "nav-link"} id="edit-tab" data-toggle="tab" href="#edit" role="tab"
                            aria-controls="edit" aria-selected={this.props.url == "/user/user-form/" ? "true" : "false"}><i className="far fa-edit"></i>&nbsp;Edit</NavLink>
                    </li>}
                    {IsAuthorize([Role.Sales, Role.AM, Role.TAM, Role.Dev, Role.Support ,Role.Manager]) && <li className="nav-item">
                        <NavLink to={"/user/detail/" + this.props.id} className={this.props.url == "/user/detail/" ? "nav-link active" : "nav-link"} id="show-tab" data-toggle="tab" href="#show" role="tab"
                            aria-controls="show" aria-selected={this.props.url == "/user/detail/" ? "true" : "false"}><i className="fas fa-info-circle"></i>&nbsp;Show</NavLink>
                    </li>}
                </ul>
                <br />
            </Fragment>
        )
    }
}
