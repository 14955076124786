
export enum Role { 
    SuperAdmin = "Super Admin",
    Admin = "Admin",
    Sales="Sales",
    AM = "AM",
    TAM="TAM",
    Dev="Dev",
    Support = "Support",
    Manager = "Manager"
}