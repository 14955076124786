import React, { Component, Fragment } from "react";
import { ExtractorRateLimitService } from "./services/extractor.rate.limit.service";
import { EditExtractorRateLimitModel } from "./models/edit.extractor.rate.limit.model";
import { HttpResponse } from "../../../../../../core";
import {
  HandleNotFoundResponse,
  ShowSuccessMessage,
} from "../../../../../../shared/helpers";
import {
  NotFoundResponseArea,
  ButtonType,
} from "../../../../../../shared/enums";
import { PostExtractorRateLimitModel } from "./models";
import { ExtractorRateLimitsTabs } from "./ExtractorRateLimitsTabs";
import { Link } from "react-router-dom";
import { Loader } from "../../../../../../shared/loaders";
import { DropdownItemModel } from "../../../../../../shared/models";
import { CommonService } from "../../../../../../shared/services/common.service";
import { Modal } from "react-bootstrap";
import { ExtractorFormPopup } from "../ExtractorFornPopup";
import { IsAuthorize } from "../../../../../../shared/authorization/check-access";
import { Role } from "../../../../../../shared/authorization/enums";

interface IState {
  id?: number;
  extractor_id?: number;
  threshold?: number;
  interval_seconds?: number;
  description?: string;
  isShowtext?: string;
  searchText?: string;
  isSave?: string; 
  isSaving?: boolean;
  extraction_error?: string;
  threshold_error?:string;
  interval_seconds_error?: string;
  extractor_name_list?: DropdownItemModel[];
  extractor_name?: string;
  showExtractorForm?: boolean;
  isNew?: boolean;
  isChildUpdate?: boolean;
}

export class ExtractorRateLimitForm extends Component<any, IState> {
  private service: ExtractorRateLimitService;
  private commonService: CommonService;
  private networkCall: any;

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.service = new ExtractorRateLimitService();
    this.commonService = new CommonService();
    this.updateExtractors = this.updateExtractors.bind(this)
  }

  //Initial State to get the data for the edit functionality from the API
  initialState: Partial<IState> = {
    id: 0,
    extractor_id: 0,
    threshold: 0,
    interval_seconds: 0,
    description: "",
    isShowtext: "New",
    searchText: "",
    isSave: "",
    isSaving: false,
    extraction_error: "",
    threshold_error: "",
    interval_seconds_error: "",
    extractor_name_list: [],
    extractor_name: "",
    showExtractorForm: false,
    isNew: false,
    isChildUpdate: false
  };


  updateExtractors(isChildUpdate, name, id) {
    this.setState({
      isChildUpdate: isChildUpdate,
      extractor_name: name,
      extractor_id: id,
    });
  }

  handleExtractorClose = () => {
    this.setState({ showExtractorForm: false }, () => {});
  };

  handleShowExtractor = () => {
    this.setState({ showExtractorForm: true, isNew: true }, () => {});
  };

  handleShowEditExtractor = () => {
    this.setState(
      { showExtractorForm: true, isNew: false },
      () => {}
    );
  };
  // To the load the initial data coming from API for the Edit functionality.
  componentDidMount() {
    if (Number(this.props.match.params.id) > 0) {
      this.setState(
        { id: this.props.match.params.id, isShowtext: "Edit" },
        () => {
          this.loadData();
        }
      );
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (event.path[0].id !== "extractor_name" && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight) {
      this.setState(
        {
          extractor_name_list: [],
        },
        () => {}
      );
    }
  };

  private getExtractorByName(text: string) {
    this.commonService
      .getExtractorByName(text)
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res) {
          this.setState({
            extractor_name_list: res.result,
          });
        }
      });
  }

  // Function for loading the data in the intial phase.
  loadData() {
    this.setSavingFlag(true);
    this.service
      .editExtractorRateLimit(this.props.match.params.id)
      .then((res: HttpResponse<EditExtractorRateLimitModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState({
            id: res.result?.id,
            extractor_id:
              res.result?.extractor_id === null ? 0 : res.result?.extractor_id,
              extractor_name:
              res.result?.extractor_name === null ? "" : res.result?.extractor_name,
            threshold:
              res.result?.threshold === null ? 0 : res.result?.threshold,
            interval_seconds:
              res.result?.interval_seconds === null ? 0 : res.result?.interval_seconds,
            description:
              res.result?.description === null ? "" : res.result?.description,
          });
        }
      })
      .catch((ex) => {
        HandleNotFoundResponse(
          ex,
          NotFoundResponseArea.Extractor_Rate_Limit,
          this.props
        );
      });
  }

  validate = () => {
    let extraction_error = "";
    let threshold_error = ""
    let interval_seconds_error = ""
    if (!this.state.extractor_id && this.state.isSave) {
      extraction_error = "Extractor Cant be blank";
    }
    if(this.state.threshold == 0 && this.state.isSave){
      threshold_error = "Thresold should be greater then 0"
    }
    if(this.state.interval_seconds == 0 && this.state.isSave){
      interval_seconds_error = "Interval seconds should be greater then 0"
    }
    if (extraction_error || threshold_error ||interval_seconds_error) {
      this.setState({
        extraction_error: extraction_error,
        threshold_error: threshold_error,
        interval_seconds_error: interval_seconds_error,
      });
      return false;
    } else {
      extraction_error = "";
      threshold_error=""
      interval_seconds_error=""
      this.setState({
        extraction_error: extraction_error,
        threshold_error: threshold_error,
        interval_seconds_error: interval_seconds_error,
      });
      return true;
    }
  };

  onInstalledApplicationChange = (e) => {
    const value = e.target.value;
    if (value.length > 0) {
      if (this.networkCall) {
        clearTimeout(this.networkCall);
      }
      this.networkCall = setTimeout(() => {
        this.getExtractorByName(value);
      }, 600);
    }
    this.setState({
      extractor_id: value === "" ? 0 : value,
      extractor_name: value,
      extraction_error: "",
    });
  };

  handleChange = (event: any) => {
    if (event.target.name == "extractor_name") {
      this.onInstalledApplicationChange(event);
    }
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        this.validate();
      }
    );
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if(!this.state.isChildUpdate){
    const isValid = this.validate();
    let payload = {
      id: this.state.id,
      extractor_id: this.state.extractor_id,
      threshold: this.state.threshold,
      interval_seconds: this.state.interval_seconds,
      description: this.state.description,
    };
    if (isValid) {
      if (payload.id === 0 && this.state.extractor_id !== 0) {
        this.postData(payload);
      } else {
        this.updateData(payload);
      }
    }
  }
  };

  postData(extractor: PostExtractorRateLimitModel) {
    this.setSavingFlag(true);
    this.service.postExtractorRateLimit(extractor).then(
      (res: HttpResponse<PostExtractorRateLimitModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              id: res.result.id,
            },
            () => {
              ShowSuccessMessage("Extractor rate limit successfully created.");
              if (this.state.isSave === ButtonType.Save) {
                this.props.history.push("/extractor-rate-limit/detail/"+ this.state.id);
                this.setState(this.initialState);
              }
              if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                this.setState(this.initialState);
                this.props.history.push(
                  "/extractor-rate-limit/extractor-rate-limit-form/0"
                );
              }
              if (this.state.isSave === ButtonType.SaveAndEdit) {
                this.props.history.push(
                  "/extractor-rate-limit/extractor-rate-limit-form/" +
                    this.state.id
                );
                this.setState({ isShowtext: "Edit" });
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  //Function to update the data
  updateData(extractor: PostExtractorRateLimitModel) {
    this.setSavingFlag(true);
    this.service.updateExtractorRateLimit(extractor).then(
      (res: HttpResponse<PostExtractorRateLimitModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              id: res.result.id,
            },
            () => {
              //Showing the message along with changing the route according to the button clicked.
              ShowSuccessMessage("Extractor rate limit successfully updated.");
              if (this.state.isSave === ButtonType.Save) {
                this.props.history.push("/extractor-rate-limit/detail/"+ this.state.id);
                this.setState(this.initialState);
              }
              if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                this.setState(this.initialState);
                this.props.history.push(
                  "/extractor-rate-limit/extractor-rate-limit-form/0"
                );
              }
              if (this.state.isSave === ButtonType.SaveAndEdit) {
                this.props.history.push(
                  "/extractor-rate-limit/extractor-rate-limit-form/" +
                    this.state.id
                );
                this.setState({ isShowtext: "Edit" });
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  selectedAccount(item) {
    this.setState({
      extractor_id: item.value,
      extractor_name: item.text,
      extractor_name_list: [],
    });
  }

  // Function to check the type of save functionality , in our case save and save & Edit.
  handleClick = (event) => {
    this.setState({ isSave: event.target.value,isChildUpdate:false })
  };

  private setSavingFlag(saving: boolean) {
    this.setState({ isSaving: saving });
  }

  renderExtractors() {
    if (this.state.extractor_name_list?.length === 0) {
      return null;
    }
    return (
      <ul className="list-unstyled auto-suggest">
        {this.state.extractor_name_list?.map((item, index) => (
          <li
            id="extractor_name"
            key={index}
            onClick={() => this.selectedAccount(item)}
          >
            {item.text}
          </li>
        ))}
      </ul>
    );
  }

  render() {
    return (
      <Fragment>
        <form onSubmit={this.handleSubmit}>
          <input type="hidden" value={this.state.id} />
          <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5>
                {this.state.isShowtext} Extractor Rate Limit
                {this.state.isShowtext === "Edit" && (
                  <span> 'ExtractorRateLimit #{this.state.id}'</span>
                )}
              </h5>
            </div>
            <Modal
              backdrop="static"
              keyboard={false}
              size="lg"
              show={this.state.showExtractorForm}
              onHide={this.handleExtractorClose}
            >
              <ExtractorFormPopup
                updateExtractors={
                  this.updateExtractors
                }
                handleClose={this.handleExtractorClose}
                isNew={this.state.isNew}
                id={this.state?.extractor_id}
              />
            </Modal>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {Number(this.props.match.params.id) !== 0 && (
                      <ExtractorRateLimitsTabs
                        id={this.props.match.params.id}
                        url="/extractor-rate-limit/extractor-rate-limit-form/"
                      />
                    )}
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            Extractor<span className=" text-danger">*</span>
                          </label>
                          <div className="input-group ">
                            <input
                              list="extractor_name"
                              autoComplete="off"
                              id="extractor_name"
                              name="extractor_name"
                              type="text"
                              onChange={this.handleChange}
                              value={this.state.extractor_name}
                              className={
                                !this.state.extraction_error
                                  ? "form-control"
                                  : "form-control  is-invalid"
                              }
                            />
                            {this.renderExtractors()}
                            {IsAuthorize([Role.Dev]) &&<div className="input-group-append">
                              <button type="button" className="btn btn-success" onClick={this.handleShowExtractor}>
                                <i
                                  className="fas fa-plus"
                                  style={{ color: "white" }}
                                ></i>
                              </button>
                              <button
                                type="button"
                                className={`btn btn-primary`}
                                disabled={this.state.extractor_id == 0}
                                onClick={this.handleShowEditExtractor}
                              >
                                <i className="far fa-edit"></i>
                              </button>
                            </div>}
                            <div className="invalid-feedback">
                              {this.state.extraction_error}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Threshold</label>
                          <div className="input-group ">
                          <input
                            type="number"
                            maxLength={255}
                            name="threshold"
                            className={
                              !this.state.threshold_error
                                ? "form-control"
                                : "form-control  is-invalid"
                            }
                            value={this.state.threshold}
                            onChange={this.handleChange}
                          />
                          <div className="invalid-feedback">
                              {this.state.threshold_error}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Interval seconds</label>
                          <div className="input-group ">
                          <input
                            type="number"
                            maxLength={255}
                            name="interval_seconds"
                            className={
                              !this.state.interval_seconds_error
                                ? "form-control"
                                : "form-control  is-invalid"
                            }
                            value={this.state.interval_seconds}
                            onChange={this.handleChange}
                          />
                          <div className="invalid-feedback">
                              {this.state.interval_seconds_error}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Description</label>
                          <textarea
                            rows={10}
                            name="description"
                            value={this.state.description}
                            onChange={this.handleChange}
                            className="form-control"
                            style={{ height: "160px" }}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-3 mb-4">
              {!this.state.isSaving && (
                <Fragment>
                  <Link
                    type="button"
                    className="btn btn-lg btn-default"
                    to="/extractor-rate-limit/list"
                  >
                    {ButtonType.Cancel}
                  </Link>
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    onClick={this.handleClick}
                    value={ButtonType.Save}
                  />
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    value={ButtonType.SaveAndAddAnother}
                    onClick={this.handleClick}
                  />
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    value={ButtonType.SaveAndEdit}
                    onClick={this.handleClick}
                  />
                </Fragment>
              )}
              <Loader
                loading={this.state.isSaving}
                marginBottom="0px"
                marginTop="8px"
                float="center"
                width="368px"
              ></Loader>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}
