import { HttpWrapper, AppConfig } from '../../../../../../core'
import { PostExportModel } from "../../../../../../shared/export/post.export.model";
import { DataEngineJobListResponseModel, DataEngineJobDetailModel, EditPostDataEngineJobModel, DataEngineJobStateTransitionResponseModel } from '../models';

export class DataEgineJobService {
    private wrapper: HttpWrapper;
    constructor() {
      this.wrapper = new HttpWrapper();
    }
  
    getDataEgineJobList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
      return this.wrapper.post<DataEngineJobListResponseModel>(AppConfig.apiEndpoint + '/v1/data_engine_jobs/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }
  
    getDataEgineJobById(id: number) {
      return this.wrapper.get<DataEngineJobDetailModel>(AppConfig.apiEndpoint + '/v1/data_engine_jobs/' + id);
    }   
    
    edittDataEgineJobB(id: number) {
      return this.wrapper.get<EditPostDataEngineJobModel>(AppConfig.apiEndpoint + '/v1/data_engine_jobs/edit/' + id);
    }
  
    updatetDataEgineJobB(data: EditPostDataEngineJobModel) {
      return this.wrapper.put<EditPostDataEngineJobModel>(AppConfig.apiEndpoint + '/v1/data_engine_jobs/' + data.id, data);
    }
      
  
    exportDataEgineJob(exportData?: PostExportModel) {
      return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/data_engine_jobs/export', exportData);
    }

    getDataEgineJobStateTransition(id: number) {
      return this.wrapper.get<DataEngineJobStateTransitionResponseModel>(AppConfig.apiEndpoint + '/v1/data_engine_job_state_transitions/' + id);
    }  
  
  
  }