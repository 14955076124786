import { HttpWrapper, HttpResponse } from '../../core';
import { AppConfig } from '../../core/app.config';
import { DropdownItemModel } from '../models/dropdown-item.model';
import { AdminTypeModel, ClearSemaphoreModel, ClearSemaphoreResponseModel, FlaggleItems, ParamaResponseModel, ParamsVersionPostModel } from '../models';
import { DatabaseEngineIdResultsModel } from '../models/database.engine.id.model';

export class CommonService {
    private wrapper: HttpWrapper;
    constructor() {
        this.wrapper = new HttpWrapper();

    }

    getRolesByName(text: string) {
        return this.wrapper.get<DropdownItemModel[]>(AppConfig.apiEndpoint + '/v1/roles/search?q=' + text);
    }

    getAccountsByName(text: string) {
        return this.wrapper.get<DropdownItemModel[]>(AppConfig.apiEndpoint + '/v1/accounts/search?q=' + text);
    }

    getApplicationsByName(text: string) {
        return this.wrapper.get<DropdownItemModel[]>(AppConfig.apiEndpoint + '/v1/applications/search?q=' + text);
    }

    getReportsByName(text: string) {
        return this.wrapper.get<DropdownItemModel[]>(AppConfig.apiEndpoint + '/v1/reports/search?q=' + text);
    }

    getAllAdmin() {
        return this.wrapper.get<AdminTypeModel[]>(AppConfig.apiEndpoint + '/v1/admins/all');
    }
    getAllDBEID () {
        return this.wrapper.get<DatabaseEngineIdResultsModel>(AppConfig.apiEndpoint + '/v1/database_engines/list');
    }
    getflaggableObjectsType() {
        return this.wrapper.get<FlaggleItems>(AppConfig.apiEndpoint + '/v1/feature_flags/flaggable_objects');
    }

    getAllCategories() {
        return this.wrapper.get<DropdownItemModel[]>(AppConfig.apiEndpoint + '/v1/platforms/get_all_categories');
    } 

    getAllExtractors() {
        return this.wrapper.get<DropdownItemModel[]>(AppConfig.apiEndpoint + '/v1/platforms/get_all_extractors');
    }

    getAllPlatforms() {
        return this.wrapper.get<DropdownItemModel[]>(AppConfig.apiEndpoint + '/v1/platforms/get_all_platforms');
    }

    getInstalledApplicatonByName(text: string) {
        return this.wrapper.get<DropdownItemModel[]>(AppConfig.apiEndpoint + '/v1/installed_report_application/get_installed_application_by_name?q=' + text);
    }

    getExtractorByName(text: string) {
        return this.wrapper.get<DropdownItemModel[]>(AppConfig.apiEndpoint + '/v1/extractors/get_extractor_by_name?q=' + text);
    }

    getCategoriesByName(text: string) {
        return this.wrapper.get<DropdownItemModel[]>(AppConfig.apiEndpoint + '/v1/subscription_categories/get_categories_by_name?q=' + text);
    }

    getEmailTemplatesByName(text: string) {
        return this.wrapper.get<DropdownItemModel[]>(AppConfig.apiEndpoint + '/v1/email_templates/get_email_template_by_name?q=' + text);
    }

    getConnectionsByName(text: string) {
        return this.wrapper.get<DropdownItemModel[]>(AppConfig.apiEndpoint + '/v1/connections/search?q=' + text);
    }
    getCustomConnectionsByName(text: string) {
        return this.wrapper.get<DropdownItemModel[]>(AppConfig.apiEndpoint + '/v1/custom_connections/search?q=' + text);
    }

    getSourceReportByName(text: string) {
        return this.wrapper.get<DropdownItemModel[]>(AppConfig.apiEndpoint + '/v1/source_report/search?q=' + text);
    }

    getScopByName(text: string) {
        return this.wrapper.get<DropdownItemModel[]>(AppConfig.apiEndpoint + '/v1/scopes/search?q=' + text);
    }

    getPlatformByName(text: string) {
        return this.wrapper.get<DropdownItemModel[]>(AppConfig.apiEndpoint + '/v1/platforms/search?q=' + text);
    }

    getCustomExtractionSchedulingRecipeByName(id?: number) {
        return this.wrapper.get<DropdownItemModel[]>(AppConfig.apiEndpoint + '/v1/extraction_scheduling_recipes/search?q=' + id);
    }

    getDataSourceById(id?: number) {
        return this.wrapper.get<DropdownItemModel[]>(AppConfig.apiEndpoint + '/v1/data_sources/search?q=' + id);
    }

    getExtractionEngineByName(text: string) {
        return this.wrapper.get<DropdownItemModel[]>(AppConfig.apiEndpoint + '/v1/extraction_engines/search?q=' + text);
    }


    getClassificationByName(text: string) {
        return this.wrapper.get<DropdownItemModel[]>(AppConfig.apiEndpoint + '/v1/field_classifications/search?q=' + text);
    }

    getEmailLayoutsByName(text: string) {
        return this.wrapper.get<DropdownItemModel[]>(AppConfig.apiEndpoint + '/v1/email_layouts/search?q=' + text);
    }
    getTagLocationCategoriesByName(text: string) {
        return this.wrapper.get<DropdownItemModel[]>(AppConfig.apiEndpoint + '/v1/tag_location_categories/search?q=' + text);
    }

    changeParamsByVersion(data: ParamsVersionPostModel) {
        return this.wrapper.post<ParamaResponseModel>(AppConfig.apiEndpoint + '/v1/versions/connection_params',data);
    }

    clearSemaphore(data: ClearSemaphoreModel) {
        return this.wrapper.post<ClearSemaphoreResponseModel>(AppConfig.apiEndpoint + '/v1/semaphore_clearances', data);
      }

}
