import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { Role } from "../../../shared/authorization/enums";
import { GetUserInfo } from "../../../shared/helpers";

interface IProps {
  id: number;
  url: string;
  name?: string;
  role?: string;
}

interface IState{
    showEditButton?: boolean
}
export class StaqContactTabs extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
        showEditButton: true
    }
  }

  componentDidMount(){
      this.checkRoles();
  }

  checkPriority(current_role, current_id, role) {
    if (current_id == this.props.id) {
      return true;
    } else {
      if (current_role.toLowerCase() === Role.Admin.toLowerCase()) {
          if (role ==  Role.SuperAdmin.toLowerCase() || role ==  Role.Admin.toLowerCase()) {
            return false;
          }
          return true;
        }else if(current_role.toLowerCase() === Role.SuperAdmin.toLowerCase()){
          return true
        }else {
          return false;
        }
    }
  }

  checkRoles() {
    const roleList = GetUserInfo().role;
    const userRole = roleList != null ? roleList[0] : null;
    const userId = GetUserInfo().id;
    this.checkPriority(userRole,userId, this.props.role)
      ? this.setState({ showEditButton: true })
      : this.setState({ showEditButton: false });
  }

  render() {
    return (
      <Fragment>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          {this.state.showEditButton && (
            <li className="nav-item">
              <NavLink
                to={"/admin/admin-form/" + this.props.id}
                className={
                  this.props.url == "/admin/admin-form/"
                    ? "nav-link active"
                    : "nav-link"
                }
                id="edit-tab"
                data-toggle="tab"
                href="#edit"
                role="tab"
                aria-controls="edit"
                aria-selected={
                  this.props.url == "/admin/admin-form/"
                    ? "true"
                    : "false"
                }
              >
                <i className="far fa-edit"></i>&nbsp;Edit
              </NavLink>
            </li>
          )}
          <li className="nav-item">
            <NavLink
              to={"/admin/detail/" + this.props.id}
              className={
                this.props.url == "/admin/detail/"
                  ? "nav-link active"
                  : "nav-link"
              }
              id="show-tab"
              data-toggle="tab"
              href="#show"
              role="tab"
              aria-controls="show"
              aria-selected={
                this.props.url == "/admin/detail/" ? "true" : "false"
              }
            >
              <i className="fas fa-info-circle"></i>&nbsp;Show
            </NavLink>
          </li>
        </ul>
        <br />
      </Fragment>
    );
  }
}
