import React, { Component, Fragment } from 'react'
import { ExtractorTimeZoneSpecificationsService } from './services/extractor.time.zone.specifications.service';
import { HandleNotFoundResponse, ConvertDateTime } from '../../../../../../shared/helpers';
import { NotFoundResponseArea} from '../../../../../../shared/enums';
import { HttpResponse } from '../../../../../../core';
import {Link} from 'react-router-dom'

import { ExtractorTimeZoneSpecificationsDetailModel } from './models/extractor.time.zone.specifications.detail.model';
import { Loader } from '../../../../../../shared/loaders';

interface IState {
    result?: ExtractorTimeZoneSpecificationsDetailModel;
    parsedData?: string;
    isLoading?: boolean;
}
export class ExtractorTimeZoneSpecificationsDetail extends Component<any, IState> {
    private service: ExtractorTimeZoneSpecificationsService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new ExtractorTimeZoneSpecificationsService();
    }

    initialState: Partial<IState> = {
        result: {             
          id: 0,        
          style:"",
          extractor_id: 0,
          extractor_name: "",
          global_default: "",
          staq_notes: "",
          instructions: "",
          configurable_choices: [],
          created_at:new Date(),
          updated_at:new Date(),
        },
        isLoading: false,
    }

    componentDidMount() { 
        this.loadData();
    }

    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getExtractorTimeZoneSpecificationsById(this.props.match.params.id)
            .then((res: HttpResponse<ExtractorTimeZoneSpecificationsDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {                                
                    this.setState({
                        result: res.result                        
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.Extractor_Time_Zone_Specifications,this.props)
            });
    }
    render() {
        return (
            <Fragment>               
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Details for Extractor Time Zone Specifications &nbsp;'ExtractorTimeZoneSpecification #{this.state.result?.id}'</h5>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">    
                        {!this.state.isLoading &&  <>                          
                            <h5 className="mb-3">Basic Info</h5>
                            <div className="row">                            
                                {this.state.result?.extractor_name && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Extractor</b>
                                            </div>
                                            <div className="card-body">
                                            <Link to={"/extractor/detail/"+ this.state.result?.extractor_id}>{this.state.result?.extractor_name}</Link>
                                            </div>
                                        </div>
                                </div>}
                                {this.state.result?.style && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Style</b>
                                            </div>
                                            <div className="card-body" style={{textTransform: 'capitalize'}}>
                                              {this.state.result?.style}
                                            </div>
                                        </div>
                                </div>}
                                {this.state.result?.global_default && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Global default</b>
                                            </div>
                                            <div className="card-body">
                                              {this.state.result?.global_default}
                                            </div>
                                        </div>
                                </div>}
                                {this.state.result?.staq_notes && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Staq notes</b>
                                            </div>
                                            <div className="card-body">
                                              {this.state.result?.staq_notes}
                                            </div>
                                        </div>
                                </div>}
                                {this.state.result?.instructions && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Instructions</b>
                                            </div>
                                            <div className="card-body">
                                              {this.state.result?.instructions}
                                            </div>
                                        </div>
                                </div>}
                                {this.state.result?.created_at && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Created at</b>
                                            </div>
                                            <div className="card-body">
                                            {ConvertDateTime(this.state.result?.created_at, 'LLL')}
                                            </div>
                                        </div>
                                </div>}
                                {this.state.result?.updated_at && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Updated at</b>
                                            </div>
                                            <div className="card-body">
                                            {ConvertDateTime(this.state.result?.updated_at, 'LLL')}
                                            </div>
                                        </div>
                                </div>}   
                                </div>
                                <div className="row">
                                {this.state.result?.configurable_choices && this.state.result?.configurable_choices.length > 0 && <div className="col-12">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Configurable choices</b>
                                            </div>
                                            <div className="card-body">
                                              <ol className="d-flex flex-wrap">
                                              {this.state.result?.configurable_choices.map(item => (
                                                <li key={item} className="col-2">{item}</li>
                                              ))}
                                              </ol>
                                            </div>
                                        </div>
                                </div>}                        
                            </div>
                            </>}                 
                        {this.state.isLoading && <Loader loading={this.state.isLoading} />}         
                        </div>
                    </div>
                </div>
            </div>            
        </Fragment>
        )
    }
}
 