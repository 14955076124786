import React, { Component, Fragment, createRef } from 'react'

import { ProtectedOperationsService } from './services';
import { HttpResponse } from '../../../core';
import { ShowSuccessMessage, HandleNotFoundResponse } from '../../../shared/helpers';
import { ButtonType } from '../../../shared/enums/button.enum';
import { Link } from "react-router-dom";
import { Loader } from '../../../shared/loaders';
import { ProtectedOperationsTabs } from './ProtectedOperationsTabs';
import { NotFoundResponseArea } from '../../../shared/enums';
import { AddUpdateProtectedOperationsModel } from './models';

interface IState {
    editProtectedOperations?: AddUpdateProtectedOperationsModel,
    id?: number;
    record_type?:string;
    operation?:string;
    staq_notes?:string; 
    recordTypeError?: string,
    operationError?:string,
    isShowtext?: string;
    isSave?: string;
    isSaving?: boolean;
}
export class ProtectedOperationsForm extends Component<any, IState> {
    private service: ProtectedOperationsService;
    private recordTypeRef: any;
    private operationRef: any;

    constructor(props) {
        super(props)
        this.service = new ProtectedOperationsService();
        this.state = this.initialState;
        this.recordTypeRef = createRef();
        this.operationRef = createRef();
    }

    initialState: Partial<IState> = {
        editProtectedOperations: {           
            id: 0,            
            operation:"",
            record_type:"",
            staq_notes:""
            
        },        
        id: 0,        
        isShowtext: "New",
        isSave: "",
        isSaving: false,
        operation:"",
            record_type:"",
            staq_notes:"",
            recordTypeError:"",
            operationError:""    
        
    }

    componentDidMount() {
        if (this.props.match.params.id > 0) {
            this.setState({ id: this.props.match.params.id, isShowtext: "Edit" }, () => {
                this.loadData();
            });
        }
    }

    loadData() {
        this.service.editProtectedOperations(this.props.match.params.id)
            .then((res: HttpResponse<AddUpdateProtectedOperationsModel>) => {
                if (res && res.result) {
                    this.setState({                       
                        id:res.result?.id,
                        record_type:res.result?.record_type,
                        operation:res.result?.operation,
                        staq_notes:res.result?.staq_notes                        
                    }, () => {
                        
                     });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.ProtectedOperation,this.props) 
            });
    }


    updateData(data: AddUpdateProtectedOperationsModel) {
        this.setSavingFlag(true);
        this.service.updateProtectedOperations(data)
            .then((res: HttpResponse<AddUpdateProtectedOperationsModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Protected Operations successfully updated.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/protected-operation/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                            this.setState(this.initialState);
                            this.props.history.push('/protected-operation/protected-operation-form/0');
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/protected-operation/protected-operation-form/' + this.state.id);
                            this.setState({ isShowtext: "Edit" })
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });
    }


    postData(data: AddUpdateProtectedOperationsModel) {
        this.setSavingFlag(true);
        this.service.postProtectedOperations(data)
            .then((res: HttpResponse<AddUpdateProtectedOperationsModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({                        
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Protected Operations successfully created.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/protected-operation/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                            this.setState(this.initialState);
                            this.props.history.push('/protected-operation/protected-operation-form/0');
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/protected-operation/protected-operation-form/' + this.state.id);
                            this.setState({ isShowtext: "Edit" })
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });;
    }


    handleChange = (event: any) => {
        const isCheckbox = event.target.type === "checkbox";
        this.setState({
            [event.target.name]: isCheckbox
                ? event.target.checked
                : event.target.value
        })
        if (this.recordTypeRef.current.value) {
            this.setState({ recordTypeError: "" });
          }
          if (this.operationRef.current.value) {
            this.setState({ operationError: "" });
          }
    }

    validate = () => {
        let recordTypeError = "";  
        let operationError ="";
        let operationvel = new RegExp(/^[a-z\-]+$/g).test(this.state.operation!);

        if (!this.recordTypeRef.current.value) {
            recordTypeError = "Record type can't be blank";
        }
        if (!this.operationRef.current.value) {
            operationError = "Operation can't be blank and must be snake-cased (e.g. no capital letters or spaces)";
        }
        if (this.operationRef.current.value && !operationvel) {
            operationError = "Operation must be snake-cased (e.g. no capital letters or spaces)";
          }
        if (recordTypeError || operationError) {
            this.setState({ recordTypeError: recordTypeError, operationError:operationError })
            return false;
        }
        if (!recordTypeError || !operationError) {
            recordTypeError = "";
            operationError="";
            this.setState({ recordTypeError: recordTypeError, operationError:operationError})
            return true;
        }

    }

    handleSubmit = event => {
        event.preventDefault();
        const isValid = this.validate();
        let data: AddUpdateProtectedOperationsModel = {                        
            id: this.state.id,
            record_type:this.state.record_type,
            operation:this.state.operation,
            staq_notes:this.state.staq_notes            
        };
        if (isValid) {
            if (data.id == 0) {
                this.postData(data);
            }
            else
            {
        this.updateData(data);
            }
        }
    }

    handleClick = (event) => {
        this.setState({ isSave: event.target.value })
    }
    private setSavingFlag(saving: boolean) {
        this.setState({ isSaving: saving });
    }

    render() {
        return (
            <Fragment>
            <form onSubmit={this.handleSubmit}>
                <input type="hidden" value={this.state.id} />
                <div>
                <div className="d-flex justify-content-between align-items-center mb-2">
                            <h5>{this.state.isShowtext} Protected Operation '{this.state.record_type}:&nbsp;{this.state.operation}'</h5>
                        </div>
                    <div className="row">
                        <div className="col-md-12 col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                {this.props.match.params.id != 0 && <ProtectedOperationsTabs id={this.props.match.params.id} url="/protected-operation/ProtectedOperations-form/" />}
                                    <div className="row mt-4">
                                        <div className="col-md-6">
                                            <div className="form-group shadow-sm p-3 mb-4 ">
                                                <label>Record Type<span className="text-danger">*</span></label>
                                                <div className="custom-control custom-checkbox">
                                                <input type="text" maxLength={255}  ref={this.recordTypeRef} name="record_type" value={this.state.record_type} onChange={this.handleChange} className= {!this.state.recordTypeError ? 'form-control' : 'form-control  is-invalid'}/>
                                                <div className="invalid-feedback">
                                                        {this.state.recordTypeError}
                                                    </div>
                                                    <small>Note there has to be a related policy in StaqAuthorization. DO NOT CHANGE THIS UNLESS YOU KNOW WHAT YOU'RE DOING!</small>
                                                </div>
                                            </div>                                          
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group shadow-sm p-3 mb-4 ">
                                                <label>Operation<span className="text-danger">*</span></label>
                                                <div className="custom-control custom-checkbox">
                                                <input type="text" maxLength={255}  ref={this.operationRef} name="operation" value={this.state.operation} onChange={this.handleChange} className= {!this.state.operationError ? 'form-control' : 'form-control  is-invalid'}/>
                                                <div className="invalid-feedback">
                                                        {this.state.operationError}
                                                    </div>
                                                    <small>Note there has to be a related predicate on the record type's policy in StaqAuthorization. DO NOT CHANGE THIS UNLESS YOU KNOW WHAT YOU'RE DOING!</small>
                                                </div>
                                            </div>                                          
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                    <div className="col-md-12">
                                <div className="form-group shadow-sm p-3 mb-4">
                                  <label>Staq Notes</label>
                                  <textarea
                                    rows={10}
                                    name="staq_notes"
                                    value={this.state.staq_notes}
                                    onChange={this.handleChange}
                                    className="form-control" style={{'height':'160px'}}
                                  ></textarea>     
                                  <small>For internal use</small>                             
                                </div>
                              </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-3 mb-4">
                        <Link type="button" className="btn btn-lg btn-default" to="/protected-operation/list">{ButtonType.Cancel}</Link>
                        {!this.state.isSaving && <Fragment>
                            <input type="submit" className="btn btn-lg btn-primary  ml-3" onClick={this.handleClick} value={ButtonType.Save} />      
                            <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndAddAnother} onClick={this.handleClick} />                      
                            <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndEdit} onClick={this.handleClick} />
                        </Fragment>
                        }
                        <Loader loading={this.state.isSaving} marginBottom="0px" marginTop="8px" width="368px" ></Loader>
                    </div>
                </div>
            </form>
        </Fragment>
        )
    }
}
