import { HttpWrapper, AppConfig } from '../../../../core'
import { LinkResourcesResponseModel, FileUploadModel, FileUploadResponseModel } from '../../../../shared/models';
import { PostExportModel } from "../../../../shared/export/post.export.model";
import { DatabaseEngineListResponseModel, DatabaseEngineDetailModel } from '../models';
export class DatabaseEngineService {
    private wrapper: HttpWrapper;
    constructor() {
      this.wrapper = new HttpWrapper();
    }
  
    getDatabaseEngineList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
      return this.wrapper.post<DatabaseEngineListResponseModel>(AppConfig.apiEndpoint + '/v1/database_engines/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }
  
    getDatabaseEngineById(id: number) {
      return this.wrapper.get<DatabaseEngineDetailModel>(AppConfig.apiEndpoint + '/v1/database_engines/' + id);
    }     
      
  
    exportDatabaseEngine(exportData?: PostExportModel) {
      return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/database_engines/export', exportData);
    }
  
  
  }