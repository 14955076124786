import { HttpWrapper, AppConfig } from '../../../../../core'
import { SubscriptionCategoryListResponseModel,SubscriptionCategoryDetailModel,PostSubscriptionCategoryModel,EditSubscriptionCategoryModel } from '../models';
import { PostExportModel } from "../../../../../shared/export/post.export.model";
import { DeleteResponseModel } from '../../../../../shared/models';
import { SubscriptionCategoryLinkResourcesResponseModel } from '../models/subscription.category,link.resource.responses.model';

export class SubscriptionCategoryService {
    private wrapper: HttpWrapper;

    constructor() {
      this.wrapper = new HttpWrapper();
    }
  
    getList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
      return this.wrapper.post<SubscriptionCategoryListResponseModel>(AppConfig.apiEndpoint + '/v1/subscription_categories/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }
  
    getSubscriptionCategoryById(id: number) {
      return this.wrapper.get<SubscriptionCategoryDetailModel>(AppConfig.apiEndpoint + '/v1/subscription_categories/' + id);
    }
  
    postSubscriptionCategory(payload: PostSubscriptionCategoryModel) {
      return this.wrapper.post<PostSubscriptionCategoryModel>(AppConfig.apiEndpoint + '/v1/subscription_categories', payload);
    }
  
    editSubscriptionCategory(id: number) {
      return this.wrapper.get<EditSubscriptionCategoryModel>(AppConfig.apiEndpoint + '/v1/subscription_categories/edit/' + id);
    }

    getLinkedResourcesById(id: number) {
        return this.wrapper.get<SubscriptionCategoryLinkResourcesResponseModel>(AppConfig.apiEndpoint + '/v1/subscription_categories/' + id + '/linked_resources');
      }
  
    deleteSubscriptionCategoryById(id: number) {
      return this.wrapper.delete<DeleteResponseModel>(AppConfig.apiEndpoint + '/v1/subscription_categories/' + id);
    }
  
    updateSubscriptionCategory(subscription: PostSubscriptionCategoryModel) {
      return this.wrapper.put<PostSubscriptionCategoryModel>(AppConfig.apiEndpoint + '/v1/subscription_categories/' + subscription.id, subscription);
    }
    
    exportSubscriptionCategory(exportData?: PostExportModel) {
      return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/subscription_categories/export', exportData);
    }
  
  
  }