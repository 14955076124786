import React, { Component, Fragment } from 'react'
import Breadcrumbs from '../../shared/Breadcrumbs'
import { ReportDataSourceFiltersRoute } from '../../routing'

export  class ReportDataSourceFilterPage extends Component {
    render() {
        return (
            <Fragment>
                <Breadcrumbs />
                <ReportDataSourceFiltersRoute />
            </Fragment>
        )
    }
}
