import React, { Component, Fragment } from "react";
import { CategoryService } from "./services/category.service";
import { EditCategoryModel } from "./models/edit.category.model";
import { HttpResponse } from "../../../core";
import {
  HandleNotFoundResponse,
  ShowSuccessMessage,
} from "../../../shared/helpers";
import { NotFoundResponseArea, ButtonType } from "../../../shared/enums";
import { PostCategoryModel } from "./models";
import { CategoryTabs } from "./CategoryTabs";
import { Link } from "react-router-dom";
import { Loader } from "../../../shared/loaders";
import { DropdownItemModel, CategoryTypeModel } from "../../../shared/models";
import { CommonService } from "../../../shared/services/common.service";
import { Modal } from "react-bootstrap";
import { PlatformFormPopup } from "../platforms/PlatformFormPopup";
import { Role } from "../../../shared/authorization/enums";
import { IsAuthorize } from '../../../shared/authorization/check-access';

interface IState {
  id?: number;
  name?: string;
  platforms_list?: DropdownItemModel[];
  platforms?: Array<CategoryTypeModel>
  unassigned_platforms?: Array<CategoryTypeModel>
  isShowtext?: string;
  searchText?: string;
  isSave?: string;
  isSaving?: boolean;
  name_error?: string;
  searchList?: DropdownItemModel[];
  showPlatformForm?: boolean;
  isNew?: boolean;
  isChildUpdate?: boolean;
  platform_id?: number;
  platformName?: string
}

export class CategoryForm extends Component<any, IState> {
  private service: CategoryService;
  private commonService: CommonService;

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.service = new CategoryService();
    this.commonService = new CommonService();
    this.updatePlatform = this.updatePlatform.bind(this);
  }

  //Initial State to get the data for the edit functionality from the API
  initialState: Partial<IState> = {
    id: 0,
    name: "",
    platforms_list: [],
    platforms: [],
    unassigned_platforms: [],
    isShowtext: "New",
    searchText: "",
    isSave: "",
    isSaving: false,
    name_error: "",
    searchList: [],
    showPlatformForm: false,
    isNew: false,
    isChildUpdate: false,
    platform_id: 0,
    platformName: ""
  };

  updatePlatform(isChildUpdate, name, id) {
    this.setState({
      isChildUpdate: isChildUpdate,
      platformName: name,
      platform_id: id, 
    });
    if(id!=0 && name!="")  
    {
      this.getAssignedPlatforms(id, name);
    }
  }

  getAssignedPlatforms(id,name) {
    this.commonService
      .getAllPlatforms()
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res) {
          this.setState({
            unassigned_platforms: res.result            
          },()=>{
            this.setPlatform(id,name)
          });
        }
      });
  }

  setPlatform(value?:number, name?:string)
  {   
    this.state.platforms?.push({
      text: name,
      value: value      
    });
    this.setState({
      unassigned_platforms: this.state.unassigned_platforms?.filter(
        (p) => p.value != value
      ),
      searchList: this.state.searchList?.filter(
        (p) => p.value != value
      ),
      platforms: this.state.platforms,
    });
  }

  handleShowPlatform = () => {
    this.setState({isChildUpdate:false, showPlatformForm: true, isNew: true }, () => {});
  };

  handlePlatformClose = () => {
    this.setState({ showPlatformForm: false }, () => {});
  };


  // To the load the initial data coming from API for the Edit functionality.
  componentDidMount() {
    if (Number(this.props.match.params.id) > 0) {
      this.setState(
        { id: this.props.match.params.id, isShowtext: "Edit" },
        () => {
          this.loadData();
        }
      );
    }
    if (Number(this.props.match.params.id) === 0) {
      this.getAllPlatforms();
    }
  }

  getAllPlatforms() {
    this.commonService
      .getAllPlatforms()
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res) {
          this.setState({
            unassigned_platforms: res.result,
            searchList: res.result            
          });
        }
      });
  }

 

  // Function for loading the data in the intial phase.
  loadData() {
    this.setSavingFlag(true);
    this.service
      .editCategory(this.props.match.params.id)
      .then((res: HttpResponse<EditCategoryModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState({
            id: res.result?.id,
            name: res.result?.name === null ? "" : res.result?.name,
            platforms: res.result?.platforms,
            unassigned_platforms: res.result?.unassigned_platforms,
            searchList: res.result.unassigned_platforms,
          });
        }
      })
      .catch((ex) => {
        HandleNotFoundResponse(
          ex,
          NotFoundResponseArea.Category,
          this.props
        );
      });
  }

  handleChange = (event: any) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        this.handleSearch(name, value);
        this.validate()
      }
    );
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if(!this.state.isChildUpdate){
    const isValid = this.validate();
    let payload = {
      id: this.state.id,
      name: this.state.name,
      platform_ids: this.state.platforms,
    };
    if (isValid) {
    if (payload.id === 0) {
      this.postData(payload);
    } else {
      this.updateData(payload);
    }
  }
}
  };

  validate = () => {
    let name_error = "";

    if (!this.state.name && this.state.isSave) {
      name_error =
        "Name cannot be blank";
    }

    if (name_error) {
      this.setState({
        name_error: name_error,
      });
      return false;
    }

    if (!name_error) {
      name_error = "";
      this.setState({
        name_error: name_error,
      });
      return true;
    }
  };

  postData(category: PostCategoryModel) {
    this.setSavingFlag(true);
    this.service.postCategory(category).then(
      (res: HttpResponse<PostCategoryModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              id: res.result.id,
            },
            () => {
              ShowSuccessMessage("Category successfully created.");
              if (this.state.isSave === ButtonType.Save) {
                this.props.history.push("/category/detail/"+ this.state.id);
                this.setState(this.initialState);
              }
              if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                this.setState(this.initialState);
                this.props.history.push(
                  "/category/category-form/0"
                );
                this.getAllPlatforms();
              }
              if (this.state.isSave === ButtonType.SaveAndEdit) {
                this.props.history.push(
                  "/category/category-form/" +
                    this.state.id
                );
                this.setState({ isShowtext: "Edit" });
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  //Function to update the data
  updateData(category: PostCategoryModel) {
    this.setSavingFlag(true);
    this.service.updateCategory(category).then(
      (res: HttpResponse<PostCategoryModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              id: res.result.id,
            },
            () => {
              //Showing the message along with changing the route according to the button clicked.
              ShowSuccessMessage("Category successfully updated.");
              if (this.state.isSave === ButtonType.Save) {
                this.props.history.push("/category/detail/"+ this.state.id);
                this.setState(this.initialState);
              }
              if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                this.setState(this.initialState);
                this.props.history.push(
                  "/category/category-form/0"
                );
                this.getAllPlatforms();
              }
              if (this.state.isSave === ButtonType.SaveAndEdit) {
                this.props.history.push(
                  "/category/category-form/" +
                    this.state.id
                );
                this.setState({ isShowtext: "Edit" });
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  // Function to check the type of save functionality , in our case save and save & Edit.
  handleClick = (event) => {
    this.setState({ isSave: event.target.value,isChildUpdate:false })
  };

  private setSavingFlag(saving: boolean) {
    this.setState({ isSaving: saving });
  }

  handleSearch(name, value) {
    if (name === "searchText") {
      let list = this.state.unassigned_platforms;
      list = list?.filter((p) =>
        p.text?.toLowerCase().startsWith(value.toLowerCase())
      );

      if (value) {
        this.setState({ unassigned_platforms: list });
      } else {
        this.setState({ unassigned_platforms: this.state.searchList });
      }
    }
  }

  onLeftSelectionChange(e: any) {
    let data = this.state.unassigned_platforms?.find(
      (p) => p.value === e.target.value
    );
    this.state.platforms?.push({
      text: data?.text,
      value: data?.value,
      isChecked: data?.isChecked,
    });
    this.setState({
      unassigned_platforms: this.state.unassigned_platforms?.filter(
        (p) => p.value !== e.target.value
      ),
      searchList: this.state.searchList?.filter(
        (p) => p.value !== e.target.value
      ),
      platforms: this.state.platforms,
    });
  }

  selectAll() {
    this.state.unassigned_platforms?.forEach((p) => {
      this.state.platforms?.push(p);
    });
    this.setState({
      unassigned_platforms: [],
      platforms: this.state.platforms,
    });
  }

  onRightSelectionChange(e: any) {
    let data = this.state.platforms?.find((p) => p.value === e.target.value);
    this.state.unassigned_platforms?.push({
      text: data?.text,
      value: data?.value,
      isChecked: data?.isChecked,
    });
    this.setState({
      platforms: this.state.platforms?.filter(
        (p) => p.value !== e.target.value
      ),
      unassigned_platforms: this.state.unassigned_platforms,
    });
  }

  clearAll() {
    this.state.platforms?.forEach((p) => {
      this.state.unassigned_platforms?.push(p);
    });
    this.setState({
      platforms: [],
      unassigned_platforms: this.state.unassigned_platforms,
    });
  }

  render() {
    return (
      <Fragment>
        <form onSubmit={this.handleSubmit}>
          <input type="hidden" value={this.state.id} />
          <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5>
                {this.state.isShowtext} Category
                {this.state.isShowtext === "Edit" && (
                  <span> '{this.state.name}'</span>
                )}
              </h5>
            </div>
            <Modal
              backdrop="static"
              keyboard={false}
              size="lg"
              show={this.state.showPlatformForm}
              onHide={this.handlePlatformClose}
            >
              <PlatformFormPopup
                updatePlatform={this.updatePlatform}
                handleClose={this.handlePlatformClose}
                isNew={this.state.isNew}
                id={this.state?.platform_id}
              />
            </Modal>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {Number(this.props.match.params.id) !== 0 && (
                      <CategoryTabs
                        id={this.props.match.params.id}
                        url="/category/category-form/"
                      />
                    )}
                    <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                          <label>Name<span className=" text-danger">*</span></label>
                          <div className="input-group">
                          <input
                            type="text"
                            maxLength={255}
                            name="name"
                            className={
                                !this.state.name_error
                                  ? "form-control"
                                  : "form-control  is-invalid"
                              }
                            value={this.state.name}
                            onChange={this.handleChange}
                          />
                          <div className="invalid-feedback">
                              {this.state.name_error}
                            </div>
                          </div>
                        </div>
                        </div>
                      </div>
                      <div className="row">
                      <div className="col-md-4">
                        <div className="form-group  shadow-sm p-3 mb-0">
                          <div className="d-flex justify-content-between mb-2">
                          <label>Platforms</label>
                          {IsAuthorize([Role.Dev, Role.AM, Role.TAM, Role.Support, Role.Manager]) &&  <button type="button" className="btn btn-success" onClick={this.handleShowPlatform}><i className="fas fa-plus" style={{ color: "white" }}></i></button>}
                          </div>
                          <input
                            type="text"
                            name="searchText"
                            value={this.state.searchText}
                            placeholder="Search"
                            onChange={this.handleChange}
                            className="form-control form-control-sm"
                          />
                        </div>
                        <div className="row">
                          <div
                            className="col-md-6"
                            style={{ paddingRight: "0px" }}
                          >
                            <div className="form-group customScrollBar shadow-sm p-3 mb-2">
                              {this.state.unassigned_platforms?.map(
                                (item, index) => {
                                  return (
                                    <div key={index}>
                                      <div>
                                        <input
                                          type="checkbox"
                                          style={{ display: "none" }}
                                          value={item.value || ""}
                                          checked={item.isChecked || false}
                                          onChange={this.onLeftSelectionChange.bind(
                                            this
                                          )}
                                          id={"checkbox" + item.value}
                                          name={"checkbox" + item.value}
                                        />
                                        <label
                                          htmlFor={"checkbox" + item.value}
                                        >
                                          {item.text}
                                        </label>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                            <button
                              type="button"
                              onClick={this.selectAll.bind(this)}
                              className="btn btn-default btn-sm mr-2"
                            >
                              Choose All{" "}
                              <i className="fas fa-chevron-circle-right"></i>
                            </button>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group customScrollBar  shadow-sm p-3 mb-2">
                              {this.state.platforms?.map((item, index) => {
                                return (
                                  <div key={index}>
                                    <div>
                                      <input
                                        style={{ display: "none" }}
                                        type="checkbox"
                                        value={item.value || ""}
                                        checked={item.isChecked || false}
                                        onChange={this.onRightSelectionChange.bind(
                                          this
                                        )}
                                        id={"checkbox" + item.value}
                                        name={"checkbox" + item.value}
                                      />
                                      <label htmlFor={"checkbox" + item.value}>
                                        {item.text}
                                      </label>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            <button
                              type="button"
                              onClick={this.clearAll.bind(this)}
                              className="btn btn-default btn-sm"
                            >
                              <i className="fas fa-chevron-circle-left"></i>{" "}
                              Clear All
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-3 mb-4">
              {!this.state.isSaving && (
                <Fragment>
                  <Link
                    type="button"
                    className="btn btn-lg btn-default"
                    to="/category/list"
                  >
                    {ButtonType.Cancel}
                  </Link>
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    onClick={this.handleClick}
                    value={ButtonType.Save}
                  />
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    value={ButtonType.SaveAndAddAnother}
                    onClick={this.handleClick}
                  />
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    value={ButtonType.SaveAndEdit}
                    onClick={this.handleClick}
                  />
                </Fragment>
              )}
              <Loader
                loading={this.state.isSaving}
                marginBottom="0px"
                marginTop="8px"
                float="center"
                width="368px"
              ></Loader>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}
