import React, { Component, Fragment } from 'react'
import { ProtectedOperationsService } from './services';
import { HandleNotFoundResponse, RedirectBySourceType, ConvertDateTime } from '../../../shared/helpers';
import { NotFoundResponseArea, SourceType } from '../../../shared/enums';
import { HttpResponse } from '../../../core';
import { NavLink } from "react-router-dom";

import { ProtectedOperationsDetailModel } from './models';
import { ProtectedOperationsTabs } from './ProtectedOperationsTabs';
import { DropdownItemModel } from '../../../shared/models';
import { Loader } from '../../../shared/loaders';

interface IState {
    result?: ProtectedOperationsDetailModel;
    parsedData?: string;
    isLoading?: boolean;
}
export class ProtectedOperationsDetail extends Component<any, IState> {
    private service: ProtectedOperationsService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new ProtectedOperationsService();
    }

    initialState: Partial<IState> = {
        result: {             
            id:0,           
            record_type:"",
            operation:"",
            staq_notes:""
        },
        isLoading: false,
    }

    componentDidMount() { 
        this.loadData();
    }
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getProtectedOperationsById(this.props.match.params.id)
            .then((res: HttpResponse<ProtectedOperationsDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {                                                       
                    this.setState({
                        result: res.result                        
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.ProtectedOperation,this.props)
            });
    }
    render() {
        return (
            <Fragment>               
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Details for Protected Operation &nbsp;'{this.state.result?.record_type}:&nbsp;{this.state.result?.operation}'</h5>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">  
                        <ProtectedOperationsTabs id={this.props.match.params.id} url="/protected-operation/detail/" />  
                        {!this.state.isLoading &&  <>                             
                            <h5 className="mb-3">Basic Info</h5>
                            <div className="row">                                      
                            {this.state.result?.record_type && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Record Type</b>
                                            </div>
                                            <div className="card-body">
                                            {this.state.result?.record_type}
                                            </div>
                                        </div>
                                    </div>} 

                                     {this.state.result?.operation && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Operation</b>
                                            </div>
                                            <div className="card-body">
                                            {this.state.result?.operation}
                                            </div>
                                        </div>
                                    </div>} 

                                     {this.state.result?.staq_notes && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Staq Notes</b>
                                            </div>
                                            <div className="card-body">
                                            {this.state.result?.staq_notes}
                                            </div>
                                        </div>
                                    </div>} 
                                   
                            </div>                        
                            </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}   
                        </div>
                    </div>
                </div>
            </div>            
        </Fragment>
        )
    }
}
 