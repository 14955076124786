import React, { Component, Fragment } from 'react'
import { ApplicationsDetailModel, ApplicationsListModel } from './models'
import { ApplicationsService } from './services/applications.service';
import { HttpResponse } from '../../../core';
import { HandleNotFoundResponse, ConvertDateTime } from '../../../shared/helpers';
import { NotFoundResponseArea } from '../../../shared/enums';
import { ApplicationsTabs } from './ApplicationsTabs';
import { Editor } from '../../../shared';
import { DropdownItemModel } from '../../../shared/models';
import { Link } from "react-router-dom";
import { Loader } from '../../../shared/loaders';

interface IState {
    result?: ApplicationsDetailModel;
    isLoading?: boolean;
}

export class ApplicationsDetail extends Component<any, IState> {
    private service: ApplicationsService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new ApplicationsService();
    }

    // Defines the initial state for the values coming from API.
    initialState: Partial<IState> = {
        result: {
             id: 0,
             name: "",
             type: "",
             enabled: true,
             created_at:  new Date(),
             updated_at:  new Date(),
             last_pushed_by: "",
             installed_report_applications:[],
             code:"",
             public:true
        },
        isLoading: false,
    }

    //Get the detail from the API whenever the page loads.
    componentDidMount() {
        this.loadData();
        
    }

    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    //function to load the initial view data coming from the API.
    loadData() {
        this.setLoading(true);
        this.service.getApplicationsById(this.props.match.params.id)
            .then((res: HttpResponse<ApplicationsDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                  
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex, NotFoundResponseArea.Applications, this.props)
               
            });
    }

    render() {
        return (
            <Fragment>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5>Details for Applications '{this.state.result?.name}'</h5>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <ApplicationsTabs id={this.props.match.params.id} name={this.state.result?.name} url="/application/detail/" />
                                {!this.state.isLoading &&  <>  
                                <h5 className="mb-3">Basic Info</h5>
                                <div className="row">
                                    {this.state.result?.name &&
                                        <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Name</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.name}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {this.state.result?.type &&
                                        <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Type</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.type}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {this.state.result?.enabled &&
                                        <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Enabled</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.enabled && <i className="fas fa-check text-success text1" title="✓"></i>}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {this.state.result?.last_pushed_by &&
                                        <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Last Pushed By</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.last_pushed_by}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {this.state.result?.created_at &&
                                        <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Created at</b>
                                                </div>
                                                <div className="card-body">
                                                    {ConvertDateTime( this.state.result?.created_at, 'LLL')}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {this.state.result?.updated_at &&
                                        <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Updated At</b>
                                                </div>
                                                <div className="card-body">
                                                {ConvertDateTime( this.state.result?.updated_at, 'LLL')}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {this.state.result?.installed_report_applications.length != 0 &&
                                        <div className="col-12">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Installed Report Applications</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.installed_report_applications.map((item:DropdownItemModel, index) =>{
                                                        return (
                                                          <span key={index}>  <Link to={"/installed-report-application/detail/" + item.value} className="badge badge-light m-1">{item.text}</Link></span>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    }   
                                </div>
 

                                <div className="row">
                                <div className="col-md-8">
                                <div className="form-group">
                                    <h5>Code</h5>
                                    <Editor
                                        StringData={
                                        this.state.result?.code == null
                                            ? ""
                                            : this.state.result?.code
                                        }
                                        FormateType="yaml"
                                    />
                                    </div>
                                </div>
                                </div>
                                </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}   
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
