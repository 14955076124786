import React, { Component, Fragment } from "react";
import Breadcrumbs from "../../shared/Breadcrumbs";
import { RolesRoute } from "../../routing/IndividualRoutes";

export class RolesPage extends Component {
  render() {
    return (
      <Fragment>
        <Breadcrumbs />
        <RolesRoute />
      </Fragment>
    );
  }
}
