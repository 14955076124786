export const GetAllTimeZone = 
[
    { value: "Hawaii", text: "(GMT-10:00) Hawaii"},
    { value: "Alaska", text: "(GMT-09:00) Alaska"},
    { value: "Pacific Time (US &amp; Canada)", text: "(GMT-08:00) Pacific Time (US &amp; Canada)"},
    { value: "Arizona", text: "(GMT-07:00) Arizona"},
    { value: "Mountain Time (US &amp; Canada)", text: "(GMT-07:00) Mountain Time (US &amp; Canada)"},
    { value: "Central Time (US &amp; Canada)", text: "(GMT-06:00) Central Time (US &amp; Canada)"},
    { value: "Eastern Time (US &amp; Canada)", text: "(GMT-05:00) Eastern Time (US &amp; Canada)"},
    { value: "Indiana (East)", text: "(GMT-05:00) Indiana (East)"},
    { value: "American Samoa", text: "(GMT-11:00) American Samoa"},
    { value: "International Date Line West", text: "(GMT-11:00) International Date Line West"},
    { value: "Midway Island", text: "(GMT-11:00) Midway Island"},
    { value: "Tijuana", text: "(GMT-08:00) Tijuana"},
    { value: "Chihuahua", text: "(GMT-07:00) Chihuahua"},
    { value: "Mazatlan", text: "(GMT-07:00) Mazatlan"},
    { value: "Central America", text: "(GMT-06:00) Central America"},
    { value: "Guadalajara", text: "(GMT-06:00) Guadalajara"},
    { value: "Mexico City", text: "(GMT-06:00) Mexico City"},
    { value: "Monterrey", text: "(GMT-06:00) Monterrey"},
    { value: "Saskatchewan", text: "(GMT-06:00) Saskatchewan"},
    { value: "Bogota", text: "(GMT-05:00) Bogota"},
    { value: "Lima", text: "(GMT-05:00) Lima"},
    { value: "Quito", text: "(GMT-05:00) Quito"},
    { value: "Atlantic Time (Canada)", text: "(GMT-04:00) Atlantic Time (Canada)"},
    { value: "Caracas", text: "(GMT-04:00) Caracas"},
    { value: "Georgetown", text: "(GMT-04:00) Georgetown"},
    { value: "La Paz", text: "(GMT-04:00) La Paz"},
    { value: "Santiago", text: "(GMT-04:00) Santiago"},
    { value: "Newfoundland", text: "(GMT-03:30) Newfoundland"},
    { value: "Brasilia", text: "(GMT-03:00) Brasilia"},
    { value: "Buenos Aires", text: "(GMT-03:00) Buenos Aires"},
    { value: "Greenland", text: "(GMT-03:00) Greenland"},
    { value: "Montevideo", text: "(GMT-03:00) Montevideo"},
    { value: "Mid-Atlantic", text: "(GMT-02:00) Mid-Atlantic"},
    { value: "Azores", text: "(GMT-01:00) Azores"},
    { value: "Cape Verde Is.", text: "(GMT-01:00) Cape Verde Is."},
    { value: "Edinburgh", text: "(GMT+00:00) Edinburgh"},
    { value: "Lisbon", text: "(GMT+00:00) Lisbon"},
    { value: "London", text: "(GMT+00:00) London"},
    { value: "Monrovia", text: "(GMT+00:00) Monrovia"},
    { value: "UTC", text: "(GMT+00:00) UTC"},
    { value: "Amsterdam", text: "(GMT+01:00) Amsterdam"},
    { value: "Belgrade", text: "(GMT+01:00) Belgrade"},
    { value: "Berlin", text: "(GMT+01:00) Berlin"},
    { value: "Bern", text: "(GMT+01:00) Bern"},
    { value: "Bratislava", text: "(GMT+01:00) Bratislava"},
    { value: "Brussels", text: "(GMT+01:00) Brussels"},
    { value: "Budapest", text: "(GMT+01:00) Budapest"},
    { value: "Casablanca", text: "(GMT+01:00) Casablanca"},
    { value: "Copenhagen", text: "(GMT+01:00) Copenhagen"},
    { value: "Dublin", text: "(GMT+01:00) Dublin"},
    { value: "Ljubljana", text: "(GMT+01:00) Ljubljana"},
    { value: "Madrid", text: "(GMT+01:00) Madrid"},
    { value: "Paris", text: "(GMT+01:00) Paris"},
    { value: "Prague", text: "(GMT+01:00) Prague"},
    { value: "Rome", text: "(GMT+01:00) Rome"},
    { value: "Sarajevo", text: "(GMT+01:00) Sarajevo"},
    { value: "Skopje", text: "(GMT+01:00) Skopje"},
    { value: "Stockholm", text: "(GMT+01:00) Stockholm"},
    { value: "Vienna", text: "(GMT+01:00) Vienna"},
    { value: "Warsaw", text: "(GMT+01:00) Warsaw"},
    { value: "West Central Africa", text: "(GMT+01:00) West Central Africa"},
    { value: "Zagreb", text: "(GMT+01:00) Zagreb"},
    { value: "Athens", text: "(GMT+02:00) Athens"},
    { value: "Bucharest", text: "(GMT+02:00) Bucharest"},
    { value: "Cairo", text: "(GMT+02:00) Cairo"},
    { value: "Harare", text: "(GMT+02:00) Harare"},
    { value: "Helsinki", text: "(GMT+02:00) Helsinki"},
    { value: "Jerusalem", text: "(GMT+02:00) Jerusalem"},
    { value: "Kaliningrad", text: "(GMT+02:00) Kaliningrad"},
    { value: "Kyiv", text: "(GMT+02:00) Kyiv"},
    { value: "Pretoria", text: "(GMT+02:00) Pretoria"},
    { value: "Riga", text: "(GMT+02:00) Riga"},
    { value: "Sofia", text: "(GMT+02:00) Sofia"},
    { value: "Tallinn", text: "(GMT+02:00) Tallinn"},
    { value: "Vilnius", text: "(GMT+02:00) Vilnius"},
    { value: "Baghdad", text: "(GMT+03:00) Baghdad"},
    { value: "Istanbul", text: "(GMT+03:00) Istanbul"},
    { value: "Kuwait", text: "(GMT+03:00) Kuwait"},
    { value: "Minsk", text: "(GMT+03:00) Minsk"},
    { value: "Moscow", text: "(GMT+03:00) Moscow"},
    { value: "Nairobi", text: "(GMT+03:00) Nairobi"},
    { value: "Riyadh", text: "(GMT+03:00) Riyadh"},
    { value: "St. Petersburg", text: "(GMT+03:00) St. Petersburg"},
    { value: "Tehran", text: "(GMT+03:30) Tehran"},
    { value: "Abu Dhabi", text: "(GMT+04:00) Abu Dhabi"},
    { value: "Baku", text: "(GMT+04:00) Baku"},
    { value: "Muscat", text: "(GMT+04:00) Muscat"},
    { value: "Samara", text: "(GMT+04:00) Samara"},
    { value: "Tbilisi", text: "(GMT+04:00) Tbilisi"},
    { value: "Volgograd", text: "(GMT+04:00) Volgograd"},
    { value: "Yerevan", text: "(GMT+04:00) Yerevan"},
    { value: "Kabul", text: "(GMT+04:30) Kabul"},
    { value: "Ekaterinburg", text: "(GMT+05:00) Ekaterinburg"},
    { value: "Islamabad", text: "(GMT+05:00) Islamabad"},
    { value: "Karachi", text: "(GMT+05:00) Karachi"},
    { value: "Tashkent", text: "(GMT+05:00) Tashkent"},
    { value: "Chennai", text: "(GMT+05:30) Chennai"},
    { value: "Kolkata", text: "(GMT+05:30) Kolkata"},
    { value: "Mumbai", text: "(GMT+05:30) Mumbai"},
    { value: "New Delhi", text: "(GMT+05:30) New Delhi"},
    { value: "Sri Jayawardenepura", text: "(GMT+05:30) Sri Jayawardenepura"},
    { value: "Kathmandu", text: "(GMT+05:45) Kathmandu"},
    { value: "Almaty", text: "(GMT+06:00) Almaty"},
    { value: "Astana", text: "(GMT+06:00) Astana"},
    { value: "Dhaka", text: "(GMT+06:00) Dhaka"},
    { value: "Urumqi", text: "(GMT+06:00) Urumqi"},
    { value: "Rangoon", text: "(GMT+06:30) Rangoon"},
    { value: "Bangkok", text: "(GMT+07:00) Bangkok"},
    { value: "Hanoi", text: "(GMT+07:00) Hanoi"},
    { value: "Jakarta", text: "(GMT+07:00) Jakarta"},
    { value: "Krasnoyarsk", text: "(GMT+07:00) Krasnoyarsk"},
    { value: "Novosibirsk", text: "(GMT+07:00) Novosibirsk"},
    { value: "Beijing", text: "(GMT+08:00) Beijing"},
    { value: "Chongqing", text: "(GMT+08:00) Chongqing"},
    { value: "Hong Kong", text: "(GMT+08:00) Hong Kong"},
    { value: "Irkutsk", text: "(GMT+08:00) Irkutsk"},
    { value: "Kuala Lumpur", text: "(GMT+08:00) Kuala Lumpur"},
    { value: "Perth", text: "(GMT+08:00) Perth"},
    { value: "Singapore", text: "(GMT+08:00) Singapore"},
    { value: "Taipei", text: "(GMT+08:00) Taipei"},
    { value: "Ulaanbaatar", text: "(GMT+08:00) Ulaanbaatar"},
    { value: "Osaka", text: "(GMT+09:00) Osaka"},
    { value: "Sapporo", text: "(GMT+09:00) Sapporo"},
    { value: "Seoul", text: "(GMT+09:00) Seoul"},
    { value: "Tokyo", text: "(GMT+09:00) Tokyo"},
    { value: "Yakutsk", text: "(GMT+09:00) Yakutsk"},
    { value: "Adelaide", text: "(GMT+09:30) Adelaide"},
    { value: "Darwin", text: "(GMT+09:30) Darwin"},
    { value: "Brisbane", text: "(GMT+10:00) Brisbane"},
    { value: "Canberra", text: "(GMT+10:00) Canberra"},
    { value: "Guam", text: "(GMT+10:00) Guam"},
    { value: "Hobart", text: "(GMT+10:00) Hobart"},
    { value: "Melbourne", text: "(GMT+10:00) Melbourne"},
    { value: "Port Moresby", text: "(GMT+10:00) Port Moresby"},
    { value: "Sydney", text: "(GMT+10:00) Sydney"},
    { value: "Vladivostok", text: "(GMT+10:00) Vladivostok"},
    { value: "Magadan", text: "(GMT+11:00) Magadan"},
    { value: "New Caledonia", text: "(GMT+11:00) New Caledonia"},
    { value: "Solomon Is.", text: "(GMT+11:00) Solomon Is."},
    { value: "Srednekolymsk", text: "(GMT+11:00) Srednekolymsk"},
    { value: "Auckland", text: "(GMT+12:00) Auckland"},
    { value: "Fiji", text: "(GMT+12:00) Fiji"},
    { value: "Kamchatka", text: "(GMT+12:00) Kamchatka"},
    { value: "Marshall Is.", text: "(GMT+12:00) Marshall Is."},
    { value: "Wellington", text: "(GMT+12:00) Wellington"},
    { value: "Chatham Is.", text: "(GMT+12:45) Chatham Is."},
    { value: "Nuku'alofa", text: "(GMT+13:00) Nuku'alofa"},
    { value: "Samoa", text: "(GMT+13:00) Samoa"},
    { value: "Tokelau Is.", text: "(GMT+13:00) Tokelau Is."}
    
    

]




