import { HttpWrapper, AppConfig } from '../../../../core'
import { CategoryListResponseModel,CategoryDetailModel,PostCategoryModel,EditCategoryModel,CategoryLinkResourcesResponseModel } from '../models';
import { PostExportModel } from "../../../../shared/export/post.export.model";
import { DeleteResponseModel } from '../../../../shared/models';

export class CategoryService {
    private wrapper: HttpWrapper;

    constructor() {
      this.wrapper = new HttpWrapper();
    }
  
    getList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
      return this.wrapper.post<CategoryListResponseModel>(AppConfig.apiEndpoint + '/v1/category/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }
  
    getCategoryById(id: number) {
      return this.wrapper.get<CategoryDetailModel>(AppConfig.apiEndpoint + '/v1/category/' + id);
    }
  
    postCategory(payload: PostCategoryModel) {
      return this.wrapper.post<PostCategoryModel>(AppConfig.apiEndpoint + '/v1/category', payload);
    }
  
    editCategory(id: number) {
      return this.wrapper.get<EditCategoryModel>(AppConfig.apiEndpoint + '/v1/category/edit/' + id);
    }

    getLinkedResourcesById(id: number) {
        return this.wrapper.get<CategoryLinkResourcesResponseModel>(AppConfig.apiEndpoint + '/v1/category/' + id + '/linked_resources');
      }
  
    deleteCategoryById(id: number) {
      return this.wrapper.delete<DeleteResponseModel>(AppConfig.apiEndpoint + '/v1/category/' + id);
    }
  
    updateCategory(category: PostCategoryModel) {
      return this.wrapper.put<PostCategoryModel>(AppConfig.apiEndpoint + '/v1/category/' + category.id, category);
    }
    
    exportCategory(exportData?: PostExportModel) {
      return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/category/export', exportData);
    }
  
  
  }