import { HttpWrapper, AppConfig } from "../../../../core";
import { PostExportModel } from "../../../../shared/export/post.export.model";
import { AccountSourceOverridesListResponseModel, EditAccountSourceOverrideModel, AccountSourceOverridePostModel } from "../models";
import { DeleteResponseModel, LinkResourcesResponseModel } from "../../../../shared/models";
import { AccountSourceOverridesDetailModel } from "../models/account.source.overrides.detail.model";

export class AccountSourceOverridesService {
    private wrapper: HttpWrapper;
    constructor() {
      this.wrapper = new HttpWrapper();
    }
  
    getAccountSourceOverridesList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
      return this.wrapper.post<AccountSourceOverridesListResponseModel>(AppConfig.apiEndpoint + '/v1/account_source_overrides/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }
  
    getAccountSourceOverridesById(id: number) {
      return this.wrapper.get<AccountSourceOverridesDetailModel>(AppConfig.apiEndpoint + '/v1/account_source_overrides/' + id);
    }
  
  
    postAccountSourceOverrides(accountSourceOverrides: AccountSourceOverridePostModel) {
      return this.wrapper.post<AccountSourceOverridePostModel>(AppConfig.apiEndpoint + '/v1/account_source_overrides', accountSourceOverrides);
    }
  
    editAccountSourceOverrides(id: number) {
      return this.wrapper.get<EditAccountSourceOverrideModel>(AppConfig.apiEndpoint + '/v1/account_source_overrides/edit/' + id);
    }
  
    updateAccountSourceOverrides(accountSourceOverrides: AccountSourceOverridePostModel) {
      return this.wrapper.put<AccountSourceOverridePostModel>(AppConfig.apiEndpoint + '/v1/account_source_overrides/' + accountSourceOverrides.id, accountSourceOverrides);
    }
  
    getLinkedResourcesById(id: number) {
      return this.wrapper.get<LinkResourcesResponseModel>(AppConfig.apiEndpoint + '/v1/account_source_overrides/' + id + '/linked_resources');
    }

    deleteRecordById(id: number) {
      return this.wrapper.delete<DeleteResponseModel>(AppConfig.apiEndpoint + '/v1/account_source_overrides/' + id);
    }
  
    exportAccountSourceOverrides(exportData?: PostExportModel) {
      return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/account_source_pverrides/export', exportData);
    }
  
  
  }