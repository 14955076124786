import React, { Component, Fragment } from 'react'

import { TagLocationService } from './services';
import { HttpResponse } from '../../../../core';
import { ShowSuccessMessage, HandleNotFoundResponse } from '../../../../shared/helpers';
import { ButtonType } from '../../../../shared/enums/button.enum';
import { Link } from "react-router-dom";
import { Loader } from '../../../../shared/loaders';
import { TagLocationTabs } from './TagLocationTabs';
import { NotFoundResponseArea } from '../../../../shared/enums';
import { AddUpdateTagLocationModel, TagLocationCategoryModel } from './models';
import { DropdownItemModel } from '../../../../shared/models';
import { CommonService } from '../../../../shared/services/common.service';
import { Modal } from 'react-bootstrap';
import { TagLocationCategoriesFormPopup } from '../tag-location-categories/TagLocationCategoriesFormPopup';
import { IsAuthorize } from '../../../../shared/authorization/check-access';
import { Role } from '../../../../shared/authorization/enums';

interface IState {
    tagLocations?: AddUpdateTagLocationModel;
    // editTagLocation?: AddUpdateTagLocationModel;
    id?: number;
    location?: string;
    tag_location_category_id?: number;
    position?: number;
    enabled?: boolean;
    locationError?: string;
    positionError?:string;
    tagLocationCategoryError?: string;
    isShowtext?: string;
    isSave?: string; 
    isSaving?: boolean;
    tagLocationCategoriesSearch?:DropdownItemModel[];
    tag_location_category?:TagLocationCategoryModel;
    tag_location_category_name?:string;

    showtagLocationsCategoryForm?: boolean;
    isNew?:boolean;
    isChildUpdate?:boolean;

}
export class TagLocationForm extends Component<any, IState> {
    private service: TagLocationService;
     commonService: CommonService;
    constructor(props) {
        super(props)
        this.service = new TagLocationService();
        this.commonService = new CommonService();
        this.state = this.initialState;
        this.updatetagLocationsCategory = this.updatetagLocationsCategory.bind(this);
        // this.selectedRoles = this.selectedRoles.bind(this)
        // this.updatetagLocationsCategory = this.updatetagLocationsCategory.bind(this);
    }

    initialState: Partial<IState> = {
        // editTagLocation: {
        //     id: 0,
        //     location: "",
        //     tag_location_category_id: 0,
        //     position: 0,
        //     enabled: true,
        //     tag_location_category_name:"",
        // },
        id: 0,
        location: "",
        tag_location_category_id: 0,
        position: 0,
        enabled: true,
        isShowtext: "New",
        isSave: "",
        isSaving: false,
        locationError: "",
        tagLocationCategoriesSearch:[],
        tag_location_category:{},
        tagLocationCategoryError:"",
        positionError:"",
        tag_location_category_name:"",

        showtagLocationsCategoryForm: false,
        isNew:false,
        isChildUpdate:false
    }

    updatetagLocationsCategory(isChildUpdate,name,id)
    {
      this.setState({isChildUpdate:isChildUpdate,tag_location_category_name:name,tag_location_category_id:id})
    }

    handleClose = () => {
        this.setState({ showtagLocationsCategoryForm: false }, () => {
        });
    }      

    handleShowtagLocationsCategory = () => {        
        this.setState({ showtagLocationsCategoryForm: true, isNew:true }, () => {

        });
    }

    handleShowEdittagLocationsCategory = () => {        
        this.setState({ showtagLocationsCategoryForm: true, isNew:false }, () => {

        });
    }

    componentDidMount() {
        if (this.props.match.params.id > 0) {
            this.setState({ id: this.props.match.params.id, isShowtext: "Edit" }, () => {
                this.loadData();
            });
        }
        document.addEventListener("mousedown", this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
      }

      handleClickOutside = (event) => {
        if (event.path[0].id !== "tag_location_category_name" && event.path[0].id !== "tag_location_category" && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight) {
          this.setState(
            {
                tagLocationCategoriesSearch: [],
            },
            () => { }
          );
        }
      };

 

    loadData() {
        this.service.editTagLocation(this.props.match.params.id)
            .then((res: HttpResponse<AddUpdateTagLocationModel>) => {
                if (res && res.result) {
                    this.setState({
                        id: res.result?.id,
                        location: res.result?.location,
                        position: res.result?.position,
                        enabled: res.result?.enabled,
                        tag_location_category_id: res.result?.tag_location_category_id,
                        tag_location_category: res.result?.tag_location_category,
                        tag_location_category_name: res.result?.tag_location_category?.text,

                    }, () => {
                        
                    });
                   
                }
                
            })
            .catch(ex => {
                HandleNotFoundResponse(ex, NotFoundResponseArea.TagLocation, this.props)
            });
    }


    updateData(data: AddUpdateTagLocationModel) {
        this.setSavingFlag(true);
        this.service.updateTagLocation(data)
            .then((res: HttpResponse<AddUpdateTagLocationModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        tagLocations: res.result,
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Tag Location successfully updated.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/tag-location/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/tag-location/tag-location-form/' + this.state.id);
                            this.setState({ isShowtext: "Edit" })
                        }
                        if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                            this.setState(this.initialState);
                            this.props.history.push('/tag-location/tag-location-form/0');
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });
    }


    postData(data: AddUpdateTagLocationModel) {
        this.setSavingFlag(true);
        this.service.postTagLocation(data)
            .then((res: HttpResponse<AddUpdateTagLocationModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        tagLocations: res.result,
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Tag Location successfully created.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/tag-location/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                            this.setState(this.initialState);
                            this.props.history.push('/tag-location/tag-location-form/0');
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/tag-location/tag-location-form/' + this.state.id);
                            this.setState({ isShowtext: "Edit" })
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            
            });;
    }


    handleChange = (event: any) => {
        if (event.target.name == "tag_location_category_name") {
            this.getAllConnection(event.target.value);
      } 
        const isCheckbox = event.target.type === "checkbox";
        this.setState({
            [event.target.name]: isCheckbox
                ? event.target.checked
                : event.target.value
        })
    }
    getAllConnection(data:string) {
        this.commonService.getTagLocationCategoriesByName(data)
            .then((res: HttpResponse<DropdownItemModel[]>) => {
                if (res) {
                    this.setState({
                        tagLocationCategoriesSearch: res.result
                    });
                    
                }
               
            });
        
     
    }
  

    selectedTagLocationCategory(item) {
        this.setState({
            tag_location_category_id: item.value,
            tag_location_category_name: item.text,
            tag_location_category:item,
            tagLocationCategoriesSearch: [],
        });
      }

      renderTagLocationCategory = () => {
        if (this.state.tagLocationCategoriesSearch?.length === 0) {
          return null;
        }
        return (
          <ul className="list-unstyled auto-suggest">            
             { this.state.tagLocationCategoriesSearch?.map((item, index) => (<li id="tag_location_category" key={index} value={item.value} onClick={() => this.selectedTagLocationCategory(item)}>{item.text}</li>))}
          </ul>
        );
    };


    validate = () => {
        let locationError = "";
       
        let tagLocationCategoryError = "";

        if (!this.state.location) {
            locationError = "Location can't be blank";
        }
       

       
        if (!this.state.tag_location_category_name) {
            tagLocationCategoryError = "Category can't be blank";
        }
        if (locationError || tagLocationCategoryError) {
            this.setState({ locationError: locationError,  tagLocationCategoryError:tagLocationCategoryError })
            return false;
        }
        else {
            locationError = "";
            tagLocationCategoryError = "";
            this.setState({ locationError: locationError, tagLocationCategoryError:tagLocationCategoryError })
            return true;
        }
    }

    handleSubmit = event => {
        event.preventDefault();
        if(!this.state.isChildUpdate)
        { 
        const isValid = this.validate();
        let data: AddUpdateTagLocationModel = {
            id: this.state.id,
            location: this.state.location,
            position: this.state.position,
            enabled:this.state.enabled,
            tag_location_category_name: this.state.tag_location_category_name,
            tag_location_category_id: this.state.tag_location_category_id
        };
        if (isValid) {
            if (data.id == 0) {
                this.postData(data);
            }
            else {
                this.updateData(data);
            }
        }
    }
    }

    handleClick = (event) => {
        this.setState({ isSave: event.target.value,isChildUpdate:false })
    }
    private setSavingFlag(saving: boolean) {
        this.setState({ isSaving: saving });
    }

    render() {
        return (
            <Fragment>
                <form onSubmit={this.handleSubmit}>
                    <input type="hidden" value={this.state.id} />
                    <div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <h5>{this.state.isShowtext} Tag Location 'TagLocation #{this.state.id}'</h5>
                        </div>
                        <Modal backdrop='static' keyboard={false} size="lg" show={this.state.showtagLocationsCategoryForm} onHide={this.handleClose}>
                            <TagLocationCategoriesFormPopup  updateTagLocationCategory={this.updatetagLocationsCategory}  handleClose={this.handleClose} isNew={this.state.isNew} id={this.state?.tag_location_category_id}/>
                        </Modal>
                        <div className="row">
                            <div className="col-md-12 col-xl-12">
                                <div className="card">
                                    <div className="card-body">
                                        {this.props.match.params.id != 0 && <TagLocationTabs id={this.props.match.params.id} url="/tag-location/tag-location-form/" />}
                                    <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group p-3 shadow-sm">
                                                    <label>Location<span className=" text-danger">*</span></label>
                                                    <input type="text" maxLength={255} name="location" value={this.state.location} onChange={this.handleChange} className={!this.state.locationError ? 'form-control' : 'form-control  is-invalid'} />
                                                    <div className="invalid-feedback">
                                                        {this.state.locationError}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group p-3 shadow-sm">

                                                <label>Tag Location Category <span className=" text-danger">*</span></label>
                                                <div className="input-group ">
                                                    <input type="text" maxLength={255}  autoComplete="off" placeholder="Search"  id="tag_location_category_name"  name="tag_location_category_name" value={this.state.tag_location_category_name} 
                                                    onChange={this.handleChange} className={!this.state.tagLocationCategoryError ? 'form-control' : 'form-control  is-invalid'} />
                                                     {this.renderTagLocationCategory()}
                                                     {IsAuthorize([Role.Dev]) && <div className="input-group-append">
                                                    <button type="button" className="btn btn-success" onClick={this.handleShowtagLocationsCategory}><i className="fas fa-plus" style={{ color: "white" }}></i></button>
                                                        <button type="button"  className={`btn btn-primary `} disabled={this.state.tag_location_category_id==0}  onClick={this.handleShowEdittagLocationsCategory}><i className="far fa-edit"></i></button>                                                          
                                                    </div>}
                                                    <div className="invalid-feedback">
                                                        {this.state.tagLocationCategoryError}
                                                    </div>
                                                </div>
                                                </div>
                                                
                                                
                                            </div>
                                        </div>

                                        
                                        <div className="row mt-4">
                                            <div className="col">
                                            <div className="form-group p-3 shadow-sm">
                                                    <label>Position<span className=" text-danger">*</span></label>
                                                    <input type="number" maxLength={255} name="position" value={this.state.position} onChange={this.handleChange} className='form-control' />
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-group  shadow-sm p-3 mb-4">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" name="enabled" checked={this.state.enabled} className="custom-control-input" onChange={this.handleChange} id="enabled" />
                                                    <label className="custom-control-label" htmlFor="enabled">Enabled</label>
                                                    </div>
                                                       
                                                </div>
                                                
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-3 mb-4">
                            <Link type="button" className="btn btn-lg btn-default" to="/tag-location/list">{ButtonType.Cancel}</Link>
                            {!this.state.isSaving && <Fragment>
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" onClick={this.handleClick} value={ButtonType.Save} />
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndAddAnother} onClick={this.handleClick} />
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndEdit} onClick={this.handleClick} />
                            </Fragment>
                            }
                            <Loader loading={this.state.isSaving} marginBottom="0px" marginTop="8px" width="368px" ></Loader>
                        </div>
                    </div>
                </form>
            </Fragment>
        )
    }
}
