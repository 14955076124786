export enum DataEngineJobState
{
    new = "new",
    preparing = "preparing",
    delayed_start = "delayed_start",
    skipped = "skipped",
    retryable_failure="retryable_failure",
    obviated="obviated",
    running="running",
    finalizing="finalizing",
    canceled="canceled",
    success="success",
    failure="failure",
    unable_to_process="unable_to_process",
    new_recollection="new_recollection"
    
}