import { HttpWrapper, AppConfig } from '../../../../core'
import { ApplicationsListResponseModel, ApplicationsDetailModel, EditApplicationsModel, PostApplicationsModel, ApplicationsLinkResourcesResponseModel } from '../models';
import { LinkResourcesResponseModel, DeleteResponseModel } from '../../../../shared/models';
import { PostExportModel } from "../../../../shared/export/post.export.model";
export class ApplicationsService {
    private wrapper: HttpWrapper;

    constructor() {
      this.wrapper = new HttpWrapper();
    }
  
    getApplicationsList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
      return this.wrapper.post<ApplicationsListResponseModel>(AppConfig.apiEndpoint + '/v1/applications/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }
  
    getApplicationsById(id: number) {
      return this.wrapper.get<ApplicationsDetailModel>(AppConfig.apiEndpoint + '/v1/applications/' + id);
    }
  
    postApplications(application: PostApplicationsModel) {
      return this.wrapper.post<PostApplicationsModel>(AppConfig.apiEndpoint + '/v1/applications', application);
    }
  
    editApplications(id?: number) {
      return this.wrapper.get<EditApplicationsModel>(AppConfig.apiEndpoint + '/v1/applications/edit/' + id);
    }
  
    updateApplications(application: PostApplicationsModel) {
      return this.wrapper.put<PostApplicationsModel>(AppConfig.apiEndpoint + '/v1/applications/' + application.id, application);
    }
  

    getLinkedResourcesById(id: number) {
      return this.wrapper.get<ApplicationsLinkResourcesResponseModel>(AppConfig.apiEndpoint + '/v1/applications/' + id + '/linked_resources');
    } 
    
    exportStaqContact(exportData?: PostExportModel) {
      return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/StaqContacts/export', exportData);
    }
  
    deleteRecordById(id: number) {
        return this.wrapper.delete<DeleteResponseModel>(AppConfig.apiEndpoint + '/v1/applications/' + id);      
      }
   
  }