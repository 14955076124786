import React, { Component } from 'react'
import TopHeader from './header/header';
import NavigationBar from './navigation/navigation';



export default class LayoutPage extends Component {   
   
    render() {
        
        return (
  <React.Fragment>
     <TopHeader />
     <NavigationBar />
  </React.Fragment>

        )
    }
}
