import React, { Component, Fragment } from 'react'
import Breadcrumbs from '../../shared/Breadcrumbs'
import { DatabaseEgineRoute } from '../../routing'

export  class DatabaseEginePage extends Component {
    render() {
        return (
            <Fragment>
        <Breadcrumbs />
        <DatabaseEgineRoute />
      </Fragment>
        )
    }
}
