import { HttpWrapper, AppConfig } from "../../../../../core";
import { PostExportModel } from "../../../../../shared/export/post.export.model";
import { ScopesListResponseModel, ScopesDetailModel, EditUpdateScopesModel } from "../models";
import { ScopeSchemaChangeModel } from "../models/scope.schema.manager.model";



export class ScopesService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getScopesList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
    return this.wrapper.post<ScopesListResponseModel>(AppConfig.apiEndpoint + '/v1/scopes/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
  }

  getScopesById(id: number) {
    return this.wrapper.get<ScopesDetailModel>(AppConfig.apiEndpoint + '/v1/scopes/' + id);
  }


  editScopes(id?: number) {
    return this.wrapper.get<EditUpdateScopesModel>(AppConfig.apiEndpoint + '/v1/scopes/edit/' + id);
  }

  updateScopes(scopes: EditUpdateScopesModel) {
    return this.wrapper.put<EditUpdateScopesModel>(AppConfig.apiEndpoint + '/v1/scopes/' + scopes.id, scopes);
  }


  postSchemaChanges(TagMatchers: ScopeSchemaChangeModel) {
    return this.wrapper.post<ScopeSchemaChangeModel>(AppConfig.apiEndpoint + '/v1/scopes/schema_changes', TagMatchers);
}

  exportScopes(exportData?: PostExportModel) {
    return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/scopes/export', exportData);
  }


}