import React from "react";

export function PaginationRecordInfo(props:any){

    let currentPage=props.currentPage || 1;
    let totalRecords=props.totalRecords || 0;
    let pageSize=props.pageSize || 10;
    if(totalRecords<=0) return null;
    let startRecord=((currentPage-1)*pageSize)+1;
    let endRecord=(currentPage*pageSize)>totalRecords?totalRecords:(currentPage*pageSize);

    return (<p className="m-0">Showing {startRecord} to {endRecord} of {totalRecords} record(s)</p>);
}