import React, { Component, Fragment } from "react";
import { StaqContactService } from "./services/staq.contact.service";
import { EditStaqContactModel } from "./models/edit.staq.contacts.model";
import { HttpResponse } from "../../../core";
import {
  HandleNotFoundResponse,
  ShowSuccessMessage,
  GetUserInfo,
} from "../../../shared/helpers";
import { NotFoundResponseArea, ButtonType } from "../../../shared/enums";
import { PostStaqContactModel } from "./models";
import { StaqContactTabs } from "./StaqContactTabs";
import { Link } from "react-router-dom";
import { Loader } from "../../../shared/loaders";
import { Get_Teams } from "../../../shared/helpers/team.helper";
import { Get_Roles } from "../../../shared/helpers/role.helper";
import { IsAuthorize } from "../../../shared/authorization/check-access";
import { Role } from "../../../shared/authorization/enums";

interface IState {
  id?: number;
  name?: string;
  email?: string;
  team?: string;
  role?: string;
  isShowtext?: string;
  isSave?: string;
  isSaving?: boolean;
  email_error?: string;
  isAdminSuperAdmin?:boolean;
}

export class StaqContactForm extends Component<any, IState> {
  private service: StaqContactService;

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.service = new StaqContactService();
  }

  //Initial State to get the data for the edit functionality from the API
  initialState: Partial<IState> = {
    id: 0,
    team: "",
    role: "",
    name: "",
    email: "",
    isShowtext: "New",
    isSave: "",
    isSaving: false,
    email_error: "",
    isAdminSuperAdmin:false
  };

  // To the load the initial data coming from API for the Edit functionality.
  componentDidMount() {
    const roleList = GetUserInfo().role;
    const userRole = roleList != null ? roleList[0] : null;
    const userId = GetUserInfo().id;
    if(userRole?.toLowerCase() == Role.Admin.toLowerCase() || userRole?.toLowerCase() == Role.SuperAdmin.toLowerCase())
    {
      this.setState({isAdminSuperAdmin:true})
    }
    if (this.props.match.params.id > 0) {
        if(userRole?.toLowerCase() == Role.Dev.toLowerCase() || userRole?.toLowerCase() == Role.TAM.toLowerCase() || userRole?.toLowerCase() == Role.Sales.toLowerCase() || userRole?.toLowerCase() == Role.AM.toLowerCase()){
            if(userId != this.props.match.params.id){
                this.props.history.push("/admin/list");
            } 
        }
      this.setState(
        { id: this.props.match.params.id, isShowtext: "Edit" },
        () => {
          this.loadData(userRole, userId);
        }
      );
    } 
     if(this.props.match.params.id == 0){
      if (
        !(
          userRole?.toLowerCase() === "super admin" ||
          userRole?.toLowerCase() === "admin"
        )
      ) {
        this.props.history.push("/admin/list");
      }
    }
  }

  // Function for loading the data in the intial phase.
  loadData(userRole, userId) {
    this.setSavingFlag(true);
    this.service
      .editStaqContact(this.props.match.params.id)
      .then((res: HttpResponse<EditStaqContactModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
            if(userRole == Role.Admin && userId != this.props.match.params.id){
                if(res.result?.role.toLowerCase() ==Role.SuperAdmin.toLowerCase() || res.result?.role.toLowerCase() == Role.Admin.toLowerCase()){
                    this.props.history.push("/admin/list");
                }
            }
          this.setState({
            id: res.result?.id,
            name: res.result?.name === null ? "" : res.result?.name,
            email: res.result?.email === null ? "" : res.result?.email,
            team: res.result?.team === null ? "" : res.result?.team,
            role: res.result?.role === null ? "" : res.result?.role,
          });
        }
      })
      .catch((ex) => {
        HandleNotFoundResponse(
          ex,
          NotFoundResponseArea.StaqContacts,
          this.props
        );
      });
  }

  validate = () => {
    let email_error = "";
    // if (
    //   this.state.run_at_time &&
    //   !moment(this.state.run_at_time, "hh:mm", true).isValid()
    // ) {
    //     email_error = "Run at time value is wrong";
    // }
    if (!this.state.email && this.state.isSave) {
      email_error = "Email cannot be blank";
    }
    if (email_error) {
      this.setState({
        email_error: email_error,
      });
      return false;
    } else {
      email_error = "";
      this.setState({
        email_error: email_error,
      });
      return true;
    }
  };

  // Function to check the type of save functionality , in our case save and save & Edit.
  handleClick = (event) => {
    this.setState({ isSave: event.target.value });
   
  };

  private setSavingFlag(saving: boolean) {
    this.setState({ isSaving: saving });
  }

  postData(admin: PostStaqContactModel) {
    this.setSavingFlag(true);
    this.service.postStaqContact(admin).then(
      (res: HttpResponse<PostStaqContactModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              id: res.result.id,
            },
            () => {
              ShowSuccessMessage("Contact successfully created.");
              if (this.state.isSave === ButtonType.Save) {
                this.props.history.push("/admin/detail/"+ this.state.id);
                this.setState(this.initialState);
              }
              if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                this.setState(this.initialState);
                this.props.history.push("/admin/admin-form/0");
              }
              if (this.state.isSave === ButtonType.SaveAndEdit) {
                this.props.history.push(
                  "/admin/admin-form/" + this.state.id
                );
                this.setState({ isShowtext: "Edit" });
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  //Function to update the data
  updateData(admin: PostStaqContactModel) {
    this.setSavingFlag(true);
    this.service.updateStaqContact(admin).then(
      (res: HttpResponse<PostStaqContactModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              id: res.result.id,
            },
            () => {
              //Showing the message along with changing the route according to the button clicked.
              ShowSuccessMessage("Contact successfully updated.");
              if (this.state.isSave === ButtonType.Save) {
                this.props.history.push("/admin/detail/"+ this.state.id);
                this.setState(this.initialState);
              }
              if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                this.setState(this.initialState);
                this.props.history.push("/admin/admin-form/0");
              }
              if (this.state.isSave === ButtonType.SaveAndEdit) {
                this.props.history.push(
                  "/admin/admin-form/" + this.state.id
                );
                this.setState({ isShowtext: "Edit" });
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const isValid = this.validate();
    let admin = {
      role: this.state.role,
      team: this.state.team,
      name: this.state.name,
      id: this.state.id,
    };
    if (isValid) {
      if (admin.id === 0) {
        let new_admin = {
          ...admin,
          email: this.state.email,
        };
        this.postData(new_admin);
      } else {
        this.updateData(admin);
        let userInfo=GetUserInfo();
        const userRole = userInfo.role != null ? userInfo.role[0] : null;
        if(admin.id==userInfo.id && admin.role!=userRole){
          this.logOut()
        }
      }
    }
  };

  handleChange = (event: any) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  logOut=()=> {
    localStorage.clear();
}
  render() {
    return (
      <Fragment>
        <form onSubmit={this.handleSubmit}>
          <input type="hidden" value={this.state.id} />
          <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4>
                {this.state.isShowtext} STAQ Contact{" "}
                {this.state.isShowtext === "Edit" &&
                  (this.state.name
                    ? `'${this.state.name}'`
                    : `'${this.state.email}'`)}
              </h4>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {Number(this.props.match.params.id) !== 0 && (
                      <StaqContactTabs
                        id={this.props.match.params.id}
                        url="/admin/admin-form/"
                        role={this.state.role}
                      />
                    )}
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            type="text"
                            maxLength={255}
                            name="name"
                            className="form-control"
                            value={this.state.name}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      {IsAuthorize([]) && Number(this.state.id) === 0 && (
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Email<span className=" text-danger">*</span>
                            </label>
                            <div className="input-group">
                              <input
                                type="email"
                                name="email"
                                className={
                                  !this.state.email_error
                                    ? "form-control"
                                    : "form-control  is-invalid"
                                }
                                value={this.state.email}
                                onChange={this.handleChange}
                              />
                              <div className="invalid-feedback">
                                {this.state.email_error}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {Number(this.state.id) !== 0 && (
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Email</label>
                            <div>{this.state.email}</div>
                            <div className="hint-text">
                              Once this is set it cannot be changed.
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Role</label>
                          <div className="input-group mb-3">
                            <select
                             disabled={!this.state.isAdminSuperAdmin} 
                              className="form-control"
                              name="role"
                              value={this.state.role}
                              onChange={this.handleChange}
                            >
                              {Get_Roles().map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.value}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Team</label>
                          <select
                            className="form-control"
                            name="team"
                            value={this.state.team}
                            onChange={this.handleChange}
                          >
                            {Get_Teams.map((item, index) => {
                              return (
                                <option key={index} value={item.value}>
                                  {item.value}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-3 mb-4">
              {!this.state.isSaving && (
                <Fragment>
                  <Link
                    type="button"
                    className="btn btn-lg btn-default"
                    to="/admin/list"
                  >
                    {ButtonType.Cancel}
                  </Link>
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    onClick={this.handleClick}
                    value={ButtonType.Save}
                  />
                  {IsAuthorize([]) && (
                    <input
                      type="submit"
                      className="btn btn-lg btn-primary  ml-3"
                      value={ButtonType.SaveAndAddAnother}
                      onClick={this.handleClick}
                    />
                  )}
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    value={ButtonType.SaveAndEdit}
                    onClick={this.handleClick}
                  />
                </Fragment>
              )}
              <Loader
                loading={this.state.isSaving}
                marginBottom="0px"
                marginTop="8px"
                float="center"
                width="368px"
              ></Loader>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}
