import React, { Component, Fragment } from 'react'
import { ReportTabs } from './ReportTabs';
import { UserReportsService } from './services';
import { ReportReadinessResponseModel } from './models';
import { HttpResponse } from '../../../core';
import { HandleNotFoundResponse } from '../../../shared/helpers';
import { NotFoundResponseArea } from '../../../shared/enums';

interface IState {
    result?: ReportReadinessResponseModel;
}
export class ReportReadiness extends Component<any, IState> {
    private service: UserReportsService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new UserReportsService();
    }

    initialState: Partial<IState> = {
        result: {
            fresh_as_of: "",
            freshness_rating: 0,
            latest_run: "",
            stale_partners: [],
            ignored_partners: [],
            up_to_date_partners: []
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.service.getReportReadinessById(this.props.match.params.id)
            .then((res: HttpResponse<ReportReadinessResponseModel>) => {
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            }, () => {
              
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.Reports,this.props)
            });
    }

    changeBackground(data) {
        let clr = "#efda55";
        if (data.freshness_rating > .8) {
            clr = "#99CC66"
        }
        return (
            <td style={{ backgroundColor: clr }}><span>{Math.round(data.freshness_rating * 100) + '%'}</span>&nbsp;
             Up to date - &nbsp;
                <span>{data.up_to_date_partners.length}</span> of &nbsp;
                <span>{(data.up_to_date_partners.length + data.stale_partners.length)}</span> Active Partners Available</td>
        )


    }


    getPartnerList(partners) {
        var today = new Date().toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
        var html = '<ul>\n';
        for (var i = 0; i < partners.length; i++) {
            html += '<li><a href="/manage/' + partners[i].source_type + '/' + partners[i].source_id + '">[' + partners[i].source_id + '] - ' + partners[i].source_name + '</a> - ' +
                '<a href="/manage/scope/' + partners[i].scope_id + '">' + partners[i].scope_name + '</a>';
            html += '<ul>\n';
            if (partners[i].collection_time) {
                html += '<li><b>Collected</b>: ' + partners[i].collection_time.replace(today + " ", "") + ' UTC</li>\n';
            }
            if (partners[i].ready_time) {
                html += '<li><b>Available in Report:</b> ' + partners[i].ready_time.replace(today + " ", "") + ' UTC</li>\n';
            }
            if (partners[i].latest_data) {
                html += '<li><b>Latest Data:</b> ' + partners[i].latest_data + '</li>\n';
            }
            html += '</ul>\n';
            html += '</li>\n';
        }
        html += '</ul>';
        if (partners.length > 0) {
            return html;
        }
    }


    render() {
        return (
            <Fragment>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4>Report Readiness</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <ReportTabs id={this.props.match.params.id} url="/report/reportreadiness/" />
                                {this.state.result && <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group">
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <td colSpan={2}>Displays overall report health based on connections and custom connections partner data collection times and when it became available to this report.</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Latest Run:</td>
                                                            <td>{this.state.result?.latest_run!=""? this.state.result?.latest_run+ ' UTC':""}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Status:</td>
                                                            <td>{this.changeBackground(this.state.result)}</td>

                                                        </tr>
                                                        <tr>
                                                            <td>Fresh:</td>
                                                            {this.state.result?.fresh_as_of && <td>{this.state.result?.fresh_as_of + ' UTC'}</td>}
                                                            {!this.state.result?.fresh_as_of && <td>No</td>}
                                                        </tr>
                                                        <tr>
                                                            <td><a href={'https://admin-staging.gke.staq-infra.com/report_tree/' + this.props.match.params.id}>View Source Tree</a></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group">
                                                <p><b><u>Stale Partners</u></b></p>
                                                {this.state.result?.stale_partners && <div>
                                                    {this.getPartnerList(this.state.result?.stale_partners)}
                                                </div>}
                                                {this.state.result?.stale_partners.length === 0 && <div>
                                                    None
                                                </div>}
                                                <p><b><u> Up to Date Partners</u></b></p>
                                                {this.state.result?.up_to_date_partners && <div>
                                                    {this.getPartnerList(this.state.result?.up_to_date_partners)}
                                                </div>}
                                                {this.state.result?.up_to_date_partners.length === 0 && <div>
                                                    None
                                                </div>}
                                                <p><b><u>  Non-Daily Partners*</u></b></p><br />
                                                {this.state.result?.ignored_partners && <div>
                                                    {this.getPartnerList(this.state.result?.ignored_partners)}
                                                </div>}
                                                {this.state.result?.ignored_partners.length === 0 && <div>
                                                    None
                                                </div>}
                                                <p>* Non-Daily Partners are ignored for readiness purposes as they are no longer active, do not arrive on a daily basis such as mapping files, or are otherwise not relevant to determining daily freshness of new data.
                                            </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>}
                            </div>
                        </div>
                    </div>

                </div>
                {/* </div>} */}
            </Fragment >
        )
    }
}
