import { HttpWrapper, AppConfig } from '../../../../../../core'
import { ReportDataSourceFilterListResponseModel,ReportDataSourceFilterDetailModel } from '../models';
import { PostExportModel } from "../../../../../../shared/export/post.export.model";

export class ReportDataSourceFilterService {
    private wrapper: HttpWrapper;

    constructor() {
      this.wrapper = new HttpWrapper();
    }
  
    getList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
      return this.wrapper.post<ReportDataSourceFilterListResponseModel>(AppConfig.apiEndpoint + '/v1/report_data_source_filter/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }
  
    getReportDataSourceFilterById(id: number) {
      return this.wrapper.get<ReportDataSourceFilterDetailModel>(AppConfig.apiEndpoint + '/v1/report_data_source_filter/' + id);
    }
    
    exportReportDataSourceFilter(exportData?: PostExportModel) {
      return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/report_data_source_filter/export', exportData);
    }
  
  
  }