import React, { Component, createRef, Fragment } from 'react'
import { PostApplicationsModel, EditApplicationsModel } from './models'
import { ApplicationsList } from './ApplicationsList'
import { ApplicationsService } from './services/applications.service';
import { HttpResponse } from '../../../core';
import { Link, Route } from "react-router-dom";
import { ShowSuccessMessage, ShowException, HandleNotFoundResponse } from '../../../shared/helpers';
import { ButtonType } from '../../../shared/enums/button.enum'
import { Loader } from '../../../shared/loaders';
import { ApplicationsTabs } from './ApplicationsTabs'
import { NotFoundResponseArea } from '../../../shared/enums';

interface IState {
    applications?: PostApplicationsModel,
    editApplications?: EditApplicationsModel,
    id?: number,
    name?: string,
    type?: string,
    public?: boolean,
    enabled?: boolean,
    nameError?: string,
    typeError?: string,
    isShowtext?: string;
    isSave?: string;
    isSaving?: boolean;
}
export class ApplicationsForm extends Component<any, IState> {
    //declaring service type
    private applicationsService: ApplicationsService;
    private nameRef: any;
    private typeRef: any;

    constructor(props) {
        super(props)
        this.applicationsService = new ApplicationsService();
        this.state = this.initialState;
        this.nameRef = createRef();
        this.typeRef = createRef();
        
    }


    initialState: IState = {
        applications:{
            id: 0,
            name: "",
            type: "",
            public: false,
            enabled: false
        },
        id: 0,
        name: "",
        type: "",
        public: true,
        enabled: false,
        nameError: "",
        typeError:"",
        isShowtext: "New",
        isSave: "",
        isSaving: false
    } 
 
    componentDidMount() {
        if (this.props.match.params.id > 0) {
            this.setState({ id: this.props.match.params.id, isShowtext: "Edit" }, () => {
                this.loadData();
            });
        }
        
    }

    loadData() {

        this.applicationsService.editApplications(this.props.match.params.id)
            .then((res: HttpResponse<EditApplicationsModel>) => {
                if (res && res.result) {
                    this.setState({
                        id: res.result?.id,
                        name: res.result?.name,
                        type: res.result?.type,
                        public: res.result?.public,
                        enabled: res.result?.enabled,
                    },()=>{
                       
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.Applications,this.props)
            });
    }

    postData(application: PostApplicationsModel) {
        this.setSavingFlag(true);
        this.applicationsService.postApplications(application)
            .then((res: HttpResponse<PostApplicationsModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        applications: res.result,
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Application successfully created.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/application/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                            this.setState(this.initialState);
                            this.props.history.push('/application/application-form/0');
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/application/application-form/' + this.state.applications?.id);
                            this.setState({ isShowtext: "Edit" })
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });;
    }

    updateData(application: PostApplicationsModel) {
        this.setSavingFlag(true);
        this.applicationsService.updateApplications(application)
            .then((res: HttpResponse<PostApplicationsModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        applications: res.result
                    }, () => {
                        ShowSuccessMessage("Application Successfully Updated.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/application/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                            this.setState(this.initialState);
                            this.props.history.push('/application/application-form/0');
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/application/application-form/' + this.state.applications?.id);
                            this.setState({ isShowtext: "Edit" })
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });
    }


  
 
    handleChange = (event: any) => {
         
        const isCheckbox = event.target.type === "checkbox";
    
        this.setState({
          [event.target.name]: isCheckbox
            ? event.target.checked
            : event.target.value,
        });

        if (event.target.name=="name" && this.nameRef.current.value) {
            this.setState({ nameError: "" });
          }
          if (event.target.name=="type" && this.typeRef.current.value) {
            this.setState({ typeError: "" });
          }

      };


    validate = () => {
        let nameError = "";
        let typeError = "";
        if (!this.nameRef.current.value) {
            nameError = "Name can't be blank";
        }
        if (!this.state.type) {
            typeError = "Type can't be blank";
        }

        if (nameError || typeError) {
            this.setState({ 
                nameError: nameError,
                typeError:typeError
            })
            return false;
        }

        else {
            nameError = "";
            typeError = "";
            this.setState({ nameError: nameError, 
                typeError:typeError
            })
            return true;
        }
    }

    handleSubmit = event => {
        event.preventDefault();
        const isValid = this.validate();
        let applicationsData: PostApplicationsModel = {
            id: this.state.id,
            name: this.state.name,
            type: this.state.type,
            public: this.state.public,
            enabled: this.state.enabled,
        };
        if (isValid) {
            if (applicationsData.id === 0) {
                this.postData(applicationsData);
            }
            else {
                this.updateData(applicationsData)
            }
        }
    }

    handleClick = (event) => {
        this.setState({ isSave: event.target.value })
    }
    private setSavingFlag(saving: boolean) {
        this.setState({ isSaving: saving });
    }



    render() {
        return (
            <Fragment>
                <form onSubmit={this.handleSubmit}>
                    <input type="hidden" value={this.state.id} />
                    <div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h4>{this.state.isShowtext} Applications{this.state.name && <span> '{this.state.name}'</span>}</h4>                            
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        {this.props.match.params.id != 0 && <ApplicationsTabs id={this.props.match.params.id} url="/application/application-form/" />}
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group p-3 shadow-sm">
                                                    <label>Name<span className=" text-danger">*</span></label>
                                                    <input type="text" maxLength={255}  ref={this.nameRef} name="name" value={this.state.name} onChange={this.handleChange} className={!this.state.nameError ? 'form-control' : 'form-control  is-invalid'} />
                                                    <div className="invalid-feedback">
                                                        {this.state.nameError}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                {this.state.id ==0 &&<div className="form-group p-3 shadow-sm">
                                                    <label>Type<span className=" text-danger">*</span> </label>
                                                    <input type="text" maxLength={255}  ref={this.typeRef} name="type" value={this.state.type} onChange={this.handleChange} className={!this.state.typeError ? 'form-control' : 'form-control  is-invalid'} />
                                                    <div className="invalid-feedback">
                                                        {this.state.typeError}
                                                    </div>
                                                    <small>You should not normally change this. It gets set by the developer the first time the application code is pushed.</small>
                                                </div>
                                                }
                                                {this.state.id != 0 && 
                                                <div className="form-group shadow-sm p-3">
                                                    <label>Type</label>
                                                   <div><span className="badge badge-light"> {this.state.type}</span></div>
                                                    <small>You should not normally change this. It gets set by the developer the first time the application code is pushed.</small>
                                                </div>
                                                }
                                            </div>
                                        </div>

                                        
                                        <div className="row mt-4">
                                            <div className="col">
                                                <div className="form-group  shadow-sm p-3 mb-4">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox"  name="public" checked={this.state.public} onChange={this.handleChange} className="custom-control-input" id="public" />
                                                    <label className="custom-control-label" htmlFor="public">Public</label>
                                                    </div>
                                                        <small>If this is true, this app will be visible in the app marketplace.</small>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-group  shadow-sm p-3 mb-4">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" name="enabled" checked={this.state.enabled} className="custom-control-input" onChange={this.handleChange} id="enabled" />
                                                    <label className="custom-control-label" htmlFor="enabled">Enabled</label>
                                                    </div>
                                                        <small>If this is false, no installed instances of this application will run.</small>
                                                </div>
                                                
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>


                        <div className="text-center mt-3 mb-4">
                            <Link type="button" className="btn btn-lg btn-default" to="/application/list">{ButtonType.Cancel}</Link>
                            {!this.state.isSaving && <Fragment>
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" onClick={this.handleClick} value={ButtonType.Save} />
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndAddAnother} onClick={this.handleClick} />
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndEdit} onClick={this.handleClick} />
                            </Fragment>
                            }
                            <Loader loading={this.state.isSaving} marginBottom="0px" marginTop="8px" width="100px" ></Loader>
                        </div>
                    </div>
                </form>
                <Route path="/application/list" component={ApplicationsList} />
            </Fragment>
        )
    }
}