import React, { Component, Fragment } from "react";
import { CustomConnectionTabs } from "./CustomConnectionTabs";
import ReactFilestack from "react-filestack";
import { AppConfig, HttpResponse } from "../../../core";
import {
  ShowSuccessMessage,
  HandleNotFoundResponse,
  ConvertDateTime,
  formatBytes,
} from "../../../shared/helpers";
import { CustomConnectionsService } from "./services";
import {
  FileUploadResponseModel,
  FileUploadModel,
} from "../../../shared/models";
import { FileUploadListResponseModel, Uploaded_File } from "./models";
import { NotFoundResponseArea } from "../../../shared/enums";
import { PaginationRecordInfo, Pagination } from "../../../shared";
import { FileLinkResourceModel } from "./models/file.link.resource.model";

interface IState {
  uploadedFilesResponse: FileUploadListResponseModel;
  fileData:FileLinkResourceModel;
  currentPage: number;
  pageSize: number;
  pageCount: number;
  totalItems: number;
  isLoading: boolean;
}
export class FileUpload extends Component<any, IState> {
  private service: CustomConnectionsService;
  constructor(props) {
    super(props);
    this.service = new CustomConnectionsService();
    this.pageChange = this.pageChange.bind(this);
    this.processFile = this.processFile.bind(this);
    this.state = {
      fileData:{
        id:0,
        name:""
      },
      uploadedFilesResponse: {
        paging: {
          current_page: 0,
          page_count: 0,
          page_size: 0,
          total_items: 0,
        },
        records: [],
      },
      currentPage: 1,
      pageSize: 20,
      pageCount: 0,
      totalItems: 0,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getFileData();
    this.loadData();
  }

  getFileData()
  {
    this.service.getFileLinkedResourcesById(this.props.match.params.id)
    .then((res: HttpResponse<FileLinkResourceModel>) => {
        if (res && res.result) {
            this.setState({
                fileData: res.result
            });
        }
    })
    .catch(ex => {
        HandleNotFoundResponse(ex,NotFoundResponseArea.CustomConnection,this.props)
    });
  }


  private loadData() {    
    this.service
      .getUploadedFileList(
        this.props.match.params.id,
        this.state.currentPage,
        this.state.pageSize
      )
      .then((res: HttpResponse<FileUploadListResponseModel>) => {        
        if (res && res.result) {
          this.setState({
            uploadedFilesResponse: res.result,
            currentPage: res.result.paging.current_page,
            pageSize: res.result.paging.page_size,
          },()=>{
           
          });
        }
      })
      .catch((ex) => {
        HandleNotFoundResponse(
          ex,
          NotFoundResponseArea.CustomConnection,
          this.props
        );
      });
  }

  pageChange(page: number) {
    if (this.state.currentPage == page) return;
    this.setState({ currentPage: page }, () => {
      this.loadData();
    });
  }

  onSuccessFileUpload(response) {
    if (response.filesUploaded[0].handle) {
      let url = AppConfig.FilePickerUrl + response.filesUploaded[0].handle;
      let filedataata: FileUploadModel = {
        url: url,
        custom_connection_id: this.props.match.params.id,
      };
      this.postFile(filedataata);
    }
  }

  postFile(filedataata: FileUploadModel) {
    this.service
      .uploadFile(filedataata)
      .then((res: HttpResponse<FileUploadResponseModel>) => {
        if (res && res.result) {
          if (res.result.id > 0) {            
            ShowSuccessMessage("File Uploaded successfully.");
            this.loadData();
          }
        }
      });
  }

  processFile(id)
  {
    this.service
    .runCollectionMission(id)
    .then((res: HttpResponse<FileUploadResponseModel>) => {
      if (res && res.result) {
        if (res.result.jid) {          
          ShowSuccessMessage("Created UploadedFile "+id+ " for Sidekiq Job ID "+ res.result.jid+", check for a collection mission to be assigned");
          this.loadData();
        }
      }
    });
  }
  render() {
    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h4>Upload Files</h4>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <CustomConnectionTabs
                  id={this.props.match.params.id}
                  url="/custom-connection/file-upload/"
                />
                <div className="row">
                  <div className="col">
                    <div className="card card-silver shadow-sm">
                      <div className="card-header">
                        <h6>
                          Upload New File for CustomConnection {this.props.match.params.id} ({this.state.fileData.name})
                        </h6>
                      </div>
                      <div className="card-body">
                        <ReactFilestack
                          apikey={AppConfig.FileStackKey}
                          mode={"pick"}
                          buttonText="Select File to Upload"
                          buttonClass="btn btn-lg btn-info"
                          onSuccess={(response) =>
                            this.onSuccessFileUpload(response)
                          }
                          onError={(e) => console.log(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <h6>Uploaded Files</h6>
                    <div className="table-responsive">
                      <table className="table table table-sm table-striped">
                        <thead>
                          <tr>
                            <th scope="col">File</th>
                            <th scope="col">Download</th>
                            <th scope="col">Size</th>
                            <th scope="col">Created at </th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                            {this.state.uploadedFilesResponse?.records.map(
                              (item: Uploaded_File, index) => {                                
                                return (
                                  <tr key={index}>
                                    {/* <td> {(item.filename).match(/.png|.jpg|.gif/) && <img src={AppConfig.CustomConnectionFileUrl+item.url} className="img-thumbnail mr-3" width="60" /> } {!(item.filename).match(/.png|.jpg|.gif/) && <i className="far fa-file-alt font-30 mr-3 img-thumbnail"></i> } {item.filename}</td>     */}
                                    <td> {item.filename}</td>    
                                    <td> <a className="btn btn-sm btn-outline-success" href={AppConfig.CustomConnectionFileUrl+item.url} target="_blank"> Download  </a></td>                                
                                    <td>{item.size!=null?formatBytes(item.size):"0 Bytes"}</td>
                                    <td>{ConvertDateTime(item.created_at, "LLL")}</td>
                                  <td> <button onClick={()=>this.processFile(item.id)} className="btn btn-sm  btn-info">
                                        Process
                                      </button></td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <PaginationRecordInfo currentPage={this.state.currentPage} totalRecords={this.state.uploadedFilesResponse?.paging.total_items} pageSize={this.state.pageSize} />
                        </div>
                        <div className="col-md-6">
                        <div className="pull-right"> <Pagination  currentPage={this.state.currentPage} pageCount={this.state.uploadedFilesResponse?.paging.page_count} onChangePage={this.pageChange} /></div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FileUpload;
