import React, { Component, createRef, Fragment } from "react";
import { PlatformService } from "./services/platform.service";
import { EditPlatformModel } from "./models/edit.platform.model";
import { HttpResponse } from "../../../core";
import {
  HandleNotFoundResponse,
  ShowSuccessMessage,
  ValidateLogoUrl,
} from "../../../shared/helpers";
import { NotFoundResponseArea, ButtonType } from "../../../shared/enums";
import { PostPlatformModel } from "./models";
import { Link } from "react-router-dom";
import { Loader } from "../../../shared/loaders";
import { DropdownItemModel, PlatformTypeModel } from "../../../shared/models";
import { CommonService } from "../../../shared/services/common.service";
import Modal from 'react-bootstrap/Modal';

interface IProps {
  handleClose: Function;
  id?: number;
  isNew?: boolean;
  updatePlatform: Function;
}
interface IState {
  id?: number;
  title?: string;
  is_public?: boolean;
  extractor_list?: DropdownItemModel[];
  extractor_id?: number;
  extractor_name?: String;
  info?: string;
  categories_list?: DropdownItemModel[];
  categories?: Array<PlatformTypeModel>;
  unassigned_categories?: Array<PlatformTypeModel>;
  favicon_url?: string;
  image_url?: string;
  isShowtext?: string;
  searchText?: string;
  isSave?: string;
  isSaving?: boolean;
  favUrlError?: string;
  imageUrlError?: string;
  searchList?: DropdownItemModel[];
  titleError?:string;
}

export class PlatformFormPopup extends Component<IProps, IState> {
  //declaring service type
  private service: PlatformService;
  private commonService: CommonService;
  private titleRef: any;

  constructor(props) {
    super(props);
    this.titleRef = createRef();
    this.state = this.initialState;
    this.service = new PlatformService();
    this.commonService = new CommonService();
    
  }

  //Initial State to get the data for the edit functionality from the API
  initialState: Partial<IState> = {
    id: 0,
    title: "",
    is_public: false,
    extractor_list: [],
    extractor_id: 0,
    extractor_name: "",
    info: "",
    categories_list: [],
    categories: [],
    unassigned_categories: [],
    favicon_url: "",
    image_url: "",
    isShowtext: "New",
    searchText: "",
    isSave: "",
    isSaving: false,
    favUrlError: "",
    imageUrlError: "",
    searchList: [],
    titleError:""
  };

  handleClose = (event) => {
    event.preventDefault();
    this.props.handleClose();
  };

  componentDidMount() {
    this.getAllExtractors();
    if (!this.props.isNew) {
      this.setState({ id: Number(this.props.id), isShowtext: "Edit" }, () => {
        this.loadData();
      });
    }
    if (this.props.isNew) {
      this.getAllCategories();
    }
  }

  getAllCategories() {
    this.commonService
      .getAllCategories()
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res) {
          this.setState({
            unassigned_categories: res.result,
            searchList: res.result,
          });
        }
      });
  }

  getAllExtractors() {
    this.commonService
      .getAllExtractors()
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res) {
          this.setState({
            extractor_list: res.result,
          });
        }
      });
  }

  loadData() {
    this.service
      .editPlatform(Number(this.props.id))
      .then((res: HttpResponse<EditPlatformModel>) => {
        if (res && res.result) {
          this.setState({
            id: res.result?.id,
            title: res.result?.title === null ? "" : res.result?.title,
            is_public: res.result?.is_public,
            extractor_id:
              res.result?.extractor === null ? 0 : res.result?.extractor.id,
            extractor_name:
              res.result?.extractor === null ? "" : res.result?.extractor.type,
            categories: res.result?.categories,
            unassigned_categories: res.result.unassigned_categories,
            searchList: res.result.unassigned_categories,
            info: res.result?.info === null ? "" : res.result?.info,
            favicon_url:
              res.result?.favicon_url === null ? "" : res.result?.favicon_url,
            image_url:
              res.result?.image_url === null ? "" : res.result?.image_url,
          });
        }
      })
      .catch((ex) => {
        HandleNotFoundResponse(ex, NotFoundResponseArea.Platforms, this.props);
      });
  }

  handleChange = (event: any) => {
    const isCheckbox = event.target.type === "checkbox";
    let name = event.target.name;
    let value = event.target.value;
    if (event.target.name == "searchText") {
      this.handleSearch(name, value);
  }
  else {
    const isCheckbox = event.target.type === "checkbox";
            this.setState({
                [event.target.name]: isCheckbox
                    ? event.target.checked
                    : event.target.value
            })
  }

    if (event.target.name=="title" && this.titleRef.current.value) {
      this.setState({ titleError: "" });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();    
    const isValid = this.validate();
    let platform:PostPlatformModel = {
      title: this.state.title,
      is_public: this.state.is_public,
      categories: this.state.categories,
      info: this.state.info,
      favicon_url: this.state.favicon_url,
      image_url: this.state.image_url,
      id: this.state.id,
      extractor_id:this.state.extractor_id==0?null:this.state.extractor_id
    };
    if (isValid) {
      if (platform.id == 0) { 
        this.postData(platform)        
      } 
      else
      {
        this.updateData(platform);
      }    
  }
  };

  validate = () => {
    let titleError = "";
    let imageUrlError = "";
    let favUrlError = "";

    if (!this.state.title) {
      titleError = "Title can't be blank";
  }

    if (!ValidateLogoUrl(this.state.image_url) && this.state.image_url) {
      imageUrlError = "Must begin with 'https://' and end with '.gif', '.jpeg', '.jpg', or '.png'.";
  }

  if (!ValidateLogoUrl(this.state.favicon_url) && this.state.favicon_url) {
    favUrlError = "Must begin with 'https://' and end with '.gif', '.jpeg', '.jpg', or '.png'.";
}

    if (imageUrlError || favUrlError || titleError) {
      this.setState({
        favUrlError: favUrlError,
        imageUrlError: imageUrlError,
        titleError:titleError
      });
      return false;
    }

    if (!imageUrlError || !favUrlError || !titleError) {
      imageUrlError = "";
      favUrlError = "";
      titleError ="";
      this.setState({
        favUrlError: favUrlError,
        imageUrlError: imageUrlError,
        titleError:titleError
      });
      return true;
    }
  };

  postData(platform: PostPlatformModel) {
    this.setSavingFlag(true);
    this.service.postPlatform(platform).then(
      (res: HttpResponse<PostPlatformModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              id: res.result.id,
            },
            () => {
              ShowSuccessMessage("Platform successfully created.");
              if (this.state.isSave === ButtonType.Save) {
                this.setState(this.initialState);
                this.props.updatePlatform(
                  true,
                  this.state.title === "" ? "New Platform" : this.state.title,
                  this.state.id
                );
                this.props.handleClose();
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  //Function to update the data
  updateData(platform) {
    this.setSavingFlag(true);
    this.service.updatePlatform(platform).then(
      (res: HttpResponse<PostPlatformModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              id: res.result.id,
            },
            () => {
              //Showing the message along with changing the route according to the button clicked.
              ShowSuccessMessage("Platform successfully updated.");
              if (this.state.isSave === ButtonType.Save) {
                this.setState(this.initialState);
                this.props.updatePlatform(
                  true,
                  this.state.title === "" ? "New Platform" : this.state.title,
                  this.state.id
                );
                this.props.handleClose();
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  // Function to check the type of save functionality , in our case save and save & Edit.
  handleClick = (event) => {
    this.setState({ isSave: event.target.value }, () => {
      this.props.updatePlatform(true, this.state.title, this.state.id);
    });
  };

  private setSavingFlag(saving: boolean) {
    this.setState({ isSaving: saving });
  }

  handleSearch(name, value) {
    if (name === "searchText") {
      let list = this.state.unassigned_categories;
      list = list?.filter((p) =>
        p.text?.toLowerCase().startsWith(value.toLowerCase())
      );

      if (value) {
        this.setState({ unassigned_categories: list,searchText:value });
      } else {
        this.setState({ unassigned_categories: this.state.searchList,searchText:value });
      }
    }
  }

  onLeftPlatformSelectionChange(e: any) {
    let data = this.state.unassigned_categories?.find(
      (p) => p.value === e.target.value
    );
    this.state.categories?.push({
      text: data?.text,
      value: data?.value,
      isChecked: data?.isChecked,
    });
    this.setState({
      unassigned_categories: this.state.unassigned_categories?.filter(
        (p) => p.value !== e.target.value
      ),
      searchList: this.state.searchList?.filter(
        (p) => p.value !== e.target.value
      ),
      categories: this.state.categories,
    });
  }

  selectAll() {
    this.state.unassigned_categories?.forEach((p) => {
      this.state.categories?.push(p);
    });
    this.setState({
      unassigned_categories: [],
      categories: this.state.categories,
    });
  }

  onRightPlatformSelectionChange(e: any) {
    let data = this.state.categories?.find((p) => p.value === e.target.value);
    this.state.unassigned_categories?.push({
      text: data?.text,
      value: data?.value,
      isChecked: data?.isChecked,
    });
    this.setState({
      categories: this.state.categories?.filter(
        (p) => p.value !== e.target.value
      ),
      unassigned_categories: this.state.unassigned_categories,
    });
  }

  clearAll() {
    this.state.categories?.forEach((p) => {
      this.state.unassigned_categories?.push(p);
    });
    this.setState({
      categories: [],
      unassigned_categories: this.state.unassigned_categories,
    });
  }

  render() {
    return (
      <Fragment>
        <form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton onClick={this.handleClose}></Modal.Header>
          <Modal.Body>
            <input type="hidden" value={this.state.id} />
            <div>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h5>
                  {this.state.isShowtext} Platform
                  {this.state.title && <span> '{this.state.title}'</span>}
                </h5>
              </div>
              <div className="row">
                <div className="col-md-12">
                 
                      {/* {Number(this.props.match.params.id) !== 0 && (
                      <PlatformTabs
                        id={this.props.match.params.id}
                        url="/platform/platform-form/"
                      />
                    )} */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Title</label>
                            <input
                              type="text"
                              maxLength={255}
                              name="title"
                              ref={this.titleRef}
                              className={!this.state.titleError ? 'form-control' : 'form-control  is-invalid'}
                              value={this.state.title}
                              onChange={this.handleChange}
                            />
                            <div className="invalid-feedback">
                              {this.state.titleError}
                            </div>
                            <small>Required. Length up to 255.</small>
                          </div>
                        </div>
                        {this.state.extractor_name === "" ||
                        this.state.id === 0 ? (
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Extractor</label>
                              <div className="input-group mb-3">
                                <select
                                  className="form-control"
                                  name="extractor_id"
                                  value={this.state.extractor_id}
                                  onChange={this.handleChange}
                                >
                                  {this.state.extractor_list?.map((item) => {
                                    return (
                                      <option key={item.id} value={item.id}>
                                        {item.type}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Extractor</label>                             
                              {this.state.id != 0 && (
                            <div>
                              <Link
                                to={"/extractor/detail/" + this.state.extractor_id}
                                className="badge badge-light"
                              >
                                {this.state.extractor_name}
                              </Link>
                            </div>
                          )}
                              <small className="hint-text">
                                Once this is set it cannot be changed.
                              </small>
                            </div>
                          </div>
                        )}
                        <div className="col-md-6">
                        <div className="form-group shadow-sm p-3 mb-4 ">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="is_public"
                              checked={this.state.is_public}
                              onChange={this.handleChange}
                              className="custom-control-input"
                              id="is_public"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="is_public"
                            >
                              Is public
                            </label>
                          </div>
                          <small>
                            Check this to show the platform in the Connection
                            Manager.
                          </small>
                        </div>
                        </div>
                        <div className="col-md-6">
                        <div className="form-group">
                            <label>Favicon Url</label>
                            <input
                              type="text"
                              maxLength={255}
                              name="favicon_url"
                              className={!this.state.favUrlError ? 'form-control' : 'form-control  is-invalid'}
                              value={this.state.favicon_url}
                              onChange={this.handleChange}
                            />
                             <div className="invalid-feedback">
                              {this.state.favUrlError}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                        <div className="form-group">
                            <label>Image Url</label>
                            <input
                              type="text"
                              maxLength={255}
                              name="image_url"
                              className={!this.state.imageUrlError ? 'form-control' : 'form-control  is-invalid'}
                              value={this.state.image_url}
                              onChange={this.handleChange}
                            />
                             <div className="invalid-feedback">
                              {this.state.imageUrlError}
                            </div>
                          </div>
                        </div>
                        
                        <div className="col-md-6">
                        <div className="form-group">
                            <label>Info</label>
                            <input
                              type="text"
                              maxLength={255}
                              name="info"
                              className="form-control"
                              value={this.state.info}
                              onChange={this.handleChange}
                            />
                            <small>
                              Describe the platform. Customers see this in
                              Connection Manager.
                            </small>
                          </div>
                        </div>
                     
                        <div className="col-md-12">
                          <div className="form-group  shadow-sm p-3 mb-0">
                            <label>Categories</label>
                            <input
                              type="text"
                              name="searchText"
                              value={this.state.searchText}
                              placeholder="Search"
                              onChange={this.handleChange}
                              className="form-control form-control-sm"
                            />
                          </div>
                          <div className="row">
                            <div
                              className="col-md-6"
                              style={{ paddingRight: "0px" }}
                            >
                              <div className="form-group customScrollBar shadow-sm p-3 mb-2">
                                {this.state.unassigned_categories?.map(
                                  (item, index) => {
                                    return (
                                      <div key={index}>
                                        <div>
                                          <input
                                            type="checkbox"
                                            style={{ display: "none" }}
                                            value={item.value || ""}
                                            checked={item.isChecked || false}
                                            onChange={this.onLeftPlatformSelectionChange.bind(
                                              this
                                            )}
                                            id={"platform" + item.value}
                                            name={"platform" + item.value}
                                          />
                                          <label
                                            htmlFor={"platform" + item.value}
                                          >
                                            {item.text}
                                          </label>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                              <button
                                type="button"
                                onClick={this.selectAll.bind(this)}
                                className="btn btn-default btn-sm mr-2"
                              >
                                Choose All{" "}
                                <i className="fas fa-chevron-circle-right"></i>
                              </button>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group customScrollBar  shadow-sm p-3 mb-2">
                                {this.state.categories?.map((item, index) => {
                                  return (
                                    <div key={index}>
                                      <div>
                                        <input
                                          style={{ display: "none" }}
                                          type="checkbox"
                                          value={item.value || ""}
                                          checked={item.isChecked || false}
                                          onChange={this.onRightPlatformSelectionChange.bind(
                                            this
                                          )}
                                          id={"platform" + item.value}
                                          name={"platform" + item.value}
                                        />
                                        <label
                                          htmlFor={"platform" + item.value}
                                        >
                                          {item.text}
                                        </label>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                              <button
                                type="button"
                                onClick={this.clearAll.bind(this)}
                                className="btn btn-default btn-sm"
                              >
                                <i className="fas fa-chevron-circle-left"></i>{" "}
                                Clear All
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                </div>
              </div>
              <div className="text-center mt-3 mb-4">
              <button
                  type="button"
                  className="btn btn-lg btn-default"
                  onClick={this.handleClose}
                >
                  {ButtonType.Cancel}
                </button>
                {!this.state.isSaving && (
                    <input
                      type="submit"
                      className="btn btn-lg btn-primary  ml-3"
                      onClick={this.handleClick}
                      value={ButtonType.Save}
                    />
                )}
                <Loader
                  loading={this.state.isSaving}
                  marginBottom="0px"
                  marginTop="8px"
                  float="center"
                  width="368px"
                ></Loader>
              </div>
            </div>
          </Modal.Body>
        </form>
      </Fragment>
    );
  }
}
