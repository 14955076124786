import { HttpWrapper, AppConfig } from '../../../../../../../core'
import { ExtractorSemaphoresListResponseModel,ExtractorSemaphoresDetailModel,PostExtractorSemaphoresModel,EditExtractorSemaphoresModel } from '../models';
import { PostExportModel } from "../../../../../../../shared/export/post.export.model";
import { DeleteResponseModel, LinkResourcesResponseModel } from '../../../../../../../shared/models';

export class ExtractorSemaphoresService {
    private wrapper: HttpWrapper;

    constructor() {
      this.wrapper = new HttpWrapper();
    }
  
    getList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
      return this.wrapper.post<ExtractorSemaphoresListResponseModel>(AppConfig.apiEndpoint + '/v1/extractor_semaphores/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }
  
    getExtractorSemaphoresById(id: number) {
      return this.wrapper.get<ExtractorSemaphoresDetailModel>(AppConfig.apiEndpoint + '/v1/extractor_semaphores/' + id);
    }
  
    postExtractorSemaphores(payload: PostExtractorSemaphoresModel) {
      return this.wrapper.post<PostExtractorSemaphoresModel>(AppConfig.apiEndpoint + '/v1/extractor_semaphores', payload);
    }
  
    editExtractorSemaphores(id: number) {
      return this.wrapper.get<EditExtractorSemaphoresModel>(AppConfig.apiEndpoint + '/v1/extractor_semaphores/edit/' + id);
    }

    getLinkedResourcesById(id: number) {
        return this.wrapper.get<LinkResourcesResponseModel>(AppConfig.apiEndpoint + '/v1/extractor_semaphores/' + id + '/linked_resources');
      }
  
    deleteExtractorSemaphoresById(id: number) {
      return this.wrapper.delete<DeleteResponseModel>(AppConfig.apiEndpoint + '/v1/extractor_semaphores/' + id);
    }
  
    updateExtractorSemaphores(extractor: PostExtractorSemaphoresModel) {
      return this.wrapper.put<PostExtractorSemaphoresModel>(AppConfig.apiEndpoint + '/v1/extractor_semaphores/' + extractor.id, extractor);
    }
    
    exportExtractorSemaphores(exportData?: PostExportModel) {
      return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/extractor_semaphores/export', exportData);
    }
  
  
  }