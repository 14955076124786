import React, { Component, Fragment } from "react";
import {
  DeleteResponseModel, LinkResourcesResponseModel
} from "../../../../shared/models";
import { InvocationSchedulesService } from "./services/invocation-schedule.service";
import { HttpResponse } from "../../../../core";
import {
  ShowSuccessMessage,
  HandleNotFoundResponse,
} from "../../../../shared/helpers";
import { InvocationSchedulesTabs } from "./InvocationSchedulesTabs";
import { NavLink } from "react-router-dom";
import { ButtonType, NotFoundResponseArea } from "../../../../shared/enums";

interface IState {
  result?: LinkResourcesResponseModel;
  deleteResult?: DeleteResponseModel;
  isDoubleleClick?: boolean;
}
export class DeleteInvocationSchedules extends Component<any, IState> {
  private service: InvocationSchedulesService;
  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.service = new InvocationSchedulesService();
    this.deleteRecord = this.deleteRecord.bind(this);
  }

  initialState: Partial<IState> = {
    result: {
      id: 0,
      name: "",
    },
    deleteResult: { isdeleted: false },
    isDoubleleClick: false,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.service
      .getLinkedResourcesById(this.props.match.params.id)
      .then((res: HttpResponse<LinkResourcesResponseModel>) => {
        if (res && res.result) {
          this.setState({
            result: res.result
          });
        }
      })
      .catch((ex) => {
        HandleNotFoundResponse(
          ex,
          NotFoundResponseArea.InvocationSchedules,
          this.props
        );
      });
  }


  deleteRecord() {
    if (!this.state.isDoubleleClick) {
      this.setState({ isDoubleleClick: true });
      this.service
        .deleteInvocationScheduleById(this.props.match.params.id)
        .then((res: HttpResponse<DeleteResponseModel>) => {
          if (res && res.result) {
            this.setState(
              {
                deleteResult: res.result,
              },
              () => {
                if (this.state.deleteResult?.isdeleted) {
                  ShowSuccessMessage("Invocation Schedule Successfully Deleted.");
                  this.props.history.push("/invocation-schedule/list");
                }
              }
            );
          }
        });
    }
  }
  render() {
    return (
      <Fragment>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h5>Delete Invocation Schedule 'InvocationSchedule #{this.props.match.params.id}'</h5>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <InvocationSchedulesTabs
                  id={this.props.match.params.id}
                  url="/invocation-schedule/delete/"
                />
                <div className="alert font-weight-bold  alert-light">
                  {" "}
                  <i className="fas fa-exclamation-circle text1"></i> Are you
                  sure you want to delete this Invocation Schedule{" "}
                  <strong>"#{this.props.match.params.id}”</strong> ?
                </div>

                <p className="mt-4 text-danger">
                  <i className="fas fa-exclamation-triangle text1"></i> The
                  following related items may be deleted or orphaned:
                </p>
                <div className="row ">
                  <div className="col-4">
                    <div className="card card-primary shadow-sm">
                      <div className="card-header">
                        <h4>Invocation Schedule</h4>
                      </div>
                      <div className="card-body">
                        <NavLink
                          className="badge badge-light text-link"
                          to={"/invocation-schedule/detail/" + this.props.match.params.id}
                        >
                          InvocationSchedule #{this.props.match.params.id}
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="mx-auto">
                <button
                  className="btn btn-lg btn-danger"
                  disabled={this.state.isDoubleleClick}
                  onClick={this.deleteRecord}
                >
                  {" "}
                  {ButtonType.Delete}
                </button>
                <NavLink
                  type="button"
                  className="btn btn-lg btn-default ml-3"
                  to="/invocation-schedule/list"
                >
                  {ButtonType.Cancel}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
