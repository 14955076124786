import React, { Component, Fragment } from 'react'
import { NavLink } from "react-router-dom";

interface IProps {
    id: number,
    url: string,
    name?:string
}

export  class CustomerFaviconTabs extends Component <IProps>{
    render() {
        return (
            <Fragment>
            <ul className="nav nav-tabs" id="myTab" role="tablist">                            
                <li className="nav-item">
                    <NavLink to={"/customer-favicon/detail/" + this.props.id} className={this.props.url == "/customer-favicon/detail/" ? "nav-link active" : "nav-link"} id="show-tab" data-toggle="tab" href="#show" role="tab"
                        aria-controls="show" aria-selected={this.props.url == "/customer-favicon/detail/" ? "true" : "false"}><i className="fas fa-info-circle"></i>&nbsp;Show</NavLink>
                </li>
            </ul>
            <br />
        </Fragment>
        )
    }
}
