export class FileDownloadService{
   async downloadFileByStream(result:any,filename:string){
        if(result){
            var file = new Blob([result], {
                type: 'application/octet-binary'
            });
            var fileUrl = URL.createObjectURL(file);
            // for IE 10+
            if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(file, filename);
            } else {
                var element = document.createElement('a');
                element.href = fileUrl;
                element.setAttribute('download', filename);
                element.setAttribute('target', '_blank');
                document.body.appendChild(element); //Append the element to work in firefox
                element.click();
            }

        }
    }
}