import React, { Component, Fragment } from 'react'
import Breadcrumbs from '../../shared/Breadcrumbs'
import { InstalledApplicationsRoute } from '../../routing'

export class InstalledApplicationPage extends Component {
    render() {
        return (
            <Fragment>
                <Breadcrumbs />
                <InstalledApplicationsRoute />
            </Fragment>
        )
    }
}
