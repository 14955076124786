import { HttpWrapper, AppConfig } from "../../../../../../core";
import { PostExportModel } from "../../../../../../shared/export/post.export.model";
import { AppletsListResponseModel } from "../models/applets.list.response.model";
import { AppletsDetailModel } from "../models/applets.detail.model";
import { AppletsDeleteModel } from "../models/applets.delete.model";
import { DeleteResponseModel } from "../../../../../../shared/models";

export class AppletsService {
    private wrapper: HttpWrapper;
    constructor() {
        this.wrapper = new HttpWrapper();
    }

    getAppletsList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
        return this.wrapper.post<AppletsListResponseModel>(AppConfig.apiEndpoint + '/v1/applet/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }   

    getAppletsById(id: number) {
        return this.wrapper.get<AppletsDetailModel>(AppConfig.apiEndpoint + '/v1/applet/' + id);
    }

    getLinkedResourcesById(id: number) {
        return this.wrapper.get<AppletsDeleteModel>(AppConfig.apiEndpoint + '/v1/applet/' + id+'/linked_resources');
    }

    deleteRecordById(id: number) {
        return this.wrapper.delete<DeleteResponseModel>(AppConfig.apiEndpoint + '/v1/applet/' + id);
    }

    exportApplets(exportData?: PostExportModel) {
        return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/applet/export', exportData);
    }

} 