import React, { Component, Fragment } from "react";
import { AccountPostModel, EditAccountModel } from "../accounts/models";
import { AccountService } from "./services/account.service";
import { CommonService } from "../../shared/services/common.service";
import { HttpResponse } from "../../core";
import {
  ShowSuccessMessage,
  GetDaysList,
  ConvertUtcToDate,
  GetLicenseList,
  GetProcessStatusList,
  ValidateUrl,
  ValidateLogoUrl,
} from "../../shared/helpers";
import { ButtonType } from "../../shared/enums";
import AccountTabs from "./AccountTabs";
import { Loader } from "../../shared/loaders";
import { Link, NavLink, Route } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ConvertDateTime } from "./../../shared/helpers";
import { DropdownItemModel, AdminTypeModel } from "../../shared/models";
import { DatabaseEngineTypeModel } from "../../shared/models/database.engine.type.model";
import toDate from "moment";
import moment from "moment";
import { OverlayTrigger, Tooltip, Button, Accordion, Card } from "react-bootstrap";
import { DatabaseEngineModel } from './models/database.engine.model';
import { DatabaseEngineIdResultsModel, ResponseDatabaseEngineModel } from "../../shared/models/database.engine.id.model";

interface IState {
  account?: AccountPostModel;
  editAccount?: EditAccountModel;
  id?: number;
  name?: string;
  active?: boolean;
  federated?: boolean;
  state?: string;
  report_status?: number;
  backup_day?: number;
  data_retention_days?: number;
  trial?: boolean;
  priority?: number;
  notes?: string;
  contract_date?: Date | null;
  contract_renewal_date?: Date | null;
  contract_billing_schedule?: string;
  contract_discount?: number;
  contract_unit_price?: number;
  contract_unit_count?: number;
  db_engine_id?: number[];
  db_engine_info?: DatabaseEngineIdResultsModel["records"];
  logo_url?: string;
  favicon_url?: string;
  url?: string;
  domain?: string;
  sftp_account_name?: string;
  report_schedule_start?: string | null;
  report_schedule_stop?: string | null;
  pause_scheduled_reports?: boolean;

  isShowtext?: string;
  isSave?: string;
  isSaving?: boolean;

  admins?: Array<AdminTypeModel>;
  unassigned_admins?: Array<AdminTypeModel>;
  Ids?: number[];
  searchText?: string;
  searchNum?: number;
  searchList?: Array<AdminTypeModel>;



  isContactDateChange?: boolean;
  isContactRenewlDateChange?: boolean;
  favUrlError?: string;
  logoUrlError?: string;
  domainUrlError?: string;

  showId?: boolean;
  isNew?: boolean;
}
export class AccountForm extends Component<any, IState> {
  //declaring service type
  private service: AccountService;
  private commonService: CommonService;
  private roleNetworkCall: any;
  private NetworkCall: any;
  db_engine_info: any;

  constructor(props) {
    super(props);
    this.service = new AccountService();
    this.commonService = new CommonService();
    this.state = this.initialState;
  }

  initialState: Partial<IState> = {
    account: {
      admins: [],
      // unassigned_admins: [],
      active: true,
      federated: false,
      backup_day: 0,
      contract_billing_schedule: "",
      contract_date: null,
      contract_discount: 0,
      contract_renewal_date: null,
      contract_unit_count: 0,
      contract_unit_price: 0,
      data_retention_days: 0,
      db_engine_id: [],
      domain: "",
      favicon_url: "",
      id: 0,
      logo_url: "",
      name: "",
      notes: "",
      pause_scheduled_reports: false,
      priority: 0,
      report_schedule_start: null,
      report_schedule_stop: null,
      report_status: 0,
      sftp_account_name: "",
      state: "",
      trial: false,
      url: "",
    },
    active: true,
    federated: false,
    backup_day: 0,
    contract_billing_schedule: "",
    contract_date: null,
    contract_discount: 0,
    contract_renewal_date: null,
    contract_unit_count: 0,
    contract_unit_price: 0,
    data_retention_days: 1095,
    db_engine_id: [],
    db_engine_info: [],
    domain: "",
    favicon_url: "",
    id: 0,
    logo_url: "",
    name: "",
    notes: "",
    pause_scheduled_reports: false,
    priority: 0,
    report_schedule_start: null,
    report_schedule_stop: null,
    report_status: GetProcessStatusList[0].value,
    sftp_account_name: "",
    state: "free",
    trial: false,
    url: "",
    isShowtext: "New",
    isSave: "",
    isSaving: false,
    admins: [],
    unassigned_admins: [],
    Ids: [],
    searchText: "",
    isContactDateChange: false,
    isContactRenewlDateChange: false,
    domainUrlError: "",
    logoUrlError: "",
    favUrlError: "",
  };

  componentDidMount() {
    if (this.props.match.params.id > 0) {
      this.setState(
        { id: this.props.match.params.id, isShowtext: "Edit" },
        () => {
          this.loadData();
        }
      );
    }
    if (this.props.match.params.id == 0) {
      this.getAllAdmins();
      this.getAllDBEIds();
    }
  }

  getAllAdmins() {
    this.commonService
      .getAllAdmin()
      .then((res: HttpResponse<AdminTypeModel[]>) => {
        if (res) {
          this.setState({
            unassigned_admins: res.result,
            searchList: res.result,
          });
        }
      });
  }


  loadData() {
    this.service
      .editAccount(this.props.match.params.id)
      .then((res: HttpResponse<EditAccountModel>) => {
        if (res && res.result) {
          this.setState(
            {
              url: res.result?.url,
              active: res.result?.active,
              federated: res.result?.federated,
              admins: res.result?.admins,
              unassigned_admins: res.result?.unassigned_admins,
              searchList: res.result?.unassigned_admins,
              backup_day: res.result?.backup_day,
              contract_billing_schedule: res.result?.contract_billing_schedule,
              contract_date:
                res.result?.contract_date != null
                  ? ConvertUtcToDate(res.result?.contract_date)
                  : null,
              contract_renewal_date:
                res.result?.contract_renewal_date != null
                  ? ConvertUtcToDate(res.result?.contract_renewal_date)
                  : null,
              contract_discount: res.result?.contract_discount,
              contract_unit_count: res.result?.contract_unit_count,
              contract_unit_price: res.result?.contract_unit_price,
              db_engine_id: res.result?.db_engine_id,
              db_engine_info: res.result?.db_engine_info,
              data_retention_days: res.result?.data_retention_days,
              domain: res.result?.domain,
              favicon_url: res.result?.favicon_url,
              id: res.result?.id,
              logo_url: res.result?.logo_url,
              name: res.result?.name,
              notes: res.result?.notes,
              pause_scheduled_reports: res.result?.pause_scheduled_reports,
              priority: res.result?.priority,
              report_schedule_start: res.result?.report_schedule_start,
              report_schedule_stop: res.result?.report_schedule_stop,
              report_status: res.result?.report_status,
              sftp_account_name: res.result?.sftp_account_name,
              state: res.result?.state,
              trial: res.result?.trial,
            },
            () => { }
          );
        }
      })
      .catch((ex) => {
        if (ex.status == 404) {
          this.props.history.push("/account/list");
        }
      });
  }

  handleChange = (event: any) => {
    const isCheckbox = event.target.type === "checkbox";
    let name = event.target.name;
    let value = event.target.value;
    this.setState(
      {
        [event.target.name]: isCheckbox
          ? event.target.checked
          : event.target.value,
      },
      () => {
        this.handleSearch(name, value);
        this.validate();
      }
    );
  };

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  handleDateChange = (date, name) => {
    if (name == "contract_date") {
      this.setState({ contract_date: date, isContactDateChange: true });
    }
    if (name == "contract_renewal_date") {
      this.setState({
        contract_renewal_date: date,
        isContactRenewlDateChange: true,
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const isValid = this.validate();
    let accountData: AccountPostModel = {
      url: this.state.url,
      active: this.state.active,
      federated: this.state.federated,
      backup_day: this.state.backup_day,
      contract_billing_schedule: this.state.contract_billing_schedule,
      contract_date:
        this.state.contract_date != null
          ? ConvertUtcToDate(this.state.contract_date)
          : null,
      contract_discount: this.state.contract_discount,
      contract_renewal_date:
        this.state.contract_renewal_date != null
          ? ConvertUtcToDate(this.state.contract_renewal_date)
          : null,
      contract_unit_count: this.state.contract_unit_count,
      contract_unit_price: this.state.contract_unit_price,
      data_retention_days: this.state.data_retention_days,
      db_engine_id: this.state.db_engine_id,
      domain: this.state.domain,
      favicon_url: this.state.favicon_url,
      id: this.state.id,
      logo_url: this.state.logo_url,
      name: this.state.name,
      notes: this.state.notes,
      pause_scheduled_reports: this.state.pause_scheduled_reports,
      priority: this.state.priority,
      report_schedule_start: this.state.report_schedule_start,
      report_schedule_stop: this.state.report_schedule_stop,
      report_status: this.state.report_status,
      sftp_account_name: this.state.sftp_account_name,
      state: this.state.state,
      trial: this.state.trial,
      admins: this.state.admins,
      // unassigned_admins: this.state.unassigned_admins
    };

    if (isValid) {
      if (accountData.id === 0) {
        if (this.state.isContactDateChange) {
          accountData.contract_date = null;
        }
        if (this.state.isContactRenewlDateChange) {
          accountData.contract_renewal_date = null;
        }
        this.postData(accountData);
      } else {
        this.updateData(accountData);
      }
    }
  };

  validate = () => {
    let evalUrlError = "";
    let domainUrlError = "";
    let logoUrlError = "";

    if (!ValidateUrl(this.state.favicon_url) && this.state.favicon_url) {
      evalUrlError = "Favicon url must begin with https://";
    }

    if (!ValidateUrl(this.state.url) && this.state.url) {
      domainUrlError =
        "The URL of the company's main website. Must begin with 'https://'.";
    }

    if (!ValidateLogoUrl(this.state.logo_url) && this.state.logo_url) {
      logoUrlError =
        "Must begin with 'https://' and end with '.gif', '.jpeg', '.jpg', or '.png'.";
    }

    if (evalUrlError || domainUrlError || logoUrlError) {
      this.setState({
        favUrlError: evalUrlError,
        domainUrlError: domainUrlError,
        logoUrlError: logoUrlError,
      });
      return false;
    }

    if (!evalUrlError || !domainUrlError || !logoUrlError) {
      domainUrlError = "";
      evalUrlError = "";
      logoUrlError = "";
      this.setState({
        domainUrlError: domainUrlError,
        favUrlError: evalUrlError,
        logoUrlError: logoUrlError,
      });
      return true;
    }
  };

  postData(account: AccountPostModel) {
    this.setSavingFlag(true);
    this.service.postAccount(account).then(
      (res: HttpResponse<AccountPostModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              id: res.result.id,
            },
            () => {
              ShowSuccessMessage("Account successfully created.");
              if (this.state.isSave === ButtonType.Save) {
                this.props.history.push("/account/detail/" + this.state.id);
                this.setState(this.initialState);
              }
              if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                this.setState(this.initialState);
                this.props.history.push("/account/account-form/0");
              }
              if (this.state.isSave === ButtonType.SaveAndEdit) {
                this.props.history.push(
                  "/account/account-form/" + this.state.id
                );
                this.setState({ isShowtext: "Edit" });
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  updateData(account: AccountPostModel) {
    this.setSavingFlag(true);
    this.service.updateAccount(account).then(
      (res: HttpResponse<AccountPostModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              id: res.result.id,
            },
            () => {
              ShowSuccessMessage("Account successfully updated.");
              if (this.state.isSave === ButtonType.Save) {
                this.props.history.push("/account/detail/" + this.state.id);
                this.setState(this.initialState);
              }
              if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                this.setState(this.initialState);
                this.props.history.push("/account/account-form/0");
              }
              if (this.state.isSave === ButtonType.SaveAndEdit) {
                this.props.history.push(
                  "/account/account-form/" + this.state.id
                );
                this.setState({ isShowtext: "Edit" });
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  handleClick = (event) => {
    this.setState({ isSave: event.target.value });
  };
  private setSavingFlag(saving: boolean) {
    this.setState({ isSaving: saving });
  }

  onLeftSelectionChange(e: any) {
    let data = this.state.unassigned_admins?.find(
      (p) => p.value == e.target.value
    );
    this.state.admins?.push({
      text: data?.text,
      value: data?.value,
      isChecked: data?.isChecked,
    });
    this.setState({
      unassigned_admins: this.state.unassigned_admins?.filter(
        (p) => p.value != e.target.value
      ),
      searchList: this.state.searchList?.filter(
        (p) => p.value != e.target.value
      ),
      admins: this.state.admins,
    });
  }

  selectAll() {
    this.state.unassigned_admins?.forEach((p) => {
      this.state.admins?.push(p);
    });
    this.setState({
      unassigned_admins: [],
      admins: this.state.admins,
    });
  }

  onRightSelectionChange(e: any) {
    let data = this.state.admins?.find((p) => p.value == e.target.value);
    this.state.unassigned_admins?.push({
      text: data?.text,
      value: data?.value,
      isChecked: data?.isChecked,
    });
    this.setState({
      admins: this.state.admins?.filter((p) => p.value != e.target.value),
      unassigned_admins: this.state.unassigned_admins,
    });
  }

  clearAll() {
    this.state.admins?.forEach((p) => {
      this.state.unassigned_admins?.push(p);
    });
    this.setState({
      admins: [],
      unassigned_admins: this.state.unassigned_admins,
    });
  }

  handleSearch(name, value) {
    if (name == "searchText") {
      let list = this.state.unassigned_admins;
      list = list?.filter((p) =>
        p.text?.toLowerCase().startsWith(value.toLowerCase())
      );

      if (value) {
        this.setState({ unassigned_admins: list });
      } else {
        this.setState({ unassigned_admins: this.state.searchList });
      }
    }
    if (name == "searchNum") {
      let list = this.state.db_engine_id;
      if (value) {
        this.setState({ db_engine_id: list });
      } else {
        this.setState({ db_engine_id: this.DBEIDS() });
      }
    }
  }

  getAllDBEIds() {
    this.commonService.getAllDBEID()
      .then((res: HttpResponse<DatabaseEngineIdResultsModel>) => {
        if (res) {
          this.setState({
            db_engine_info: res.result?.records,
          });

        }
        return this.db_engine_info.records;
      });
  }
  DBEIDS() {
    let dbid: number[] = [];
    var recordsinfo: any = Array();
    recordsinfo = this.getAllDBEIds();
    for (let i = 0; i < recordsinfo.length; i++) {
      const element = recordsinfo[i];
      dbid.push(element.id)

    }
    return dbid;
  }

  handleChangeId = (event: any) => {
    const isCheckbox = event.target.type === "checkbox";
    let name = event.target.name;
    let value = event.target.value;
    this.setState(
      {
        [event.target.name]: isCheckbox
          ? event.target.checked
          : event.target.value,
      },
      () => {
        this.handleSearch(name, value);
        this.validate();
      }
    );
  };

  render() {
    return (
      <Fragment>
        <form onSubmit={this.handleSubmit}>
          <input type="hidden" value={this.state.id} />
          <div>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h5>
                {this.state.isShowtext} Account
                {this.state.name && <span> '{this.state.name}'</span>}
              </h5>
            </div>
            <div className="row">
              <div className="col-md-12 col-xl-12">
                <div className="card">
                  <div className="card-body">
                    {this.props.match.params.id != 0 && (
                      <AccountTabs
                        id={this.props.match.params.id}
                        url="/account/account-form/"
                      />
                    )}
                    <div className="row">
                      <div className="col-xl-4 col-md-4">
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            type="text"
                            maxLength={255}
                            name="name"
                            value={this.state.name}
                            placeholder="Enter Name"
                            onChange={this.handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4">
                        <div className="form-group">
                          <label>
                            License Type
                            {/* <a
                              href="javascript:void(0)"
                              data-toggle="tooltip"
                              title=""
                              data-original-title="The type of license determines the functionality available to account users."
                            >
                              <i className="fas fa-info-circle"></i>
                            </a> */}
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="button-tooltip">
                                  The type of license determines the
                                  functionality available to account users.
                                </Tooltip>
                              }
                            >

                              <i className="fas fa-info-circle ml-1 btn btn-outline-dark border-0 btn-sm"></i>

                            </OverlayTrigger>
                          </label>
                          <select
                            className="form-control"
                            name="state"
                            value={this.state.state}
                            onChange={this.handleChange}
                          >
                            {GetLicenseList.map((item, index) => {
                              return (
                                <option key={index} value={item.value}>
                                  {item.value}
                                </option>
                              );
                            })}
                          </select>
                          {/* <small>The type of license determines the functionality available to account users.</small> */}
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4">
                        <div className="form-group">
                          <label>
                            Report Process Status
                            <OverlayTrigger
                              placement="right"
                              overlay={
                                <Tooltip id="button-tooltip">
                                  Please do not change this! Reports will
                                  not run for this account unless this value
                                  is 'job_processing_active'. If this value
                                  is 'job_processing_pausing' or
                                  'job_processing_paused', report jobs will
                                  be retried until this value is again
                                  'job_processing_active'. This is used only
                                  for migrating between report databases.
                                </Tooltip>
                              }
                            >

                              <i className="fas fa-info-circle btn btn-outline-dark border-0 btn-sm ml-1"></i>

                            </OverlayTrigger>
                          </label>
                          <select
                            className="form-control"
                            name="report_status"
                            value={this.state.report_status}
                            onChange={this.handleChange}
                          >
                            {GetProcessStatusList.map((item, index) => {
                              return (
                                <option key={index} value={item.value}>
                                  {item.text}
                                </option>
                              );
                            })}
                          </select>
                          {/* <small>Please do not change this! Reports will not run for this account unless this value is 'job_processing_active'. If this value is 'job_processing_pausing' or 'job_processing_paused', report jobs will be retried until this value is again 'job_processing_active'. This is used only for migrating between report databases.</small> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group shadow-sm p-3 mb-4">
                          <label>
                            Backup Day of the Week
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="button-tooltip">
                                  Do not change unless you have discussed
                                  with the reporting team first
                                </Tooltip>
                              }
                            >
                              <i className="fas fa-info-circle btn btn-outline-dark border-0 btn-sm ml-1"></i>
                            </OverlayTrigger>
                          </label>
                          <select
                            className="form-control"
                            name="backup_day"
                            value={this.state.backup_day}
                            onChange={this.handleChange}
                          >
                            {GetDaysList.map(
                              (item: DropdownItemModel, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.text}
                                  </option>
                                );
                              }
                            )}
                          </select>
                          {/* <small>Do not change unless you have discussed with the reporting team first</small> */}
                        </div>
                      </div>
                      <div className="col-md-4 ">
                        <div className="form-group shadow-sm p-3 mb-4 ">
                          <label>
                            Data Retention Days
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="button-tooltip">

                                  The number of days to retain data in
                                  connections and custom connections.
                                  Default is 1095 (3 years). A value of zero
                                  or less disables purging of old data
                                  entirely.
                                </Tooltip>
                              }
                            >
                              <i className="fas fa-info-circle btn btn-outline-dark border-0 btn-sm ml-1"></i>
                            </OverlayTrigger>
                          </label>
                          <input
                            type="number"
                            name="data_retention_days"
                            value={this.state.data_retention_days}
                            onChange={this.handleChange}
                            className="form-control"
                          />
                          {/* <small>The number of days to retain data in connections and custom connections. Default is 1095 (3 years). A value of zero or less disables purging of old data entirely.</small> */}
                        </div>
                      </div>
                      <div className="col-md-4 ">
                        <div className="form-group shadow-sm p-3 mb-4">
                          <label>
                            Priority
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="button-tooltip">

                                  Select a higher number for higher priority
                                  accounts. A priority of 0 indicates very
                                  low priority.
                                </Tooltip>
                              }
                            >
                              <i className="fas fa-info-circle btn btn-outline-dark border-0 btn-sm ml-1"></i>
                            </OverlayTrigger>
                          </label>
                          <input
                            type="number"
                            name="priority"
                            value={this.state.priority}
                            onChange={this.handleChange}
                            className="form-control"
                          />
                          {/* <small>Select a higher number for higher priority accounts. A priority of 0 indicates very low priority.</small> */}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group shadow-sm p-3 mb-4 ">

                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="active"
                              checked={this.state.active}
                              onChange={this.handleChange}
                              className="custom-control-input"
                              id="active"
                            />
                            <label className="custom-control-label" htmlFor="active">Active</label>
                          </div>
                          <small>Select it to Activate</small>
                        </div>
                        <div className="form-group shadow-sm p-3 mb-4 ">

                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="federated"
                              checked={this.state.federated}
                              onChange={this.handleChange}
                              className="custom-control-input"
                              id="federated"
                            />
                            <label className="custom-control-label" htmlFor="federated">Federated</label>
                          </div>
                          <small>Federated</small>
                        </div>
                        <div className="form-group shadow-sm p-3 mb-4 ">

                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="trial"
                              checked={this.state.trial}
                              onChange={this.handleChange}
                              className="custom-control-input"
                              id="trial"
                            />
                            <label className="custom-control-label" htmlFor="trial">Trial</label>
                          </div>
                          <small>
                            Used by sales for billing. Selecting this does
                            not change the application's behavior.
                          </small>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group  shadow-sm p-3 mb-0">
                          <label>Assigned STAQ Contacts</label>
                          <input
                            type="tex"
                            name="searchText"
                            value={this.state.searchText}
                            placeholder="Search"
                            onChange={this.handleChange}
                            className="form-control form-control-sm"
                          />
                        </div>
                        <div className="row">
                          <div className="col-md-6" style={{ paddingRight: "0px" }}>
                            <div className="form-group customScrollBar  shadow-sm p-3 mb-2">
                              {this.state.unassigned_admins?.map(
                                (item, index) => {
                                  return (
                                    <div key={index}>
                                      <div>
                                        <input
                                          type="checkbox"
                                          style={{ display: "none" }}
                                          value={item.value || ""}
                                          checked={item.isChecked || false}
                                          onChange={this.onLeftSelectionChange.bind(
                                            this
                                          )}
                                          id={"checkbox" + item.value}
                                          name={"checkbox" + item.value}
                                        />
                                        <label
                                          htmlFor={"checkbox" + item.value}
                                        >
                                          {item.text}
                                        </label>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                            <button
                              type="button"
                              onClick={this.selectAll.bind(this)}
                              className="btn btn-default btn-sm mr-2"
                            >
                              Choose All <i className="fas fa-chevron-circle-right"></i>
                            </button>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group customScrollBar  shadow-sm p-3 mb-2">
                              {this.state.admins?.map((item, index) => {
                                return (
                                  <div key={index}>
                                    <div>
                                      <input
                                        style={{ display: "none" }}
                                        type="checkbox"
                                        value={item.value || ""}
                                        checked={item.isChecked || false}
                                        onChange={this.onRightSelectionChange.bind(
                                          this
                                        )}
                                        id={"checkbox" + item.value}
                                        name={"checkbox" + item.value}
                                      />
                                      <label
                                        htmlFor={"checkbox" + item.value}
                                      >
                                        {item.text}
                                      </label>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            <button
                              type="button"
                              onClick={this.clearAll.bind(this)}
                              className="btn btn-default btn-sm"
                            >
                              <i className="fas fa-chevron-circle-left"></i> Clear All
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group shadow-sm p-3 mb-0">
                          <label>Notes</label>
                          <textarea
                            rows={10}
                            name="notes"
                            value={this.state.notes}
                            onChange={this.handleChange}
                            className="form-control" style={{ 'height': '160px' }}
                          ></textarea>
                          <small>
                            This information is only shown to STAQ
                            staff.
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group shadow-sm p-3 mb-0">
                          <label>Database Engine Id</label>
                          <div className="input-group mb-3">
                            <input
                              type="tex"
                              name="searchNum"
                              value={this.state.searchNum}
                              placeholder="Search"
                              onChange={this.handleChange}
                              className="form-control form-control-sm"
                            />
                          </div>
                          <small>
                            Ask a data engineer for which database
                            engine to use.
                          </small>
                        </div>
                      </div>
                    </div>

                    <hr />
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Contract Date</label>
                          <DatePicker
                            popperModifiers={{
                              preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: "viewport",
                              },
                            }}
                            dateFormat="MMMM d, yyyy"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            selected={this.state.contract_date}
                            onChange={(date) =>
                              this.handleDateChange(date, "contract_date")
                            }
                            className="form-control "
                          />
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4">
                        <div className="form-group">
                          <label>Contract Renewal Date</label>
                          <DatePicker
                            popperModifiers={{
                              preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: "viewport",
                              },
                            }}
                            dateFormat="MMMM d, yyyy"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            selected={this.state.contract_renewal_date}
                            onChange={(date) =>
                              this.handleDateChange(
                                date,
                                "contract_renewal_date"
                              )
                            }
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4">
                        <div className="form-group">
                          <label>Contract Billing Schedule</label>
                          <input
                            type="text"
                            maxLength={255}
                            name="contract_billing_schedule"
                            value={this.state.contract_billing_schedule}
                            placeholder=""
                            onChange={this.handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xl-4 col-md-4">
                        <div className="form-group">
                          <label>Contract Discount</label>
                          <input
                            type="text"
                            maxLength={255}
                            name="contract_discount"
                            value={this.state.contract_discount}
                            placeholder=""
                            onChange={this.handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4">
                        <div className="form-group">
                          <label>Contract Unit Price</label>
                          <input
                            type="text"
                            maxLength={255}
                            name="contract_unit_price"
                            value={this.state.contract_unit_price}
                            placeholder=""
                            onChange={this.handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4">
                        <div className="form-group">
                          <label>Contract Unit Count</label>
                          <input
                            type="number"
                            maxLength={255}
                            name="contract_unit_count"
                            value={this.state.contract_unit_count}
                            placeholder=""
                            onChange={this.handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xl-4 col-md-4">
                        <div className="form-group">
                          <label>Logo URL</label>
                          <input
                            type="text"
                            maxLength={255}
                            name="logo_url"
                            value={this.state.logo_url}
                            placeholder=""
                            onChange={this.handleChange}
                            className={
                              !this.state.logoUrlError
                                ? "form-control"
                                : "form-control  is-invalid"
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.logoUrlError}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4">
                        <div className="form-group">
                          <label>Favicon URL</label>
                          <input
                            type="text"
                            maxLength={255}
                            name="favicon_url"
                            value={this.state.favicon_url}
                            placeholder=""
                            onChange={this.handleChange}
                            className={
                              !this.state.favUrlError
                                ? "form-control"
                                : "form-control  is-invalid"
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.favUrlError}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4">
                        <div className="form-group">
                          <label>Homepage URL</label>
                          <input
                            type="text"
                            maxLength={255}
                            name="url"
                            value={this.state.url}
                            placeholder=""
                            onChange={this.handleChange}
                            className={
                              !this.state.domainUrlError
                                ? "form-control"
                                : "form-control  is-invalid"
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.domainUrlError}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6 col-md-6">
                        <div className="form-group">
                          <label>White Label Domain</label>
                          <input
                            type="text"
                            maxLength={255}
                            name="domain"
                            value={this.state.domain}
                            placeholder=""
                            onChange={this.handleChange}
                            className="form-control"
                          />
                          <small>
                            Enter the URL that account users will use to
                            access STAQ. Only set this for accounts that are
                            using the white label feature.
                          </small>
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6">
                        <div className="form-group">
                          <label>SFTP Account Name</label>
                          <input
                            type="text"
                            maxLength={255}
                            name="sftp_account_name"
                            value={this.state.sftp_account_name}
                            placeholder=""
                            onChange={this.handleChange}
                            className="form-control"
                          />
                          <small>
                            Type a name with all lowercase letters, no
                            punctuation, and no special symbols.
                          </small>
                        </div>
                      </div>
                    </div>



                    <Accordion>
                      <Card className="accoridan-ss">
                        <Card.Header className="accoridan-ss--header">
                          <Accordion.Toggle as={Button} variant="link" eventKey="0"             >
                            <h6 className="mb-1">Report Scheduling <small>(click to expand)</small></h6>
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <small>
                              When the report scheduling window (start/stop) is not
                              set, dependent jobs will not be created by parent
                              sources for scheduled reports. They will only run when
                              they are scheduled. If a start/stop time is specified,
                              parents will create dependent jobs for scheduled
                              reports outside of that window. Schedules will always
                              run at the scheduled time regardless of the window
                              settings unless the pause scheduled reports checkbox
                              is checked.
                            </small>
                            <hr />
                            <div className="row mt-3">
                              <div className="col-xl-4 col-md-4">
                                <div className="form-group">
                                  <label>Report Schedule Start</label>
                                  <input
                                    type="time"
                                    name="report_schedule_start"
                                    placeholder="HH:MM"
                                    value={this.state.report_schedule_start || ""}
                                    onChange={this.handleChange}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="col-xl-4 col-md-4">
                                <div className="form-group">
                                  <label>Report Schedule Stop</label>
                                  <input
                                    type="time"
                                    name="report_schedule_stop"
                                    placeholder="HH:MM"
                                    value={this.state.report_schedule_stop || ""}
                                    onChange={this.handleChange}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="col-xl-4 col-md-4">
                                <div className="form-group mt-4">
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      name="pause_scheduled_reports"
                                      checked={this.state.pause_scheduled_reports}
                                      onChange={this.handleChange}
                                      className="custom-control-input"
                                      id="pause_scheduled_reports"
                                    />
                                    <label className="custom-control-label" htmlFor="pause_scheduled_reports">Pause Scheduled Reports</label>
                                  </div>
                                  <small>
                                    This will prevent scheduled reports from
                                    running until unchecked.
                                  </small>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>


                  </div>
                </div>
              </div>
            </div>

            <div className="text-center mt-3 mb-4">
              <Link
                type="button"
                className="btn btn-lg btn-default"
                to="/account/list"
              >
                {ButtonType.Cancel}
              </Link>
              {!this.state.isSaving && (
                <Fragment>
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    onClick={this.handleClick}
                    value={ButtonType.Save}
                  />
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    value={ButtonType.SaveAndAddAnother}
                    onClick={this.handleClick}
                  />
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    value={ButtonType.SaveAndEdit}
                    onClick={this.handleClick}
                  />
                </Fragment>
              )}
              <Loader
                loading={this.state.isSaving}
                marginBottom="0px"
                marginTop="8px"
                width="368px"
              ></Loader>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}
