import React, { Component, Fragment } from 'react'
 
export class DashboardPage extends Component {
    componentDidMount() {
        setTimeout(() => {
          this.reloadPage()
        }, 1000)        
      }
    reloadPage()
    {
      if( window.localStorage )
      {
        if( !localStorage.getItem('firstLoad') )
        {
          localStorage['firstLoad'] = true;
          window.location.reload(false);
        }
        else
          localStorage.removeItem('firstLoad');
      }
    }
    render() {
        return (
            <Fragment>
                <div style={{ 'height': 'calc(100vh - 50px)', position: 'relative', 'float': 'left', 'width': '100%', 'background': 'url(../../dash-back-16.jpg) no-repeat right center ', 'backgroundSize': '80%', backgroundAttachment: 'fixed' }}>
                    <div style={{ width: '425px', marginLeft: '3.5%', marginTop: '15%', background: 'white', borderRadius: '5px' }}><img src="../../staq-admin.png" loading="lazy" style={{ width: '100%' }} /></div>
                    <div style={{ position: 'absolute', left: '5%', bottom: '8%', width: '550px' }}>
                        <span className="badge badge-dark m-1 p-2 text-uppercase rounded">Useful Links :</span>
                        <a href="https://sites.google.com/a/staq.com/staqnowledged/" target="_blank" className="btn btn-default btn-sm m-1" >STAQ Wiki</a>
                        <a href="https://admin-staging.gke.staq-infra.com/quality/" target="_blank" className="btn btn-default btn-sm m-1" >Quality Dashboard</a>
                        <a href="https://admin-staging.gke.staq-infra.com/turbo_qa/" target="_blank" className="btn btn-default btn-sm m-1" >Turbo QA</a>
                        <a href="https://admin-staging.gke.staq-infra.com/tag_matcher/" target="_blank" className="btn btn-default btn-sm m-1" >Tag Matcher</a>
                        <a href="https://admin-staging.gke.staq-infra.com/tag_unwrappings" target="_blank" className="btn btn-default btn-sm m-1" >Tag Unwrapper</a>
                        <a href="https://admin-staging.gke.staq-infra.com/sidekiq" target="_blank" className="btn btn-default btn-sm m-1" >Sidekiq Queue</a>
                        <a href="https://admin-staging.gke.staq-infra.com/pghero/" target="_blank" className="btn btn-default btn-sm m-1" >Postgres database analysis</a>
                        <a href="https://admin-staging.gke.staq-infra.com/hawq_queries/active" target="_blank" className="btn btn-default btn-sm m-1" >Current HAWQ Queries</a>
                        <a href="https://admin-staging.gke.staq-infra.com/hawq_queries/blocked" target="_blank" className="btn btn-default btn-sm m-1" >Blocked HAWQ Queries</a>
                    </div>
                </div>
            </Fragment>
        )
    }
}