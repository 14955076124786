import React, { Component, Fragment } from 'react'
import Breadcrumbs from '../../shared/Breadcrumbs'
import { ScopesRoute } from '../../routing'

export  class ScopesPage extends Component {
    render() {
        return (
            <Fragment>
            <Breadcrumbs />
            <ScopesRoute />
          </Fragment>
        )
    }
}
