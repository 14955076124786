import React, { Component, Fragment } from 'react'
import { ControlMissionResponseModel, TasksModel, TransitionsModel } from './models/control.mission.response.model'
import { ControlMissionService } from '../control-mission/services/controlo.mission.service'
import { HttpResponse } from '../../../core';
import { ConvertDateTime } from '../../../shared/helpers';
import { NavLink } from "react-router-dom";
import { Loader } from '../../../shared/loaders';
import { Editor } from '../../../shared';

interface IState {
    result?: ControlMissionResponseModel;
    isLoading?: boolean;
}
export default class ControlMission extends Component<any, IState> {
    private service: ControlMissionService;
    constructor(props) {
        super(props);
        this.service = new ControlMissionService();
        this.state = {
            result: {
                created_at: null,
                id: 0,
                updated_at: new Date(),
                transitions: [],
                tasks: [],
                installed_report_application_id: 0,
                options: "",
                state: "",
                triggered_by: "",
                installed_report_application_name: ""
            },
            isLoading: false,

        }
    }

    componentDidMount() {
        this.loadData();
    }
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getControlMissionById(this.props.match.params.id)
            .then((res: HttpResponse<ControlMissionResponseModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }

            });
    }

    render() {
        return (
            <Fragment>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5>Details for Control Mission 'ControlMission #{this.state.result?.id}'</h5>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                {!this.state.isLoading && <>
                                    <h5 className="mb-3">Basic Info</h5>

                                    <div className="row">
                                        {this.state.result?.state && <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>State</b>
                                                </div>
                                                <div className="card-body">
                                                    <a href={"https://papertrailapp.com/groups/773093/events?q=" + this.state.result?.id}>
                                                        {this.state.result?.state}</a><br />
                                                        Click to see logs

                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.result?.installed_report_application_name && <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Installed Report Application</b>
                                                </div>
                                                <div className="card-body">
                                                    <NavLink to={"/installed-report-application/detail/" + this.state.result.installed_report_application_id}>{this.state.result?.installed_report_application_name}</NavLink>

                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.result?.triggered_by && <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Triggered By</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.triggered_by}
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="row">
                                        
                                    </div>
                                    <div className="row">
                                        {this.state.result?.created_at && <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Created At</b>
                                                </div>
                                                <div className="card-body">
                                                    {ConvertDateTime(this.state.result?.created_at, 'LLL')}
                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.result?.updated_at && <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Updated  At</b>
                                                </div>
                                                <div className="card-body">
                                                    {ConvertDateTime(this.state.result?.updated_at, 'LLL')}
                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.result?.options != "" && <div className="col-md-12 mt-12">
                                            {this.state.result?.id == this.props.match.params.id && this.state.result?.options != null && <div className="col-md-6 mt-6">
                                                <h5>Options</h5>
                                                <Editor StringData={this.state.result?.options} FormateType="yaml" />
                                            </div>}
                                        </div>}

                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="mt-4">Transitions</h5>
                                            <div className="table-responsive">
                                                <table className="table table-sm table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Created At</th>
                                                            <th scope="col">Event</th>
                                                            <th scope="col">From</th>
                                                            <th scope="col">To</th>
                                                            <th scope="col">Message</th>
                                                        </tr>
                                                    </thead>
                                                    {this.state.result?.transitions && <tbody>
                                                        {this.state.result?.transitions.map((item: TransitionsModel, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{ConvertDateTime(item.created_at, 'LLL')}</td>
                                                                    <td>{item.event == "" ? "--" : item.event}</td>
                                                                    <td>{item.from_}</td>
                                                                    <td>{item.to}</td>
                                                                    <td>{item.message == "" ? "--" : item.message}</td>
                                                                </tr>

                                                            );
                                                        })}
                                                    </tbody>}
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="mt-4">Tasks</h5>
                                            <div className="table-responsive">
                                                <table className="table table-sm table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                #
                                                            </th>
                                                            <th>
                                                                State
                                                            </th>
                                                            <th>
                                                                <th>Created At</th>
                                                            </th>
                                                            <th>Updated At</th>
                                                        </tr>
                                                    </thead>
                                                    {this.state.result?.tasks && <tbody>
                                                        {this.state.result?.tasks.map((task: TasksModel, idx) => {
                                                            return (
                                                                <tr key={idx}>
                                                                    <td><NavLink to={"/control_mission/control_mission_task/" + task.id}>{task.id}</NavLink></td>
                                                                    <td><NavLink to={"/control_mission/control_mission_task/" + task.id}>{task.state}</NavLink></td>
                                                                    <td>{ConvertDateTime(task.created_at, 'LLL')}</td>
                                                                    <td>{ConvertDateTime(task.updated_at, 'LLL')}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>} 
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </>}
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
