import React, { Component, Fragment } from 'react'
import { ReportTableStorageModel, TableStorageSettingResponseModel } from './models'
import { UserReportsService } from './services';
import { HttpResponse } from '../../../core';
import { ReportTabs } from '.';
import { Loader } from '../../../shared/loaders';
import { ButtonType } from '../../../shared/enums/button.enum';
import { ShowSuccessMessage, HandleNotFoundResponse } from '../../../shared/helpers';
import { NotFoundResponseArea } from '../../../shared/enums';

interface IState {
    result?: TableStorageSettingResponseModel,
    report_table?: ReportTableStorageModel,
    orientation?: string,
    distributed_by?: string,
    compressed?: boolean,
    partition_strategy?: string;
    isSaving?: boolean;
    isDistributedByMatch?: boolean;
}
export class ReportTableStorageSettings extends Component<any, IState>  {
    private service: UserReportsService;
    constructor(props) {
        super(props)
        this.state = this.initialState;
        this.service = new UserReportsService();
    }

    initialState: Partial<IState> = {
        isSaving: false,
        compressed: false,
        distributed_by: "",
        orientation: "row",
        partition_strategy: "none",
        report_table: {
            compressed: false,
            distributed_by: "",
            orientation: "",
            partition_strategy: ""
        },
        isDistributedByMatch: false

    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.service.geReportTableStorageSettingsById(this.props.match.params.id)
            .then((res: HttpResponse<TableStorageSettingResponseModel>) => {
                if (res && res.result) {
                    this.setState({
                        compressed: res.result?.compressed,
                        distributed_by: res.result?.distributed_by,
                        orientation: res.result?.orientation,
                        partition_strategy: res.result?.partition_strategy,
                        result: res.result
                    }, () => {
                        let currentDistribution = this.state.result?.all_distribution.filter(p => p == this.state.distributed_by);
                        if (currentDistribution?.length == 0) {                            
                            this.setState({ isDistributedByMatch: true })
                        }
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.Reports,this.props)
            });
    }

    handleChange = (event: any) => {
        this.setState({ isDistributedByMatch: false })
        const isCheckbox = event.target.type === "checkbox";
        this.setState({
            [event.target.name]: isCheckbox
                ? event.target.checked
                : event.target.value
        })
    }

    updateData(reporttable: ReportTableStorageModel) {
        this.setSavingFlag(true);
        this.service.updateReportTableStorageSettings(this.props.match.params.id, reporttable)
            .then((res: HttpResponse<ReportTableStorageModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        report_table: res.result
                    });
                }
            }, () => {
                this.props.history.push('/reports/storagesettings/' + this.props.match.params.id, reporttable);
                this.setSavingFlag(false);
            });
    }

    handleSubmit = event => {
        event.preventDefault();
        let reporttabledata: ReportTableStorageModel = {
            compressed: this.state.compressed,
            distributed_by: this.state.distributed_by,
            orientation: this.state.orientation,
            partition_strategy: this.state.partition_strategy
        };
        this.updateData(reporttabledata);
    }

    private setSavingFlag(saving: boolean) {
        this.setState({ isSaving: saving });
    }

    render() {
        return (
            <Fragment>
                <form onSubmit={this.handleSubmit}>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h4>Table Storage Settings</h4>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <ReportTabs id={this.props.match.params.id} url="/report/storagesettings/" />
                                        <div className="row">
                                            <div className="col">
                                                <div className="alert alert-light">
                                                    {/* <i className="fas fa-exclamation-circle text4"></i> */}
                                                    <p> Modifying these settings can dramatically affect a report's runtime. The optimal settings for a table should produce report results as quickly as the data engine can process them however incorrect settings can make the report take longer to run.</p>
                                                    <p>It is recommended you understand the ramifications of the changes you make here and test the report run once complete by clicking "Rebuild Table".</p>
                                                    <p>Discussion on how to change storage settings is maintained in the STAQ Wiki:</p>
                                                    <p><a href="https://staqapp.atlassian.net/wiki/spaces/PLAT/pages/237993985/Report+Table+Considerations+and+Best+Practices" target="_blank">Report Table Considerations and Best Practices <i className="fas fa-external-link-alt"></i></a></p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Table Orientation</label>
                                                    <select className="form-control" name="orientation" value={this.state.orientation} onChange={this.handleChange}>
                                                        <option value="Column">Column</option>
                                                        <option value="Row">Row</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Distribution Keys</label>
                                                    <select className="form-control" name="distributed_by" value={this.state.distributed_by} onChange={this.handleChange}>
                                                    {this.state.isDistributedByMatch==true && <option value={this.state.distributed_by}>{this.state.distributed_by}</option>}
                                                        {this.state.result?.all_distribution.map((item: string, index) => {
                                                            return (
                                                                <option key={index} value={item}>{item}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Partition Strategy</label>
                                                    <select className="form-control" name="partition_strategy" value={this.state.partition_strategy} onChange={this.handleChange}>
                                                        <option value="none">None</option>
                                                        <option value="year">Year</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Compressed?</label>
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" name="compressed" checked={this.state.compressed} onChange={this.handleChange} className="custom-control-input" id="compressed" />
                                                        <label className="custom-control-label" htmlFor="compressed"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-3 mb-4">
                            {!this.state.isSaving && <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.RegenerateTable} />}
                            <Loader loading={this.state.isSaving} marginBottom="0px" marginTop="8px" width="368px" ></Loader>
                        </div> 
                </form>
            </Fragment >
        )
    }
}
