import React, { Component, Fragment } from "react";
import { DeleteResponseModel } from "../../../shared/models";
import { CategoryService } from "./services/category.service";
import { HttpResponse } from "../../../core";
import {
  ShowSuccessMessage,
  HandleNotFoundResponse,
} from "../../../shared/helpers";
import { CategoryTabs } from "./CategoryTabs";
import { NavLink } from "react-router-dom";
import { ButtonType, NotFoundResponseArea } from "../../../shared/enums";
import { CategoryLinkResourcesResponseModel } from "./models/category.link.resource.responses.model";
import {Link} from 'react-router-dom'

interface IState {
  result?: CategoryLinkResourcesResponseModel;
  deleteResult?: DeleteResponseModel;
  isDoubleleClick?: boolean;
}
export class DeleteCategory extends Component<any, IState> {
  private service: CategoryService;
  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.service = new CategoryService();
    this.deleteRecord = this.deleteRecord.bind(this);
  }

  initialState: Partial<IState> = {
    result: {
      id: 0,
      name: "",
      platforms: [],
      platform_categories: [],
    },
    deleteResult: { isdeleted: false },
    isDoubleleClick: false,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.service
      .getLinkedResourcesById(this.props.match.params.id)
      .then((res: HttpResponse<CategoryLinkResourcesResponseModel>) => {
        if (res && res.result) {
          this.setState({
            result: res.result,
          });
        }
      })
      .catch((ex) => {
        HandleNotFoundResponse(ex, NotFoundResponseArea.Category, this.props);
      });
  }

  deleteRecord() {
    if (!this.state.isDoubleleClick) {
      this.setState({ isDoubleleClick: true });
      this.service
        .deleteCategoryById(this.props.match.params.id)
        .then((res: HttpResponse<DeleteResponseModel>) => {
          if (res && res.result) {
            this.setState(
              {
                deleteResult: res.result,
              },
              () => {
                if (this.state.deleteResult?.isdeleted) {
                  ShowSuccessMessage("Category Successfully Deleted.");
                  this.props.history.push("/category/list");
                }
              }
            );
          }
        });
    }
  }
  render() {
    return (
      <Fragment>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h5>Delete Subscription Category '{this.state.result?.name}'</h5>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <CategoryTabs
                  id={this.props.match.params.id}
                  url="/category/delete/"
                />
                <div className="alert font-weight-bold  alert-light">
                  {" "}
                  <i className="fas fa-exclamation-circle text1"></i> Are you
                  sure you want to delete this Category{" "}
                  <strong>"{this.state.result?.name}”</strong> ?
                </div>

                <p className="mt-4 text-danger">
                  <i className="fas fa-exclamation-triangle text1"></i> The
                  following related items may be deleted or orphaned:
                </p>
                <div className="row ">
                  <div className="col-4">
                    <div className="card card-primary shadow-sm">
                      <div className="card-header">
                        <h4>Category</h4>
                      </div>
                      <div className="card-body">
                        <NavLink
                          className="badge badge-light text-link"
                          to={"/category/detail/" + this.props.match.params.id}
                        >
                          Category '{this.state.result?.name}'
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {this.state.result?.platform_categories &&
                    this.state.result?.platform_categories.length > 0 && (
                      <div className="col-12">
                        <div className="card card-silver shadow-sm">
                          <div className="card-header">
                            <b>Platform Categories</b>
                          </div>
                          <div className="card-body">
                            <ol className="d-flex flex-wrap">
                              {this.state.result?.platform_categories.map(
                                (item) => (
                                  <li key={item} className="col-2">
                                    PlatformCategory #{item}
                                  </li>
                                )
                              )}
                            </ol>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
                <div className="row">
                  {this.state.result?.platforms &&
                    this.state.result?.platforms.length > 0 && (
                      <div className="col-12">
                        <div className="card card-silver shadow-sm">
                          <div className="card-header">
                            <b>Platforms</b>
                          </div>
                          <div className="card-body">
                            <ol className="d-flex flex-wrap">
                              {this.state.result?.platforms.map((item) => (
                                <li key={item.value} className="col-4">
                                  <Link to={"/platform/detail/" + item.value}><div>{item.text}</div></Link>
                                </li>
                              ))}
                            </ol>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="mx-auto">
                <button
                  className="btn btn-lg btn-danger"
                  disabled={this.state.isDoubleleClick}
                  onClick={this.deleteRecord}
                >
                  {" "}
                  {ButtonType.Delete}
                </button>
                <NavLink
                  type="button"
                  className="btn btn-lg btn-default ml-3"
                  to="/category/list"
                >
                  {ButtonType.Cancel}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
