import { HttpWrapper, AppConfig } from "../../../../../../core";
import { PostExportModel } from "../../../../../../shared/export/post.export.model";
import { LinkResourcesResponseModel } from "../../../../connections/models/link.resources.response.model";
import { InboundEmailMessagesListResponseModel, InboundEmailMessagesDetailsModel } from "../models";



export class InboundEmailMessagesService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getInboudEmailMessagesList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
    return this.wrapper.post<InboundEmailMessagesListResponseModel>(AppConfig.apiEndpoint + '/v1/inbound_email_messages/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
  }

  getInboudEmailMessagesById(id: number) {
    return this.wrapper.get<InboundEmailMessagesDetailsModel>(AppConfig.apiEndpoint + '/v1/inbound_email_messages/' + id);
  }

  reProcessById(id:number)
  {
    return this.wrapper.post<any>(AppConfig.apiEndpoint+'/v1/inbound_email_messages/'+id+'/reprocess',{});
  } 


  // postInboudEmailMessages(InboudEmailMessages: InboundEmailMessagesPostModel) {
  //     return this.wrapper.post<InboundEmailMessagesPostModel>(AppConfig.apiEndpoint + '/v1/inbound_email_Messages', InboudEmailMessages);
  //   }

  //   editInboudEmailMessages(id: number) {
  //     return this.wrapper.get<InboundEmailMessagesEditModel>(AppConfig.apiEndpoint + '/v1/inbound_email_messages/edit/' + id);
  //   }

  //   updateInboudEmailMessages(InboudEmailMessages: InboundEmailMessagesPostModel) {
  //     return this.wrapper.put<InboundEmailMessagesPostModel>(AppConfig.apiEndpoint + '/v1/inbound_email_messages/' + InboudEmailMessages.id, InboudEmailMessages);
  //   }

    // getLinkedResourcesById(id: number) {
    //   return this.wrapper.get<LinkResourcesResponseModel>(AppConfig.apiEndpoint + '/v1/inbound_email_messages/' + id + '/linked_resources');
    // }
  
    // deleteRecordById(id: number) {
    //   return this.wrapper.delete<any>(AppConfig.DeleteCustomConnectionUrl + id);      
    // }
 
exportInboundEmailMessages(exportData?: PostExportModel) {
    return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/inbound-email-messages/export', exportData);
  }

}