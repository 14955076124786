import React, { Component, Fragment } from 'react'
import Breadcrumbs from '../../shared/Breadcrumbs'
import { InboundEmailMessagesRoute } from '../../routing'

export class InboundEmailMessagesPage extends Component {
    render() {
        return (
            <Fragment>
                <Breadcrumbs />
                <InboundEmailMessagesRoute />
            </Fragment>
        )
    }
}