import React, { Component } from "react";
import { MapRoute } from "../routing";
import LayoutPage from "../layout/LayoutPage";
import { AuthService } from "../components/auth/services";
import { AuthResponseModel, UserDetailResponseModel } from "../components/auth/models";
import { HttpResponse, AppConfig } from "../core";
import { Decrypt, Encrypt, getCookie, setCookie, SetUserInfo, ShowException } from "../shared/helpers";
import moment from "moment";

interface IState {
  tokenInterval?: any | null
}

export class SecurePage extends Component<any, IState> {
  private service: AuthService;
  constructor(props){
    super(props);
    this.service = new AuthService();
    this.state = {
      tokenInterval: null
    }
  }
  getUserDetail() {            
    this.service.getGetUserInfo()
        .then((res: HttpResponse<UserDetailResponseModel>) => {
            if (res && res.result) {                
                SetUserInfo(res.result);   
                this.setState({});            
              }
        })
        .catch(ex => {         
          if (ex.status == 401) {
            ShowException(ex.response.result ? ex.response.result : ex.response);
            localStorage.clear();
            let currentUrl = window.location.pathname;
      window.location.href = window.location.origin + '/login?rtu='+currentUrl;
          }
      });
}

getAccessTokenByRefreshToken = () => {
  let expire_in = localStorage.getItem("expire_in") || "";
  if (expire_in){
    let dateDiffInMillSeconds = moment(expire_in).diff(moment());
    if (dateDiffInMillSeconds < 300000){
      //Access token is about to expire
      let authData = {
        grant_type: AppConfig.RefreshTokenGrantType,
        client_id: AppConfig.ClientId,
        client_secret: AppConfig.ClientSecret,
        refresh_token: Decrypt<string>(getCookie(AppConfig.RefreshTokenKey)) as string,
        redirect_uri: "",
        code: null,
      };
      this.service.getAccessTokenByRefreshToken(authData)
        .then((res: HttpResponse<AuthResponseModel>) => {
          if (res && res.result) {               
              let expire_in = (moment().add(parseInt(res.result.expires_in), 'seconds'));
              localStorage.setItem('lg', 'true');
              localStorage.setItem('expire_in', expire_in.toString());
              localStorage.setItem(AppConfig.TokenKey,Encrypt(res.result.id_token));
              let refresh_token = getCookie(AppConfig.RefreshTokenKey)
              setCookie(AppConfig.RefreshTokenKey, refresh_token, AppConfig.RefreshTokenExpiry);  
          }
      });
    }
  }

}

  componentDidMount(){
    this.getUserDetail();
    let tokenInterval = setInterval(this.getAccessTokenByRefreshToken, 60000);
    this.setState({
      tokenInterval: tokenInterval
    })
  }

  componentWillUnmount(){
    // clear interval
    if(this.state.tokenInterval){
      clearInterval(this.state.tokenInterval);
    }
    
    this.setState({
      tokenInterval: null
    })
  }
    render() {     
        return (           
            <div className="main-wrapper main-wrapper-1">
            <div className="navbar-bg"></div>           
            <div className="main-content" >
              <MapRoute />
              <LayoutPage />
            </div>
          </div>
        )
    }
}
