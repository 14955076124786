import { HttpWrapper, AppConfig } from '../../../../../../../core'
import { ExtractorRateLimitListResponseModel,ExtractorRateLimitDetailModel,PostExtractorRateLimitModel,EditExtractorRateLimitModel } from '../models';
import { PostExportModel } from "../../../../../../../shared/export/post.export.model";
import { DeleteResponseModel, LinkResourcesResponseModel } from '../../../../../../../shared/models';

export class ExtractorRateLimitService {
    private wrapper: HttpWrapper;

    constructor() {
      this.wrapper = new HttpWrapper();
    }
  
    getList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
      return this.wrapper.post<ExtractorRateLimitListResponseModel>(AppConfig.apiEndpoint + '/v1/extractor_rate_limits/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }
  
    getExtractorRateLimitById(id: number) {
      return this.wrapper.get<ExtractorRateLimitDetailModel>(AppConfig.apiEndpoint + '/v1/extractor_rate_limits/' + id);
    }
  
    postExtractorRateLimit(payload: PostExtractorRateLimitModel) {
      return this.wrapper.post<PostExtractorRateLimitModel>(AppConfig.apiEndpoint + '/v1/extractor_rate_limits', payload);
    }
  
    editExtractorRateLimit(id: number) {
      return this.wrapper.get<EditExtractorRateLimitModel>(AppConfig.apiEndpoint + '/v1/extractor_rate_limits/edit/' + id);
    }

    getLinkedResourcesById(id: number) {
        return this.wrapper.get<LinkResourcesResponseModel>(AppConfig.apiEndpoint + '/v1/extractor_rate_limits/' + id + '/linked_resources');
      }
  
    deleteExtractorRateLimitById(id: number) {
      return this.wrapper.delete<DeleteResponseModel>(AppConfig.apiEndpoint + '/v1/extractor_rate_limits/' + id);
    }
  
    updateExtractorRateLimit(extractor: PostExtractorRateLimitModel) {
      return this.wrapper.put<PostExtractorRateLimitModel>(AppConfig.apiEndpoint + '/v1/extractor_rate_limits/' + extractor.id, extractor);
    }
    
    exportExtractorRateLimit(exportData?: PostExportModel) {
      return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/extractor_rate_limits/export', exportData);
    }
  
  
  }