import { HttpWrapper, AppConfig } from "../../../../core";
import { PostExportModel } from "../../../../shared/export/post.export.model";
import { StaqEventsListResponseModel, StaqEventsDetailModel } from "../models";



export class StaqEventsService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getSchemasList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
    return this.wrapper.post<StaqEventsListResponseModel>(AppConfig.apiEndpoint + '/v1/staq_events/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
  }

  getSchemasById(id: number) {
    return this.wrapper.get<StaqEventsDetailModel>(AppConfig.apiEndpoint + '/v1/staq_events/' + id);
  }  

  exportSchemas(exportData?: PostExportModel) {
    return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/staq_events/export', exportData);
  }


}