import React, { Component, Fragment, createRef } from 'react'
import { ReportModel, EditReportModel } from './models'
import { ReportList } from './ReportList'
import { Link, Route } from "react-router-dom";
import { Loader } from '../../../shared/loaders';
import { ButtonType } from '../../../shared/enums/button.enum';
import { Editor } from '../../../shared'
import { UserReportsService } from './services';
import { HttpResponse } from '../../../core';
import { ShowSuccessMessage, ValidateUrl, HandleNotFoundResponse } from '../../../shared/helpers';
import { NotFoundResponseArea } from '../../../shared/enums';
import Modal from 'react-bootstrap/Modal';

interface IProps {
    handleClose: Function,
    id?:number; 
    isNew?:boolean;    
    updateReport:Function
}

interface IState {
    report?: ReportModel,
    editReport?: EditReportModel,
    id?: number,
    name?: string,
    time_range?: string,
    favicon_url?: string,
    reportEnabled?: boolean,
    always_rebuild?: boolean,
    alert_on_failure?: boolean,
    query_strategy?: string;
    custom_sql?: string,
    nameError?: string,
    favUrlError?: string,
    isShowtext?: string;
    isSave?: string;
    isSaving?: boolean;
    ignore_health?: boolean;
    skip_when_priority_dependent_job?: boolean;
}
export class ReportFormPopup extends Component<IProps, IState> {
    private service: UserReportsService;
    private nameRef: any;
    constructor(props:IProps) {
        super(props)
        this.state = this.initialState;
        this.editorhandleChange = this.editorhandleChange.bind(this);
        this.service = new UserReportsService();
        this.nameRef = createRef();
    }

    initialState: Partial<IState> = {
        alert_on_failure: false,
        always_rebuild: false,
        custom_sql: "",
        reportEnabled: false,
        favicon_url: "",
        id: 0,
        isSave: "",
        isSaving: false,
        isShowtext: "",
        name: "",
        nameError: "",
        favUrlError: "",
        query_strategy: "v3",
        time_range: "",
        ignore_health: false,
        skip_when_priority_dependent_job: false,
        report: {
            alert_on_failure: false,
            always_rebuild: false,
            custom_sql: "",
            enabled: false,
            favicon_url: "",
            id: 0,
            name: "",
            query_strategy: "v3",
            time_range: "",
            ignore_health: false,
            skip_when_priority_dependent_job: false
        }
    }

    handleClose = event => {
        event.preventDefault();
        this.props.handleClose();        
    }
    componentDidMount() {
            this.loadData();        
    }

    loadData() {
        this.service.editUserReport(this.props.id)
            .then((res: HttpResponse<EditReportModel>) => {
                if (res && res.result) {
                    this.setState({
                        alert_on_failure: res.result?.alert_on_failure == null ? false : res.result?.alert_on_failure,
                        always_rebuild: res.result?.always_rebuild == null ? false : res.result?.always_rebuild,
                        custom_sql: res.result?.custom_sql == null ? "" : res.result?.custom_sql,
                        reportEnabled: res.result?.enabled == null ? false : res.result?.enabled,
                        favicon_url: res.result?.favicon_url == null ? "" : res.result?.favicon_url,
                        id: res.result?.id == null ? 0 : res.result?.id,
                        name: res.result?.name == null ? "" : res.result?.name,
                        query_strategy: res.result?.query_strategy == null ? "v3" : res.result?.query_strategy,
                        time_range: res.result?.time_range == null ? "" : res.result?.time_range,
                        ignore_health: res.result?.ignore_health == null ? false : res.result?.ignore_health,
                        skip_when_priority_dependent_job: res.result?.skip_when_priority_dependent_job == null ? false : res.result?.skip_when_priority_dependent_job

                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.Reports,this.props)
            });
    }



    updateData(report: ReportModel) {
        this.setSavingFlag(true);
        this.service.updateUserReport(report)
            .then((res: HttpResponse<ReportModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        report: res.result
                    }, () => {
                        ShowSuccessMessage("Report successfully updated.");
                        if (this.state.isSave === ButtonType.Save) {                            
                            this.setState(this.initialState);
                            this.props.updateReport(true,this.state.name,this.state.id);
                            this.props.handleClose();
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });
    }


    editorhandleChange(data) {
        this.setState({ custom_sql: data })
    }

    handleChange = (event: any) => {        
        const isCheckbox = event.target.type === "checkbox";
        this.setState({
            [event.target.name]: isCheckbox
                ? event.target.checked
                : event.target.value
        }, () => {
            // this.validate();
        })
        if (this.nameRef.current.value) {
            this.setState({ nameError: "" });
          }
    }

    handleSubmit = event => {
        event.preventDefault();
        const isValid = this.validate();
        let reportdata: ReportModel = {

            alert_on_failure: this.state.alert_on_failure,
            always_rebuild: this.state.always_rebuild,
            custom_sql: this.state.custom_sql,
            enabled: this.state.reportEnabled,
            favicon_url: this.state.favicon_url,
            id: this.state.id,
            name: this.state.name,
            query_strategy: this.state.query_strategy,
            time_range: this.state.time_range,
            ignore_health: this.state?.ignore_health,
            skip_when_priority_dependent_job: this.state?.skip_when_priority_dependent_job
        };

        if (isValid) {
            this.updateData(reportdata);
        }
    }

    validate = () => {
        let evalUrlError = "";
        let nameError = "";
        if (!this.nameRef.current.value) {
            nameError = "Name can't be blank";
        }
        let evalFavUrl = ValidateUrl(this.state.favicon_url);
        if (!evalFavUrl && this.state.favicon_url) {
            evalUrlError = 'Favicon url must begin with https://';
        }

        if (nameError || evalUrlError) {
            this.setState({ nameError: nameError, favUrlError: evalUrlError })
            return false;
        }

        if (!evalUrlError || !nameError) {
            nameError = "";
            evalUrlError = "";
            this.setState({ nameError: nameError, favUrlError: evalUrlError })
            return true;
        }

    }

    handleClick = (event) => {
        this.setState({ isSave: event.target.value },()=>{
            this.props.updateReport(true,this.state.name,this.state.id);
        })
    }
    private setSavingFlag(saving: boolean) {
        this.setState({ isSaving: saving });
    }

    render() {
        return (
            <Fragment>
                <form onSubmit={this.handleSubmit}>
                <Modal.Header closeButton onClick={this.handleClose}>
                    </Modal.Header>
                    <Modal.Body>
                    <input type="hidden" value={this.state.id} />
                    <div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h4>{this.state.isShowtext} Report {this.state.name && <span> '{this.state.name}'</span>}</h4>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">                                        
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Name<span className=" text-danger">*</span></label>
                                                    <input type="text" maxLength={255}  ref={this.nameRef} name="name" value={this.state.name} onChange={this.handleChange} className={!this.state.nameError ? 'form-control' : 'form-control  is-invalid'} />                                                    
                                                    <div className="invalid-feedback">
                                                        {this.state.nameError}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Time Range</label>
                                                    <input type="text" name="time_range" maxLength={255} value={this.state.time_range} onChange={this.handleChange} className="form-control" />
                                                    {/* <div className="hint-text">Optional. Length up to 255.</div> */}
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Favicon Url</label>
                                                    <input type="text" maxLength={255} name="favicon_url" value={this.state.favicon_url || ''} onChange={this.handleChange} className={!this.state.favUrlError ? 'form-control' : 'form-control  is-invalid'} />
                                                    {/* <div className="hint-text">Optional. Length up to 255.</div> */}
                                                    <div className="invalid-feedback">
                                                        {this.state.favUrlError}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>



                                        <div className="row">
                                            
                                            <div className="col-md-8">
                                                <div className="form-group shadow-sm p-3 mb-4 ">
                                                   
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" name="always_rebuild" checked={this.state.always_rebuild} onChange={this.handleChange} className="custom-control-input" id="always_rebuild" />
                                                        <label className="custom-control-label" htmlFor="always_rebuild">Always Rebuild</label>
                                                    </div>
                                                    <small>If selected, this report is always rebuilt when it runs. If not, this report may incrementally update data.</small>
                                                </div>
                                                <div className="form-group shadow-sm p-3 mb-4 ">
                                                   
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" name="alert_on_failure" checked={this.state.alert_on_failure} onChange={this.handleChange} className="custom-control-input" id="alert_on_failure" />
                                                        <label className="custom-control-label" htmlFor="alert_on_failure">Alert On Failure</label>
                                                    </div>
                                                        <small>If checked, this report will send notifications to the TAMs assigned to the account when the report fails to run.</small>
                                                </div>
                                                <div className="form-group shadow-sm p-3 mb-4">
                                                    <label>Query Strategy</label>
                                                    <select className="form-control col-md-6" name="query_strategy" value={this.state.query_strategy} onChange={this.handleChange}>
                                                        <option value="v3">v3</option>
                                                        <option value="legacy">legacy</option>
                                                    </select>
                                                    <small>v3 is the new preferred strategy for reporting. It is under test right now.</small>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group shadow-sm p-3 mb-4 ">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" name="ignore_health" checked={this.state.ignore_health} onChange={this.handleChange} className="custom-control-input" id="ignore_health" />
                                                    <label className="custom-control-label" htmlFor="ignore_health">Ignore Health</label>
                                                    </div>
                                                        <small>Check this to Ignore health </small>
                                                </div>
                                                <div className="form-group shadow-sm p-3 mb-4 ">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" name="skip_when_priority_dependent_job" checked={this.state.skip_when_priority_dependent_job} onChange={this.handleChange} className="custom-control-input" id="skip_when_priority_dependent_job" />
                                                    <label className="custom-control-label" htmlFor="skip_when_priority_dependent_job">Skip When Parent Triggered By User</label>
                                                    </div>
                                                        <small >Check this to skip when parent triggered by user</small>
                                                </div>
                                                <div className="form-group shadow-sm p-3 mb-4 ">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" name="reportEnabled" checked={this.state.reportEnabled} onChange={this.handleChange} className="custom-control-input" id="reportEnabled" />
                                                    <label className="custom-control-label" htmlFor="reportEnabled">Enabled</label>
                                                    </div>
                                                        <small >Check this to enable</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-8 mt-4">
                                                <div className="form-group">
                                                    <h5>Custom SQL</h5>
                                                    <p className="hint-text text-muted">Any text here will override how data is read from the database. The report definition will be used only for column labeling.</p>
                                                    <Editor StringData={this.state.custom_sql} FormateType="mysql" onChange={this.editorhandleChange} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>


                        <div className="text-center mt-3 mb-4">
                            {!this.state.isSaving && <Fragment><Link type="button" className="btn btn-lg btn-default" to="/report/list">{ButtonType.Cancel}</Link>
                                <input type="submit" className="btn btn-lg btn-primary ml-3" onClick={this.handleClick} value={ButtonType.Save} />
                            </Fragment>
                            }
                            <Loader loading={this.state.isSaving} marginBottom="10px" marginTop="8px" width="168px" ></Loader>
                        </div>
                    </div>
                    </Modal.Body>
                </form>
                <Route path="/report/list" component={ReportList} />
            </Fragment >
        )
    }
}
