import React, { Component, Fragment } from "react";
import { RoleDetailModel } from "./models";
import { RoleService } from "./services/role.service";
import { HttpResponse } from "../../../core";
import {
  HandleNotFoundResponse,
  ConvertDateTime,
} from "../../../shared/helpers";
import { NotFoundResponseArea } from "../../../shared/enums";
import { RoleTabs } from "./RoleTabs";
import { Link } from "react-router-dom";
import { DropdownItemModel } from "../../../shared/models";
import { Loader } from "../../../shared/loaders";

interface IState {
  result?: RoleDetailModel;
  isLoading?: boolean;
}

export class RoleDetail extends Component<any, IState> {
  private service: RoleService;
  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.service = new RoleService();
  }

  // Defines the initial state for the values coming from API.
  initialState: Partial<IState> = {
    result: {
      id: 0,
      name: "",
      description: "",
      protected_operations: [],
      created_at: new Date(),
      updated_at: new Date(),
    },
    isLoading: false,
  };

  //Get the detail from the API whenever the page loads.
  componentDidMount() {
    this.loadData();
  }

  //function to load the initial view data coming from the API.
  private setLoading(loading: boolean) {
    this.setState({ isLoading: loading });
}
loadData() {
    this.setLoading(true);
    this.service
      .getRoleById(this.props.match.params.id)
      .then((res: HttpResponse<RoleDetailModel>) => {
        this.setLoading(false);
        if (res && res.result) {
          this.setState({
            result: res.result,
          });
        }
      })
      .catch((ex) => {
        HandleNotFoundResponse(ex, NotFoundResponseArea.Roles, this.props);
      });
  }

  renderProtectedOperation(protected_operations) {
    return (
      <div className="row">
        <div className="col-12">
          <div className="card card-silver shadow-sm">
            <div className="card-header">
              <b>Protected operations permitted by this role</b>
            </div>
            <div className="card-body">
              <p>
                Each operation corresponds to a check performed by
                StaqAuthorization
              </p>
              <div className="d-flex flex-wrap">
                {protected_operations.map((ele) => {
                  return (
                    <div key={ele.record_type} className="card-body col-2">
                      <h6 style={{ textTransform: 'capitalize'}}>{ele.record_type}</h6>
                      <ul className="m-0 p-0">
                        {ele.operations.map((op, index) => {
                          return <li key={op} className="m-0 p-0" style={{ textTransform: 'capitalize'}}><Link to={"/protected-operation/detail/"+ ele['ids'][index]}>{op}</Link></li>;
                        })}
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h5>Details for Role '{this.state.result?.name}'</h5>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <RoleTabs
                  id={this.props.match.params.id}
                  name={this.state.result?.name}
                  url="/role/detail/"
                />
                {!this.state.isLoading &&  <>    
                <h5 className="mb-3">Basic Info</h5>
                <div className="row">
                  {this.state.result?.name && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Name</b>
                        </div>
                        <div className="card-body">
                          {this.state.result?.name}
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.description && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Description</b>
                        </div>
                        <div className="card-body">
                          {this.state.result?.description}
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.created_at && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Created At</b>
                        </div>
                        <div className="card-body">
                          {ConvertDateTime(
                            this.state.result?.created_at,
                            "LLL"
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.updated_at && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Updated At</b>
                        </div>
                        <div className="card-body">
                          {ConvertDateTime(
                            this.state.result?.updated_at,
                            "LLL"
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}  
                                
                {this.state.result?.protected_operations &&
                  this.state.result?.protected_operations.length > 0 &&
                  this.renderProtectedOperation(
                    this.state.result?.protected_operations
                  )}
                   
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
