import React, { Component, Fragment } from 'react'
import { HttpResponse, AppConfig } from '../../../../../core';
import { HandleNotFoundResponse, ConvertDateTime, formatBytes, RedirectBySourceType } from '../../../../../shared/helpers';
import { NotFoundResponseArea } from '../../../../../shared/enums';
import { NavLink } from "react-router-dom";
import { DataEgineJobService } from './services/data.engines.job.service';
import { DataEngineJobDetailModel, StatsModel, TransitionsModel, JobLineageModel } from './models/data.engine.job.detail.model';
import { Editor } from '../../../../../shared';
import { DataEgineJobTabs } from '.';
import { Loader } from '../../../../../shared/loaders';

interface IState {
    result?: DataEngineJobDetailModel;
    parsedData?: string;
    isLoading?: boolean;
}
export  class DataEgineJobDetail extends Component<any, IState> {
    private service: DataEgineJobService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new DataEgineJobService();
    }

    initialState: Partial<IState> = {
        result: {     
            data_source_owner: {
                id: 0,
                name: "",
                type: ""
            },
            id: 0,
            created_at: null,
            age: 0,
            backend_pid: 0,
            definition: JSON,
            sql: "",
            flags: {},
            job_type: "",
            row_count: 0,
            runtime: "",
            scope: {
                text: "",
                value: 0
            },
            size_on_disk: 0,
            started_at: null,
            state: "",
            stats: [],
            display_time_range: "",
            transitions: [],
            triggered_by: "",
            updated_at: null,
            priority: 0,
            data_engine: {
                text: "",
                value: "",
                database_engine: {
                    text: "",
                    value: 0
                }
            },
            current_runtime: 0,
            trigger_dependencies: false,
            job_lineage: [],
            report: {
                text: "",
                value: 0
            }      

        },
        parsedData: "",
        isLoading: false,
    }

    componentDidMount() { 
        this.loadData();
    }

    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getDataEgineJobById(this.props.match.params.id)
            .then((res: HttpResponse<DataEngineJobDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {                                    
                    this.setState({
                        result: res.result                        
                    },()=>{
                        this.setState({
                        parsedData: JSON.stringify(this.state.result?.definition, null, '\t')
                        });                      
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.DataEngineJob,this.props)
            });
    }
    render() {
        return (
            <Fragment>               
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Details for Data Engine Job '#{this.state.result?.id==0?"-":this.state.result?.id}'</h5>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <DataEgineJobTabs id={this.props.match.params.id} url="/data-engine-job/detail/" />
                            {!this.state.isLoading &&  <>  
                            <h5 className="mb-3">Basic Info</h5>
                            <div className="row">
                            {this.state.result?.id!=0 &&    <div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Id</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.id}
                                        </div>
                                    </div>
                                </div>}
                                {this.state.result?.job_type &&  <div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Job Type</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.job_type}
                                        </div>
                                    </div>
                                </div>}
                               <div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Data Source</b>
                                        </div>
                                        {this.state.result?.data_source_owner && <div className="card-body">
                                        {RedirectBySourceType(this.state.result?.data_source_owner.type,this.state.result?.data_source_owner.name,this.state.result?.data_source_owner.id)}
                                        </div>}
                                        {!this.state.result?.data_source_owner && this.state.result?.report && <div className="card-body">
                                        <NavLink to={"/report/detail/" + this.state.result?.report.value}>{this.state.result?.report.text}</NavLink>
                                        </div>}
                                    </div>
                                </div>
                                {this.state.result?.scope && <div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Scope</b>
                                        </div>
                                        <div className="card-body">
                                        <NavLink to={"/scope/detail/" + this.state.result?.scope.value}>
                                             {this.state.result?.scope.text}
                                             </NavLink>
                                        </div>
                                    </div>
                                </div>}
                               
                             
                            {this.state.result?.data_engine.value!="" && <div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Data Engine</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.data_engine.value && <NavLink to={"/data-engine/detail/" + this.state.result?.data_engine.value}>{this.state.result?.data_engine.text}</NavLink>}
                                        ({this.state.result?.data_engine.database_engine && <NavLink to={"/database-engine/detail/" + this.state.result?.data_engine.database_engine.value}>{this.state.result?.data_engine.database_engine.text}</NavLink>})
                                        </div>
                                    </div>
                                </div>}
                                {this.state.result?.   display_time_range &&    <div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Time Range</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.   display_time_range}
                                        </div>
                                    </div>
                                </div>}
                                {this.state.result?.state &&    <div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>State</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.state}
                                        </div>
                                    </div>
                                </div>}
                                {this.state.result?.flags &&    <div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Flags</b>
                                        </div>
                                        <div className="card-body">                                        
                                        {this.state.result?.flags.hasOwnProperty('collection_mission_id') && <div>
                                        <strong> Collection Mission </strong><br/>
                                        <a href="#">{this.state.result?.flags['collection_mission_id']}</a>
                                        </div>}
                                        {this.state.result?.flags.hasOwnProperty('scope_schema_change_id') && <div>
                                        <strong> Scope Schema Change </strong><br/>
                                        <NavLink to={"/scope-schema-change/detail/" + this.state.result?.flags['scope_schema_change_id']}>
                                        {this.state.result?.flags['scope_schema_change_id']}
                                        </NavLink>
                                        </div>}
                                        {this.state.result?.flags.hasOwnProperty('sidekiq_job_id') &&<div>
                                        <strong> Sidekiq Job </strong><br/>
                                        <a href={AppConfig.PapertrailUrl+this.state.result?.flags['sidekiq_job_id']}>{this.state.result?.flags['sidekiq_job_id']}
                                         </a>
                                        </div>}                                        
                                        </div>
                                    </div>
                                </div>}
                             
                             <div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Row Count</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.row_count}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Size on Disk</b>
                                        </div>
                                        <div className="card-body">                                        
                                        {this.state.result?.size_on_disk!=null?formatBytes(this.state.result?.size_on_disk):"0 Bytes"}
                                        </div>
                                    </div>
                                </div>
                                 <div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Runtime</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.runtime}
                                        </div>
                                    </div>
                                </div>
                                {this.state.result?.age!=0 &&  <div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Age</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.age}
                                        </div>
                                    </div>
                                </div>}
                                
                                {this.state.result?.priority!=0 &&  <div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Priority</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.priority}
                                        </div>
                                    </div>
                                </div>}
                                
                                {this.state.result?.triggered_by &&  <div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Triggered By</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.triggered_by}
                                        </div>
                                    </div>
                                </div>}

                                {this.state.result?.current_runtime!=0 && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Current Runtime</b>
                                            </div>
                                            <div className="card-body"> <i className="fas fa-check text-success"></i>   Yes</div>
                                        </div>
                                    </div>
                                    }                            

                              
                                {this.state.result?.created_at && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Created At</b>
                                            </div>
                                            <div className="card-body">{ConvertDateTime(this.state.result?.created_at, 'LLL')}</div>
                                        </div>
                                    </div>}
                                    {this.state.result?.started_at && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Started At</b>
                                            </div>
                                            <div className="card-body">{ConvertDateTime(this.state.result?.started_at, 'LLL')}</div>
                                        </div>
                                    </div>}
                                    {this.state.result?.updated_at && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Updated At</b>
                                            </div>
                                            <div className="card-body">{ConvertDateTime(this.state.result?.updated_at, 'LLL')}</div>
                                        </div>
                                    </div>}
                                    {this.state.result?.backend_pid!=null &&  <div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Backend Pid</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.backend_pid}
                                        </div>
                                    </div>
                                </div>}
                            
                                        
                                    </div>
                                    <div className="row"> 
                                    {this.props.match.params.id &&  this.state.result?.definition != null && 
                                        <div className="col-6"> 
                                        <h5>Definition</h5>                                           
                                                <Editor Height="300px" StringData={this.state.parsedData} FormateType="json" />                                           
                                        </div>}

                                        {this.state.result?.sql && <div className="col-6">
                                            <h5>SQL</h5>
                                            <Editor StringData={this.state.result?.sql} FormateType="mysql"  Height="300px" />
                                        </div> 
                                    }
                                    </div>
                                    {this.state.result?.stats && <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="mt-4">Stats</h5>
                                            <p>Stats in hawq:</p>
                                            <div className="table-responsive">
                                                <table className="table table-sm table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Stat</th>
                                                            <th scope="col">Value</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.result?.stats.map((item: StatsModel, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{item.text}</td>
                                                                    <td>{item.value}	</td>
                                                                </tr>

                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>}

                                   <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="mt-4">Transitions</h5>
                                            <div className="table-responsive">
                                                <table className="table table-sm table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Time</th>
                                                            <th scope="col">Event</th>
                                                            <th scope="col">From</th>
                                                            <th scope="col">To</th> 
                                                            <th scope="col">Message</th>
                                                            <th scope="col">Sidekiq Job</th>
                                                        </tr>
                                                    </thead>
                                                    {this.state.result?.transitions && <tbody>
                                                        {this.state.result?.transitions.map((item: TransitionsModel, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td> <NavLink to={"/data-engine-job/state-transition/" + item.id}>{ConvertDateTime(item.created_at, 'LLL')}</NavLink></td>
                                                                     <td>{item.event == "" ? "--" : item.event}</td>
                                                                    <td>{item.event == "" ? "--" : item.event}</td>
                                                                    <td>{item.to}</td>
                                                                    <td>{item.message == "" ? "--" : item.message}</td>
                                                                    <td><a href={AppConfig.PapertrailUrl + item.sidekiq_job_id}>{item.sidekiq_job_id}</a></td>
                                                                </tr>

                                                            );
                                                        })}
                                                    </tbody>}
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.result?.job_lineage.length != 0 && <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="mt-4">Job Lineage</h5>                                            
                                            <div className="table-responsive">
                                                <table className="table table-sm table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Job</th>
                                                            <th scope="col">Parent Job</th>
                                                            <th scope="col">Job Owner</th>
                                                            <th scope="col">Created At</th>
                                                            <th scope="col">Finished At</th>
                                                            <th scope="col">Duration</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.result?.job_lineage.map((item: JobLineageModel, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td><NavLink to={"/data-engine-job/detail/" + item.job_id}>#Job{item.job_id}</NavLink></td>
                                                                    <td><NavLink to={"/data-engine-job/detail/" + item.parent_job_id}>#Job{item.parent_job_id}</NavLink></td>
                                                                    <td>
                                                                    {RedirectBySourceType(item.job_owner_type,item.job_owner_name,item.job_owner_id)}
                                                                    </td>
                                                                    <td>{ConvertDateTime(item.created_at, 'LLL')}</td>
                                                                    <td>{ConvertDateTime(item.finished_at, 'LLL')}</td>
                                                                    <td>{item.duration}	</td>
                                                                </tr>

                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>}
                                    </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}
                        </div>
                    </div>
                </div>
            </div>            
        </Fragment>
        )
    }
}
 