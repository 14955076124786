import { HttpWrapper,AppConfig } from '../../../../../core'
import { PostExportModel } from '../../../../../shared/export/post.export.model';
import {ReportViewsListResponseModel,ReportViewsDetailModel} from '../models'

export class ReportViewsService {
    private wrapper: HttpWrapper;
    constructor() {
        this.wrapper = new HttpWrapper();
    }

    getReportViewsList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
        return this.wrapper.post<ReportViewsListResponseModel>(AppConfig.apiEndpoint + '/v1/report_view/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }   

    getReportViewsById(id: number) {
        return this.wrapper.get<ReportViewsDetailModel>(AppConfig.apiEndpoint + '/v1/report_view/' + id);
    }

    
    exportReportViews(exportData?: PostExportModel) {
        return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/report_view/export', exportData);
    }

}