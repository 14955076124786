
import { Role } from "./enums";
import { GetUserInfo } from "../helpers";
export const IsAuthorize = (roles: string[]) => {
    let roleList=GetUserInfo().role;
    let userRole = roleList!=null?roleList[0]:null;
    if (userRole == Role.Admin || userRole == Role.SuperAdmin) {
        return true;
    }
    return roles.find(p => p == userRole) != null;
}