import React, { Component, Fragment } from 'react'
import { NavLink } from "react-router-dom";

interface IProps
{
    id:number,
    url:string
}
export  class DashboardTabs extends Component<IProps> {
    render() {
        return (
            <Fragment>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                        <NavLink to={"/dashboard/detail/" + this.props.id} className={this.props.url=="/dashboard/detail/"?"nav-link active":"nav-link"} id="edit-tab" data-toggle="tab" href="#edit" role="tab"
                            aria-controls="edit" aria-selected={this.props.url=="/dashboard/detail/"?"true":"false"}><i className="fas fa-info-circle"></i>&nbsp;Show</NavLink>
                    </li>
                </ul>
                <br/>
            </Fragment>
        )
    }
}
