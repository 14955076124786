import React, { Component } from "react";
import Modal from 'react-bootstrap/Modal';
import { ExportColumnCheckBoxList } from '..';
import { IExportColumnModel } from "../models";
import { ButtonType } from '../../shared/enums/button.enum'
import { Loader } from "../loaders";
import { ConvertDateTime } from "../helpers";
import { ShowWarningMessage } from '../../shared/helpers';

interface IExportComponentProps {
    columnList: IExportColumnModel[],
    handleClose: Function,
    exportTo: Function,
    isExporting: boolean,
    fileNamePrefix: string,
    isSelectedAll?: boolean,
    isNoHeader?: boolean,
    clearExportModal: Function
}
interface IState {
    isShow?: boolean;
    isSelectedAll?: boolean;
    columnList?: IExportColumnModel[],
    encodeToId?: number;
    encodeToName?: string;
    isNoHeader?: boolean;
    columnSeparator?: string;
    exportTo?: string;
    isCsv?: boolean;
    export?: {};
    clearData?: {};
    isSelectedSection?: boolean;

}
export default class ExportComponent extends Component<IExportComponentProps, IState>{
    exportFileNamePostFix = ConvertDateTime(new Date(), 'MM_DD_YYYY');
    constructor(props: any) {
        super(props);
        this.state = { columnList: this.props.columnList, isSelectedAll: this.props.isSelectedAll, isNoHeader: this.props.isNoHeader };
        this.state = this.initialState;
    }
    initialState: Partial<IState> = {
        columnSeparator: ",",
        encodeToId: 0,
        encodeToName: "utf-8",
        exportTo: "csv",
        isNoHeader: this.props.isNoHeader,
        columnList: this.props.columnList,
        isCsv: true,
        isSelectedAll: this.props.isSelectedAll,
        isShow: false,
        export: {
            csv_setting: { encoding: "", header: false, separator: "" },
            file_type: "",
            included_properties: [],
            exportFileName: "",
            filters: [],
            is_desc: false,
            sort_by: "",
            isSelectedAll: this.props.isSelectedAll
        },
        isSelectedSection: false
    }

    componentDidMount() {
        this.clearPopupData();
    }

    onCheckBoxChange(checkName, isChecked) {
        let columnList = this.state.columnList || [];
        if (checkName == 'all') {
            columnList.map((column, index) => {
                column.isAllChecked = isChecked;
                column.columns.forEach((item) => {
                    item.checked = isChecked;
                });
            });
        } else {
            let splitedColumnName = checkName.split("::");
            let mainColumnValue = splitedColumnName[0];
            let childColumnValue = splitedColumnName[1];
            columnList.filter(x => x.value == mainColumnValue).forEach((section) => {
                section.columns.forEach((item) => {
                    if (childColumnValue === 'all' || item.value == childColumnValue) {
                        item.checked = isChecked;
                    }
                })
                section.isAllChecked = ((section.columns.length === section.columns.filter(p => p.checked == true).length) && isChecked);

            })
        }
        let isAllSelected = columnList.every(x => x.isAllChecked);

        this.setState({
            isSelectedAll: isAllSelected,
            columnList: columnList
        }, () => {
            if (!this.state.isSelectedAll && !this.isSelectAllCheckBox()) {
                this.setState({ isSelectedSection: false });
            }
            else {
                this.setState({ isSelectedSection: true });
            }
        });

    }

    handleChange = (event: any) => {
        const isCheckbox = event.target.type === "checkbox";
        this.setState({
            [event.target.name]: isCheckbox
                ? event.target.checked
                : event.target.value
        }, () => {

        })

    }

    handleExportType = (event: any) => {
        this.setState({
            [event.target.name]: event.target.value
        })
        if (event.target.value === "CSV") {
            this.setState({ isCsv: true })
        }
        else {
            this.setState({ isCsv: false })
        }
    }

    clearPopupData() {
        this.state.columnList?.forEach(p => p.isAllChecked = true)
        this.state.columnList?.forEach(p => p.columns.forEach(p => p.checked = true))
        this.setState({
            isSelectedAll: true,
            clearData: {
                isSelectedAll: true,
                isNoHeader: false,
                columnSeparator: this.initialState.columnSeparator,
                encodeToName: this.initialState.encodeToName,
                exportTo: this.initialState.exportTo,
            }
        }, () => {
            this.props.clearExportModal(this.state.clearData)
        })
    }

    handleClose = event => {
        event.preventDefault();
        this.state.columnList?.forEach(p => p.isAllChecked = true)
        this.state.columnList?.forEach(p => p.columns.forEach(p => p.checked = true))
        this.setState({
            isSelectedAll: true,
            clearData: {
                isSelectedAll: true,
                isNoHeader: false,
                columnSeparator: this.initialState.columnSeparator,
                encodeToName: this.initialState.encodeToName,
                exportTo: this.initialState.exportTo,
            }
        }, () => {
            this.props.handleClose();
            this.props.clearExportModal(this.state.clearData)
        })
    }
    getSelectedColumnsList() {
        // let columnList: ExportedColumns[] = [];
        let columnList = {};
        this.state.columnList?.forEach(section => {

            let selectedColumns: string[] = [];
            section.columns.forEach(item => {
                if (item.checked) {
                    selectedColumns.push(item.value.toLowerCase())
                }

            });
            if (selectedColumns.length > 0) {
                // columnList.push({ title: section.value, columns: selectedColumns })
                columnList[section.value] = selectedColumns
            }
            else {
                columnList[section.value] = [];
            }
        });
        return columnList;
    }
    exportTo = event => {
        event.preventDefault();
        this.setState({
            export: {
                file_type: this.state.exportTo,
                csv_setting: { encoding: this.state.encodeToName, header: this.state.isNoHeader, separator: this.state.columnSeparator },
                included_properties: this.getSelectedColumnsList(),
                exportFileName: this.props.fileNamePrefix + this.exportFileNamePostFix + this.getFileExtensionFromExportType(),
                isSelectedAll: this.state.isSelectedAll,
                isNoHeader: this.state.isNoHeader
            }
        }, () => {
            if (!this.state.isSelectedAll && !this.isSelectAllCheckBox()) {
                ShowWarningMessage("Select atleast one field to export");
            }
            else {
                this.props.exportTo(this.state.export);
            }
        })
    }

    isSelectAllCheckBox() {
        let isSelectedSection = false;
        let data = this.getSelectedColumnsList();
        for (let i = 0; i < Object.keys(data).length; i++) {
            if (data[Object.keys(data)[i]].length > 0) {
                isSelectedSection = true;
                break;
            }
        }
        return isSelectedSection;
    }

    private getFileExtensionFromExportType(): string {
        return '.' + this.state.exportTo?.toLowerCase()
    }

    render() {
        return <form>
            {!this.props.isExporting && <Modal.Header closeButton onClick={this.handleClose}>
                <h5 className="modal-title">Select Fields to Export</h5>
            </Modal.Header>}
            {this.props.isExporting && <Modal.Header>
                <h5 className="modal-title">Select Fields to Export</h5>
            </Modal.Header>}
            <Modal.Body>
                <div className="section bottomfixed ">
                    {/* <div className="section-title mt-3 mb-0">Select fields to export</div> */}
                    <div className="row">
                        <div className="col-12 datatop">
                            <ExportColumnCheckBoxList options={this.state.columnList} isCheckedAll={this.state?.isSelectedAll} onCheck={this.onCheckBoxChange.bind(this)} />
                        </div>
                        <div className="col-12 databottom">
                            <div className="form-row">
                                <div className="form-group col-md-5">
                                    <p className="mt-3 mb-0 font-weight-bold">Export To</p>
                                    <select className="form-control" name="exportTo" value={this.state.exportTo} onChange={this.handleExportType}>
                                        <option value="CSV">CSV</option>
                                        <option value="JSON">JSON</option>
                                        <option value="XML">XML</option>
                                    </select>
                                </div>
                            </div>
                            {this.state.isCsv && <>
                                <div className="form-row">
                                    <div className="form-group col-md-5">
                                        <p className="mt-3 mb-0 font-weight-bold">Encode to</p>
                                        <select className="form-control" name="encodeToId" value={this.state.encodeToId} onChange={this.handleChange}>
                                            <option value="utf_8">UTF-8</option>
                                            <option value="utf_16_le">UTF-16LE</option>
                                            <option value="utf_16_be">UTF-16BE</option>
                                            <option value="utf_32_le">UTF-32LE</option>
                                            <option value="utf_32_be">UTF-32BE</option>
                                            <option value="utf_7">UTF-7</option>
                                            <option value="latin_1">ISO-8859-1</option>
                                            <option value="iso8859_15">ISO-8859-15</option>
                                            <option value="cp850">IBM-850</option>
                                            <option value="mac_roman">MacRoman</option>
                                            <option value="cp1252">Windows-1252</option>
                                            <option value="iso8859_3">ISO-8859-3</option>
                                            <option value="cp852">IBM-852</option>
                                            <option value="iso8859_2">ISO-8859-2</option>
                                            <option value="mac_latin2">MacCE</option>
                                            <option value="cp1250">Windows-1250</option>
                                            <option value="cp855">IBM-855</option>
                                            <option value="iso8859_5">ISO-8859-5</option>
                                            <option value="koi8_r">KOI8-R</option>
                                            <option value="mac_cyrillic">MacCyrillic</option>
                                            <option value="cp1251">Windows-1251</option>
                                            <option value="cp866">CP-866</option>
                                            <option value="koi8_u">KOI-U</option>
                                            <option value="gb2312">GB2312</option>
                                            <option value="gbk">GBK</option>
                                            <option value="gb18030">GB18030</option>
                                            <option value="hz">HZ</option>
                                            <option value="big5">Big5</option>
                                            <option value="big5hkscs">Big5-HKSCS</option>
                                            <option value="euc_tw">EUC-TW</option>
                                            <option value="euc_jp">EUC-JP</option>
                                            <option value="iso2022_jp">ISO-2022-JP</option>
                                            <option value="shift_jis">Shift_JIS</option>
                                            <option value="euc_kr">EUC-KR</option>
                                            <option value="cp949">UHC</option>
                                            <option value="johab">JOHAB</option>
                                            <option value="iso2022_kr">ISO-2022-KR</option>
                                        </select>
                                        <small>Leave empty to let current input encoding untouched: (UTF8)</small>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <p className="mt-3 mb-0 font-weight-bold">Column Seperator</p>
                                        <select className="form-control" name="columnSeparator" value={this.state.columnSeparator} onChange={this.handleChange}>
                                            <option value=",">&lt;comma&gt; ','</option>
                                            <option value=";">&lt;semicolon&gt; ';'</option>
                                            <option value="t">&lt;tabs&gt;</option>
                                        </select>
                                        <small>Leave blank for default (',')</small>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <p className="mt-3 mb-0 font-weight-bold">No header</p>
                                        <div className="custom-control custom-checkbox">
                                            <input disabled={!this.state.isSelectedAll && !this.state.isSelectedSection} type="checkbox" id="isNoHeader" name="isNoHeader" checked={this.state.isNoHeader} onChange={this.handleChange} className="custom-control-input" />
                                            {/* <input type="checkbox" id="isNoHeader" name="isNoHeader" checked={this.state.isNoHeader} onChange={this.handleChange} className="custom-control-input" /> */}
                                            <label className="custom-control-label" htmlFor="isNoHeader"> No fields description</label>
                                            {/* <small>Do not output a header</small> */}
                                        </div>
                                    </div>
                                </div>
                            </>
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="bg-whitesmoke ">
                <><div>
                    <button type="button" disabled={this.props.isExporting} className="btn btn-default" onClick={this.handleClose}>{ButtonType.Close}</button>
                    {!this.props.isExporting && <button type="button" className="btn btn-primary ml-3" onClick={this.exportTo}>{ButtonType.Export}</button>}
                    <Loader loading={this.props.isExporting} marginBottom="0px" marginTop="8px" float="right" width="83px" /></div>
                </>
            </Modal.Footer>
        </form>
    }
}