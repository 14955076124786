import React, { Component, Fragment } from 'react'
import { EditAccountSourceOverrideModel, AccountSourceOverridePostModel } from './models'
import { DropdownItemModel } from '../../../shared/models'
import { AccountSourceOverridesService } from './services';
import { CommonService } from '../../../shared/services/common.service';
import { HttpResponse } from '../../../core';
import { ShowSuccessMessage, HandleNotFoundResponse } from '../../../shared/helpers';
import { ButtonType, NotFoundResponseArea } from '../../../shared/enums';
import { AccountSourceOverridesTabs } from '.';
import { Link } from "react-router-dom";
import { Loader } from '../../../shared/loaders';
import { Modal } from 'react-bootstrap';
import { AccountFormPopup } from '..';
import { SourceReportEditFormPopup } from '../../user/reports/source-reports';
import { ConnectionFormPopup } from '../connections';
import { ScopesEditFormPopup } from '../../individual/schema/scopes/ScopesEditFormPopup';
import { IsAuthorize } from '../../../shared/authorization/check-access';
import { Role } from '../../../shared/authorization/enums';
import { CustomConnectionFormPopup } from '../custom-connection';


interface IState {
    showAccountForm?: boolean;
    showConnectionForm?: boolean;
    showCustomConnectionForm?: boolean;
    showSourceReportForm?: boolean;
    showScopeForm?: boolean;
    accountSourceOverride?: AccountSourceOverridePostModel,
    editaccountSourceOverride?: EditAccountSourceOverrideModel,
    id?: number,

    account_id?: number,
    accountList?: DropdownItemModel[],
    accountName?: string,
    accountError?: string,

    connection_id?: number,
    connectionList?: DropdownItemModel[],
    connectionName?: string,
    connectionError?: string,

    custom_connection_id?: number;
    customConnectionList?: DropdownItemModel[],
    customConnectionName?: string,

    sourceReport_id?: number,
    sourceReportList?: DropdownItemModel[],
    sourceReportName?: string,
    sourceReportError?: string,

    scope_id?: number,
    scopeList?: DropdownItemModel[],
    scopeName?: string,
    scopeError?: string,

    isShowtext?: string;
    isSave?: string;
    isSaving?: boolean;

    isNew?: boolean;
    isChildUpdate?: boolean;
}
export class AccountSourceOverridesForm extends Component<any, IState> {
    //declaring service type
    private service: AccountSourceOverridesService;
    private commonService: CommonService;


    private networkCall: any;

    constructor(props) {
        super(props)
        this.service = new AccountSourceOverridesService();
        this.commonService = new CommonService();
        this.state = this.initialState;
        this.handleClose = this.handleClose.bind(this);
        this.updateAccount = this.updateAccount.bind(this);
        this.updateConnection = this.updateConnection.bind(this);
        this.updateCustomConnection = this.updateCustomConnection.bind(this);
        this.updateSourceReport = this.updateSourceReport.bind(this);
        this.updateSourceReport = this.updateSourceReport.bind(this);
        this.updateScope = this.updateScope.bind(this);

    }

    initialState: Partial<IState> = {
        accountSourceOverride: {
            id: 0,
            account_id: 0,
            connection_id: 0,
            scope_id: 0,
            source_report_id: 0

        },
        id: 0,
        account_id: 0,
        accountName: "",
        accountList: [],
        accountError: "",

        connection_id: 0,
        connectionName: "",
        connectionList: [],
        connectionError: "",

        custom_connection_id: 0,
        customConnectionList: [],
        customConnectionName: "",


        scope_id: 0,
        scopeName: "",
        scopeList: [],
        scopeError: "",

        sourceReport_id: 0,
        sourceReportName: "",
        sourceReportList: [],
        sourceReportError: "",

        isShowtext: "New",
        isSave: "",
        isSaving: false,
        showAccountForm: false,
        showConnectionForm: false,
        showCustomConnectionForm: false,
        showSourceReportForm: false,
        showScopeForm: false,

        isNew: false,
        isChildUpdate: false
    }

    updateAccount(isChildUpdate, name, id) {
        this.setState({ isChildUpdate: isChildUpdate, accountName: name, account_id: id })
    }

    handleClose = () => {
        this.setState({ showAccountForm: false }, () => {
        });
    }

    handleShowAccount = () => {
        this.setState({ showAccountForm: true, isNew: true }, () => {

        });
    }

    handleShowEditAccount = () => {
        this.setState({ showAccountForm: true, isNew: false }, () => {

        });
    }



    updateConnection(isChildUpdate, name, id) {
        this.setState({ isChildUpdate: isChildUpdate, connectionName: name, connection_id: id })
    }

    handleCloseConnection = () => {
        this.setState({ showConnectionForm: false }, () => {
        });
    }

    handleShowConnection = () => {
        this.setState({ showConnectionForm: true, isNew: true }, () => {

        });
    }

    handleShowEditConnection = () => {
        this.setState({ showConnectionForm: true, isNew: false }, () => {

        });
    }


    updateCustomConnection(isChildUpdate, name, id) {
        this.setState({ isChildUpdate: isChildUpdate, customConnectionName: name, custom_connection_id: id })
    }

    handleCloseCustomConnection = () => {
        this.setState({ showCustomConnectionForm: false }, () => {
        });
    }

    handleShowCustomConnection = () => {
        this.setState({ showCustomConnectionForm: true, isNew: true }, () => {

        });
    }

    handleShowEditCustomConnection = () => {
        this.setState({ showCustomConnectionForm: true, isNew: false }, () => {

        });
    }



    handleShowEditSourceReport = () => {
        this.setState({ showSourceReportForm: true, isNew: false }, () => {

        });
    }

    updateSourceReport(isChildUpdate) {
        this.setState({ isChildUpdate: isChildUpdate })
    }

    handleCloseSourceReport = () => {
        this.setState({ showSourceReportForm: false }, () => {
        });
    }


    handleShowEditScope = () => {
        this.setState({ showScopeForm: true, isNew: false }, () => {

        });
    }

    updateScope(isChildUpdate, name, id) {
        this.setState({ isChildUpdate: isChildUpdate, scopeName: name, scope_id: id })
    }

    handleCloseScope = () => {
        this.setState({ showScopeForm: false }, () => {
        });
    }


    componentDidMount() {
        if (this.props.match.params.id > 0) {
            this.setState({ id: this.props.match.params.id, isShowtext: "Edit" }, () => {
                this.loadData();
            });
        }
        document.addEventListener('mousedown', this.handleClickOutside)
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (event.path[0].id !== 'accountName' && event.path[0].id !== 'account' && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight) {
            this.setState({
                accountList: []
            }, () => {

            })
        }

        if (event.path[0].id !== 'connectionName' && event.path[0].id !== 'connection' && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight) {
            this.setState({
                connectionList: []
            }, () => {

            })
        }
        if (event.path[0].id !== 'customConnectionName' && event.path[0].id !== 'custom_connection' && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight) {
            this.setState({
                customConnectionList: []
            }, () => {

            })
        }

        if (event.path[0].id !== 'sourceReportName' && event.path[0].id !== 'source_report' && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight) {
            this.setState({
                sourceReportList: []
            }, () => {

            })
        }

        if (event.path[0].id !== 'scopeName' && event.path[0].id !== 'scope' && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight) {
            this.setState({
                scopeList: []
            }, () => {

            })
        }
    }


    loadData() {
        this.service.editAccountSourceOverrides(this.props.match.params.id)
            .then((res: HttpResponse<EditAccountSourceOverrideModel>) => {
                if (res && res.result) {
                    this.setState({
                        id: res.result?.id,
                        accountName: res.result?.account === null ? '' : res.result?.account.text,
                        account_id: res.result?.account === null ? 0 : res.result?.account.value,
                        connectionName: res.result?.connection === null ? '' : res.result?.connection.text,
                        connection_id: res.result?.connection === null ? 0 : res.result?.connection.value,
                        customConnectionName: res.result?.custom_connection === null ? '' : res.result?.custom_connection.text,
                        custom_connection_id: res.result?.custom_connection === null ? 0 : res.result?.custom_connection.value,
                        sourceReportName: res.result?.source_report === null ? '' : res.result?.source_report.text,
                        sourceReport_id: res.result?.source_report === null ? 0 : res.result?.source_report.value,
                        scopeName: res.result?.scope === null ? '' : res.result?.scope.text,
                        scope_id: res.result?.scope === null ? 0 : res.result?.scope.value
                    });

                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex, NotFoundResponseArea.AccountSourceOverrides, this.props)
            });
    }

    postData(accountSourceOverride: AccountSourceOverridePostModel) {
        this.setSavingFlag(true);
        this.service.postAccountSourceOverrides(accountSourceOverride)
            .then((res: HttpResponse<AccountSourceOverridePostModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        accountSourceOverride: res.result,
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Account Source Override Successfully Created.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/account-source-override/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                            this.setState(this.initialState);
                            this.props.history.push('/account-source-override/account-source-override-form/0/0');
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/account-source-override/account-source-override-form/' + this.state?.id);
                            this.setState({ isShowtext: "Edit" })
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });;
    }

    updateData(accountSourceOverride: AccountSourceOverridePostModel) {
        this.setSavingFlag(true);
        this.service.updateAccountSourceOverrides(accountSourceOverride)
            .then((res: HttpResponse<AccountSourceOverridePostModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        accountSourceOverride: res.result
                    }, () => {
                        ShowSuccessMessage("Account Source Override successfully Updated.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/account-source-override/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                            this.setState(this.initialState);
                            this.props.history.push('/account-source-override/account-source-override-form/0');
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/account-source-override/account-source-override-form/' + this.state?.id);
                            this.setState({ isShowtext: "Edit" })
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });
    }

    private getAccounts(text: string) {
        this.commonService.getAccountsByName(text)
            .then((res: HttpResponse<DropdownItemModel[]>) => {
                if (res) {
                    this.setState({
                        accountList: res.result
                    });
                }
            });

    }

    private getConnections(text: string) {
        this.commonService.getConnectionsByName(text)
            .then((res: HttpResponse<DropdownItemModel[]>) => {
                if (res) {
                    this.setState({
                        connectionList: res.result
                    });
                }
            });

    }

    private getCustomConnections(text: string) {
        this.commonService.getCustomConnectionsByName(text)
            .then((res: HttpResponse<DropdownItemModel[]>) => {
                if (res) {
                    this.setState({
                        customConnectionList: res.result
                    });
                }
            });

    }

    private getScopes(text: string) {
        this.commonService.getScopByName(text)
            .then((res: HttpResponse<DropdownItemModel[]>) => {
                if (res) {
                    this.setState({
                        scopeList: res.result
                    });
                }
            });

    }

    private getSourceReports(text: string) {
        this.commonService.getSourceReportByName(text)
            .then((res: HttpResponse<DropdownItemModel[]>) => {
                if (res) {
                    this.setState({
                        sourceReportList: res.result
                    });
                }
            });

    }

    //*************** * Accounts************************* //

    onAccountChange = (e) => {
        const value = e.target.value;
        if (value.length > 0) {
            if (this.networkCall) {
                clearTimeout(this.networkCall);
            }
            this.networkCall = setTimeout(() => {
                this.getAccounts(value)
            }, 600)
        }
        this.setState({
            accountName: value,
            account_id: value === '' ? 0 : value,
            accountError: ""
        })
    }

    selectedAccount(item) {
        this.setState({
            account_id: item.value,
            accountName: item.text,
            accountList: []
        })
    }

    renderAccount = () => {
        if (this.state.accountList?.length === 0) {
            return null;
        }
        return (
            <ul className="list-unstyled auto-suggest">
                {
                    this.state.accountList?.map((item, index) => (<li id="account" key={index} onClick={() => this.selectedAccount(item)}>{item.text}</li>))
                }
            </ul>
            //     <datalist id="accountNames" >
            //     {
            //          this.state.accountList?.map((item, index) => (<option id="account" key={index} onSelect={() => this.selectedAccount(item)} value={item.text}>{item.text}</option>))
            //   }
            // </datalist>
        );
    }
    //*************** * Accounts************************* //


    //*************** * Connection************************* //

    onConnectionChange = (e) => {

        const value = e.target.value;
        if (value.length > 0) {
            if (this.networkCall) {
                clearTimeout(this.networkCall);
            }
            this.networkCall = setTimeout(() => {
                this.getConnections(value)
            }, 600)
        }
        this.setState({
            connectionName: value,
            connection_id: value === '' ? 0 : value,
            connectionError: ""
        })
    }

    selectedConnection(item) {
        this.setState({
            connection_id: item.value,
            connectionName: item.text,
            connectionList: []
        })
    }

    renderConnection = () => {
        if (this.state.connectionList?.length === 0) {
            return null;
        }
        return (
            <ul className="list-unstyled auto-suggest">
                {
                    this.state.connectionList?.map((item, index) => (<li id="connection" key={index} onClick={() => this.selectedConnection(item)}>{item.text}</li>))
                }
            </ul>
        );
    }
    //*************** *Connection************************* //


    //*************** * Custom Connection************************* //

    onCustomConnectionChange = (e) => {

        const value = e.target.value;
        if (value.length > 0) {
            if (this.networkCall) {
                clearTimeout(this.networkCall);
            }
            this.networkCall = setTimeout(() => {
                this.getCustomConnections(value)
            }, 600)
        }
        this.setState({
            customConnectionName: value,
            custom_connection_id: value === '' ? 0 : value,
            connectionError: ""
        })
    }

    selectedCustomConnection(item) {
        this.setState({
            custom_connection_id: item.value,
            customConnectionName: item.text,
            customConnectionList: []
        })
    }

    renderCustomConnection = () => {
        if (this.state.customConnectionList?.length === 0) {
            return null;
        }
        return (
            <ul className="list-unstyled auto-suggest">
                {
                    this.state.customConnectionList?.map((item, index) => (<li id="custom_connection" key={index} onClick={() => this.selectedCustomConnection(item)}>{item.text}</li>))
                }
            </ul>
        );
    }
    //*************** *Custom Connection************************* //

    //*************** *Source Report************************* //

    onSourceReportChange = (e) => {
        const value = e.target.value;
        if (value.length > 0) {
            if (this.networkCall) {
                clearTimeout(this.networkCall);
            }
            this.networkCall = setTimeout(() => {
                this.getSourceReports(value)
            }, 600)
        }
        this.setState({
            sourceReportName: value,
            sourceReport_id: value === '' ? 0 : value,
            sourceReportError: ""
        })
    }

    selectedSourceReport(item) {
        this.setState({
            sourceReport_id: item.value,
            sourceReportName: item.text,
            sourceReportList: []
        })
    }

    renderSourceReport = () => {
        if (this.state.sourceReportList?.length === 0) {
            return null;
        }
        return (
            <ul className="list-unstyled auto-suggest">
                {
                    this.state.sourceReportList?.map((item, index) => (<li id="source_report" key={index} onClick={() => this.selectedSourceReport(item)}>{item.text}</li>))
                }
            </ul>
        );
    }
    //*************** * Source Report************************* //

    //*************** *Scope************************* //

    onScopeChange = (e) => {
        const value = e.target.value;
        if (value.length > 0) {
            if (this.networkCall) {
                clearTimeout(this.networkCall);
            }
            this.networkCall = setTimeout(() => {
                this.getScopes(value)
            }, 600)
        }
        this.setState({
            scopeName: value,
            scope_id: value === '' ? 0 : value,
            scopeError: ""
        })
    }

    selectedScope(item) {
        this.setState({
            scope_id: item.value,
            scopeName: item.text,
            scopeList: []
        })
    }

    renderScope = () => {
        if (this.state.scopeList?.length === 0) {
            return null;
        }
        return (
            <ul className="list-unstyled auto-suggest">
                {
                    this.state.scopeList?.map((item, index) => (<li id="scope" key={index} onClick={() => this.selectedScope(item)}>{item.text}</li>))
                }
            </ul>
        );
    }
    //*************** * Scope************************* //

    handleChange = (event: any) => {
        if (event.target.name == "accountName") {
            this.onAccountChange(event);
        }
        if (event.target.name == "connectionName") {
            this.onConnectionChange(event);
        }
        if (event.target.name == "customConnectionName") {
            this.onCustomConnectionChange(event);
        }
        if (event.target.name == "sourceReportName") {
            this.onSourceReportChange(event);
        }
        if (event.target.name == "scopeName") {
            this.onScopeChange(event);
        }
        else {
            const isCheckbox = event.target.type === "checkbox";
            this.setState({
                [event.target.name]: isCheckbox
                    ? event.target.checked
                    : event.target.value
            })
        }

    }

    validate = () => {
        let accountError = "";
        let connectionError = "";
        let sourceReportError = "";
        let scopeError = ""

        if (!this.state.account_id) {
            accountError = "Account can't be blank";
        }

        if (!this.state.connection_id && !this.state.custom_connection_id && !this.state.sourceReport_id) {
            connectionError = "Connection  can't be blank";
        }

        if ((this.state.connection_id || this.state.custom_connection_id) && !this.state.scope_id) {
            scopeError = "Scope can't be blank";
        }

        // if (!this.state.connection_id && !this.state.custom_connection_id && !this.state.sourceReport_id) {
        //     sourceReportError = "Source Report can't be blank";
        // }

        if (connectionError || accountError || scopeError) {
            this.setState({ connectionError: connectionError, accountError: accountError, scopeError: scopeError })
            return false;
        }
        else {
            connectionError = "";
            accountError = "";
            scopeError = "";
            this.setState({ connectionError: connectionError, accountError: accountError, scopeError: scopeError })
            return true;
        }
    }

    handleSubmit = event => {
        event.preventDefault();
        if (!this.state.isChildUpdate) {
            const isValid = this.validate();
            let accountSourceOverideData: AccountSourceOverridePostModel = {
                id: this.state.id,
                account_id: this.state.account_id,
                connection_id: this.state.connection_id === 0 ? null : this.state.connection_id,
                custom_connection_id: this.state.custom_connection_id === 0 ? null : this.state.custom_connection_id,
                scope_id: this.state.scope_id === 0 ? null : this.state.scope_id,
                source_report_id: this.state.sourceReport_id === 0 ? null : this.state.sourceReport_id
            };


            if (isValid) {
                if (accountSourceOverideData.id === 0) {
                    this.postData(accountSourceOverideData);
                }
                else {

                    this.updateData(accountSourceOverideData)
                }
            }
        }
    }

    handleClick = (event) => {
        this.setState({ isSave: event.target.value, isChildUpdate: false })
    }
    private setSavingFlag(saving: boolean) {
        this.setState({ isSaving: saving });
    }

    render() {
        return (
            <Fragment>
                <form onSubmit={this.handleSubmit}>
                    <input type="hidden" value={this.state.id} />
                    <div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h4>{this.state.isShowtext}  Account Source Override {this.props.match.params.id != 0 && <span> 'AccountSourceOverride #{this.props.match.params.id}'</span>}</h4>
                        </div>
                        <Modal backdrop='static' keyboard={false} size="lg" show={this.state.showAccountForm} onHide={this.handleClose}>
                            <AccountFormPopup updateAccount={this.updateAccount} handleClose={this.handleClose} isNew={this.state.isNew} id={this.state?.account_id} />
                        </Modal>

                        <Modal backdrop='static' keyboard={false} size="lg" show={this.state.showConnectionForm} onHide={this.handleCloseConnection}>
                            <ConnectionFormPopup updateConnection={this.updateConnection} handleClose={this.handleCloseConnection} isNew={this.state.isNew} id={this.state?.connection_id} />
                        </Modal>
                        <Modal backdrop='static' keyboard={false} size="lg" show={this.state.showCustomConnectionForm} onHide={this.handleCloseCustomConnection}>
                            <CustomConnectionFormPopup updateCustomConnection={this.updateCustomConnection} handleCloseCustomConnection={this.handleCloseCustomConnection} isNew={this.state.isNew} id={this.state?.custom_connection_id} />
                        </Modal>

                        <Modal backdrop='static' keyboard={false} size="lg" show={this.state.showSourceReportForm} onHide={this.handleCloseSourceReport}>
                            <SourceReportEditFormPopup updateSourceReport={this.updateSourceReport} handleClose={this.handleCloseSourceReport} isNew={this.state.isNew} id={this.state?.sourceReport_id} />
                        </Modal>

                        <Modal backdrop='static' keyboard={false} size="lg" show={this.state.showScopeForm} onHide={this.handleCloseScope}>
                            <ScopesEditFormPopup updateScopes={this.updateScope} handleClose={this.handleCloseScope} isNew={this.state.isNew} id={this.state?.scope_id} />
                        </Modal>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        {this.props.match.params.id != 0 && <AccountSourceOverridesTabs id={this.props.match.params.id} url="/account-source-override/edit/" />}
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group shadow-sm p-3 mb-2 position-relative">
                                                    <label>Account<span className=" text-danger">*</span></label>
                                                    <div className="input-group ">
                                                        <input list="accountNames" autoComplete="off" id="accountName" name="accountName" type="text" onChange={this.handleChange} value={this.state.accountName}
                                                            className={!this.state.accountError ? 'form-control' : 'form-control  is-invalid'} />
                                                        {this.renderAccount()}
                                                        {IsAuthorize([Role.TAM, Role.Dev, Role.AM, Role.Support, Role.Manager]) && <div className="input-group-append">
                                                            <button type="button" className="btn btn-success" onClick={this.handleShowAccount}><i className="fas fa-plus" style={{ color: "white" }}></i></button>
                                                            <button type="button" className={`btn btn-primary`} disabled={this.state.account_id == 0} onClick={this.handleShowEditAccount}><i className="far fa-edit"></i></button>
                                                        </div>}
                                                        <div className="invalid-feedback">
                                                            {this.state.accountError}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-md-4">
                                                <div className="form-group shadow-sm p-3  mb-2 position-relative">
                                                    <label>Connection</label>
                                                    <div className="input-group ">
                                                        <input list="connectionNames" autoComplete="off" id="connectionName" name="connectionName" type="text" onChange={this.handleChange} value={this.state.connectionName}
                                                            className={!this.state.connectionError ? 'form-control' : 'form-control  is-invalid'} />
                                                        {this.renderConnection()}
                                                        {IsAuthorize([Role.Dev, Role.TAM]) && <div className="input-group-append">
                                                            <button type="button" className="btn btn-success" onClick={this.handleShowConnection}><i className="fas fa-plus" style={{ color: "white" }}></i></button>
                                                            <button type="button" className={`btn btn-primary`} disabled={this.state.connection_id == 0} onClick={this.handleShowEditConnection}><i className="far fa-edit"></i></button>
                                                        </div>}
                                                        <div className="invalid-feedback">
                                                            {this.state.connectionError}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group shadow-sm p-3  mb-2 position-relative">
                                                    <label>Custom Connection</label>
                                                    <div className="input-group ">
                                                        <input list="connectionNames" autoComplete="off" id="customConnectionName" name="customConnectionName" type="text" onChange={this.handleChange} value={this.state.customConnectionName}
                                                            className="form-control" />
                                                        {this.renderCustomConnection()}
                                                        {IsAuthorize([Role.Dev, Role.TAM]) && <div className="input-group-append">
                                                            <button type="button" className="btn btn-success" onClick={this.handleShowCustomConnection}><i className="fas fa-plus" style={{ color: "white" }}></i></button>
                                                            <button type="button" className={`btn btn-primary`} disabled={this.state.custom_connection_id == 0} onClick={this.handleShowEditCustomConnection}><i className="far fa-edit"></i></button>
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div> */}


<div className="col-md-4 d-flex">
                                                <div className="form-group shadow-sm p-3  mb-2 position-relative flex-grow-1">
                                                    <label>Connection</label>
                                                    <div className="input-group ">
                                                        <input list="connectionNames" autoComplete="off" id="connectionName" name="connectionName" type="text" onChange={this.handleChange} value={this.state.connectionName}
                                                            className={!this.state.connectionError ? 'form-control' : 'form-control  is-invalid'} />
                                                        {this.renderConnection()}
                                                        {IsAuthorize([Role.Dev, Role.TAM,Role.Support, Role.Manager]) && <div className="input-group-append">
                                                            <button type="button" className="btn btn-success" onClick={this.handleShowConnection}><i className="fas fa-plus" style={{ color: "white" }}></i></button>
                                                            {IsAuthorize([Role.Dev, Role.TAM,Role.AM, Role.Support]) && <button type="button" className={`btn btn-primary`} disabled={this.state.connection_id == 0} onClick={this.handleShowEditConnection}><i className="far fa-edit"></i></button>}
                                                        </div>}
                                                        <div className="invalid-feedback">
                                                            {this.state.connectionError}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="align-self-center ml-4"><span style={{fontWeight:"bold", fontSize:"12px",marginTop:"24px",float:"right"}}>OR</span></div>
                                            </div>
                                            
                                            <div className="col-md-4">
                                            <div className="form-group shadow-sm p-3  mb-2 position-relative">
                                                    <label>Custom Connection</label>
                                                    <div className="input-group ">
                                                        <input list="connectionNames" autoComplete="off" id="customConnectionName" name="customConnectionName" type="text" onChange={this.handleChange} value={this.state.customConnectionName}
                                                            className="form-control" />
                                                        {this.renderCustomConnection()}
                                                        {IsAuthorize([Role.Dev, Role.TAM, Role.Support, Role.Manager]) && <div className="input-group-append">
                                                            <button type="button" className="btn btn-success" onClick={this.handleShowCustomConnection}><i className="fas fa-plus" style={{ color: "white" }}></i></button>
                                                            {IsAuthorize([Role.Dev, Role.TAM,Role.AM, Role.Support]) && <button type="button" className={`btn btn-primary`} disabled={this.state.custom_connection_id == 0} onClick={this.handleShowEditCustomConnection}><i className="far fa-edit"></i></button>}
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group shadow-sm p-3  mb-2 position-relative">
                                                    <label>Scope</label>
                                                    <div className="input-group ">
                                                        <input list="scopeNames" autoComplete="off" id="scopeName" name="scopeName" type="text" onChange={this.handleChange} value={this.state.scopeName}
                                                            className={!this.state.scopeError ? 'form-control' : 'form-control  is-invalid'} />
                                                        {this.renderScope()}
                                                        {IsAuthorize([Role.TAM, Role.Dev, Role.Support]) && <div className="input-group-append">
                                                            <button type="button" className={`btn btn-primary `} disabled={this.state.scope_id == 0} onClick={this.handleShowEditScope}><i className="far fa-edit"></i></button>
                                                        </div>}
                                                        <div className="invalid-feedback">
                                                            {this.state.scopeError}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group shadow-sm p-3  mb-2 position-relative">
                                                    <label>Source Report</label>
                                                    <div className="input-group ">
                                                        <input list="sourceReportNames" autoComplete="off" id="sourceReportName" name="sourceReportName" type="text" onChange={this.handleChange} value={this.state.sourceReportName}
                                                            className="form-control" />
                                                        {this.renderSourceReport()}
                                                        {IsAuthorize([Role.TAM, Role.Support]) &&   <div className="input-group-append">
                                                            <button type="button" className={`btn btn-primary `} disabled={this.state.sourceReport_id == 0} onClick={this.handleShowEditSourceReport}><i className="far fa-edit"></i></button>
                                                        </div>}
                                                        {/* <div className="invalid-feedback">
                                                            {this.state.sourceReportError}
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-3 mb-4">
                            {!this.state.isSaving && <Fragment>
                                <Link type="button" className="btn btn-lg btn-default" to="/account-source-override/list">{ButtonType.Cancel}</Link>
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" onClick={this.handleClick} value={ButtonType.Save} />
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndAddAnother} onClick={this.handleClick} />
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndEdit} onClick={this.handleClick} />
                            </Fragment>
                            }
                            <Loader loading={this.state.isSaving} marginBottom="0px" marginTop="8px" float="center" width="200px" ></Loader>
                        </div>
                    </div>
                </form>
            </Fragment>
        )
    }
}
