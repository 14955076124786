import React, { Component, Fragment } from 'react'
import { ReportDetailModel, ReportColumns, ReportViewModel, ReportStatsModel, ReportRunSchedule } from './models'
import { UserReportsService } from './services';
import { AppConfig, HttpResponse } from '../../../core';
import { DropdownItemModel } from '../../../shared/models';
import { ConvertDateTime, GetDays, HandleNotFoundResponse } from '../../../shared/helpers';
import { NavLink } from "react-router-dom";
import { Editor } from '../../../shared'
import { ReportTabs } from './ReportTabs';
import { NotFoundResponseArea } from '../../../shared/enums';
import { Loader } from '../../../shared/loaders';
import { IsAuthorize } from '../../../shared/authorization/check-access';
import { Role } from '../../../shared/authorization/enums';

interface IState {
    result?: ReportDetailModel;
    isLoading?: boolean;
}
export class ReportDetail extends Component<any, IState> {
    private service: UserReportsService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new UserReportsService();
    }

    initialState: Partial<IState> = {
        result: {
            id: 0,
            user_id: 0,
            user_name: "",
            edited_by_user_id: 0,
            created_at: new Date(),
            custom_sql: "",
            dependent_reports: [],
            enabled: false,
            last_generated_at: new Date(),
            name: "",
            favicon_url: "",
            query_strategy: "",
            report_columns: [],
            report_views: [],
            source_report_id: 0,
            sources: [],
            stats: [],
            report_run_schedule: [],
            installed_applications: [],
            recent_report_jobs: [],
            time_range: "",
            edited_by_user_name: "",
            edited_at: new Date(),

            updated_at: new Date(),
            viewed_at: new Date(),
            alert_on_failure: false,
            always_rebuild: false,
            ignore_health: false,
            skip_when_priority_dependent_job: false,
            user_email:""
        },
        isLoading: false,
    }

    componentDidMount() {
        this.loadData(this.props.match.params.id);
    }

    componentWillReceiveProps(nextprops) {
        this.loadData(nextprops.match.params.id);
    }

    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
       

    loadData(id) {
        this.setLoading(true);
        this.service.getUserReportById(id)
            .then((res: HttpResponse<ReportDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.Reports,this.props)
            });
    }




    render() {
        return (
            <Fragment>
                <div>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h4>Details for Report  '{this.state.result?.name}'</h4>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <ReportTabs id={this.props.match.params.id} url="/report/detail/" />
                                    {!this.state.isLoading &&  <>  
                                    <h5 className="mb-3">Basic Info</h5>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold">Name And Favicon</div>
                                                <div className="card-body"><img src={this.state.result?.favicon_url} height="16"  /> {this.state.result?.name}</div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold">Report Readiness</div>
                                                <div className="card-body"><a href="#">View Health</a></div>
                                            </div>
                                        </div> */}

                                      

                                        <div className="col-md-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold">Time Range </div>
                                                <div className="card-body">{this.state.result?.time_range}</div>
                                            </div>
                                        </div>
                                    

                                        {this.state.result?.alert_on_failure && <div className="col-md-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold">Alert On Failure</div>
                                                <div className="card-body"> <i className="fas fa-check text-success"></i></div>
                                            </div>
                                        </div>}
                                        {this.state.result?.always_rebuild && <div className="col-md-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold">Always Rebuild</div>
                                                <div className="card-body"> <i className="fas fa-check text-success"></i></div>
                                            </div>
                                        </div>}

                                        {this.state.result?.ignore_health && <div className="col-md-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold">Ignore Health</div>
                                                <div className="card-body"> <i className="fas fa-check text-success"></i></div>
                                            </div>
                                        </div>}
                                        {this.state.result?.skip_when_priority_dependent_job && <div className="col-md-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold">Skip When Priority Dependent Job</div>
                                                <div className="card-body"> <i className="fas fa-check text-success"></i></div>
                                            </div>
                                        </div>}
                                        {this.state.result?.enabled && <div className="col-md-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold">Enabled</div>
                                                <div className="card-body"> <i className="fas fa-check text-success"></i></div>
                                            </div>
                                        </div>}
                                    </div>
                                    

                                    <div className="row">
                                    {IsAuthorize([Role.Sales, Role.AM, Role.TAM, Role.Dev, Role.Support ,Role.Manager]) && <div className="col-md-12 my-4">
                                            <label className="font-weight-bold">View In Report Builder</label><br />                                           
                                            <a href={AppConfig.ImpersonationUrl+this.state.result?.user_id+"&report_builder_report_id="+this.props.match.params.id} className="btn btn-success" style={{color:"white"}}>View in report builder as 'User {this.state.result?.user_id} - {this.state.result?.user_name} &lt;{this.state.result?.user_email}&gt;' </a>

                                    </div> }
                                    </div> 

                                    {this.state.result?.report_run_schedule.length != 0 && <div className="row">

                                    <div className="col-md-12  my-4">
                                            <h5>Report Run Schedule</h5>
                                            <div className="table-responsive">
                                                <table className="table table-md table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Run At</th>
                                                            <th scope="col">Day Of Week</th>
                                                            <th scope="col">Time Range</th>
                                                            <th scope="col">Priority</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.result?.report_run_schedule.map((item: ReportRunSchedule, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{item.run_at}</td>
                                                                    <td>{GetDays(item.day_of_week)}	</td>
                                                                    <td>{item.time_range}</td>
                                                                    <td>{item.priority}</td>
                                                                </tr>

                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>}

                                    {this.state.result?.stats.length != 0 && <div className="row  my-4">

                                        <div className="col-md-12 ">
                                            <h5>Stats</h5>
                                            <div className="table-responsive">
                                                <table className="table table-md table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Stat</th>
                                                            <th scope="col">Value</th>
                                                        </tr>
                                                    </thead>

                                                    {this.state.result?.stats.map((item: ReportStatsModel, index) => {
                                                        return (
                                                            <tbody key={index}>
                                                                <tr>
                                                                    <td>Earliest Data</td>
                                                                    <td>{item.min_date}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Latest Data</td>
                                                                    <td>{item.max_date}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Row Count</td>
                                                                    <td>{item.row_count}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Distinct Dates</td>
                                                                    <td>{item.distinct_dates}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Avg Rows Per Date</td>
                                                                    <td>{item.avg_rows_per_date}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Size On Disk</td>
                                                                    <td>{item.size_on_disk}</td>
                                                                </tr>

                                                                <tr>

                                                                    <td>Current Schema</td>
                                                                    <td>{item.working_schema}</td>

                                                                </tr>
                                                            </tbody>
                                                        );
                                                    })}

                                                </table>
                                            </div>
                                        </div>

                                    </div>}

                                    <div className="row mt-4">
                                        <div className="col-md-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold">Created At</div>
                                                <div className="card-body">{ConvertDateTime(this.state.result?.created_at, 'LLL')}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold">Created By</div>
                                                <div className="card-body"><NavLink to={"/user/detail/" + this.state.result?.user_id}>{this.state.result?.user_name}</NavLink></div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold">Last Edited At</div>
                                                <div className="card-body">{ConvertDateTime(this.state.result?.edited_at, 'LLL')}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold">Last Edited By</div>
                                                <div className="card-body"><NavLink to={"/user/detail/" + this.state.result?.edited_by_user_id}>{this.state.result?.edited_by_user_name}</NavLink></div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold">Viewed At</div>
                                                <div className="card-body">{ConvertDateTime(this.state.result?.viewed_at, 'LLL')}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold">Last Generated At</div>                                                
                                                <div className="card-body">{ConvertDateTime(this.state.result?.last_generated_at, 'LLL')}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold">Updated At</div>
                                                <div className="card-body">{ConvertDateTime(this.state.result?.updated_at, 'LLL')}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold">Query Strategy</div>
                                                <div className="card-body">{this.state.result?.query_strategy}</div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row ">
                                        {this.state.result?.id == this.props.match.params.id && this.state.result?.custom_sql != null && <div className="col-xl-9">
                                            <h5>Custom SQL</h5>
                                            <Editor StringData={this.state.result?.custom_sql} FormateType="mysql" />
                                        </div>}
                                    </div>

                                    <div className="row ">
                                        {this.state.result?.source_report_id!=null &&<div className="col mt-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold">Source Report</div>
                                                <div className="card-body">  <NavLink to={"/source-report/detail/" + this.state.result?.source_report_id}>{this.state.result?.name}</NavLink></div>
                                              
                                            </div>
                                        </div>}
                                        {this.state.result?.sources.length != 0 &&
                                            <div className="col mt-4">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header font-weight-bold">Sources</div>
                                                    <div className="card-body">
                                                        {this.state.result?.sources.map((item: DropdownItemModel, index) => {
                                                            return (
                                                                <span key={item.value}>
                                                                    <NavLink className="badge badge-light m-1" to={"/report/detail/" + item.value}><strong>{index + 1}:</strong> {item.text}</NavLink>
                                                                </span>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {this.state.result?.dependent_reports.length != 0 &&
                                            <div className="col mt-4">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header font-weight-bold">Dependent Reports</div>
                                                    <div className="card-body">
                                                        {this.state.result?.dependent_reports.map((item: DropdownItemModel, index) => {
                                                            return (
                                                                <span key={item.value}>
                                                                    <NavLink className="badge badge-light m-1" to={"/report/detail/" + item.value}><strong>{index + 1}:</strong> {item.text}</NavLink>
                                                                </span>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {this.state.result?.installed_applications.length != 0 &&
                                            <div className="col mt-4">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header font-weight-bold">Installed Applications</div>
                                                    <div className="card-body">
                                                        {this.state.result?.installed_applications.map((item: DropdownItemModel, index) => {
                                                            return (
                                                                <span key={item.value}>
                                                                     <NavLink className="badge badge-light m-1" to={"/installed-report-application/detail/" + item.value}><strong>{index + 1}:</strong> {item.text}</NavLink>
                                                                </span>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>

                                    {this.state.result?.report_columns.length != 0 && <div className="row">
                                        <div className="col-md-12 mt-4">
                                            <h5>Report Columns</h5>
                                            <div className="table-responsive">
                                                <table className="table table-md table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Label</th>
                                                            <th scope="col">Column</th>
                                                            <th scope="col">Type</th>
                                                            <th scope="col">Base Type</th>
                                                            <th scope="col">Column Type</th>
                                                            <th scope="col">Unique Key?</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.result?.report_columns.map((item: ReportColumns, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td><NavLink to={"/report-column/detail/" + item.id}>{item.label}</NavLink></td>
                                                                    <td>{item.column_id}	</td>
                                                                    <td>{item.type}</td>
                                                                    <td>{item.base_type}</td>
                                                                    <td>{item.column_type}</td>
                                                                    <td>{item.is_unique_key && <i className="fas fa-check"></i>}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>}

                                    {this.state.result?.report_views.length != 0 && <div className="row">
                                        <div className="col-md-12 mt-4">
                                            <h5>Report Views</h5>
                                            <div className="table-responsive">

                                                <table className="table table-md table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">View</th>
                                                            <th scope="col">Owner</th>
                                                            <th scope="col">Parent View</th>
                                                            <th scope="col">Filtered?</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.result?.report_views.map((item: ReportViewModel, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    {item.parent_report_view_id === null && <td><NavLink to={"/report-view/detail/" + item.id}>{item.user_name}ReportView{" " + item.user_id + " (base)"}</NavLink></td>}
                                                                    {item.parent_report_view_id != null && <td><NavLink to={"/report-view/detail/" + item.id}>{item.user_name}ReportView{" " + item.user_id}</NavLink></td>}
                                                                    <td> <NavLink to={"/user/detail/" + item.user_id}>{item.user_name}</NavLink>	</td>
                                                                    <td><a href="#"> {item.parent_report_view_id}</a></td>
                                                                    {item.filtered === true && <td>true</td>}
                                                                    {item.filtered === false && <td>false</td>}
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>}
                                </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}     
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
