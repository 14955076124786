import { HttpWrapper, AppConfig } from "../../../../../core";
import { PostExportModel } from "../../../../../shared/export/post.export.model";
import { TagMatchersListResponseModel, TagMatchersDetailModel, AddUpdateTagMatchersModel} from "../models";
import { LinkResourcesResponseModel, DeleteResponseModel } from "../../../../../shared/models";



export class TagMatchersService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getTagMatchersList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
    return this.wrapper.post<TagMatchersListResponseModel>(AppConfig.apiEndpoint + '/v1/tag_matchers/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
  }

  getTagMatchersById(id: number) {
    return this.wrapper.get<TagMatchersDetailModel>(AppConfig.apiEndpoint + '/v1/tag_matchers/' + id);
  }  

  postTagMatchers(TagMatchers: AddUpdateTagMatchersModel) {
    return this.wrapper.post<AddUpdateTagMatchersModel>(AppConfig.apiEndpoint + '/v1/tag_matchers', TagMatchers);
}

editTagMatchers(id?: number) {
    return this.wrapper.get<AddUpdateTagMatchersModel>(AppConfig.apiEndpoint + '/v1/tag_matchers/edit/' + id);
}

updateTagMatchers(TagMatchers: AddUpdateTagMatchersModel) {
    return this.wrapper.put<AddUpdateTagMatchersModel>(AppConfig.apiEndpoint + '/v1/tag_matchers/' + TagMatchers.id, TagMatchers);
}

getLinkedResourcesById(id: number) {
  return this.wrapper.get<LinkResourcesResponseModel>(AppConfig.apiEndpoint + '/v1/tag_matchers/' + id+'/linked_resources');
}

deleteRecordById(id: number) {
  return this.wrapper.delete<DeleteResponseModel>(AppConfig.apiEndpoint + '/v1/tag_matchers/' + id);
}

  exportTagMatchers(exportData?: PostExportModel) {
    return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/tag_matchers/export', exportData);
  }


} 