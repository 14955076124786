import { HttpWrapper, AppConfig } from "../../../../core";
import { PostExportModel } from "../../../../shared/export/post.export.model";
import { LinkResourcesResponseModel } from "../../connections/models/link.resources.response.model";
import { CollectionMissionResponseModel, CollectionMissionsResponseListModel } from "../../connections/models";
import { CustomConnectionListResponseModel, CustomConnectionPostModel, EditCustomConnectionModel, LinkResponseCustomConnectionMaintain,CustomConnectionDetailModel, FileUploadListResponseModel, DeleteCustomConnectionModel } from "../models";
import { CancelCollectionMission, CollectionMissionPostModel, DataEngineJobsListModel, BackupsResponseModel } from "../../connections/models";
import { FileUploadResponseModel, FileUploadModel, DeleteResponseModel, RerunCollectionMissionPostModel, RerunCollectionMissionResponseModel } from "../../../../shared/models";
import { FileLinkResourceModel } from "../models/file.link.resource.model";



export class CustomConnectionsService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getCustomConnectionList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
    return this.wrapper.post<CustomConnectionListResponseModel>(AppConfig.apiEndpoint + '/v1/custom_connections/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
  }

  getCustomConnectionById(id: number) {
    return this.wrapper.get<CustomConnectionDetailModel>(AppConfig.apiEndpoint + '/v1/custom_connections/' + id);
  }

  
  getCollectionMissionList(id: number, currentPage: number, pageSize: number) {
    return this.wrapper.get<CollectionMissionsResponseListModel>(AppConfig.apiEndpoint + '/v1/custom_connections/' + id + '/collection_missions?current_page=' + currentPage + '&page_size=' + pageSize);
  }
  
  getDataEngineJobsById(id: number) {
    return this.wrapper.get<Array<DataEngineJobsListModel>>(AppConfig.apiEndpoint + '/v1/custom_connections/' + id+'/data_engine_jobs');
  }

  geBackupsById(id: number, currentPage: number, pageSize: number) {
    return this.wrapper.get<BackupsResponseModel>(AppConfig.apiEndpoint + '/v1/custom_connections/' + id+'/connection_archives?current_page=' + currentPage + '&page_size=' + pageSize);
  }

  postCustomConnection(CustomConnection: CustomConnectionPostModel) {
      return this.wrapper.post<CustomConnectionPostModel>(AppConfig.apiEndpoint + '/v1/custom_connections', CustomConnection);
    }

    editCustomConnection(id: number) {
      return this.wrapper.get<EditCustomConnectionModel>(AppConfig.apiEndpoint + '/v1/custom_connections/edit/' + id);
    }

    updateCustomConnection(CustomConnection: CustomConnectionPostModel) {
      return this.wrapper.put<CustomConnectionPostModel>(AppConfig.apiEndpoint + '/v1/custom_connections/' + CustomConnection.id, CustomConnection);
    }

    getLinkedResourcesById(id: number) {
      return this.wrapper.get<LinkResourcesResponseModel>(AppConfig.apiEndpoint + '/v1/custom_connections/' + id + '/linked_resources');
    }
  
    deleteRecordById(data:DeleteCustomConnectionModel, id:number) {
      return this.wrapper.delete<DeleteResponseModel>(AppConfig.apiEndpoint + '/v1/custom_connections/'+ id,data);   
    }
 
 
    getLinkCustomConnectionMaintainById(id: number) {
      return this.wrapper.get<LinkResponseCustomConnectionMaintain>(AppConfig.apiEndpoint + '/v1/custom_connections/' + id + '/maintain');
    }
    
    cancelCollectionMissionById(id: number, missionId?:number) {
      return this.wrapper.put<CancelCollectionMission>(AppConfig.apiEndpoint + '/v1/custom_connections/'+ id +'/cancel_collection_missions?mission_id='+missionId,{});
    }

    rerunCollectionMissionById(data:RerunCollectionMissionPostModel) {
      return this.wrapper.post<RerunCollectionMissionResponseModel>(AppConfig.apiEndpoint + '/v1/collection_missions/rerun', data);
    }
   

    
   

    getUploadedFileList(id: number, currentPage: number, pageSize: number) {
      return this.wrapper.get<FileUploadListResponseModel>(AppConfig.apiEndpoint + '/v1/custom_connections/' + id + '/uploaded_files?current_page=' + currentPage + '&page_size=' + pageSize);
    }

    uploadFile(data:FileUploadModel) {
      return this.wrapper.post<FileUploadResponseModel>(AppConfig.apiEndpoint + '/v1/uploaded_files',data);
    }

    runCollectionMission(id: number) {
      return this.wrapper.post<FileUploadResponseModel>(AppConfig.apiEndpoint + '/v1/uploaded_files/'+ id +'/run_collection_mission',{});
    }
    reprocessRunCollectionMission(id: number) {
      return this.wrapper.post<FileUploadResponseModel>(AppConfig.apiEndpoint + '/v1/inbound_files/'+ id +'/run_collection_mission',{});
    }

    getFileLinkedResourcesById(id: number) {
      return this.wrapper.get<FileLinkResourceModel>(AppConfig.apiEndpoint + '/v1/custom_connections/' + id + '/info');
    }

    verifyCustomConnectionById(id: number) {
      return this.wrapper.post<any>(AppConfig.ConnectionVerificationUrl, id);
    }
    
    postCollectionMission(data: CollectionMissionPostModel) {
      return this.wrapper.post<CollectionMissionResponseModel>(AppConfig.apiEndpoint + '/v1/collection_missions/create', data);
    }
  

  exportCustomConnection(exportData?: PostExportModel) {
    return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/custom_connections/export', exportData);
  }

}