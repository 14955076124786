import React, { Component, Fragment } from 'react'
import { ReportRunScheduleDetailModel } from './models/report.run.schedule.detail.model'
import { ReportRunScheduleService } from './service/report.run.schedule.service';
import { HttpResponse } from '../../../../core';
import { ConvertDateTime, GetDays, HandleNotFoundResponse } from '../../../../shared/helpers';
import { NavLink } from "react-router-dom";
import { ReportRunSchedulesTabs } from './ReportRunSchedulesTabs';
import { NotFoundResponseArea } from '../../../../shared/enums';
import { Loader } from '../../../../shared/loaders';

interface IState {
    result?: ReportRunScheduleDetailModel;
    isLoading?: boolean;
}
export class ReportRunSchedulesDetail extends Component<any, IState> {
    private service: ReportRunScheduleService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new ReportRunScheduleService();
    }

    initialState: Partial<IState> = {
        result: {
            account: {
                text: "",
                value: 0
            },
            report: {
                text: "",
                value: 0
            },
            report_id: 0,
            created_at: new Date(),
            day_of_week: 0,
            id: 0,
            priority: 0,
            run_at: "",
            time_range: "",
            trigger_dependencies: false
        },
        isLoading: false,
    }

    componentDidMount() {
        this.loadData();
    }

    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getReportRunScheduleById(this.props.match.params.id)
            .then((res: HttpResponse<ReportRunScheduleDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.ReportRunSchedules,this.props) 
            });
    }
    render() {
        return (
            <Fragment>
                <div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <h5>Details for Report Run Schedule 'ReportRunSchedule #{this.state.result?.id}'</h5>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <ReportRunSchedulesTabs id={this.props.match.params.id} url="/report-run-schedule/detail/" />
                                    {!this.state.isLoading &&  <> 
                                    <h5 className="mb-3">Basic Info</h5>
                                    <div className="row">
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Id</b>
                                                </div>
                                                <div className="card-body">{this.state.result?.id}</div>
                                            </div>
                                        </div>

                                        {this.state.result?.account && <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Account</b>
                                                </div>
                                                <div className="card-body">
                                                    <NavLink to={"/account/detail/" + this.state.result?.account.value}>{this.state.result?.account.text}</NavLink>
                                                </div>
                                            </div>
                                        </div>}

                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Report</b>
                                                </div>
                                                <div className="card-body">
                                                    <NavLink to={"/report/detail/" + this.state.result?.report.value}>{this.state.result?.report.text}</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Run At</b>
                                                </div>
                                                <div className="card-body">{this.state.result?.run_at}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Day Of Week</b>
                                                </div>
                                                <div className="card-body">{GetDays(this.state.result?.day_of_week)}</div>
                                            </div>
                                        </div>
                                        {this.state.result?.time_range && <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>	Time Range</b>
                                                </div>
                                                <div className="card-body">{this.state.result?.time_range}</div>
                                            </div>
                                        </div>}
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Priority</b>
                                                </div>
                                                <div className="card-body">{this.state.result?.priority}</div>
                                            </div>
                                        </div>
                                        {this.state.result?.trigger_dependencies && <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Trigger Dependencies</b>
                                                </div>
                                                <div className="card-body"> <i className="fas fa-check text-success"></i> Yes</div>
                                            </div>
                                        </div>
                                        }

                                    </div>
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Created At</b>
                                                </div>
                                                <div className="card-body">{ConvertDateTime(this.state.result?.created_at, 'LLL')}</div>
                                            </div>
                                        </div>
                                    </div>
                                    </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
