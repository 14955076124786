import React, { Component, Fragment } from 'react'
import { Columns, FilterModel, ColumnType } from './models/post.filter.model'
import { FilterItemNumber } from './NumberFilterItem';
import { FilterItemString } from './StringFilterItem';
import { FilterItemDate } from './DateFilterItem';
import { ButtonType } from '../../shared/enums/button.enum'
import { FilterItemBoolean } from './BooleanFilterItem';
import { FilterItemDays } from './DaysFilterItem';
import { FilterItemTime } from './TimeFilterItem';
import { FilterItemInclusionExclusion } from './InclusionExclusionFilterItem';
import { FilterItemState } from './StateFilterItem';
import { FilterItemDataEngineJobState } from './DataEngineJobStateFilterItem';
import { FilterItemJobType } from './JobTypeFilterItem';
import { FilterItemSchemasType } from './SchemasTypeFilterItem';
import { FilterItemFieldType } from './FieldTypeFilterItem';
import { FilterItemFieldColumnType } from './FieldColumnTypeFilterItem';
import { FilterItemExtractorStyle } from './ExtractorStyleFilterItem';


interface IProps {
    columnList?: Columns[];
    applyFilter: Function;
    clearFilter: Function;
}

interface IState {
    filters: FilterModel[];
}
export default class Filter extends Component<IProps, IState> {

    appliedFilters: FilterModel[] = [];
    constructor(props: IProps) {
        super(props);
        this.state = {
            filters: []
        }
    }


    filterValueChange = (event) => {
        let item = this.appliedFilters.find(x => x.name == event.column);
        if (item) {
            item.op = event.op;
            item.value = event.value;
        } else {
            this.appliedFilters.push({ name: event.column, op: event.op, value: event.value });
        }

        this.setState({ filters: this.appliedFilters });

    }
    removeFilterItem = (event) => {
        let itemIndex = this.appliedFilters.findIndex(x => x.name == event);
        if (itemIndex >= 0) {
            this.appliedFilters.splice(itemIndex, 1);
        }
        this.setState({ filters: this.appliedFilters });
    }

    clearFilter = (event) => {
        event.preventDefault();
        this.appliedFilters = [];
        this.props.clearFilter();
    }

    submiFilter(e) {
        e.preventDefault();
        this.props.applyFilter(this.appliedFilters);
    }
    
    render() {
        return (
            <React.Fragment>
                <form id="filters" onSubmit={this.submiFilter.bind(this)}>
                    {this.props.columnList && <div>
                        {this.props.columnList.map((data: Columns) => {
                            return (
                                <div key={data.id}>
                                    {data.type == ColumnType.Number &&
                                        <FilterItemNumber column={data} onValueChange={this.filterValueChange} clearFilter={this.removeFilterItem} />
                                    }
                                    {data.type == ColumnType.String &&
                                        <FilterItemString column={data} onValueChange={this.filterValueChange} clearFilter={this.removeFilterItem} />
                                    }
                                    {data.type == ColumnType.Date &&
                                        <FilterItemDate column={data} onValueChange={this.filterValueChange} clearFilter={this.removeFilterItem} />
                                    }
                                    {data.type == ColumnType.Boolean &&
                                        <FilterItemBoolean column={data} onValueChange={this.filterValueChange} clearFilter={this.removeFilterItem} />
                                    }
                                     {data.type == ColumnType.Days &&
                                        <FilterItemDays column={data} onValueChange={this.filterValueChange} clearFilter={this.removeFilterItem} />
                                    }
                                     {data.type == ColumnType.Time &&
                                        <FilterItemTime column={data} onValueChange={this.filterValueChange} clearFilter={this.removeFilterItem} />
                                    }
                                    {data.type == ColumnType.InclusionExclusion &&
                                        <FilterItemInclusionExclusion column={data} onValueChange={this.filterValueChange} clearFilter={this.removeFilterItem} />
                                    }
                                      {data.type == ColumnType.State &&
                                        <FilterItemState column={data} onValueChange={this.filterValueChange} clearFilter={this.removeFilterItem} />
                                    }
                                    {data.type == ColumnType.ExtractorStyle &&
                                        <FilterItemExtractorStyle column={data} onValueChange={this.filterValueChange} clearFilter={this.removeFilterItem} />
                                    }
                                     {data.type == ColumnType.DataEngineJobsState &&
                                        <FilterItemDataEngineJobState column={data} onValueChange={this.filterValueChange} clearFilter={this.removeFilterItem} />
                                    }
                                     {data.type == ColumnType.JobType &&
                                        <FilterItemJobType column={data} onValueChange={this.filterValueChange} clearFilter={this.removeFilterItem} />
                                    }
                                    {data.type == ColumnType.SchemasType &&
                                        <FilterItemSchemasType column={data} onValueChange={this.filterValueChange} clearFilter={this.removeFilterItem} />
                                    }
                                    {data.type == ColumnType.FieldType &&
                                        <FilterItemFieldType column={data} onValueChange={this.filterValueChange} clearFilter={this.removeFilterItem} />
                                    }
                                     {data.type == ColumnType.FieldColumnType &&
                                        <FilterItemFieldColumnType column={data} onValueChange={this.filterValueChange} clearFilter={this.removeFilterItem} />
                                    }
                                    
                                </div>
                            );
                        })}
                        <div className="input-group-btn text-right" >
                            <button type="button" onClick={this.clearFilter} className="btn btn-default">{ButtonType.Clear}</button>
                            <button type="submit" className="btn btn-primary ml-3">{ButtonType.Filter}</button>
                        </div>
                    </div>
                    }
                </form>
            </React.Fragment>
        )
    }
}
