import React, { Component, Fragment } from 'react'
import { NavLink } from "react-router-dom";
import { IsAuthorize } from '../../../../shared/authorization/check-access';
import { Role } from '../../../../shared/authorization/enums';

interface IProps {
    id: number,
    url: string
}
export  class SourceReportTabs extends Component<IProps>  {
    render() {
        return (
            <Fragment>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                {IsAuthorize([Role.TAM,Role.Support,Role.Dev]) && <li className="nav-item">
                    <NavLink to={"/source-report/edit/" + this.props.id} className={this.props.url=="/source-report/edit/"?"nav-link active":"nav-link"} id="show-tab" data-toggle="tab" href="#edit" role="tab"
                        aria-controls="edit" aria-selected={this.props.url=="/source-report/edit/"?"true":"false"}><i className="far fa-edit"></i>&nbsp;Edit</NavLink>
                </li>}
                    <li className="nav-item">
                        <NavLink to={"/source-report/detail/" + this.props.id} className={this.props.url == "/source-report/detail/" ? "nav-link active" : "nav-link"} id="edit-tab" data-toggle="tab" href="#edit" role="tab"
                            aria-controls="edit" aria-selected={this.props.url == "/source-report/detail/" ? "true" : "false"}><i className="fas fa-info-circle"></i>&nbsp;Show</NavLink>
                    </li>
                </ul>
                <br />
            </Fragment>
        )
    }
}
