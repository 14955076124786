import { AppConfig, HttpWrapper } from "../../../../core";
import { ControlMissionResponseModel } from "../models/control.mission.response.model";
import { ControlMissionTaskResponseModel } from "../models/control.mission.task.response.model";

export class ControlMissionService {
    private wrapper: HttpWrapper;
    constructor() {
        this.wrapper = new HttpWrapper();
    }

    getControlMissionById(id: number) {
        return this.wrapper.get<ControlMissionResponseModel>(AppConfig.apiEndpoint + '/v1/control_missions/' + id);
    }
    getControlMissionTaskById(id: number) {
        return this.wrapper.get<ControlMissionTaskResponseModel>(AppConfig.apiEndpoint + '/v1/control_mission_tasks/' + id);
    }
}