import React, { Component, Fragment, createRef } from 'react'
import { EditPostDataEngineJobModel } from './models/data.engine.job.edit.post.model'
import { DataEgineJobService } from './services/data.engines.job.service';
import { HttpResponse } from '../../../../../core';
import { HandleNotFoundResponse, ShowSuccessMessage, GetDataEngineJobsStateList } from '../../../../../shared/helpers';
import { NotFoundResponseArea, ButtonType } from '../../../../../shared/enums';
import { DataEgineJobTabs } from './DataEgineJobTabs';
import { Link } from "react-router-dom";
import { Loader } from "../../../../../shared/loaders";

interface IState {
    dataEngineJob?: EditPostDataEngineJobModel,
    editdataEngineJob?: EditPostDataEngineJobModel,
    id?: number,
    state?:string;
    priority?:number;
    trigger_dependencies?:boolean;
    time_range_override?:string;
    stateError?: string,
    priorityErroe?:string,
    isShowtext?: string;
    isSave?: string;
    isSaving?: boolean;
}
export  class DataEgineJobForm extends Component<any, IState> {
     //declaring service type
     private service: DataEgineJobService;  
     private priorityRef: any;
        
 
     private roleNetworkCall: any;
     private accountNetworkCall: any;
 
     constructor(props) {
         super(props)
         this.service = new DataEgineJobService();         
         this.state = this.initialState;
         this.priorityRef = createRef();
 
     }
 
     initialState: Partial<IState> = {
         dataEngineJob:{
            id: 0,
            state: "",
            priority:0,         
            time_range_override:"",
            trigger_dependencies:false
         },
         id: 0,
         state: "",
         priority:0,         
         time_range_override:"",
         trigger_dependencies:false,
         priorityErroe:"",
         stateError:"",
         isShowtext: "Edit",
         isSave: "",
         isSaving: false
     }

     componentDidMount() {
        if (this.props.match.params.id > 0) {
            this.loadData();
        }        
    }

    loadData() {
        this.service.edittDataEgineJobB(this.props.match.params.id)
            .then((res: HttpResponse<EditPostDataEngineJobModel>) => {
                if (res && res.result) {
                    this.setState({
                        state: res.result?.state,                        
                        id: res.result?.id,
                        priority: res.result?.priority,
                        trigger_dependencies:res.result?.trigger_dependencies,
                        time_range_override:res.result?.time_range_override
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.DataEngineJob,this.props)
            });
    }

    updateData(data: EditPostDataEngineJobModel) {
        this.setSavingFlag(true);
        this.service.updatetDataEgineJobB(data)
            .then((res: HttpResponse<EditPostDataEngineJobModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        dataEngineJob: res.result
                    }, () => {
                        ShowSuccessMessage("Data Engine Job  successfully updated.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/data-engine-job/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                            this.setState(this.initialState);
                            this.props.history.push('/data-engine-job/data-engine-job-form/0');
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/data-engine-job/data-engine-job-form/' + this.state?.id);
                            this.setState({ isShowtext: "Edit" })
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });
    }

    handleChange = (event: any) => {
            const isCheckbox = event.target.type === "checkbox";
            this.setState({
                [event.target.name]: isCheckbox
                    ? event.target.checked
                    : event.target.value
            }) 
            if (this.priorityRef.current.value) {
                this.setState({ priorityErroe: "" });
              }

    }

    validate = () => {
        let stateError = "";
        let pririotyError = "";
        if (!this.state.state) {
            stateError = "State is invalid and can't be blank";
        }
        if (!this.priorityRef.current.value) {
            pririotyError = "Priority can't be blank";
        }
        if (stateError || pririotyError) {
            this.setState({ stateError:stateError, priorityErroe: pririotyError })
            return false;
        }
        else {
            stateError = "";
            pririotyError = "";
            this.setState({ stateError: stateError, priorityErroe: pririotyError })
            return true;
        }
        return;
    }

    handleSubmit = event => {
        event.preventDefault();
        const isValid = this.validate();
        let data: EditPostDataEngineJobModel = {            
            id:this.props.match.params.id,
            state: this.state.state,
            priority: this.state.priority,
            trigger_dependencies: this.state.trigger_dependencies,
            time_range_override : this.state.time_range_override
        };

        if (isValid) {
            this.updateData(data)
        }
    }

    handleClick = (event) => {
        this.setState({ isSave: event.target.value })
    }
    private setSavingFlag(saving: boolean) {
        this.setState({ isSaving: saving });
    }

    render() {
        return (
            <Fragment>
                <form onSubmit={this.handleSubmit}>
                    <input type="hidden" value={this.state.id} />
                    <div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h4>Edit Data Engine Job 'DataEngineJob #{this.props.match.params.id}</h4>
                        </div>
                        <div className="row">
              <div className="col-md-12 col-xl-12">
                <div className="card">
                  <div className="card-body">
                    {this.props.match.params.id != 0 && (
                      <DataEgineJobTabs
                        id={this.props.match.params.id}
                        url="/data-engine-job/data-engine-job-form/"
                      />
                    )}

                      <div className="row">
                        <div className="col-md-3">
                        <div className="form-group shadow-sm p-3">
                          <label>State </label>
                          <select
                            className="form-control"
                            name="state"
                            value={this.state.state}
                            onChange={this.handleChange}
                          >
                            {GetDataEngineJobsStateList.map((item, index) => {
                              return (
                                <option key={index} value={item.value}>
                                  {item.value}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group shadow-sm p-3 mb-4">
                          <label>Priority</label>
                          <input
                           ref={this.priorityRef}
                            type="number"
                            name="priority"
                            value={this.state.priority}
                            onChange={this.handleChange}
                            className={!this.state.priorityErroe ? 'form-control' : 'form-control  is-invalid'}
                          />
                          <div className="invalid-feedback">
                                                        {this.state.priorityErroe}
                                                    </div>
                        </div>
                      </div>                     

                    <div className="col-md-3">
                        <div className="form-group shadow-sm p-3 mb-4 ">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="trigger_dependencies"
                              checked={this.state.trigger_dependencies}
                              onChange={this.handleChange}
                              className="custom-control-input"
                              id="trigger_dependencies"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="trigger_dependencies"
                            >Trigger Dependencies
                            </label>
                          </div>
                          <small>
                          <br />
                            <br />
                          </small>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group shadow-sm p-3 mb-4">
                          <label>Time Range Override</label>
                          <input
                            type="text"
                            name="time_range_override"
                            value={this.state.time_range_override}
                            onChange={this.handleChange}
                            className="form-control"
                          /></div>
                      </div>                     
                    </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                        <div className="text-center mt-3 mb-4">

                            {!this.state.isSaving && <Fragment>
                                <Link type="button" className="btn btn-lg btn-default" to="/data-engine-job/list">{ButtonType.Cancel}</Link>
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" onClick={this.handleClick} value={ButtonType.Save} />                                
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndEdit} onClick={this.handleClick} />
                            </Fragment>
                            }
                            <Loader loading={this.state.isSaving} marginBottom="0px" marginTop="8px" float="center" width="200px" ></Loader>
                        </div>
                    
                </form>
            </Fragment>
        )
    }
}
