import React, { Component, Fragment } from 'react'
import { SourceReportDetailModel } from './models/source.report.detail.model'
import { SourceReportService } from '../source-reports/services';
import { HttpResponse } from '../../../../core';
import { NavLink } from "react-router-dom";
import { SourceReportTabs } from '../source-reports/SourceReportTabs'
import { DropdownItemModel } from '../../../../shared/models';
import { HandleNotFoundResponse } from '../../../../shared/helpers';
import { NotFoundResponseArea } from '../../../../shared/enums';
import { Loader } from '../../../../shared/loaders';
interface IState {
    result?: SourceReportDetailModel;
    isLoading?: boolean;
}
export class SourceReportDetail extends Component<any, IState> {
    private service: SourceReportService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new SourceReportService();
    }

    initialState: Partial<IState> = {
        result: {
            account: {
                text: "",
                value: 0
            },
            dependent_reports: [],
            edited_connection: false,
            id: 0,
            ignore_health: false,
            name: "",
            report: {
                text: "",
                value: 0
            },
            report_schema: {
                text: "",
                value: 0
            }
        },
        isLoading: false,
    }

    componentDidMount() {
        this.loadData();
    }

    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getSourceReportsById(this.props.match.params.id)
            .then((res: HttpResponse<SourceReportDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.SourceReports,this.props) 
            });
    }
    render() {
        return (
            <Fragment>
                <div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <h5>Details for Source Reports '{this.state.result?.name===""?"-":this.state.result?.name}'</h5>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <SourceReportTabs id={this.props.match.params.id} url="/source-report/detail/" />
                                    {!this.state.isLoading &&  <>  
                                    <h4>Basic Info</h4>
                                    <div className="row">

                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Name</b>
                                                </div>
                                                <div className="card-body">{this.state.result?.name}</div>
                                            </div>
                                        </div>


                                        {this.state.result?.account && <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Account</b>
                                                </div>
                                                <div className="card-body">
                                                <NavLink to={"/account/detail/" + this.state.result?.account.value}>{this.state.result?.account.text}</NavLink>
                                                    </div>
                                            </div>
                                        </div>}
                                        {this.state.result?.report_schema && <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Schema</b>
                                                </div>
                                                <div className="card-body">
                                                    <NavLink to={"/schema/detail/" + this.state.result?.report_schema.value}>{this.state.result?.report_schema.text}</NavLink>
                                               </div>
                                            </div>
                                        </div>}                                      


                                    </div>

                                    <div className="row">
                                    <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Report</b>
                                                </div>
                                                {this.state.result?.report && <div className="card-body">
                                                    <NavLink to={"/report/detail/" + this.state.result?.report.value}>{this.state.result?.report.text}</NavLink>
                                                </div>}
                                            </div>
                                        </div>
                                        {this.state.result?.edited_connection && <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Edited Connection?</b>
                                                </div>
                                                <div className="card-body"> <i className="fas fa-check text-success"></i>   Yes</div>
                                            </div>
                                        </div>
                                        }
                                        {this.state.result?.ignore_health && <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Ignore Health</b>
                                                </div>
                                                <div className="card-body"> <i className="fas fa-check text-success"></i>   Yes</div>
                                            </div>
                                        </div>
                                        }

                                    </div>

                                    <div className="row">
                                        {this.state.result?.dependent_reports.length != 0 &&
                                            <div className="col">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header font-weight-bold">Dependent Reports</div>
                                                    <div className="card-body">
                                                        {this.state.result?.dependent_reports.map((item: DropdownItemModel, index) => {
                                                            return (
                                                                <span key={item.value}>
                                                                    <NavLink className="badge badge-light m-1" to={"/report/detail/" + item.value}><strong>{index + 1}:</strong> {item.text}</NavLink>
                                                                </span>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />} 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
