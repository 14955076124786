import React, { Component, Fragment } from 'react'
import { ScopesService } from './services';
import { HandleNotFoundResponse, RedirectBySourceType, ConvertDateTime } from '../../../../shared/helpers';
import { NotFoundResponseArea, SchemaChangeType, SourceType } from '../../../../shared/enums';
import { HttpResponse } from '../../../../core';
import { NavLink } from "react-router-dom";
import { ScopesDetailModel, SchemaChangesModel, FieldModel } from './models';
import { DropdownItemModel } from '../../../../shared/models';
import { ScopesTabs } from '.';
import { Loader } from '../../../../shared/loaders';

interface IState {
    result?: ScopesDetailModel;
    parsedData?: string;
    isLoading?: boolean;
}
export class ScopesDetail extends Component<any, IState> {
    private service: ScopesService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new ScopesService();
    }

    initialState: Partial<IState> = {
        result:{
            id:0,
            dependent_reports:[],
            fields_:[],            
            name:"",
            schema_:{
                id:0,
                name:"",
                schema_owner:{
                    text:"",
                    value:""
                },
                type:0
            },
            schema_change_details:[],
            table_names:JSON
        },
        isLoading: false,
    }

    componentDidMount() { 
        this.loadData();
    }
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getScopesById(this.props.match.params.id)
            .then((res: HttpResponse<ScopesDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {                                      
                    this.setState({
                        result: res.result                        
                    },()=>{
                        this.setState({
                        parsedData: JSON.stringify(this.state.result?.table_names, null, '\t')
                        });                      
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.Scopes,this.props)
            });
    }
    render() {
        return (
            <Fragment>               
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Details for Scope  &nbsp;'{this.state.result?.name}'</h5>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">   
                        <ScopesTabs id={this.props.match.params.id} name={this.state.result?.name} schemaName={this.state.result?.schema_.name} isSchema={(this.state.result?.schema_.name== SourceType.CONNECTION.toLocaleLowerCase() ||this.state.result?.schema_.name== SourceType.CUSTOM_CONNECTION.toLocaleLowerCase() ||this.state.result?.schema_.name== SourceType.EXTRACTOR.toLocaleLowerCase())?true:false} url="/scope/detail/" />                      
                        {!this.state.isLoading &&  <>  
                            <h5 className="mb-3">Basic Info</h5>
                            <div className="row">                            
                                {this.state.result?.name && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Name</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.name}
                                            </div>
                                        </div>
                                    </div>}
                                    
                                    {this.state.result?.schema_ &&<div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Schema</b>
                                        </div>
                                        <div className="card-body"><NavLink to={"/schema/detail/" + this.state.result?.schema_.id}>{this.state.result?.schema_.name+" "+  this.state.result?.schema_.id+ " "+this.state.result?.schema_.schema_owner.text}</NavLink></div>
                                       
                                    </div>
                                </div>}

                                {this.state.parsedData && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Table Names</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.parsedData}
                                            </div>
                                        </div>
                                    </div>}                                  
                               
                            </div>

                            {this.state.result?.schema_change_details.length != 0 && <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="mt-4">Schema Changes</h5>                                            
                                            <div className="table-responsive">
                                                <table className="table table-sm table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Created</th>                                                                                                                   
                                                            <th scope="col">Requested By</th>
                                                            <th scope="col">State</th>                                                            
                                                            <th scope="col">Change Type</th>
                                                            <th scope="col">Field</th>
                                                            <th scope="col">Details</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.result?.schema_change_details.map((item: SchemaChangesModel, index) => {
                                                            return (
                                                                <tr key={index}>                                                                   
                                                                    <td><NavLink to={"/scope-schema-change/detail/" + item.id}>{ConvertDateTime(item.created_at, 'LLL')}</NavLink></td>  
                                                                    <td>{item.requested_by}</td>                                                                    
                                                                    <td>{item.state}</td>
                                                                    <td>{item.change_type}</td>                                                                    
                                                                    {item.field_id &&<td><NavLink to={"/field/detail/" + item.field_id+"/"+this.state.result?.schema_.name+"/"+ this.state.result?.schema_.id}>{(!item.new_name?item.old_name:item.new_name) || `Field #${item.field_id}`}</NavLink></td>}
                                                                    {!item.field_id &&<td>{item.new_name==""?item.old_name:item.new_name}</td>}
                                                                    {item.change_type==SchemaChangeType.add &&<td>{item.new_type}</td>}
                                                                    {item.change_type==SchemaChangeType.change &&<td>from&nbsp;{item.old_type} to {item.new_type}</td>}
                                                                    {item.change_type==SchemaChangeType.rename &&<td>from&nbsp;{item.old_name} to {item.new_name}</td>}
                                                                </tr>

                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>}

                                    {this.state.result?.fields_.length != 0 && <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="mt-4">Fields</h5>                                            
                                            <div className="table-responsive">
                                                <table className="table table-sm table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>                                                                                                                   
                                                            <th scope="col">Classification</th>
                                                            <th scope="col">Column Type</th>                                                            
                                                            <th scope="col">Type</th>
                                                            <th scope="col">Unique Key?</th>
                                                            <th scope="col">Exclude From Load?</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.result?.fields_.map((item: FieldModel, index) => {
                                                            return (
                                                                <tr key={index}>                                                                                                                                   
                                                                    {item.id!=0 && item.name &&<td><NavLink to={"/field/detail/" + item.id+"/"+this.state.result?.schema_.name+"/"+ this.state.result?.id}>{item.name}</NavLink></td>}
                                                                    {item.id==0 &&<td></td>}
                                                                    <td>{item.field_classification?.name}</td>
                                                                    <td>{item.column_type}</td>                                                                    
                                                                    <td>{item.type}</td>
                                                                    <td>{item.is_unique_key &&  <i className="fas fa-check text-success text1" title="&#x2713;" ></i>}
                                                                    {!item.is_unique_key && <i className="fas fa-times text-danger text1" title="&#x2718;"></i>} </td>
                                                                    <td>{item.exclude_from_load &&  <i className="fas fa-check text-success text1" title="&#x2713;" ></i>}
                                                                    {!item.exclude_from_load && <i className="fas fa-times text-danger text1" title="&#x2718;"></i>} </td>
                                                                </tr>

                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>}

                                    <div className="row">
                                    {this.state.result?.dependent_reports && <div className="col-12">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header font-weight-bold">Dependents Reports</div>
                                                {this.state.result?.dependent_reports && <div className="card-body">
                                                    {this.state.result?.dependent_reports.map((item: DropdownItemModel, index) => {
                                                        return (
                                                            <span key={item.value}>
                                                                {item && <NavLink className="badge badge-light m-1" to={"/report/detail/" + item.value}><strong>{index + 1}:</strong> {item.text}</NavLink>}
                                                            </span>
                                                        );
                                                    })}
                                                </div>}
                                            </div>
                                        </div>}
                                    </div>

                                    </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}     
                            
                        </div>
                    </div>
                </div>
            </div>            
        </Fragment>
        )
    }
}
 