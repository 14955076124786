import React, { Component, Fragment } from 'react'
import { EmailTemplatesService } from './services';
import { HandleNotFoundResponse, RedirectBySourceType, ConvertDateTime } from '../../../../shared/helpers';
import { NotFoundResponseArea, SourceType } from '../../../../shared/enums';
import { HttpResponse } from '../../../../core';
import { NavLink } from "react-router-dom";

import { EmailTemplatesDetailModel } from './models';
import { EmailTemplatesTabs } from './EmailTemplatesTabs';
import { DropdownItemModel } from '../../../../shared/models';
import { Loader } from '../../../../shared/loaders';

interface IState {
    result?: EmailTemplatesDetailModel;
    parsedData?: string;
    isLoading?: boolean;
}
export class EmailTemplatesDetail extends Component<any, IState> {
    private service: EmailTemplatesService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new EmailTemplatesService();
    }

    initialState: Partial<IState> = {
        result: {             
            id:0,           
            name:"",
            email_layout:{
                name:"",
                id:0
            },
            email_layout_id:0,
            html:"",
            subject:"",
            text:""
        },
        isLoading: false,
    }

    componentDidMount() { 
        this.loadData();
    }
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getEmailTemplatesById(this.props.match.params.id)
            .then((res: HttpResponse<EmailTemplatesDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {                                                       
                    this.setState({
                        result: res.result                        
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.EmailTemplates,this.props)
            });
    }
    render() {
        return (
            <Fragment>               
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Details for Email Template &nbsp;'{this.state.result?.name}'</h5>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">  
                        <EmailTemplatesTabs id={this.props.match.params.id} url="/field-classification/detail/" />  
                        {!this.state.isLoading &&  <>                                   
                            <h5 className="mb-3">Basic Info</h5>
                            <div className="row">                                      
                            {this.state.result?.name && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Name</b>
                                            </div>
                                            <div className="card-body">
                                            {this.state.result?.name} 
                                            </div>
                                        </div>
                                    </div>} 

                                    {this.state.result?.email_layout && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Email Layout</b>
                                            </div>
                                            <div className="card-body">
                                            <NavLink className="badge badge-light text-link" to={"/email-layout/detail/" + this.state.result?.email_layout.id}>{this.state.result?.email_layout.name} </NavLink>
                                            </div>
                                        </div>
                                    </div>} 

                                    {this.state.result?.subject && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Subject</b>
                                            </div>
                                            <div className="card-body">
                                            {this.state.result?.subject} 
                                            </div>
                                        </div>
                                    </div>} 
                                    
                              

                            {this.state.result?.html && <div className="col-6">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>HTML</b>
                                            </div>
                                            <div className="card-body">
                                            <pre> {this.state.result?.html}</pre>
                                        </div></div>
                                    </div>}   

                                    {this.state.result?.text && <div className="col-6">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Text</b>
                                            </div>
                                            <div className="card-body"><pre>{this.state.result?.text}</pre></div>
                                        </div>
                                    </div>}   
                                    </div>       
                                    </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}   
                                
                        </div>
                    </div>
                </div>
            </div>            
        </Fragment>
        )
    }
}
 