import React, { Component } from 'react'
import { IFilterItemProps } from "./models/filter-item.props";
import { OperatorList } from "./models/post.filter.model";
import { Fragment } from "react";
import { FilterItemInputRenderType } from "./models";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ConvertDateTime } from './../../shared/helpers'

interface IState {
    id: string,
    op: string,
    value: string

}

export class FilterItemDate extends Component<IFilterItemProps, IState> {

    constructor(props) {
        super(props);
    }


    oprators = OperatorList.date;
    inputRenderType: any = FilterItemInputRenderType.SingleBox;

    selectedOptionValue = this.oprators[0].operator;
    selectedValue = '';
    inputValue2 = '';
    inputValue1 = '';

    handleDropDownChange = (event: any) => {
        let selectedObject = this.oprators.find(x => x.name == event.target.value);
        this.selectedOptionValue = selectedObject != null ? selectedObject.operator : "";
        this.inputRenderType = selectedObject?.renderType;
        if (this.selectedOptionValue == 'is' || this.selectedOptionValue == 'isnot') {
            this.selectedValue = 'null';
        }
        if (this.selectedOptionValue == 'dateeq' || 'range') {
            this.inputValue2 = "";
            this.inputValue1 = "";
        }
        this.brodCastValue();
    }

    brodCastValue = () => {
        this.setState({ id: this.props.column.columnName, op: this.selectedOptionValue, value: this.selectedValue });
        if (this.inputRenderType == FilterItemInputRenderType.NoBox) {
            this.props.onValueChange({ column: this.props.column.columnName, op: this.selectedOptionValue, value: this.selectedValue });
        }
        else {
            if (this.selectedOptionValue != '') {
                if (this.selectedValue != '') {
                    this.props.onValueChange({ column: this.props.column.columnName, op: this.selectedOptionValue, value: this.selectedValue });
                } else {
                    this.props.clearFilter(this.props.column.columnName);
                }

            }
        }
    }

    handleInputChange = (value1: string, value2: string) => {
        this.inputValue1 = value1;
        this.inputValue2 = value2;
        this.selectedValue = '';
        if (this.inputRenderType == FilterItemInputRenderType.SingleBox) {
            this.selectedValue = ConvertDateTime(this.inputValue1, 'YYYY-MM-DD')
        } else if (this.inputRenderType == FilterItemInputRenderType.RangeBox) {
            this.selectedValue = ConvertDateTime(this.inputValue1, 'YYYY-MM-DD') + "::" + ConvertDateTime(this.inputValue2, 'YYYY-MM-DD');
        }

        this.brodCastValue();
    }

    handleChangeVal1 = date => {
        this.handleInputChange(date, this.inputValue2);
    };
    handleChangeVal2 = date => {
        this.handleInputChange(this.inputValue1, date);
    };

    render() {
        return (
            <div className="form-group mb-3">
                <label>{this.props.column.displayText}</label>
                <div className="row">
                    <div className={(this.inputRenderType === FilterItemInputRenderType.RangeBox ? 'col-12' : 'col-5')}>
                        <select className="form-control form-control-sm mb-1" name={this.props.column.columnName} onChange={this.handleDropDownChange}>
                            {this.oprators.map((num, index) => {
                                return (
                                    <option key={index} value={num.name} className='form-control'>{num.name}</option>
                                );
                            })}

                        </select>
                    </div>
                    {(this.inputRenderType === FilterItemInputRenderType.SingleBox) ?

                        (
                            <div className="col-7 pl-0">
                                <DatePicker popperPlacement="bottom left"
                                 
                                    popperModifiers={{
                                        offset: {
                                            enabled: true,
                                            offset: "25px, 0px"
                                        },
                                        preventOverflow: {
                                            enabled: true,
                                            escapeWithReference: false,
                                            boundariesElement: "viewport"
                                        }
                                    }}
                                    peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" selected={this.inputValue1} onChange={this.handleChangeVal1} className="form-control form-control-sm" />
                            </div>
                        )
                        : null


                    }

                    {(this.inputRenderType === FilterItemInputRenderType.RangeBox) ?
                        (<Fragment>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 pr-2">
                                        <DatePicker peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" selected={this.inputValue1} onChange={this.handleChangeVal1} className="form-control form-control-sm" />
                                    </div>
                                    <div className="col-6 pl-2">
                                        <DatePicker popperPlacement="bottom left"
                                            popperModifiers={{
                                                offset: {
                                                    enabled: true,
                                                    offset: "25px, 0px"
                                                },
                                                preventOverflow: {
                                                    enabled: true,
                                                    escapeWithReference: false,
                                                    boundariesElement: "viewport"
                                                }
                                            }}
                                            peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" selected={this.inputValue2} onChange={this.handleChangeVal2} className="form-control form-control-sm" />
                                    </div>
                                </div>
                            </div>


                        </Fragment>) : null
                    }
                </div>
            </div>
        )
    }

}