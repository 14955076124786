import React, { Component, Fragment } from 'react'
import { NavLink } from "react-router-dom";
import { IsAuthorize } from '../../../../../shared/authorization/check-access';
import { Role } from '../../../../../shared/authorization/enums';

interface IProps {
    id: number,
    url: string,
    name?:string
}
export  class DataEgineJobTabs extends Component <IProps> {
    render() {
        return (
            <Fragment>
            <ul className="nav nav-tabs" id="myTab" role="tablist"> 
            {IsAuthorize([Role.TAM, Role.Dev, Role.Support]) &&  <li className="nav-item">
                            <NavLink to={"/data-engine-job/data-engine-job-form/" + this.props.id} className={this.props.url == "/data-engine-job/data-engine-job-form/" + this.props.id ? "nav-link active" : "nav-link"} id="edit-tab" data-toggle="tab" href="#edit" role="tab"
                                aria-controls="edit" aria-selected={this.props.url == "/data-engine-job/data-engine-job-form/" + this.props.id ? "true" : "false"}><i className="far fa-edit"></i>&nbsp;Edit</NavLink>
                        </li>  }                         
                        {IsAuthorize([Role.Sales, Role.AM, Role.TAM, Role.Dev, Role.Support ,Role.Manager]) && <li className="nav-item">
                    <NavLink to={"/data-engine-job/detail/" + this.props.id} className={this.props.url == "/data-engine-job/detail/" ? "nav-link active" : "nav-link"} id="show-tab" data-toggle="tab" href="#show" role="tab"
                        aria-controls="show" aria-selected={this.props.url == "/data-engine-job/detail/" ? "true" : "false"}><i className="fas fa-info-circle"></i>&nbsp;Show</NavLink>
                </li>}
            </ul>
            <br />
        </Fragment>
        )
    }
}
