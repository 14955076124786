import React, { Component, Fragment } from 'react'
import { CollectionMissionStateTransitionRoute } from '../../routing'
import Breadcrumbs from '../../shared/Breadcrumbs'

export  class CollectionMissionStateTransitionPage extends Component {
    render() {
        return (
            <Fragment>
                <Breadcrumbs />
                <CollectionMissionStateTransitionRoute />
            </Fragment>
        )
    }
}
