import React, { Component, Fragment } from 'react'
import { AppConfig } from '../../core';
// import './Login.css'

interface IState {
    isSignedIn: any;
}
export default class Login extends Component<any, IState> {
    constructor(props) {
        super(props);
    }
    auth2: any;
    authenticate=()=> {
        let returUrl = this.getParameterByName("rtu", this.props.location.search);        
        window.location.href = AppConfig.AuthUrl + "client_id=" + AppConfig.ClientId + "&response_type=" + AppConfig.ResponseType + 
        "&scope=" + AppConfig.Scope + "&redirect_uri=" + AppConfig.Redirect_Uri + "&access_type=" + AppConfig.AccessType +
        "&prompt=consent&state="+returUrl;
    }
    
    getParameterByName=(name, url)=> {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
    render() {
        return (
            <Fragment>
                {/* <div className="row mt-5">
                    <div className="col-md-12 text-center">
                        <h2>Admin Sign In</h2>
                        <button className="loginBtn loginBtn--google" onClick={this.authenticate} >Authenticate</button>
                    </div>
                </div> */}
                <div  style={{backgroundColor: '#f3f3f3', position: 'relative', height:'43vh', width: '100%'}}></div>
                <div className="row" style={{margin:'0px', 'marginTop': '-134px'}}>
                    <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                        <div className="card text-center" style={{ 'boxShadow':'0px 0px 20px #c1c1c1', 'border': '2px solid #fff' }}>
                        <div className="card-header" style={{padding: '40px 0px', 'background': '#f3f3f3'}}>
                            <img src="./logo.png" style={{ width: '200px', margin: '0px auto' }}/>
                        </div>
                        <div className="card-body" style={{padding: '40px 0px'}}>
                        <h4>Admin Sign In</h4>
                        {/* <button className="btn btn-info loginBtn--google" onClick={this.authenticate} >   <img className="google-icon-wrapper" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/> Login with Google</button> */}
                        <button className="btn btn-success  loginBtn--google" onClick={this.authenticate} ><i className="fas fa-sign-in-alt font-20 google-icon-wrapper"></i> Authenticate</button>
                        </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
