import React, { Component, Fragment, createRef } from 'react'

import { FieldClassificationService } from './services';
import { HttpResponse } from '../../../../core';
import { ShowSuccessMessage, HandleNotFoundResponse } from '../../../../shared/helpers';
import { ButtonType } from '../../../../shared/enums/button.enum';
import { Link } from "react-router-dom";
import { Loader } from '../../../../shared/loaders';
import { FieldClassificationTabs } from './FieldClassificationTabs';
import { NotFoundResponseArea } from '../../../../shared/enums';
import { AddUpdateFieldClassificationModel } from './models';
import Modal from 'react-bootstrap/Modal';

interface IProps {
    handleClose: Function,
    id?:number;
    isNew?:boolean;    
    updateFieldClassification:Function
}

interface IState {
    editFieldClassification?: AddUpdateFieldClassificationModel,
    id?: number;
    name?:string;
    notes?:string;
    nameError?: string,
    isShowtext?: string;
    isSave?: string;
    isSaving?: boolean;
}
export class FieldClassificationFormPopup extends Component<IProps, IState> {
    private service: FieldClassificationService;
    private nameRef: any;

    constructor(props:IProps) { 
        super(props)
        this.service = new FieldClassificationService();
        this.state = this.initialState;
        this.nameRef = createRef();
        this.handleClose = this.handleClose.bind(this);
    }

    initialState: Partial<IState> = {
        editFieldClassification: {           
            id: 0,            
            name:""
        },        
        id: 0,        
        isShowtext: "New",
        isSave: "",
        isSaving: false,
        name:"",
        notes:"",
        nameError:""
    }

    handleClose = event => {
        event.preventDefault();
        this.props.handleClose();        
    }
    componentDidMount() {   
        if (!this.props.isNew) {
            this.setState({ id: this.props.id, isShowtext: "Edit" }, () => {
                this.loadData();
            });
        }
                                  
    }

    loadData() {
        this.service.editFieldClassification(this.props.id)
            .then((res: HttpResponse<AddUpdateFieldClassificationModel>) => {
                if (res && res.result) {
                    this.setState({                       
                        id:res.result?.id,
                        name:res.result?.name,
                        notes:res.result?.notes
                    }, () => {
                        
                     });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.FieldsClassification,this.props) 
            });
    }


    updateData(data: AddUpdateFieldClassificationModel) {
        this.setSavingFlag(true);
        this.service.updateFieldClassification(data)
            .then((res: HttpResponse<AddUpdateFieldClassificationModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Field Classification successfully updated.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.setState(this.initialState);
                            this.props.updateFieldClassification(true,this.state.name,this.state.id);
                            this.props.handleClose();
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });
    }


    postData(data: AddUpdateFieldClassificationModel) {
        this.setSavingFlag(true);
        this.service.postFieldClassification(data)
            .then((res: HttpResponse<AddUpdateFieldClassificationModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({                        
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Field Classification successfully created.");
                        if (this.state.isSave === ButtonType.Save) {                            
                            this.setState(this.initialState);
                            this.props.updateFieldClassification(true,this.state.name,this.state.id);
                            this.props.handleClose();
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });;
    }


    handleChange = (event: any) => {
        const isCheckbox = event.target.type === "checkbox";
        this.setState({
            [event.target.name]: isCheckbox
                ? event.target.checked
                : event.target.value
        })
        if (this.nameRef.current.value) {
            this.setState({ nameError: "" });
          }
    }

    validate = () => {
        let nameError = "";      
        if (!this.nameRef.current.value) {
            nameError = "Name can't be blank";
        }
        if (nameError) {
            this.setState({ nameError: nameError })
            return false;
        }
        if (!nameError) {
            nameError = "";
            this.setState({ nameError: nameError})
            return true;
        }

    }

    handleSubmit = event => {
        event.preventDefault();
        const isValid = this.validate();
        let data: AddUpdateFieldClassificationModel = {                        
            id: this.state.id,
            name:this.state.name,
            notes:this.state.notes
        };
        if (isValid) {
            if (data.id == 0) {
                this.postData(data);
            }
            else
            {
        this.updateData(data);
            }
        }
    }

    handleClick = (event) => {
        this.setState({ isSave: event.target.value },()=>{
            this.props.updateFieldClassification(true,this.state.name,this.state.id);
        })
    }
    private setSavingFlag(saving: boolean) {
        this.setState({ isSaving: saving });
    }

    render() {
        return (
            <Fragment>
            <form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton onClick={this.handleClose}>
                    </Modal.Header>
                    <Modal.Body>
                <input type="hidden" value={this.state.id} />
                <div>
                <div className="d-flex justify-content-between align-items-center mb-2">
                            <h5>{this.state.isShowtext} Field Cassification  <span> '{this.state.name}'</span></h5>                            
                        </div>
                    <div className="row">
                        <div className="col-md-12 col-xl-12"> 
                            <div className="card">
                                <div className="card-body">                                
                                    <div className="row mt-4">
                                        <div className="col-md-6">
                                            <div className="form-group shadow-sm p-3 mb-4 ">
                                                <label>Name<span className=" text-danger">*</span></label>
                                                <div className="custom-control custom-checkbox">
                                                <input type="text" maxLength={255}  ref={this.nameRef} name="name" value={this.state.name} onChange={this.handleChange} className= {!this.state.nameError ? 'form-control' : 'form-control  is-invalid'}/>
                                                <div className="invalid-feedback">
                                                        {this.state.nameError}
                                                    </div>
                                                </div>
                                            </div>                                          
                                        </div>
                                        <div className="col-md-6">
                                <div className="form-group shadow-sm p-3 mb-0">
                                  <label>Notes</label>
                                  <textarea
                                    rows={10}
                                    name="notes"
                                    value={this.state.notes}
                                    onChange={this.handleChange}
                                    className="form-control" style={{'height':'160px'}}
                                  ></textarea>                                  
                                </div>
                              </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-3 mb-4">
                    <button type="button" className="btn btn-lg btn-default" onClick={this.handleClose}>{ButtonType.Cancel}</button>
                                {!this.state.isSaving && <Fragment>
                                    <input type="submit" className="btn btn-lg btn-primary  ml-3" onClick={this.handleClick} value={ButtonType.Save} />                                    
                                </Fragment>
                                }
                                <Loader loading={this.state.isSaving} marginBottom="0px" marginTop="8px" width="368px" ></Loader>
                    </div>
                </div>
                </Modal.Body>
            </form>
        </Fragment>
        )
    }
}
