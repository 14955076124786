import React, { Component, Fragment, createRef } from "react";

import { DropdownItemModel, ParamaResponseModel, ParamsVersionPostModel, ParamsVersionsModel } from "../../../shared/models";
import { CommonService } from "../../../shared/services/common.service";
import {
    ConnectionState,
    ButtonType,
    NotFoundResponseArea,
} from "../../../shared/enums";
import { HttpResponse } from "../../../core";
import {
    ShowSuccessMessage,
    GetConnectionStateList,
    GetAllTimeZone,
    Capitalize,
    HandleNotFoundResponse, ConvertDateTime
} from "../../../shared/helpers";
import { ValidateYaml } from "../../../shared/helpers/yaml.helper";
import { Link, NavLink, Route } from "react-router-dom";
import { Loader } from "../../../shared/loaders";
import { OverlayTrigger, Tooltip, Button, Modal } from "react-bootstrap";
import { Editor } from "../../../shared";
import { spawn } from "child_process";
import { CustomConnectionPostModel, EditCustomConnectionModel } from "./models";
import { ExtractorTimeZoneSpecification } from "../connections/models";
import { CustomConnectionsService } from "./services";
import { CustomConnectionTabs } from "./CustomConnectionTabs";
import { AccountFormPopup } from "..";
import { ExtractionEnginesFormPopup } from "./extraction-engines";
import { ExtractionSchedulingRecipesFormPopup } from "../../individual/extraction-scheduling-recipes";
import { IsAuthorize } from "../../../shared/authorization/check-access";
import { Role } from "../../../shared/authorization/enums";
import { InboundEmailAddressesFormPopup } from "./inbound-email-addresses";

interface IProps {
    handleCloseCustomConnection: Function,
    id?: number;
    isNew?: boolean;
    updateCustomConnection: Function
}
interface IState {
    showInboundEmailAddressesForm?: boolean;
    ShowExtractionSchedulingRecipiForm?: boolean;
    customConnection?: CustomConnectionPostModel;
    editCustomConnecion?: EditCustomConnectionModel;
    id?: number;
    name?: string;
    nameError?: string;
    available_to_external_users?: boolean;
    billable?: boolean;
    state?: string;
    state_message?: string;
    ignore_health?: boolean;
    data_retention_days?: number;
    maintenance_mode?: boolean;
    authorization_unstable_at?: Date | null;
    authorization_unstable?: boolean;
    deleted?: boolean;

    params?: string;
    paramsError?: string;
    time_zone?: string;
    internal_notes?: string;

    isShowtext?: string;
    isSave?: string;
    isSaving?: boolean;

    extraction_engine_id?: number;
    extractionEngineList?: DropdownItemModel[];
    extractionEngineName?: string;
    extractionEngineError?: string;

    account_id?: number;
    accountList?: DropdownItemModel[];
    accountName?: string;
    accountError?: string;

    custom_extraction_scheduling_recipe_id?: number;
    customExtractionSchedulingRecipeList?: DropdownItemModel[];
    customExtractionSchedulingRecipeName?: string;

    data_source?: DropdownItemModel;
    data_source_id?: number;
    dataSourceName?: string;
    dataSourceList?: DropdownItemModel[];

    inbound_email_addresses?: Array<DropdownItemModel>;
    // extractor_time_zone_specification?: ExtractorTimeZoneSpecification | null;
    paramsVersionsList?: Array<ParamsVersionsModel>;
    paramsVersion?: number;

    download_link_matcher?: string;
    subdirectory_name?: string;

    showAccountForm?: boolean;
    showExtractionEngine?: boolean;
    isNew?: boolean;
    isChildUpdate?: boolean;
}
export class CustomConnectionFormPopup extends Component<IProps, IState> {
    //declaring service type
    private service: CustomConnectionsService;
    private commonService: CommonService;
    private networkCall: any;
    private nameRef: any;

    constructor(props) {
        super(props);
        this.service = new CustomConnectionsService();
        this.commonService = new CommonService();
        this.state = this.initialState;
        this.editorhandleParams = this.editorhandleParams.bind(this);
        this.nameRef = createRef();
        this.handleClose = this.handleClose.bind(this);
        this.updateAccount = this.updateAccount.bind(this);
        this.updateExtractionEngine = this.updateExtractionEngine.bind(this);
        this.updateExtractionSchedulingRecipi = this.updateExtractionSchedulingRecipi.bind(this);
        this.updateInboundEmailAddresses = this.updateInboundEmailAddresses.bind(this);
    }

    initialState: Partial<IState> = {
        customConnection: {
            account_id: 0,
            authorization_unstable_at: null,
            available_to_external_users: false,
            billable: true,
            custom_extraction_scheduling_recipe_id: 0,
            // data_retention_days: 0,
            data_source_id: 0,
            id: 0,
            ignore_health: false,
            internal_notes: "",
            maintenance_mode: false,
            name: "",
            params: "",
            extraction_engine_id: 0,
            state: ConnectionState.NEW,
            state_message: "",
            time_zone: "UTC",
            download_link_matcher: "",
            authorization_unstable: false,
            data_retention_days: 0,
            deleted: false,
            inbound_email_addresses: [],
            subdirectory_name: "",
        },
        account_id: 0,
        authorization_unstable_at: null,
        available_to_external_users: false,
        billable: true,
        custom_extraction_scheduling_recipe_id: 0,
        data_retention_days: 0,
        data_source_id: 0,
        dataSourceName: "",
        id: 0,
        ignore_health: false,
        internal_notes: "",
        maintenance_mode: false,
        name: "",
        params: "",
        extraction_engine_id: 0,
        state: ConnectionState.NEW,
        state_message: "",
        time_zone: "UTC",
        accountError: "",
        accountList: [],
        accountName: "",
        customExtractionSchedulingRecipeList: [],
        dataSourceList: [],
        extractionEngineList: [],
        extractionEngineError: "",
        extractionEngineName: "",
        paramsError: "",
        authorization_unstable: false,

        isShowtext: "New",
        isSave: "",
        isSaving: false,
        inbound_email_addresses: [],
        data_source: {
            text: "",
            value: 0,
        },
        nameError: "",
        deleted: false,
        download_link_matcher: "",
        subdirectory_name: "",
        customExtractionSchedulingRecipeName: "",
        paramsVersionsList: [],
        paramsVersion: 0,
        showAccountForm: false,
        showExtractionEngine: false,
        ShowExtractionSchedulingRecipiForm: false,
        isNew: false,
        isChildUpdate: false,
        showInboundEmailAddressesForm: false,
        // extractor_time_zone_specification:null
    };

    handleCloseCustomConnection = event => {
        event.preventDefault();
        this.props.handleCloseCustomConnection();
    }

    updateAccount(isChildUpdate, name, id) {
        this.setState({ isChildUpdate: isChildUpdate, accountName: name, account_id: id })
    }

    handleClose = () => {
        this.setState({ showAccountForm: false }, () => {
        });
    }

    handleShowAccount = () => {
        this.setState({ showAccountForm: true, isNew: true }, () => {

        });
    }

    handleShowEditAccount = () => {
        this.setState({ showAccountForm: true, isNew: false }, () => {

        });
    }



    updateExtractionEngine(isChildUpdate, name, id) {
        this.setState({ isChildUpdate: isChildUpdate, extractionEngineName: name, extraction_engine_id: id })
    }

    handleCloseExtractionEngine = () => {
        this.setState({ showExtractionEngine: false }, () => {
        });
    }

    handleShowExtractionEngine = () => {
        this.setState({ showExtractionEngine: true, isNew: true }, () => {

        });
    }

    handleShowEditExtractionEngine = () => {
        this.setState({ showExtractionEngine: true, isNew: false }, () => {

        });
    }


    updateExtractionSchedulingRecipi(isChildUpdate, name, id) {
        this.setState({
            isChildUpdate: isChildUpdate,
            customExtractionSchedulingRecipeName: name,
            custom_extraction_scheduling_recipe_id: id,
        });
    }

    handleExtractionSchedulingRecipiClose = () => {
        this.setState({ ShowExtractionSchedulingRecipiForm: false }, () => { });
    };

    handleShowEditExtractionSchedulingRecipi = () => {
        this.setState({ ShowExtractionSchedulingRecipiForm: true, isNew: false }, () => { });
    };

    updateInboundEmailAddresses(isChildUpdate, name, id, connectionId) {
        if (isChildUpdate) {
            if (id != 0 && connectionId == this.props.id) {
                this.state.inbound_email_addresses?.push({ text: name, value: id })
            }
            this.setState({
                isChildUpdate: isChildUpdate
            }, () => {

            });
        }
    }


    handleShowInboundEmailAddresses = () => {
        this.setState({ showInboundEmailAddressesForm: true, isNew: false }, () => { });
    };
    handleInboundEmailAddressesClose = () => {
        this.setState({ showInboundEmailAddressesForm: false }, () => { });
    };

    componentDidMount() {
        if (this.props.isNew) {
            this.setState({ id: 0 })
        }
        if (!this.props.isNew) {
            this.setState({ id: this.props.id, isShowtext: "Edit" }, () => {
                this.loadData(this.props.id)
            });
        }

        // if (this.props.id > 0) {
        //   this.setState(
        //     { id: this.props.id, isShowtext: "Edit" },
        //     () => {
        //       this.loadData(this.props.id);
        //     }
        //   );
        // }
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    // componentWillReceiveProps(nextprops) {
    //     if (nextprops.match.params.id > 0) {
    //         this.loadData(nextprops.match.params.id);
    //     }
    // }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (event.path[0].id !== "accountName" && event.path[0].id !== "account" && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight) {
            this.setState(
                {
                    accountList: [],
                },
                () => { }
            );
        }

        if (
            event.path[0].id !== "extractionEngineName" &&
            event.path[0].id !== "extractionEngine" && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight
        ) {
            this.setState(
                {
                    extractionEngineList: [],
                },
                () => { }
            );
        }

        if (
            event.path[0].id !== "customExtractionSchedulingRecipename" &&
            event.path[0].id !== "cesr" && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight
        ) {
            this.setState(
                {
                    customExtractionSchedulingRecipeList: [],
                },
                () => { }
            );
        }

        if (
            event.path[0].id !== "dataSourceName" &&
            event.path[0].id !== "dataSource" && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight
        ) {
            this.setState(
                {
                    dataSourceList: [],
                },
                () => { }
            );
        }
    };

    loadData(id) {
        this.service
            .editCustomConnection(id)
            .then((res: HttpResponse<EditCustomConnectionModel>) => {
                if (res && res.result) {
                    this.setState(
                        {
                            id: res.result?.id,
                            accountName:
                                res.result?.account == null ? "" : res.result?.account.text,
                            account_id:
                                res.result?.account == null ? 0 : res.result?.account.value,
                            extractionEngineName:
                                res.result?.extraction_engine == null
                                    ? ""
                                    : res.result?.extraction_engine.text,
                            extraction_engine_id:
                                res.result?.extraction_engine == null
                                    ? 0
                                    : res.result?.extraction_engine.value,
                            custom_extraction_scheduling_recipe_id:
                                res.result?.custom_extraction_scheduling_recipe_id == null ? 0 : res.result?.custom_extraction_scheduling_recipe_id,
                            customExtractionSchedulingRecipeName:
                                res.result?.custom_extraction_scheduling_recipe_id != null
                                    ? "ExtractionSchedulingRecipe #" +
                                    res.result?.custom_extraction_scheduling_recipe_id
                                    : "",
                            data_source_id: res.result?.data_source_id,
                            data_source: res.result?.data_source,
                            authorization_unstable:
                                res.result?.authorization_unstable_at == null ? false : true,
                            billable: res.result?.billable,
                            data_retention_days:
                                res.result?.data_retention_days == null
                                    ? 0
                                    : res.result?.data_retention_days,
                            ignore_health: res.result?.ignore_health,
                            internal_notes: res.result?.internal_notes,
                            maintenance_mode: res.result?.maintenance_mode,
                            name: res.result?.name,
                            params: res.result?.params == null ? "" : res.result?.params,
                            state_message: res.result?.state_message,
                            time_zone: res.result?.time_zone == "" ? "UTC" : res.result?.time_zone,
                            inbound_email_addresses: res.result?.inbound_email_addresses,
                            subdirectory_name: res.result?.subdirectory_name,
                            download_link_matcher: res.result?.download_link_matcher,
                            paramsVersionsList: res.result?.params_versions
                        },
                        () => {

                        }
                    );
                }
            })
            .catch((ex) => {
                HandleNotFoundResponse(
                    ex,
                    NotFoundResponseArea.CustomConnection,
                    this.props
                );
            });
    }

    private getAccounts(text: string) {
        this.commonService
            .getAccountsByName(text)
            .then((res: HttpResponse<DropdownItemModel[]>) => {
                if (res) {
                    this.setState({
                        accountList: res.result,
                    });
                }
            });
    }

    private getextractionEngine(text: string) {
        this.commonService
            .getExtractionEngineByName(text)
            .then((res: HttpResponse<DropdownItemModel[]>) => {
                if (res) {
                    this.setState({
                        extractionEngineList: res.result,
                    });
                }
            });
    }

    private getCustomExtractionSchedulingRecipes(id?: number) {
        this.commonService
            .getCustomExtractionSchedulingRecipeByName(id)
            .then((res: HttpResponse<DropdownItemModel[]>) => {
                if (res) {
                    this.setState({
                        customExtractionSchedulingRecipeList: res.result,
                    });
                }
            });
    }

    private getDataSources(id?: number) {
        this.commonService
            .getDataSourceById(id)
            .then((res: HttpResponse<DropdownItemModel[]>) => {
                if (res) {
                    this.setState({
                        dataSourceList: res.result,
                    });
                }
            });
    }

    //*************** * Accounts************************* //

    onAccountChange = (e) => {
        const value = e.target.value;
        if (value.length > 0) {
            if (this.networkCall) {
                clearTimeout(this.networkCall);
            }
            this.networkCall = setTimeout(() => {
                this.getAccounts(value);
            }, 600);
        }
        this.setState({
            accountName: value,
            account_id: value === "" ? 0 : value,
            accountError: "",
        });
    };

    selectedAccount(item) {
        this.setState({
            account_id: item.value,
            accountName: item.text,
            accountList: [],
        });
    }

    renderAccount = () => {
        if (this.state.accountList?.length === 0) {
            return null;
        }
        return (
            <ul className="list-unstyled auto-suggest">
                {this.state.accountList?.map((item, index) => (
                    <li
                        id="account"
                        key={index}
                        onClick={() => this.selectedAccount(item)}
                    >
                        {item.text}
                    </li>
                ))}
            </ul>
        );
    };
    //*************** * Accounts************************* //

    //*************** * Extraction engine************************* //

    onExtractionEngineChange = (e) => {
        const value = e.target.value;
        if (value.length > 0) {
            if (this.networkCall) {
                clearTimeout(this.networkCall);
            }
            this.networkCall = setTimeout(() => {
                this.getextractionEngine(value);
            }, 600);
        }
        this.setState({
            extractionEngineName: value,
            extraction_engine_id: value === "" ? 0 : value,
            extractionEngineError: "",
        });
    };

    selectedExtractionEngine(item) {
        this.setState({
            extraction_engine_id: item.value,
            extractionEngineName: item.text,
            extractionEngineList: [],
        });
    }

    renderExtractionEngine = () => {
        if (this.state.extractionEngineList?.length === 0) {
            return null;
        }
        return (
            <ul className="list-unstyled auto-suggest">
                {this.state.extractionEngineList?.map((item, index) => (
                    <li
                        id="extractionEngine"
                        key={index}
                        onClick={() => this.selectedExtractionEngine(item)}
                    >
                        {item.text}
                    </li>
                ))}
            </ul>
        );
    };
    //*************** *Platform************************* //

    //*************** * Custom Extraction Scheduling Recipe************************* //

    oncustomExtractionSchedulingRecipeChange = (e) => {
        const value = e.target.value;
        if (value.length > 0) {
            if (this.networkCall) {
                clearTimeout(this.networkCall);
            }
            this.networkCall = setTimeout(() => {
                this.getCustomExtractionSchedulingRecipes(value);
            }, 600);
        }
        this.setState({
            customExtractionSchedulingRecipeName: value,
            custom_extraction_scheduling_recipe_id: value === "" ? 0 : value,
        });
    };

    selectedExtractionSchedulingRecipe(item) {
        this.setState({
            custom_extraction_scheduling_recipe_id: item.value,
            customExtractionSchedulingRecipeName:
                "ExtractionSchedulingRecipe #" + item.value,
            customExtractionSchedulingRecipeList: [],
        });
    }

    renderExtractionSchedulingRecipe = () => {
        if (this.state.customExtractionSchedulingRecipeList?.length === 0) {
            return null;
        }
        return (
            <ul className="list-unstyled auto-suggest">
                {this.state.customExtractionSchedulingRecipeList?.map((item, index) => (
                    <li
                        id="cesr"
                        key={index}
                        onClick={() => this.selectedExtractionSchedulingRecipe(item)}
                    >
                        ExtractionSchedulingRecipe #{item.value}
                    </li>
                ))}
            </ul>
        );
    };
    //*************** *Extraction Scheduling Recipe************************* //

    //*************** * Data Source************************* //

    onDataSourceChange = (e) => {
        const value = e.target.value;
        if (value.length > 0) {
            if (this.networkCall) {
                clearTimeout(this.networkCall);
            }
            this.networkCall = setTimeout(() => {
                this.getDataSources(value);
            }, 600);
        }
        this.setState({
            dataSourceName: value,
            data_source_id: value === "" ? 0 : value,
        });
    };

    selectedDataSource(item) {
        this.setState({
            data_source_id: item.value,
            dataSourceName: item.text + " Source #" + item.value,
            dataSourceList: [],
        });
    }

    renderDataSource = () => {
        if (this.state.dataSourceList?.length === 0) {
            return null;
        }
        return (
            <ul className="list-unstyled auto-suggest">
                {this.state.dataSourceList?.map((item, index) => (
                    <li
                        id="dataSource"
                        key={index}
                        onClick={() => this.selectedDataSource(item)}
                    >
                        {item.text} Source #{item.value}
                    </li>
                ))}
            </ul>
        );
    };
    //*************** *Data Source************************* //

    onParamsChange = (e) => {
        const value = e.target.value;
        let data: ParamsVersionPostModel = {
            connection_id: this.props.id,
            connection_type: "custom_connection",
            id: value
        }

        this.commonService
            .changeParamsByVersion(data)
            .then((res: HttpResponse<ParamaResponseModel>) => {
                if (res && res.result) {
                    this.setState({ params: res.result.params })
                }
            });

    }

    handleChange = (event: any) => {
        if (event.target.name == "paramsVersion") {
            this.onParamsChange(event);
        }
        if (event.target.name == "accountName") {
            this.onAccountChange(event);
        }
        if (event.target.name == "extractionEngineName") {
            this.onExtractionEngineChange(event);
        }
        if (event.target.name == "customExtractionSchedulingRecipename") {
            this.oncustomExtractionSchedulingRecipeChange(event);
        }
        if (event.target.name == "dataSourceName") {
            this.onDataSourceChange(event);
        }
        const isCheckbox = event.target.type === "checkbox";
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [event.target.name]: isCheckbox
                ? event.target.checked
                : event.target.value,
        });
        if (this.nameRef.current.value) {
            this.setState({ nameError: "" });
        }
    };

    editorhandleParams(data) {
        this.setState({
            params: data,
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (!this.state.isChildUpdate) {
            const isValid = this.validate();
            const isParamsValid = this.validateParams();
            let accountData: CustomConnectionPostModel = {
                name: this.state.name,
                id: this.state.id,
                account_id: this.state.account_id,
                available_to_external_users: this.state.available_to_external_users,
                billable: this.state.billable,
                custom_extraction_scheduling_recipe_id: this.state.custom_extraction_scheduling_recipe_id == 0 ? null : this.state.custom_extraction_scheduling_recipe_id,
                data_retention_days: this.state.data_retention_days,
                data_source_id: this.state.data_source_id,
                ignore_health: this.state.ignore_health,
                internal_notes: this.state.internal_notes,
                maintenance_mode: this.state.maintenance_mode,
                params: this.state.params,
                extraction_engine_id: this.state.extraction_engine_id,
                state: this.state.state,
                state_message: this.state.state_message,
                time_zone: this.state.time_zone,
                authorization_unstable: this.state.authorization_unstable,
                download_link_matcher: this.state.download_link_matcher,
                subdirectory_name: this.state.subdirectory_name
            };

            if (isValid && isParamsValid) {
                if (accountData.id === 0) {
                    this.postData(accountData);
                } else {
                    this.updateData(accountData);
                }
            }
        }
    };

    validate = () => {
        let nameError = "";
        let accountError = "";
        let extractionEngineError = "";

        if (!this.nameRef.current.value) {
            nameError = "Name can't be blank";
        }
        if (!this.state.account_id) {
            accountError = "Account can't be blank";
        }
        if (!this.state.extraction_engine_id) {
            extractionEngineError = "Extraction engine can't be blank";

            if (nameError || extractionEngineError || accountError) {
                this.setState({
                    nameError: nameError,
                    extractionEngineError: extractionEngineError,
                    accountError: accountError,
                });
                return false;
            }
        }
        if (!nameError || !extractionEngineError || !accountError) {
            nameError = "";
            extractionEngineError = "";
            accountError = "";
            this.setState({
                nameError: nameError,
                extractionEngineError: extractionEngineError,
                accountError: accountError,
            });
            return true;
        }
    };

    validateParams() {
        let isValid = ValidateYaml(this.state.params);
        if (isValid) {
            this.setState({ paramsError: "" });
            return true;
        }
        if (!isValid) {
            this.setState({
                paramsError: "Parameters is not valid please type valid YAML",
            });
            return false;
        }
    }

    postData(account: CustomConnectionPostModel) {
        this.setSavingFlag(true);
        this.service.postCustomConnection(account).then(
            (res: HttpResponse<CustomConnectionPostModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState(
                        {
                            id: res.result.id,
                        },
                        () => {
                            ShowSuccessMessage("Custom Connection successfully created.");
                            if (this.state.isSave === ButtonType.Save) {
                                this.setState(this.initialState);
                                this.props.updateCustomConnection(true, this.state.name, this.state.id);
                                this.props.handleCloseCustomConnection();
                            }
                        }
                    );
                }
            },
            () => {
                this.setSavingFlag(false);
            }
        );
    }

    updateData(account: CustomConnectionPostModel) {
        this.setSavingFlag(true);
        this.service.updateCustomConnection(account).then(
            (res: HttpResponse<CustomConnectionPostModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState(
                        {
                            id: res.result.id,
                        },
                        () => {
                            ShowSuccessMessage("Custom Connection successfully updated.");
                            if (this.state.isSave === ButtonType.Save) {
                                this.setState(this.initialState);
                                this.props.updateCustomConnection(true, this.state.name, this.state.id);
                                this.props.handleCloseCustomConnection();
                            }
                        }
                    );
                }
            },
            () => {
                this.setSavingFlag(false);
            }
        );
    }

    handleClick = (event) => {
        this.setState({ isSave: event.target.value }, () => {
            this.props.updateCustomConnection(true, this.state.name, this.state.id);
            
        })

    };
    private setSavingFlag(saving: boolean) {
        this.setState({ isSaving: saving });
    }

    render() {
        return (
            <Fragment>
                <form onSubmit={this.handleSubmit}>
                    <Modal.Header closeButton onClick={this.handleCloseCustomConnection}>
                    </Modal.Header>
                    <Modal.Body>
                        <input type="hidden" value={this.state.id} />
                        <div>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <h5>
                                    {this.state.isShowtext} Custom Connection
                {this.state.name && <span> '{this.state.name}'</span>}
                                </h5>
                            </div>
                            {/* <Modal backdrop='static' keyboard={false} size="lg" show={this.state.showAccountForm} onHide={this.handleClose}>
                                <AccountFormPopup updateAccount={this.updateAccount} handleClose={this.handleClose} isNew={this.state.isNew} id={this.state?.account_id} />
                            </Modal> */}
                            {/* <Modal backdrop='static' keyboard={false} size="lg" show={this.state.showExtractionEngine} onHide={this.handleCloseExtractionEngine}>
                                <ExtractionEnginesFormPopup updateExtractionEngine={this.updateExtractionEngine} handleClose={this.handleCloseExtractionEngine} isNew={this.state.isNew} id={this.state?.extraction_engine_id} />
                            </Modal> */}

                            {/* <Modal backdrop='static' keyboard={false} size="lg" show={this.state.ShowExtractionSchedulingRecipiForm} onHide={this.handleExtractionSchedulingRecipiClose}>
                                <ExtractionSchedulingRecipesFormPopup updateExtractionSchedulingRecipi={this.updateExtractionSchedulingRecipi} handleClose={this.handleExtractionSchedulingRecipiClose} isNew={this.state.isNew} id={this.state?.custom_extraction_scheduling_recipe_id} />
                            </Modal> */}
                            {/* <Modal backdrop='static' keyboard={false} size="lg" show={this.state.showInboundEmailAddressesForm} onHide={this.handleInboundEmailAddressesClose}>
                                <InboundEmailAddressesFormPopup updateInboundEmailAddresses={this.updateInboundEmailAddresses} handleClose={this.handleInboundEmailAddressesClose} isNew={this.state.isNew} id={0} connectionId={this.props.id} connectionType="CustomConnection" connectionName={this.state.name} />
                            </Modal> */}
                            <div className="row">
                                <div className="col-md-12 col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xl-4 col-md-4">
                                                    <div className="form-group shadow-sm p-3 ">
                                                        <label>
                                                            Name
                            <span className=" text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            ref={this.nameRef}
                                                            maxLength={255}
                                                            name="name"
                                                            value={this.state.name}
                                                            placeholder="Enter Name"
                                                            onChange={this.handleChange}
                                                            className={
                                                                !this.state.nameError
                                                                    ? "form-control"
                                                                    : "form-control  is-invalid"
                                                            }
                                                        />
                                                        <div className="invalid-feedback">
                                                            {this.state.nameError}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group shadow-sm p-3">
                                                        <label>
                                                            Extraction Engine
                            <span className=" text-danger">*</span>
                                                        </label>
                                                        {this.props.id == 0 && (
                                                            <div className="input-group ">
                                                                <input
                                                                    autoComplete="off"
                                                                    id="extractionEngineName"
                                                                    name="extractionEngineName"
                                                                    type="text"
                                                                    onChange={this.handleChange}
                                                                    value={this.state.extractionEngineName}
                                                                    className={
                                                                        !this.state.extractionEngineError
                                                                            ? "form-control"
                                                                            : "form-control  is-invalid"
                                                                    }
                                                                />
                                                                {this.renderExtractionEngine()}
                                                                {/* {IsAuthorize([Role.Dev]) && <div className="input-group-append">
                                                                    <button type="button" className={`btn btn-primary`} disabled={this.state.extraction_engine_id == 0} onClick={this.handleShowEditExtractionEngine}><i className="far fa-edit"></i></button>
                                                                </div>} */}
                                                                <div className="invalid-feedback">
                                                                    {this.state.extractionEngineError}
                                                                </div>
                                                            </div>
                                                        )}
                                                        {this.props.id != 0 && (
                                                            <div>
                                                                <a href="#" className="badge badge-light">
                                                                    {this.state.extractionEngineName}
                                                                </a>
                                                            </div>
                                                        )}
                                                        <small>
                                                            See &nbsp;
                            <a href="https://sites.google.com/a/staq.com/staqnowledged/home/4---our-product/staq-admin/custom-connections">
                                                                the wiki&nbsp;
                            </a>
                            for more information on choosing an engine.
                          </small>
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group shadow-sm p-3">
                                                        <label>
                                                            Account <span className=" text-danger">*</span>
                                                            <OverlayTrigger
                                                                overlay={
                                                                    <Tooltip id="button-tooltip">
                                                                        Once this is set, it cannot be changed.
                                </Tooltip>
                                                                }
                                                            >
                                                                <a className="btn btn-outline-dark border-0 btn-sm ml-1">
                                                                    <i className="fas fa-info-circle"></i>
                                                                </a>
                                                            </OverlayTrigger>
                                                        </label>
                                                        {this.props.id == 0 && (
                                                            <div className="input-group ">
                                                                <input
                                                                    autoComplete="off"
                                                                    id="accountName"
                                                                    name="accountName"
                                                                    type="text"
                                                                    onChange={this.handleChange}
                                                                    value={this.state.accountName}
                                                                    className={
                                                                        !this.state.accountError
                                                                            ? "form-control"
                                                                            : "form-control  is-invalid"
                                                                    }
                                                                />
                                                                {this.renderAccount()}
                                                                {/* {IsAuthorize([Role.TAM, Role.Dev, Role.AM]) && <div className="input-group-append">
                                                                    <button type="button" className="btn btn-success" onClick={this.handleShowAccount}><i className="fas fa-plus" style={{ color: "white" }}></i></button>
                                                                    <button type="button" className={`btn btn-primary`} disabled={this.state.account_id == 0} onClick={this.handleShowEditAccount}><i className="far fa-edit"></i></button>
                                                                </div>} */}
                                                                <div className="invalid-feedback">
                                                                    {this.state.accountError}
                                                                </div>
                                                            </div>
                                                        )}
                                                        {this.props.id != 0 && (
                                                            <div>
                                                                <Link
                                                                    to={"/account/detail/" + this.state.account_id}
                                                                    className="badge badge-light"
                                                                >
                                                                    {this.state.accountName}
                                                                </Link>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-xl-4 col-md-4">
                                                    <div className="form-group shadow-sm p-3 mb-4 ">
                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                type="checkbox"
                                                                name="billable"
                                                                checked={this.state.billable}
                                                                onChange={this.handleChange}
                                                                className="custom-control-input"
                                                                id="billable"
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor="billable"
                                                            >
                                                                Billable{" "}
                                                            </label>
                                                        </div>
                                                        <small>
                                                            {" "}
                            If this flag is set, this Custom Connection is
                            included in the count on account management page in
                            staqweb.
                          </small>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group shadow-sm p-3 mb-4">
                                                        <label>
                                                            Subdirectory Name
                            <OverlayTrigger
                                                                overlay={
                                                                    <Tooltip id="button-tooltip">
                                                                        The place where the files for this custom
                                                                        connection, if it is using an InboundFile
                                                                        Extraction Engine, are located in the inbound
                                                                        file system.
                                </Tooltip>
                                                                }
                                                            >
                                                                <a className="btn btn-outline-dark border-0 btn-sm ml-1">
                                                                    <i className="fas fa-info-circle"></i>
                                                                </a>
                                                            </OverlayTrigger>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="subdirectory_name"
                                                            value={this.state.subdirectory_name}
                                                            onChange={this.handleChange}
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4">
                                                    <div className="form-group shadow-sm p-3">
                                                        <label>State </label>
                                                        <select
                                                            className="form-control"
                                                            name="state"
                                                            value={this.state.state}
                                                            onChange={this.handleChange}
                                                        >
                                                            {GetConnectionStateList.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item.value}>
                                                                        {item.value}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group shadow-sm p-3 mb-4 ">
                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                type="checkbox"
                                                                name="ignore_health"
                                                                checked={this.state.ignore_health}
                                                                onChange={this.handleChange}
                                                                className="custom-control-input"
                                                                id="ignore_health"
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor="ignore_health"
                                                            >
                                                                Ignore Health
                            </label>
                                                        </div>
                                                        <small>
                                                            <br />
                                                            <br />
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 ">
                                                    <div className="form-group shadow-sm p-3 mb-4 ">
                                                        <label>
                                                            Data Retention Days
                            <OverlayTrigger
                                                                overlay={
                                                                    <Tooltip id="button-tooltip">
                                                                        The number of days to retain data in Custom
                                                                        Connections and custom Custom Connections.
                                                                        Default for an account is 1095 (3 years). A
                                                                        value of zero or less disables purging of old
                                                                        data for this Custom Connection entirely. No
                                                                        value specified uses the account default.
                                </Tooltip>
                                                                }
                                                            >
                                                                <a className="btn btn-outline-dark border-0 btn-sm ml-1">
                                                                    <i className="fas fa-info-circle"></i>
                                                                </a>
                                                            </OverlayTrigger>
                                                        </label>
                                                        <input
                                                            type="number"
                                                            name="data_retention_days"
                                                            value={this.state.data_retention_days}
                                                            onChange={this.handleChange}
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4">
                                                    <div className="form-group shadow-sm p-3">
                                                        <label>State Message</label>
                                                        <input
                                                            type="text"
                                                            maxLength={255}
                                                            name="state_message"
                                                            value={this.state.state_message}
                                                            placeholder="Enter State Message"
                                                            onChange={this.handleChange}
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group shadow-sm p-3 mb-4 ">
                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                type="checkbox"
                                                                name="maintenance_mode"
                                                                checked={this.state.maintenance_mode}
                                                                onChange={this.handleChange}
                                                                className="custom-control-input"
                                                                id="maintenance_mode"
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor="maintenance_mode"
                                                            >
                                                                Maintenance Mode
                            </label>
                                                        </div>
                                                        <small>
                                                            If this flag is set, a maintenance mode message is shown to users in connection manager.
                          </small>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4">
                                                    <div className="form-group shadow-sm p-3 mb-4 ">
                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                type="checkbox"
                                                                name="authorization_unstable"
                                                                checked={this.state.authorization_unstable}
                                                                onChange={this.handleChange}
                                                                className="custom-control-input"
                                                                id="authorization_unstable"
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor="authorization_unstable"
                                                            >
                                                                Authorization Unstable At
                            </label>
                                                        </div>
                                                        <small>
                                                            {" "}
                            If this flag is set, once a day, a single Connection Verifier mission for this connection is run.
                          </small>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4">
                                                    <div className="form-group shadow-sm p-3">
                                                        <label>Time Zone </label>
                                                        {/* {this.props.id==0 && <p>Please set the Custom Connection's time zone after you save it.</p>} 
                         {this.props.id!=0 && this.state.extractor_time_zone_specification==null && <p>Please set the Custom Connection's time zone after the extractor is created.</p>}      
                          <div>
                          {this.state.extractor_time_zone_specification?.style==0 && <div>
                          <p>
                            Platform &nbsp;{this.state.platform_id}&nbsp; is configured to use a global time zone ({this.state.extractor_time_zone_specification.global_default}) , so individual Custom Connections cannot have their own time zones.                            
                          </p>
                          <a href ="#">Extractor Time Zone Specification: {this.state.time_zone}&nbsp; {this.state.extractor_time_zone_specification.id>0 &&<span>(ID {this.state.extractor_time_zone_specification.id}) </span>}</a>
                          </div>} */}

                                                        {/* {this.state.extractor_time_zone_specification?.style==1 && <div>
                            {this.state.extractor_time_zone_specification.configurable_choices.length>0 &&  <select
                            className="form-control"
                            name="time_zone"
                            value={this.state.time_zone}
                            onChange={this.handleChange}
                          >                            
                            {this.state.extractor_time_zone_specification.configurable_choices?.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                          </select>}
                          {this.state.extractor_time_zone_specification.configurable_choices.length==0 &&  <select
                            className="form-control"
                            name="time_zone"
                            value={this.state.time_zone}
                            onChange={this.handleChange}
                          >                            
                            {GetAllTimeZone.map((item, index) => {
                              return (
                                <option key={index} value={item.value}>
                                  {item.text}
                                </option>
                              );
                            })}
                          </select>}
                            </div>}
                         </div>                         
                          <p>See <a href="https://sites.google.com/a/staq.com/staqnowledged/home/infrastructure/staq-repos/extractors/time-zones">time zone info</a></p> */}

                                                        <select
                                                            className="form-control"
                                                            name="time_zone"
                                                            value={this.state.time_zone}
                                                            onChange={this.handleChange}
                                                        >
                                                            {GetAllTimeZone.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item.value}>
                                                                        {item.value}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group shadow-sm p-3">
                                                        <label>
                                                            Custom Extraction Scheduling Recipe
                            <span className=" text-danger"></span>
                                                        </label>
                                                        <div className="input-group ">
                                                            <input
                                                                autoComplete="off"
                                                                id="customExtractionSchedulingRecipename"
                                                                name="customExtractionSchedulingRecipename"
                                                                type="text"
                                                                onChange={this.handleChange}
                                                                value={
                                                                    this.state.customExtractionSchedulingRecipeName
                                                                }
                                                                className="form-control"
                                                            />
                                                            {this.renderExtractionSchedulingRecipe()}
                                                            {/* {IsAuthorize([Role.TAM, Role.Dev]) && <div className="input-group-append">
                                                                <button type="button" className={`btn btn-primary `} disabled={this.state.custom_extraction_scheduling_recipe_id == 0 || this.state.custom_extraction_scheduling_recipe_id == null} onClick={this.handleShowEditExtractionSchedulingRecipi}><i className="far fa-edit"></i></button>
                                                            </div>} */}
                                                        </div>
                                                        <small>
                                                            If this is set, we use this recipe instead of the default Extraction Scheduling Recipe for
                          </small>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group shadow-sm p-3 mb-4">
                                                        <label>
                                                            Data Source<span className=" text-danger"></span>
                                                        </label>
                                                        {this.props.id != 0 && <p>{Capitalize(this.state.data_source?.text)}&nbsp;Source #{this.state.data_source?.value}</p>}
                                                        {this.props.id == 0 && <div className="input-group ">
                                                            <input
                                                                autoComplete="off"
                                                                id="dataSourceName"
                                                                name="dataSourceName"
                                                                type="text"
                                                                onChange={this.handleChange}
                                                                value={this.state.dataSourceName}
                                                                className="form-control"
                                                            />
                                                            {this.renderDataSource()}
                                                        </div>}

                                                        <small>
                                                            This is automatically created. Once it is set, it can't be
                                                            changed.
                  </small>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group shadow-sm p-3">
                                                        <label>
                                                            Download Link Matcher
                            <OverlayTrigger
                                                                overlay={
                                                                    <Tooltip id="button-tooltip">
                                                                        Enter a POSIX-style regular expression here if
                                                                        you want the collection system to pull out
                                                                        embedded URLs that have reports we should
                                                                        download. Only applies to Inbound Emails for
                                                                        now. For more information, see &nbsp;
                                  <a href="https://www.postgresql.org/docs/9.4/functions-matching.html">
                                                                            Postgres docs.
                                  </a>
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <a className="btn btn-outline-dark border-0 btn-sm ml-1">
                                                                    <i className="fas fa-info-circle"></i>
                                                                </a>
                                                            </OverlayTrigger>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="download_link_matcher"
                                                            value={this.state.download_link_matcher}
                                                            onChange={this.handleChange}
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group shadow-sm p-3 mb-4">
                                                        <label>Inbound Email Addresses</label> &nbsp;
                          {/* {this.props.id != 0 && IsAuthorize([Role.TAM, Role.Dev]) && (

                                                            <button type="button" className="btn btn-success" onClick={this.handleShowInboundEmailAddresses}><i className="fas fa-plus" style={{ color: "white" }}></i></button>
                                                        )} */}

                                                        {IsAuthorize([Role.TAM, Role.Dev, Role.Support]) && <div>
                                                            {this.state.inbound_email_addresses?.map(
                                                                (item, index) => {
                                                                    return (
                                                                        <span key={item.value}>
                                                                            {item && <NavLink to={"/inbound-email-address/detail/" + item.value} className="badge badge-light m-1"><strong>{index + 1}:</strong> {item.text}</NavLink>}
                                                                        </span>
                                                                    );
                                                                }
                                                            )}
                                                        </div>}

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group shadow-sm p-3 mb-0">
                                                        <h6>Parameters </h6>
                                                        <Editor
                                                            StringData={this.state.params}
                                                            FormateType="yaml"
                                                            onChange={this.editorhandleParams}
                                                        />
                                                        {this.state.paramsError != "" && (
                                                            <div style={{ color: "red" }}>
                                                                {this.state.paramsError}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group shadow-sm p-3">
                                                        <h6>Internal Notes</h6>
                                                        <textarea
                                                            rows={15}
                                                            name="internal_notes"
                                                            value={this.state.internal_notes}
                                                            onChange={this.handleChange}
                                                            className="form-control"
                                                            style={{ height: "230px" }}
                                                        ></textarea>
                                                        <small>
                                                            <a href="https://sites.google.com/a/staq.com/staqnowledged/home/5---our-processes/quality-assurance/internal-notes">
                                                                Instructions on what to add to this field
                            </a>
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group shadow-sm p-3">
                                                        <h6>Load params from a previous state</h6>
                                                        <select
                                                            className="form-control  col-md-4"
                                                            name="paramsVersion"
                                                            value={this.state.paramsVersion}
                                                            onChange={this.handleChange}
                                                        >
                                                            <option value="0">
                                                                Current Version
                            </option>
                                                            {this.state.paramsVersionsList?.map((item: ParamsVersionsModel, index) => {
                                                                return (
                                                                    <option key={index} value={item.id}>
                                                                        {ConvertDateTime(item.created_at, "LLL")}&nbsp;UTC
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>

                                                        <small>
                                                            Provides authentication details and other control options for our custom connection extractor engine. For queries, submit on a single line to avoid an invalid sql statement error.
                          </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="text-center mt-3 mb-4">
                                <button type="button" className="btn btn-lg btn-default" onClick={this.handleCloseCustomConnection}>{ButtonType.Cancel}</button>
                                {!this.state.isSaving && <Fragment>
                                    <input type="submit" className="btn btn-lg btn-primary  ml-3" onClick={this.handleClick} value={ButtonType.Save} />
                                </Fragment>
                                }
                                <Loader loading={this.state.isSaving} marginBottom="0px" marginTop="8px" width="368px" ></Loader>
                            </div>
                        </div>
                    </Modal.Body>
                </form>
            </Fragment>
        );
    }
}
