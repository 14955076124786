import { HttpWrapper, AppConfig } from "../../../../../core";
import { ReportFiltersListResponseModel } from "../models/report.filters.list.response.model";
import { ReportFiltersDetailModel } from "../models/report.filters.detail.model";
import { PostExportModel } from "../../../../../shared/export/post.export.model";

export class ReportFiltersService {
    private wrapper: HttpWrapper;
    constructor() {
        this.wrapper = new HttpWrapper();
    }

    getReportFiltersList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
        return this.wrapper.post<ReportFiltersListResponseModel>(AppConfig.apiEndpoint + '/v1/report_filter/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }   

    getReportFiltersById(id: number) {
        return this.wrapper.get<ReportFiltersDetailModel>(AppConfig.apiEndpoint + '/v1/report_filter/' + id);
    }
    
    exportReportFilters(exportData?: PostExportModel) {
        return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/report_filter/export', exportData);
    }

}