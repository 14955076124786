import React, { Component, Fragment } from 'react'

import { ConfigurationsService } from './services';
import { HttpResponse } from '../../../core';
import { ShowSuccessMessage, HandleNotFoundResponse } from '../../../shared/helpers';
import { ButtonType } from '../../../shared/enums/button.enum';
import { Link } from "react-router-dom";
import { Loader } from '../../../shared/loaders';
import { ConfigurationsTabs } from './ConfigurationsTabs';
import { NotFoundResponseArea } from '../../../shared/enums';
import { AddUpdateConfigurationsModel } from './models';
import { DropdownItemModel, FlaggleItems } from '../../../shared/models';
import { CommonService } from '../../../shared/services/common.service';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Editor } from '../../../shared';

interface IState {
    Configurations?: AddUpdateConfigurationsModel;
    id?: number;
    name?: string;
    value?: string;
    nameError?: string;
    isShowtext?: string;
    isSave?: string;
    isSaving?: boolean;

}
export class ConfigurationsForm extends Component<any, IState> {
    private service: ConfigurationsService;
     commonService: CommonService;
    constructor(props) {
        super(props)
        this.service = new ConfigurationsService();
        this.commonService = new CommonService();
        this.state = this.initialState;
    }

    initialState: Partial<IState> = {
        name: "",
        value: "",
        id: 0,
        isShowtext: "New",
        isSave: "",
        isSaving: false,
        nameError: "",
    }

    componentDidMount() {
        if (this.props.match.params.id > 0) {
            this.setState({ id: this.props.match.params.id, isShowtext: "Edit" }, () => {
                this.loadData();
            });
        }
      
    }
   

     loadData() {
        this.service.editConfigurations(this.props.match.params.id)
            .then((res: HttpResponse<AddUpdateConfigurationsModel>) => {
                if (res && res.result) {
                    this.setState({
                        id: res.result?.id,
                        name: res.result?.name,
                        value: res.result?.value
                    }, () => {
                         
                    });
                   
                }
                
            })
            .catch(ex => {
                HandleNotFoundResponse(ex, NotFoundResponseArea.Configurations, this.props)
            });
            
    }


    updateData(data: AddUpdateConfigurationsModel) {
        this.setSavingFlag(true);
        this.service.updateConfigurations(data)
            .then((res: HttpResponse<AddUpdateConfigurationsModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        Configurations: res.result,
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Configuration successfully updated.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/configuration/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/configuration/configuration-form/' + this.state.id);
                            this.setState({ isShowtext: "Edit" })
                        }
                        if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                            this.setState(this.initialState);
                            this.props.history.push('/configuration/configuration-form/0');
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });
    }


    postData(data: AddUpdateConfigurationsModel) {
        this.setSavingFlag(true);
        this.service.postConfigurations(data)
            .then((res: HttpResponse<AddUpdateConfigurationsModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        Configurations: res.result,
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Configuration successfully created.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/configuration/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                            this.setState(this.initialState);
                            this.props.history.push('/configuration/configuration-form/0');
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/configuration/configuration-form/' + this.state.id);
                            this.setState({ isShowtext: "Edit" })
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
           
            });;
    }

    

    handleChange = (event: any) => {
        const isCheckbox = event.target.type === "checkbox";
        this.setState({
            [event.target.name]: isCheckbox
                ? event.target.checked
                : event.target.value
        })
    }

    validate = () => {
        let nameError = "";
       

        if (!this.state.name) {
            nameError = "Name can't be blank";
        }
        if (this.state.name?.match(' ') || this.state.name?.match('-')) {
            nameError = "Name must be Snake-cased, e.g. advanced_scheduling";
        }
       
        if (nameError ) {
            this.setState({ nameError: nameError})
            return false;
        }
        else {
            nameError = "";
           this.setState({ nameError: nameError })
            return true;
        }
    }

    handleSubmit = event => {
        event.preventDefault();
        const isValid = this.validate();
        let data: AddUpdateConfigurationsModel = {
            id: this.state.id,
            name: this.state.name,
            value: this.state.value,
        };
        if (isValid) {
            if (data.id == 0) {
                this.postData(data);
            }
            else {
                this.updateData(data);
            }
        }
    }

    handleClick = (event) => {
        this.setState({ isSave: event.target.value })
    }
    private setSavingFlag(saving: boolean) {
        this.setState({ isSaving: saving });
    }

    render() {
                return (
            <Fragment>
                <form onSubmit={this.handleSubmit}>
                    <input type="hidden" value={this.state.id} />
                    <div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <h5>{this.state.isShowtext} Configuration '{this.state.name}'</h5>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-xl-12">
                                <div className="card">
                                    <div className="card-body">
                                        {this.props.match.params.id != 0 && <ConfigurationsTabs id={this.props.match.params.id} url="/configuration/configuration-form/" />}
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group p-3 shadow-sm">
                                                    <label>Name<span className=" text-danger">*</span> <OverlayTrigger  overlay={
                                                    <Tooltip id="button-tooltip">
                                                    Must be a lowercase alphanumeric name spaced with underscores. (e.g. professional_default_user_limit).
                                                    </Tooltip>
                                                     } >
                                                    <a className="btn btn-outline-dark border-0 btn-sm ml-1">
                                                        <i className="fas fa-info-circle"></i>
                                                    </a>
                                                    </OverlayTrigger> </label>
                                                    <input type="text" maxLength={255} name="name" value={this.state.name} onChange={this.handleChange} className={!this.state.nameError ? 'form-control' : 'form-control  is-invalid'} />
                                                    <div className="invalid-feedback">
                                                        {this.state.nameError}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">    
                                            <div className="col-md-6">
                                                <div className="form-group p-3 shadow-sm">
                                                <label>Value </label>
                                                <textarea style={{height: '100px'}} className="form-control" name="value" value={this.state.value} onChange={this.handleChange} ></textarea>
                                                </div>
                                            </div>
                                             
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-3 mb-4">
                            <Link type="button" className="btn btn-lg btn-default" to="/configuration/list">{ButtonType.Cancel}</Link>
                            {!this.state.isSaving && <Fragment>
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" onClick={this.handleClick} value={ButtonType.Save} />
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndAddAnother} onClick={this.handleClick} />
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndEdit} onClick={this.handleClick} />
                            </Fragment>
                            }
                            <Loader loading={this.state.isSaving} marginBottom="0px" marginTop="8px" width="368px" ></Loader>
                        </div>
                    </div>
                </form>
            </Fragment>
        )
    }
}
