import React, { Component, Fragment} from "react";
import {InboundFilesService} from "./services/inbound.files.service"
import { HttpResponse } from '../../../../core';
import { Link, NavLink } from "react-router-dom";
import { HandleNotFoundResponse, ConvertDateTime, ShowSuccessMessage } from '../../../../shared/helpers';
import { NotFoundResponseArea } from '../../../../shared/enums';
import { InboundFilesDetailModel, CollectionMissionResponseListModel, CollectionMissionModel, CancelInboundFilesMission } from "./models";
import { PaginationRecordInfo } from "../../../../shared/PaginationRecordInfo";
import { Pagination } from "../../../../shared/Pagination";
import { FileUploadResponseModel } from "../../../../shared/models";
import { Loader } from "../../../../shared/loaders";
 
interface IState{
    result?:InboundFilesDetailModel;
    collectionMissionResponse: CollectionMissionResponseListModel;
    currentPage: number;
    pageSize: number;
    pageCount: number;
    totalItems: number;
    isLoading?: boolean;
}

export class InboundFilesDetail extends Component<any, IState> {
    private service: InboundFilesService;
     constructor(props){
         super(props);
         this.state = this.intialState;
         this.service = new InboundFilesService();
         this.pageChange = this.pageChange.bind(this);
         this.reProcessFile = this.reProcessFile.bind(this);
         this.cancelMission = this.cancelMission.bind(this);
     }
     intialState:IState = {
        collectionMissionResponse: {
            paging: {
              current_page: 0,
              page_count: 0,
              page_size: 0,
              total_items: 0,
            },
            records: [],
          },
          currentPage: 1,
         pageSize: 20,
         pageCount: 0,
         totalItems: 0,
        result: {
            id: 0,
            path: "",
            interface: "",
            action: "",
            custom_connection_id: 0,
            custom_connection: {
                text: "",
                value: 0
            },
            username: "",
            container: "",
            key: "",
            url: "",
            download_link: "",
            created_at: "",
            updated_at: ""
        },
        isLoading: false,
     }

     reProcessFile(id)
     {
       this.service
       .reprocessRunCollectionMission(id)
       .then((res: HttpResponse<FileUploadResponseModel>) => {
         if (res && res.result) {
           if (res.result.jid) {
             ShowSuccessMessage("Created Sidekiq Job ID  "+res.result.jid+ "  to process InboundFile "+id+ ". Watch for a new collection mission to be created shortly.");
             this.loadData();
           }
         }
       });
     }
   
    cancelMission(connection_id, id, index)
    {
      this.service
     .cancelCollectionMissionById(connection_id, id)
     .then((res: HttpResponse<CancelInboundFilesMission>) => {
      if (res && res.result) {
        ShowSuccessMessage(res.result.mission_count +" missions and "+res.result.task_count+" tasks for this connection have been canceled.");
        let data = this.state.collectionMissionResponse;
        data.records[index]['state']="failed";
        this.setState({collectionMissionResponse:data})
      }
     })    
    }



     componentDidMount(){
        this.loadData();
        this.getControlMission();
     }
     private setLoading(loading: boolean) {
      this.setState({ isLoading: loading });
  }
     loadData(){
      this.setLoading(true);
         this.service.getInboundFilesById(this.props.match.params.id)
         .then((res:HttpResponse<InboundFilesDetailModel>) =>{
          this.setLoading(false);
             if (res && res.result){
                 this.setState({
                     result: res.result
                 })
             }
         })
         .catch(ex => {
            HandleNotFoundResponse(ex,NotFoundResponseArea.InboundFiles, this.props)
         })
     }
     pageChange(page: number) {
        if (this.state.currentPage == page) return;
        this.setState({ currentPage: page }, () => {
          this.getControlMission();
        });
      }
      getControlMission() {
        this.service
          .getCollectionMissionList(
            this.props.match.params.id,
            this.state.currentPage,
            this.state.pageSize
          )
          .then((res: HttpResponse<CollectionMissionResponseListModel>) => {
            if (res && res.result) {
              this.setState(
                {
                  collectionMissionResponse: res.result,
                },
                () => {
                 
                }
              );
            }
          })
          .catch((ex) => {
            HandleNotFoundResponse(
              ex,
              NotFoundResponseArea.CustomConnection,
              this.props
            );
          });
      }

     render() {
         return (
             <Fragment>
                 <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5>Details for Inbound Files 'InboundFile #{this.state.result?.id}'</h5>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                            {!this.state.isLoading &&  <>  
                                <h5 className="mb-3">Basic Info</h5>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Path</b>
                                            </div>
                                            <div className="card-body">
                                             <a href={this.state.result?.download_link} target="_blank">  {this.state.result?.path}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Interface</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.interface}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Custom connection</b>
                                            </div>
                                            <div className="card-body">
                                             <Link to={"/custom-connection/detail/"+ this.state.result?.custom_connection.value} > {this.state.result?.custom_connection.text} </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    
                                    <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Action</b>
                                            </div>
                                            <div className="card-body">
                                                {/* {this.state.result?.account && <NavLink to={"/account/detail/" + this.state.result?.account.value}>{this.state.result?.account.text}</NavLink>} */}
                                                {this.state.result?.action}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Username</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.username}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Actions</b>
                                            </div>
                                            <div className="card-body">
                                                <button  onClick={()=>this.reProcessFile(this.props.match.params.id)} className="btn btn-sm  btn-info"> Reprocess  </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Container</b>
                                            </div>
                                            <div className="card-body">
                                               {this.state.result?.container}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Url</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.url}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Created at</b>
                                            </div>
                                            <div className="card-body">
                                            {ConvertDateTime(this.state.result?.created_at, 'LLL')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Updated at</b>
                                            </div>
                                            <div className="card-body">
                                            {ConvertDateTime(this.state.result?.updated_at, 'LLL')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.collectionMissionResponse.records.length > 0 && (
                  <div className="row">
                    <div className="col-md-12  mt-3">
                      <h6>Recent Collection Missions</h6>
                    </div>
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table table-md table-striped">
                          <thead>
                            <tr>
                              <th>Cancel</th>     
                              <th>#</th>
                              <th>Created At</th>
                              <th>Interval of Interest</th>
                              <th>Scheduled At</th>
                              <th>State</th>
                              <th># Datapoints</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.collectionMissionResponse.records?.map(
                              (item: CollectionMissionModel,index) => {
                                return (
                                  <tr key={item.id}>
                                     {(item.state=="new" || item.state=="in_progress") &&  <td> <button onClick={()=>this.cancelMission(item.connection_id, item.id,index)}
                                className="btn btn-sm btn-danger">
                                Cancel
                              </button></td>}
                              {(item.state!="new" && item.state!="in_progress") &&  <td></td>}
                                    <td>
                                      <a href="#">{item.id}</a>
                                    </td>
                                    <td>
                                      {ConvertDateTime(item.created_at, "LLL")}
                                    </td>
                                    <td>{ConvertDateTime(item.mission_start_at, "l")}-{ConvertDateTime(item.mission_end_at, "l")}(UTC)</td>
                                    <td>
                                      {item.scheduled_at.toString()<= Date.now().toString()?"ago":"from now"}
                                    </td>
                                    <td>
                                      <a href="#"  style={{color:item.state=="failure"?"red":""}}>{item.state}</a>
                                    </td>
                                    <td>{item.state=="success"?item.datapoint_count:"-"}</td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="d-flex justify-content-between">
                    <PaginationRecordInfo
                      currentPage={this.state.currentPage}
                      totalRecords={
                        this.state.collectionMissionResponse?.paging
                          .total_items
                      }
                      pageSize={this.state.pageSize}
                    />
                    <Pagination
                      currentPage={this.state.currentPage}
                      pageCount={
                        this.state.collectionMissionResponse?.paging.page_count
                      }
                      onChangePage={this.pageChange}
                    />
                  </div>
                </div>
                </>}                 
                        {this.state.isLoading && <Loader loading={this.state.isLoading} />}
                            </div>
                        </div>
                    </div>
                </div>
             </Fragment>
         )
     }
}