export enum FieldTypeEnum
{
    big_integer="big_integer",
    boolean="boolean",
    currency="currency",
    date="date",
    float="float",
    integer="integer",
    raw="raw",
    string="string",
    text="text",
    time="time",
    url="url"
}