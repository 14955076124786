import { HttpWrapper, AppConfig } from '../../../../../../core'
import { ExtractorListResponseModel,ExtractorDetailModel,PostExtractorModel,EditExtractorModel } from '../models';
import { PostExportModel } from "../../../../../../shared/export/post.export.model";

export class ExtractorService {
    private wrapper: HttpWrapper;

    constructor() {
      this.wrapper = new HttpWrapper();
    }
  
    getList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
      return this.wrapper.post<ExtractorListResponseModel>(AppConfig.apiEndpoint + '/v1/extractors/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }
  
    getExtractorById(id: number) {
      return this.wrapper.get<ExtractorDetailModel>(AppConfig.apiEndpoint + '/v1/extractors/' + id);
    }
  
    postExtractor(payload: PostExtractorModel) {
      return this.wrapper.post<PostExtractorModel>(AppConfig.apiEndpoint + '/v1/extractors', payload);
    }
  
    editExtractor(id?: number) {
      return this.wrapper.get<EditExtractorModel>(AppConfig.apiEndpoint + '/v1/extractors/edit/' + id);
    }

    // getLinkedResourcesById(id: number) {
    //     return this.wrapper.get<LinkResourcesResponseModel>(AppConfig.apiEndpoint + '/v1/extractors/' + id + '/linked_resources');
    //   }
  
    // deleteExtractorById(id: number) {
    //   return this.wrapper.delete<DeleteResponseModel>(AppConfig.apiEndpoint + '/v1/extractors/' + id);
    // }
  
    updateExtractor(extractor: PostExtractorModel) {
      return this.wrapper.put<PostExtractorModel>(AppConfig.apiEndpoint + '/v1/extractors/' + extractor.id, extractor);
    }
    
    exportExtractor(exportData?: PostExportModel) {
      return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/extractors/export', exportData);
    }
  
  
  }