import React, { Component,Fragment } from 'react'
import {Cell} from 'react-sticky-table';

interface IProps{
    columnName:string,
    sortOrder:boolean,
    onClick?:Function,
    currentColumn:string,
    displayName?:string
}
export  class Sorting extends Component<IProps> {
    

    constructor(props: IProps) {
        super(props);
        
    }

    sortList(sortColumn:string,sortOrder:boolean)
    {
        
       if(this.props.onClick)
       {  
           this.props.onClick(sortColumn.toLowerCase(),sortOrder)
       }
    }


    render() {     
        return (
                         <Fragment>
                             {this.props.currentColumn.toLowerCase()!==this.props.columnName.toLowerCase()?<Cell className="sorting_desc" onClick={()=>this.sortList(this.props.columnName,false)}>{this.props.displayName}</Cell>
                             :<Cell className={this.props.sortOrder==false?"sorting_asc":"sorting_desc"} onClick={()=>this.sortList(this.props.columnName,!this.props.sortOrder)}>{this.props.displayName}</Cell>
                             }
                        </Fragment>
        )
    }
}
