import React, { Component, Fragment } from 'react'
import { ReportRunScheduleModel, EditReportRunScheduleModel } from './models'
import { DropdownItemModel } from '../../../../shared/models'
import { ReportRunScheduleService } from './service/report.run.schedule.service';
import { CommonService } from '../../../../shared/services/common.service';
import { HttpResponse } from '../../../../core';
import { ButtonType, NotFoundResponseArea } from '../../../../shared/enums';
import { ReportRunSchedulesTabs } from '.';
import { Link } from "react-router-dom";
import { Loader } from '../../../../shared/loaders';
import { Modal } from 'react-bootstrap';
import { ReportFormPopup } from '..';
import { IsAuthorize } from '../../../../shared/authorization/check-access';
import { Role } from '../../../../shared/authorization/enums';
import { ShowSuccessMessage, ConvertDateTime, GetDaysList, HandleNotFoundResponse, GetReportDaysList } from '../../../../shared/helpers';

interface IState {
    reportRunSchedule?: ReportRunScheduleModel,
    // editReportRunSchedule?: EditReportRunScheduleModel,
    id?: number;
    report_id?: number;
    runAt?: string;
    dayOfWeek?: string;
    timeRange?: string;
    priority?: number;
    triggerDependencies?: boolean
    reportList?: DropdownItemModel[],
    reportName?: string, 
    setHours?:string;
    setMin?:string;
    runAtError?: string,
    reportError?: string,
    isShowtext?: string;
    isSave?: string;
    isSaving?: boolean;
    showReportForm?: boolean;
    isNew?:boolean;
    isChildUpdate?:boolean;


}
export class ReportRunScheduleForm extends Component<any, IState> {
    //declaring service type
    private service: ReportRunScheduleService;
    private commonService: CommonService;

    private roleNetworkCall: any;
    private NetworkCall: any;

    constructor(props) {
        super(props)
        this.service = new ReportRunScheduleService();
        this.commonService = new CommonService();
        this.state = this.initialState;
        this.updateReport = this.updateReport.bind(this);

    }

    initialState: Partial<IState> = {
        reportRunSchedule: {
            id: 0,
            report_id: 0,
            priority: 0,
            day_of_week: null,
            run_at: "",
            time_range: "",
            trigger_dependencies: false
        },
        id: 0,
        report_id: 0,
        priority: 30,
        dayOfWeek:"",
        runAt: "",
        timeRange: "",
        setHours:"",
        setMin:"",
        triggerDependencies: true,
        reportList: [],
        reportName: "",
        reportError: "",
        isShowtext: "New",
        isSave: "",
        isSaving: false
    }

    updateReport(isChildUpdate,name,id)
    {
      this.setState({isChildUpdate:isChildUpdate,reportName:name,report_id:id})
    }

    handleClose = () => {
        this.setState({ showReportForm: false }, () => {
        });
    }

    handleShowReport = () => {        
        this.setState({ showReportForm: true, isNew:true }, () => {

        });
    }

    handleShowEditReport = () => {        
        this.setState({ showReportForm: true, isNew:false }, () => {
 
        });
    }

    componentDidMount() {
        if (this.props.match.params.id > 0) {
            this.setState({ id: this.props.match.params.id, isShowtext: "Edit" }, () => {
                this.loadData();
            });
        }
        document.addEventListener('mousedown', this.handleClickOutside)
    }
    
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (event.path[0].id !== 'reportName' && event.path[0].id !== 'report' && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight) {
            this.setState({
                reportList: []
            }, () => {

            })

        }

    }

    loadData() {
        this.service.editReportRunSchedule(this.props.match.params.id)
            .then((res: HttpResponse<EditReportRunScheduleModel>) => {
                if (res && res.result) {
                    this.setState({
                        triggerDependencies: res.result?.trigger_dependencies,
                        reportName: res.result?.report.text,
                        report_id: res.result?.report.value,
                        id: res.result?.id,
                        priority: res.result?.priority,
                        dayOfWeek: res.result?.day_of_week==null?"":res.result?.day_of_week,
                        runAt: res.result?.run_at,
                        timeRange: res.result?.time_range
                    }, () => {
                        this.setHourMin(res.result?.run_at)
                     });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.ReportRunSchedules,this.props) 
            });
    }
    setHourMin = (runAt)=>{
      let hrsAndMins = runAt.split(":")
      this.setState({
        setHours: hrsAndMins[0],
        setMin: hrsAndMins[1],
      })
    }

    handleChange = (event: any) => {
        if (event.target.name == "reportName") {
            this.onReportChange(event);
        }

        else {
            const isCheckbox = event.target.type === "checkbox";
            this.setState({
                [event.target.name]: isCheckbox
                    ? event.target.checked
                    : event.target.value
            })
        }
       
        if (event.target.name === "setHours" && event.target.name === "setMin" ) {
            this.setState({ runAtError: "" })
        }

    }


    validate = () => {
        let runAtError = "";
        let reportError = "";
        if (this.state.setHours ==""  || this.state.setMin =="" ) {
            runAtError = "Hour/Minute can't be blank";
        }
        if (!this.state.reportName) {
            reportError = "Report can't be blank";
        }

        if (runAtError || reportError) {
            this.setState({ runAtError: runAtError, reportError: reportError })
            return false;
        }

        if (!runAtError || !reportError) {
            runAtError = "";
            reportError = "";
            this.setState({ runAtError: runAtError, reportError: reportError })
            return true;
        }


    }

    handleSubmit = event => {
        event.preventDefault();
        if(!this.state.isChildUpdate)
        {
        const isValid = this.validate();
        let installedApplicationData: ReportRunScheduleModel = {
            priority: this.state.priority,
            report_id: this.state.report_id,
            id: this.state.id,
            day_of_week: this.state.dayOfWeek==""?null:this.state.dayOfWeek,
            run_at: this.state.setHours + ':' + this.state.setMin,
            time_range: this.state.timeRange,
            trigger_dependencies: this.state.triggerDependencies
        };

        if (isValid) {
            if (installedApplicationData.id === 0) {
                this.postData(installedApplicationData);
            }
            else {
                this.updateData(installedApplicationData)
            }
        }
    }
    }

    postData(reportRunSchedule: ReportRunScheduleModel) {
        this.setSavingFlag(true);
        this.service.postReportRunSchedule(reportRunSchedule)
            .then((res: HttpResponse<ReportRunScheduleModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Report run schedule successfully created.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/report-run-schedule/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                            this.setState(this.initialState);
                            this.props.history.push('/report-run-schedule/report-run-schedule-form/0');
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/report-run-schedule/report-run-schedule-form/' + this.state.id);
                            this.setState({ isShowtext: "Edit" })
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });;
    }

    updateData(reportRunSchedule: ReportRunScheduleModel) {
        this.setSavingFlag(true);
        this.service.updateReportRunSchedule(reportRunSchedule)
            .then((res: HttpResponse<ReportRunScheduleModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Report run schedule successfully updated.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.props.history.push('/report-run-schedule/detail/'+ this.state.id);
                            this.setState(this.initialState);
                        }
                        if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                            this.setState(this.initialState);
                            this.props.history.push('/report-run-schedule/report-run-schedule-form/0');
                        }
                        if (this.state.isSave === ButtonType.SaveAndEdit) {
                            this.props.history.push('/report-run-schedule/report-run-schedule-form/' + this.state.id);
                            this.setState({ isShowtext: "Edit" })
                        }
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });
    }

    handleClick = (event) => {
        this.setState({ isSave: event.target.value,isChildUpdate:false })
    }
    private setSavingFlag(saving: boolean) {
        this.setState({ isSaving: saving });
    }

    //*************** * Reports Start************************* //
    onReportChange = (e) => {
        const value = e.target.value;
        if (value.length > 0) {
            if (this.NetworkCall) {
                clearTimeout(this.NetworkCall);
            }
            this.NetworkCall = setTimeout(() => {
                this.getReports(value)
            }, 600)
        }
        this.setState({
            reportName: value,
            reportError: ""
        }, () => {
            // this.validate();
        })
    }

    selectedReport(item) {
        this.setState({
            report_id: item.value,
            reportName: item.text,
            reportList: []
        })
    }

    private getReports(text: string) {
        this.commonService.getReportsByName(text)
            .then((res: HttpResponse<DropdownItemModel[]>) => {
                if (res) {
                    this.setState({
                        reportList: res.result
                    });
                }
            });

    }

    renderReport = () => {
        if (this.state.reportList?.length === 0) {
            return null;
        }
        return (
            <ul className="list-unstyled auto-suggest">
                {
                    this.state.reportList?.map((item, index) => (<li id="report" key={index} onClick={() => this.selectedReport(item)}>{item.text}</li>))
                }
            </ul>
        );
    }
    //*************** * Reports End************************* //

    loadTime = (maxVal) => {
        let timeValue:string[] = [];
        for(let x = 0; x < maxVal; x++) {
            if(x < 10) timeValue.push('0' + x)
            else timeValue.push(x.toString())
        }
       return timeValue.map((x) => {return(<option key={x} value={x}>{x}</option>)});
    }

    render() {

        return (
            <Fragment>
                <form onSubmit={this.handleSubmit}>
                    <input type="hidden" value={this.state.id} />
                    <div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <h5>{this.state.isShowtext} Report Run Schedule {this.state.id != 0 && <span> '#{this.state.id}'</span>}</h5>
                        </div>
                        <Modal backdrop='static' keyboard={false} size="lg" show={this.state.showReportForm} onHide={this.handleClose}>
                            <ReportFormPopup  updateReport={this.updateReport}  handleClose={this.handleClose} isNew={this.state.isNew} id={this.state?.report_id}/>
                        </Modal>
                        <div className="row">
                            <div className="col-md-12 col-xl-12">
                                <div className="card">
                                    <div className="card-body">
                                        {this.props.match.params.id != 0 && <ReportRunSchedulesTabs id={this.props.match.params.id} url="/report-run-schedule/edit/" />}
                                        <div className="row">
                                            <div className="col-xl-4 col-md-4">
                                                 <div className="form-group">
                                                    <label>Report<span className="text-danger">*</span></label>
                                                    <div className="input-group mb-3">
                                                        <input autoComplete="off" placeholder="Search" id="reportName" name="reportName" type="text" onChange={this.handleChange} value={this.state.reportName}
                                                            className={!this.state.reportError ? 'form-control' : 'form-control  is-invalid'} />
                                                        {this.renderReport()}                                                        
                                                        {IsAuthorize([Role.TAM, Role.Dev, Role.Support])  &&<div className="input-group-append">                                                            
                                                        <button type="button"  className="btn btn-primary" disabled={this.state.report_id==0}   onClick={this.handleShowEditReport}><i className="far fa-edit"></i></button> 
                                                        </div>}
                                                        <div className="invalid-feedback" >
                                                            {this.state.reportError}
                                                        </div>
                                                    </div>
                                                </div>  
                                            </div>
                                            <div className="col-xl-4 col-md-4">
                                                <div className="form-group">
                                                    <label>Run At<span className=" text-danger">*</span></label>
                                                    <div className="input-group">
                                                            <select className={!this.state.runAtError ? 'form-control rounded' : 'rounded form-control  is-invalid'} name="setHours" value={this.state.setHours} onChange={this.handleChange}>
                                                                <option value="">HH</option>
                                                                {this.loadTime(24)}
                                                            </select>
                                                            <span className="p-2"> : </span>
                                                            <select className={!this.state.runAtError ? 'form-control rounded' : 'rounded form-control  is-invalid'} name="setMin" value={this.state.setMin} onChange={this.handleChange}>
                                                                <option value="">MM</option>
                                                                {this.loadTime(60)}
                                                            </select>
                                                        <div className="invalid-feedback d-block">
                                                            {this.state.runAtError}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-md-4">
                                                <div className="form-group">
                                                    <label>Day Of Week</label>
                                                    <select className="form-control" name="dayOfWeek" value={this.state.dayOfWeek} onChange={this.handleChange}>
                                                        {GetReportDaysList.map((item: any, index) => {
                                                            return (
                                                                <option key={index} value={item.value}>{item.text}</option>

                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-4 col-md-4">
                                                <div className="form-group">
                                                    <label>Time Range</label>
                                                    <input type="text" name="timeRange" value={this.state.timeRange} onChange={this.handleChange} className='form-control' />
                                                </div>
                                            </div>

                                            <div className="col-xl-4 col-md-4">
                                                <div className="form-group">
                                                    <label>Priority</label>
                                                    <input type="number" name="priority" value={this.state.priority} onChange={this.handleChange} className='form-control' />
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-md-4">
                                                <div className="form-group">
                                                    <label>Trigger Dependencies</label>
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" name="triggerDependencies" checked={this.state.triggerDependencies} onChange={this.handleChange} className="custom-control-input" id="triggerDependencies" />
                                                        <label className="custom-control-label" htmlFor="triggerDependencies"> Check to Activate Trigger Dependencies</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-3 mb-4">
                            <Link type="button" className="btn btn-lg btn-default" to="/report-run-schedule/list">{ButtonType.Cancel}</Link>
                            {!this.state.isSaving && <Fragment>
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" onClick={this.handleClick} value={ButtonType.Save} />
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndAddAnother} onClick={this.handleClick} />
                                <input type="submit" className="btn btn-lg btn-primary  ml-3" value={ButtonType.SaveAndEdit} onClick={this.handleClick} />
                            </Fragment>
                            }
                            <Loader loading={this.state.isSaving} marginBottom="0px" marginTop="8px" width="368px" ></Loader>
                        </div>
                    </div>
                </form>
            </Fragment>
        )
    }
}
