import { HttpWrapper, AppConfig } from "../../../../../core";
import { PostExportModel } from "../../../../../shared/export/post.export.model";
import { TagLocationCategoriesListResponseModel, TagLocationCategoriesDetailModel, AddUpdateTagLocationCategoriesModel, TagLocationCategoriesDeleteResponseModel, TagLocationCategoriesDeleteModel } from "../models";



export class TagLocationCategoriesService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getTagLocationCategoriesList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
    return this.wrapper.post<TagLocationCategoriesListResponseModel>(AppConfig.apiEndpoint + '/v1/tag_location_categories/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
  }

  getTagLocationCategoriesById(id: number) {
    return this.wrapper.get<TagLocationCategoriesDetailModel>(AppConfig.apiEndpoint + '/v1/tag_location_categories/' + id);
  }  

  postTagLocationCategories(TagLocationCategories: AddUpdateTagLocationCategoriesModel) {
    return this.wrapper.post<AddUpdateTagLocationCategoriesModel>(AppConfig.apiEndpoint + '/v1/tag_location_categories', TagLocationCategories);
}

editTagLocationCategories(id?: number) {
    return this.wrapper.get<AddUpdateTagLocationCategoriesModel>(AppConfig.apiEndpoint + '/v1/tag_location_categories/edit/' + id);
}

updateTagLocationCategories(TagLocationCategories: AddUpdateTagLocationCategoriesModel) {
    return this.wrapper.put<AddUpdateTagLocationCategoriesModel>(AppConfig.apiEndpoint + '/v1/tag_location_categories/' + TagLocationCategories.id, TagLocationCategories);
}

getLinkedResourcesById(id: number) {
  return this.wrapper.get<TagLocationCategoriesDeleteModel>(AppConfig.apiEndpoint + '/v1/tag_location_categories/' + id+'/linked_resources');
}

deleteRecordById(id: number) {
  return this.wrapper.delete<TagLocationCategoriesDeleteResponseModel>(AppConfig.apiEndpoint + '/v1/tag_location_categories/' + id);
}

  exportTagLocationCategories(exportData?: PostExportModel) {
    return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/tag_location_categories/export', exportData);
  }


} 