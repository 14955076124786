import React, { Component, Fragment } from 'react'
import { DeleteResponseModel } from '../../../shared/models'
import { HttpResponse } from '../../../core';
import { ShowSuccessMessage, ConvertDateTime, HandleNotFoundResponse } from '../../../shared/helpers';
import { NavLink, Link } from "react-router-dom";
import { ButtonType, NotFoundResponseArea } from '../../../shared/enums';
import { ApplicationsService } from './services/applications.service';
import { ApplicationsLinkResourcesResponseModel } from './models'
import { ApplicationsTabs } from './ApplicationsTabs';


interface IState {
    result?: ApplicationsLinkResourcesResponseModel;
    deleteResult?: DeleteResponseModel;
    isDoubleleClick?: boolean;
    buttonText?:string;
    isShowButton?:boolean;
    isRecords?:boolean;
    isShowCommonText?:boolean;
}
export  class ApplicationsDelete extends Component<any, IState> {
    private service: ApplicationsService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new ApplicationsService();
        this.deleteRecord = this.deleteRecord.bind(this);
    }

    initialState: Partial<IState> = {
        result: {
            id: 0,
            name: "",
            deleted:false,
            installed_report_applications:[]
        },
        deleteResult: { isdeleted: false },
        isDoubleleClick: false,
        buttonText:"",
        isShowButton:true,
        isRecords:false,
        isShowCommonText:false

    }
    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.service.getLinkedResourcesById(this.props.match.params.id)
            .then((res: HttpResponse<ApplicationsLinkResourcesResponseModel>) => {
                if (res && res.result) {
                    this.setState({
                        result: res.result,
                        buttonText:"Yes, I'm Sure" , 
                        isRecords:res.result.installed_report_applications.length==0?false:true ,
                        isShowCommonText:res.result.deleted?false:true                     
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.CustomConnection,this.props)
            });
    }


    deleteRecord() {        
        if (!this.state.isDoubleleClick) {
            this.setState({ isDoubleleClick: true,buttonText:"deleting..." })
            this.service.deleteRecordById(this.props.match.params.id)
                .then((res: HttpResponse<DeleteResponseModel>) => {
                    if (res && res.result) {                        
                        this.setState({
                            deleteResult: res.result,
                            buttonText:"",
                            isShowButton:false
                        }, () => {
                            if (this.state.deleteResult?.isdeleted) {
                                ShowSuccessMessage("Application Successfully Deleted.");
                                this.props.history.push('/application/list');
                            }
                        });
                    }
                });

        }
    }
    render() {
        return (
            <Fragment>
            <div className="d-flex justify-content-between align-items-center mb-2">
                {this.state.result?.name &&<h5>Delete Application '{this.state.result?.name}'</h5>}
            </div>
            {!this.state.result?.deleted &&<div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <ApplicationsTabs id={this.props.match.params.id} url="/application/delete/" />
                            <div className="row">
                                <div className="col-md-12">
                                {/* {this.state?.isShowCommonText &&  <p className=" text-danger"><i className="fas fa-exclamation-triangle text1"></i> </p>} */}
                                {this.state?.isShowCommonText &&  <div className="alert font-weight-bold  alert-light"> <i className="fas fa-exclamation-circle text1"></i> Are you sure you want to delete this application “<b>{this.state.result?.name}</b>”? The following related items may be deleted or orphaned:</div>}
                                </div>
                                <div className="col-md-12">
                                 <p><span className="badge badge-light">Application Name:</span> <b><Link to={"/application/detail/" + this.state.result?.id} >{this.state.result?.name}</Link> </b></p>
                                 <ul>{this.state.result?.installed_report_applications.map((item, index) => {
                                        return (
                                                <li key={index}>Installed report applications: <Link to={"/installed-report-application/detail/" + item.value} >{item.text}</Link></li>
                                        )
                                 })}
                                 </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {this.state.isShowButton && this.state.isRecords &&  this.state.buttonText &&   } */}
                   <div className="row mb-4">
                        <div className="mx-auto">
                          <button className="btn btn-lg btn-danger ml-3" disabled={this.state.isDoubleleClick}onClick={() => {if(window.confirm('Are you sure to delete this record?')){this.deleteRecord()};}}>{this.state.buttonText}</button>
                          {/* <button className="btn btn-lg btn-danger ml-3" disabled={this.state.isDoubleleClick} onClick={this.deleteRecord}> {ButtonType.Delete}</button> */}
                                <NavLink type="button" className="btn btn-lg btn-default ml-3" to="/application/list">{ButtonType.Cancel}</NavLink>
                        </div>
                    </div>
                     
                </div>

            </div>}

        </Fragment>
        )
    }
}
