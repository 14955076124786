import React, { Component } from "react";
import { IExportColumnModel, IExporSectionColumn } from "./models";

export function CheckBox({ name, value, tick, onCheck }) {
    return (
        <div className="custom-control custom-checkbox">
            <input
                name={name}
                className="custom-control-input"
                id={"check" + value}
                type="checkbox"
                value={value}
                checked={tick || false}
                onChange={onCheck}
            />
            <label htmlFor={"check" + value} className="custom-control-label">
                {name}
            </label>
        </div>

    );
}

export function CheckBoxList({ options, isCheckedAll, onCheck }) {
    const checkBoxOptions = (
        <div className="checkbox-list">
            <div className="row">
                {options.map((option, index) => {
                    return (
                        <div className="col-md-4" key={index}>
                            <CheckBox name={option.name} value={option.value} tick={option.checked} onCheck={(e) => onCheck(option.value, e.target.checked)} />
                        </div>
                    );
                })}
            </div>
        </div>
    );

    return (
        <div>
            <div className="text-info font-weight-bold selectAll-box"> <CheckBox name="Select ALL" value="Select ALL" tick={isCheckedAll} onCheck={(e) => onCheck('all', e.target.checked)} /></div>
            {checkBoxOptions}
        </div>


    );
}

export function ExportColumnCheckBoxList({ options, isCheckedAll, onCheck }) {
    const checkBoxOptions = (
        <div className="checkbox-list">

            {options.map((option: IExportColumnModel, index) => {
                return (
                    <div className="row" key={index}>
                        <div className="col-12 bg-light-c mt-4 px-4 py-1">
                            <b onClick={(e) => onCheck(option.value + '::all', !option.isAllChecked)}>{option.name}</b>
                        </div>
                        {option.columns.map((item: IExporSectionColumn, index1) => {
                            return (<div className="col-md-4" key={index1}>
                                <CheckBox name={item.name} value={item.value + "_" + option.value} tick={item.checked} onCheck={(e) => onCheck(option.value + '::' + item.value, e.target.checked)} />
                            </div>
                            );
                        })
                        }
                    </div>
                );
            })}

        </div>
    );

    return (
        <div>
            <div className="text-Primary font-weight-bold selectAll-box" >   <CheckBox name="Select ALL" value="Select ALL" tick={isCheckedAll} onCheck={(e) => onCheck('all', e.target.checked)} /></div>
            {checkBoxOptions}
        </div>


    );
}