import React, { Component, Fragment } from 'react'
import { DataEngineDetailModel } from './models/data.engine.detail.model'
import { DataEngineService } from './services/data.engines.service';
import { HttpResponse } from '../../../../core';
import { HandleNotFoundResponse, ConvertDateTime } from '../../../../shared/helpers';
import { NotFoundResponseArea } from '../../../../shared/enums';
import { NavLink } from "react-router-dom";
import { Loader } from '../../../../shared/loaders';

interface IState {
    result?: DataEngineDetailModel;
    isLoading?: boolean;
}
export  class DataEgineDetail extends Component<any, IState> {
    private service: DataEngineService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new DataEngineService();
    }

    initialState: Partial<IState> = {
        result: {             
            id: 0,            
            created_at:null,
            database_engine:{
                 text:"",
                 value:0
            },
            database_engine_id:0,
            description:"",
            engine_type:"",
            max_concurrency:0,
            minimum_priority_override:0,
            name:"",
            updated_at:null
        },
        isLoading: false,

    }

    componentDidMount() { 
        this.loadData();
    }
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getDataEngineById(this.props.match.params.id)
            .then((res: HttpResponse<DataEngineDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.DataEngine,this.props)
            });
    }
    render() {
        return (
            <Fragment>               
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Details for Data Engine '{this.state.result?.name==""?"-":this.state.result?.name}'</h5>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            {/* <CustomerFaviconTabs id={this.props.match.params.id} url="/customer-favicon/detail/" /> */}
                            {!this.state.isLoading &&  <>  
                            <h5 className="mb-3">Basic Info</h5>
                            <div className="row">
                            {this.state.result?.name &&    <div className="col-4">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Name</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.name}
                                        </div>
                                    </div>
                                </div>}
                                {this.state.result?.engine_type &&  <div className="col-4">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Engine Type</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.engine_type}
                                        </div>
                                    </div>
                                </div>}
                               {this.state.result?.description && <div className="col-4">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Description</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.description}
                                        </div>
                                    </div>
                                </div>}
                                {this.state.result?.max_concurrency!=0 && <div className="col-4">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Max Concurrency</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.max_concurrency}
                                        </div>
                                    </div>
                                </div>}
                            </div>

                            
                            
                            <div className="row">                                
                               {this.state.result?.database_engine.value!=0 && <div className="col-4">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Database Engine</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.database_engine && <NavLink to={"/database-engine/detail/" + this.state.result?.database_engine.value}>{this.state.result?.database_engine.text}</NavLink>}
                                        </div>
                                    </div>
                                </div>}
                                {this.state.result?.created_at && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Created At</b>
                                            </div>
                                            <div className="card-body">{ConvertDateTime(this.state.result?.created_at, 'LLL')}</div>
                                        </div>
                                    </div>}
                                    {this.state.result?.updated_at && <div className="col-4">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Updated At</b>
                                            </div>
                                            <div className="card-body">{ConvertDateTime(this.state.result?.updated_at, 'LLL')}</div>
                                        </div>
                                    </div>}
                            </div>
                            </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}     
                        </div>
                    </div>
                </div>
            </div>            
        </Fragment>
        )
    }
}
 