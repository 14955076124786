import React, { Component, Fragment } from 'react'
import { AccountInstalledAppsModel, InstalledReportApplicationsListModel } from './models'
import { AccountService } from './services/account.service';
import { HttpResponse } from '../../core';
import AccountTabs from './AccountTabs';
import { ConvertDateTime, HandleNotFoundResponse } from '../../shared/helpers';
import { NavLink } from "react-router-dom";
import { NotFoundResponseArea } from '../../shared/enums';

interface IState {
    result?: AccountInstalledAppsModel;
}
export class AccountInstalledApps extends Component<any, IState> {
    private service: AccountService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new AccountService();
    }
    initialState: Partial<IState> = {
        result: {
            id: 0,
            installed_app_billable:0,
            installed_apps:[]

        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.service.getAccountInstalledAppsById(this.props.match.params.id)
            .then((res: HttpResponse<AccountInstalledAppsModel>) => {
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    },()=>{
                        
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.Accounts,this.props) 
            });
    }
    render() {
        return (
            <Fragment>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5>Installed Apps</h5>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <AccountTabs id={this.props.match.params.id} url="/account/installed-apps/" />
                                <h5 className="mb-3">Basic Info</h5>
                                <div className="row">
                                    <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Billable Installed Applications</b>
                                            </div>
                                            <div className="card-body">
                                               {this.state.result?.installed_app_billable}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-12 mt-4">
                                        <h5>Installed Report Applications/List</h5>
                                        <div className="table-responsive">
                                            <table className="table table table-sm table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Report</th>
                                                        <th scope="col">Billable</th>
                                                        <th scope="col">Application</th>
                                                        <th scope="col">Created At</th>

                                                    </tr>
                                                </thead>
                                                {this.state.result?.installed_apps.length != 0 && <tbody>
                                                    {this.state.result?.installed_apps.map((item: InstalledReportApplicationsListModel, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                {item.name && <td><NavLink to={"/installed-report-application/detail/" + item.id}>{item.name}</NavLink></td>}
                                                                {!item.name && <td></td>}

                                                                {item.report && <td><NavLink to={"/report/detail/" + item.report.value}>{item.report.text}</NavLink></td>}
                                                                {!item.report && <td></td>}

                                                                <td>{item.billable && <i className="fas fa-check text-success text1" title="&#x2713;" ></i>}
                                                                    {!item.billable && <i className="fas fa-times text-danger text1" title="&#x2718;"></i>} </td>

                                                                                                                                
                                                                {item.application && <td><NavLink to={"/application/detail/" + item.application.value}>{item.application.text}</NavLink></td>}
                                                                {!item.report && <td></td>}
                                                                
                                                                <td>{ConvertDateTime(item.created_at, 'LLL')}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
