import { HttpWrapper, AppConfig } from '../../../../../core'
import { LinkResourcesResponseModel, FileUploadModel, FileUploadResponseModel } from '../../../../../shared/models';
import { PostExportModel } from "../../../../../shared/export/post.export.model";
import { DataEngineListResponseModel, DataEngineDetailModel } from '../models';
export class DataEngineService {
    private wrapper: HttpWrapper;
    constructor() {
      this.wrapper = new HttpWrapper();
    }
  
    getDataEngineList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
      return this.wrapper.post<DataEngineListResponseModel>(AppConfig.apiEndpoint + '/v1/data_engines/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }
  
    getDataEngineById(id: number) {
      return this.wrapper.get<DataEngineDetailModel>(AppConfig.apiEndpoint + '/v1/data_engines/' + id);
    }     
      
  
    exportDataEngine(exportData?: PostExportModel) {
      return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/data_engines/export', exportData);
    }
  
  
  }