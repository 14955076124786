import React, { Component, createRef, Fragment } from "react";
import { ExtractorService } from "./services/extractor.service";
import { EditExtractorModel } from "./models/edit.extractor.model";
import { HttpResponse } from "../../../../../core";
import {
  HandleNotFoundResponse,
  ShowSuccessMessage,
} from "../../../../../shared/helpers";
import { NotFoundResponseArea, ButtonType } from "../../../../../shared/enums";
import { PostExtractorModel } from "./models";
import { ExtractorTabs } from "./ExtractorTabs";
import { Link } from "react-router-dom";
import { Loader } from "../../../../../shared/loaders";
import { DropdownItemModel } from "../../../../../shared/models";
import { CommonService } from "../../../../../shared/services/common.service";
import { Modal } from "react-bootstrap";
import { ExtractionSchedulingRecipesFormPopup } from "../../../../individual/extraction-scheduling-recipes";
import { IsAuthorize } from "../../../../../shared/authorization/check-access";
import { Role } from "../../../../../shared/authorization/enums";

interface IState {
  extractor?: PostExtractorModel,
  id?: number;
  type?: string;
  available?: boolean;
  schema_id?: number;
  sikuli?: boolean;
  tags?: Array<string>;
  extraction_scheduling_recipe_id?: number;
  schedule_locked?: boolean;
  exclude_from_last_month_collection?: boolean;
  exclude_from_last_seven_collection?: boolean;
  exclude_from_automatic_scheduling?: boolean;
  exclude_from_multiple_daily_collection?: boolean;
  use_date_for_reporting?: boolean;
  dateless?: boolean;
  instructions?: string;
  auth_provider?: string;
  isShowtext?: string;
  searchText?: string;
  isSave?: string;
  isSaving?: boolean;
  extractor_recipe_name_list?: DropdownItemModel[];
  extractor_recipe_name?: string;
  name_error?: string;
  extraction_recipe_error?: string;
  ShowExtractionSchedulingRecipiForm?: boolean;
  isNew?: boolean;
  isChildUpdate?: boolean;
}

export class ExtractorForm extends Component<any, IState> {
  private service: ExtractorService;
  private commonService: CommonService;
  private networkCall: any;
  private typeRef: any;

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.service = new ExtractorService();
    this.commonService = new CommonService();
    this.updateExtractionSchedulingRecipi = this.updateExtractionSchedulingRecipi.bind(this);
    this.typeRef = createRef();
  }

  //Initial State to get the data for the edit functionality from the API
  initialState: Partial<IState> = {
    id: 0,
    type: "",
    available: false,
    schema_id: 0,
    sikuli: false,
    tags: [],
    extraction_scheduling_recipe_id: 0,
    schedule_locked: false,
    exclude_from_last_month_collection: false,
    exclude_from_last_seven_collection: false,
    exclude_from_automatic_scheduling: false,
    exclude_from_multiple_daily_collection: true,
    use_date_for_reporting: true,
    dateless: false,
    instructions: "",
    auth_provider: "",
    isShowtext: "New",
    searchText: "",
    isSave: "",
    isSaving: false,
    extractor_recipe_name_list: [],
    name_error: "",
    extraction_recipe_error: "",
    extractor_recipe_name:"",
    ShowExtractionSchedulingRecipiForm: false,
    isNew: false,
    isChildUpdate: false,
  };

  updateExtractionSchedulingRecipi(isChildUpdate, name, id) {
    this.setState({
      isChildUpdate: isChildUpdate,
      extractor_recipe_name: name,
      extraction_scheduling_recipe_id: id,
    });
  }

  handleExtractionSchedulingRecipiClose = () => {
    this.setState({ ShowExtractionSchedulingRecipiForm: false }, () => {});
  };

  
 handleShowExtractionSchedulingRecipi = () => {
  this.setState({ ShowExtractionSchedulingRecipiForm: true, isNew: true }, () => {});
};

  handleShowEditExtractionSchedulingRecipi = () => {
    this.setState(
      { ShowExtractionSchedulingRecipiForm: true, isNew: false },
      () => {}
    );
  };

  // To the load the initial data coming from API for the Edit functionality.
  componentDidMount() {
    if (this.props.match.params.id > 0) {
      this.setState({ id: this.props.match.params.id, isShowtext: "Edit" }, () => {
          this.loadData();
      });
  }
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (event.path[0].id !== "extractor_recipe_name" && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight) {
      this.setState(
        {
          extractor_recipe_name_list: [],
        },
        () => {}
      );
    }
  };

  private getExtractorRecipeById(id: number) {
    this.commonService
      .getCustomExtractionSchedulingRecipeByName(id)
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res) {
          this.setState({
            extractor_recipe_name_list: res.result,
          });
        }
      });
  }

  // Function for loading the data in the intial phase.
  loadData() {
    this.setSavingFlag(true);
    this.service
      .editExtractor(this.props.match.params.id)
      .then((res: HttpResponse<EditExtractorModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {          
          this.setTags(res.result?.tags);
          this.setState({
            id: res.result?.id,
            type: res.result?.type === null ? "" : res.result?.type,
            available: res.result?.available,
            sikuli: res.result?.sikuli,
            schema_id:
              res.result?.schema_id === null ? 0 : res.result?.schema_id,
            extraction_scheduling_recipe_id:
              res.result?.extraction_scheduling_recipe_id === null
                ? 0
                : res.result?.extraction_scheduling_recipe_id,
            extractor_recipe_name:
              res.result?.extraction_scheduling_recipe_id != null
                ? "ExtractionSchedulingRecipe #" +
                  res.result?.extraction_scheduling_recipe_id
                : "",
            schedule_locked: res.result?.schedule_locked,
            exclude_from_last_month_collection:
              res.result?.exclude_from_last_month_collection,
            exclude_from_last_seven_collection:
              res.result?.exclude_from_last_seven_collection,
            exclude_from_automatic_scheduling:
              res.result?.exclude_from_automatic_scheduling,
            exclude_from_multiple_daily_collection:
              res.result?.exclude_from_multiple_daily_collection,
            use_date_for_reporting: res.result?.use_date_for_reporting,
            dateless: res.result?.dateless,
            instructions:
              res.result?.instructions === null ? "" : res.result?.instructions,
            auth_provider:
              res.result?.auth_provider === null
                ? ""
                : res.result?.auth_provider,
          });
        }
      })
      .catch((ex) => {
        HandleNotFoundResponse(ex, NotFoundResponseArea.Extractor, this.props);
      });
  }

  setTags(tags) {
    const new_tags: any = [];
    tags.map((tag) => new_tags.push(tag.text));
    this.setState({ tags: new_tags.join(", ") });
  }

  validate = () => {
    let extraction_recipe_error = "";
    let name_error = "";

    if (!this.state.type && this.state.isSave) {
      name_error = "Name Cant be blank";
    }
    if (!this.state.extraction_scheduling_recipe_id && this.state.isSave) {
      extraction_recipe_error = "Extraction Scheduling recipe Cant be blank";
    }
    if (extraction_recipe_error || name_error) {
      this.setState({
        name_error: name_error,
        extraction_recipe_error: extraction_recipe_error,
      });
      return false;
    } else {
      extraction_recipe_error = "";
      name_error = "";
      this.setState({
        extraction_recipe_error: extraction_recipe_error,
        name_error: name_error,
      });
      return true;
    }
  };

  onExtractorRecipeChange = (e) => {
    const value = e.target.value;
    if (value.length > 0) {
      if (this.networkCall) {
        clearTimeout(this.networkCall);
      }
      this.networkCall = setTimeout(() => {
        this.getExtractorRecipeById(value);
      }, 600);
    }
    this.setState({
      extractor_recipe_name: value,
      extraction_scheduling_recipe_id: value === "" ? 0 : value,
      extraction_recipe_error: "",
    });
  };

  handleChange = (event: any) => {
    const isCheckbox = event.target.type === "checkbox";
    if (event.target.name == "extractor_recipe_name") {
      this.onExtractorRecipeChange(event);
    }
    this.setState(
      {
        [event.target.name]: isCheckbox
          ? event.target.checked
          : event.target.value,
      },
      () => {
        // this.validate();
      }
    );
    if (event.target.name=="type" && this.typeRef.current.value) {
      this.setState({ name_error: "" });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();    
    if(!this.state.isChildUpdate)
        { 
          let tags=[];
          if(this.state.tags?.length!=0)
          {
           tags = this.sendTags(this.state.tags)
          }
    const isValid = this.validate();
    let payload = {
      id: this.state.id,
      type: this.state.type,
      available: this.state.available,
      extraction_scheduling_recipe_id: this.state
        .extraction_scheduling_recipe_id,
      schedule_locked: this.state.schedule_locked,
      exclude_from_last_month_collection: this.state
        .exclude_from_last_month_collection,
      tags:tags,
      exclude_from_last_seven_collection: this.state
        .exclude_from_last_seven_collection,
      exclude_from_automatic_scheduling: this.state
        .exclude_from_automatic_scheduling,
      exclude_from_multiple_daily_collection: this.state
        .exclude_from_multiple_daily_collection,
      use_date_for_reporting: this.state.use_date_for_reporting,
      dateless: this.state.dateless,
      instructions: this.state.instructions,
      auth_provider: this.state.auth_provider,
    };
    if (isValid) {
      if (payload.id === 0) {
        this.postData(payload);
    }
    else {
        this.updateData(payload)
    }      
    }
  }
  };

  sendTags(tags) {
    if (tags === "") {
      return [];
    } else if (tags.indexOf(",") === -1) {
      return [tags.trim()];
    } else {
      let new_tags = tags.split(",");
      return new_tags.filter((tag) => tag.trim().length !== 0);
    }
  }


  postData(extractor: PostExtractorModel) {
    this.setSavingFlag(true);
    this.service.postExtractor(extractor)
        .then((res: HttpResponse<PostExtractorModel>) => {
            this.setSavingFlag(false);
            if (res && res.result) {
                this.setState({
                  extractor: res.result,
                    id: res.result.id
                }, () => {
                    ShowSuccessMessage("Extractor successfully created.");
                    if (this.state.isSave === ButtonType.Save) {
                      this.setState(this.initialState);
                        this.props.history.push('/extractor/detail/'+ this.state.id);                        
                    }
                    if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                        this.setState(this.initialState);
                        this.props.history.push('/extractor/extractor-form/0');
                    }
                    if (this.state.isSave === ButtonType.SaveAndEdit) {
                        this.props.history.push('/extractor/extractor-form/' + this.state?.id);
                        this.setState({ isShowtext: "Edit" })
                    }
                });

            }
        }, () => {
            this.setSavingFlag(false);
        });;
}

  //Function to update the data
  updateData(extractor: PostExtractorModel) {
    this.setSavingFlag(true);
    this.service.updateExtractor(extractor).then(
      (res: HttpResponse<PostExtractorModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              id: res.result.id,
            },
            () => {
              //Showing the message along with changing the route according to the button clicked.
              ShowSuccessMessage("Extractor successfully updated.");
              if (this.state.isSave === ButtonType.Save) {
                this.props.history.push("/extractor/detail/"+ this.state.id);
                this.setState(this.initialState);
              }
              if (this.state.isSave === ButtonType.SaveAndEdit) {
                this.props.history.push(
                  "/extractor/extractor-form/" + this.state.id
                );
                this.setState({ isShowtext: "Edit" });
              }
              if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                this.setState(this.initialState);
                this.props.history.push('/extractor/extractor-form/0');
            }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  selectedExtractionRecipe(item) {
    this.setState({
      extraction_scheduling_recipe_id: item.value,
      extractor_recipe_name: "ExtractionSchedulingRecipe #" + item.value,
      extractor_recipe_name_list: [],
    });
  }

  // Function to check the type of save functionality , in our case save and save & Edit.
  handleClick = (event) => {
    this.setState({ isSave: event.target.value, isChildUpdate: false });
  };

  private setSavingFlag(saving: boolean) {
    this.setState({ isSaving: saving });
  }

  renderExtractorRecipe() {
    if (this.state.extractor_recipe_name_list?.length === 0) {
      return null;
    }
    return (
      <ul className="list-unstyled auto-suggest">
        {this.state.extractor_recipe_name_list?.map((item, index) => (
          <li
            id="extractor_recipe_name"
            key={index}
            onClick={() => this.selectedExtractionRecipe(item)}
          >
            ExtractionSchedulingRecipe #{item.value}
          </li>
        ))}
      </ul>
    );
  }

  render() {
    return (
      <Fragment>
        <form onSubmit={this.handleSubmit}>
          <input type="hidden" value={this.state.id} />
          <div>
            <div className="d-flex justify-content-between align-items-center mb-3">              
              <h4>{this.state.isShowtext} Extractor {this.state.type && <span> '{this.state.type}'</span>}</h4>
            </div>
            <Modal
              backdrop="static"
              keyboard={false}
              size="lg"
              show={this.state.ShowExtractionSchedulingRecipiForm}
              onHide={this.handleExtractionSchedulingRecipiClose}
            >
              <ExtractionSchedulingRecipesFormPopup
                updateExtractionSchedulingRecipi={
                  this.updateExtractionSchedulingRecipi
                }
                handleClose={this.handleExtractionSchedulingRecipiClose}
                isNew={this.state.isNew}
                id={this.state?.extraction_scheduling_recipe_id}
              />
            </Modal>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {Number(this.props.match.params.id) !== 0 && (
                      <ExtractorTabs
                        id={this.props.match.params.id}
                        url="/extractor/extractor-form/"
                      />
                    )}
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group  shadow-sm p-3">
                          <label>
                            Type<span className=" text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <input
                             ref={this.typeRef}
                              type="text"
                              maxLength={255}
                              name="type"
                              className={
                                !this.state.name_error
                                  ? "form-control"
                                  : "form-control  is-invalid"
                              }
                              value={this.state.type}
                              onChange={this.handleChange}
                            />
                            <div className="invalid-feedback">
                              {this.state.name_error}
                            </div>
                          </div>
                          <small>
                            This needs to match the script name in our
                            extractors repository
                          </small>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group shadow-sm p-3 mb-4 ">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="available"
                              checked={this.state.available}
                              onChange={this.handleChange}
                              className="custom-control-input"
                              id="available"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="available"
                            >
                              Available
                            </label>
                          </div>
                          <small>
                            We only try to run collection missions when this
                            value is true
                          </small>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group shadow-sm p-3 ">
                          <label>Sikuli</label>
                          <br />
                          {this.state.sikuli ? (
                            <i className="fas fa-check text-success text2"></i>
                          ) : (
                            <i
                              className="fas fa-times text-danger text1"
                              title="&#x2718;"
                            ></i>
                          )}<br/>
                        <small>
                          This flag gets set when you push the extractor, if the
                          extractor has a Sikuli asset directory. See
                          <a href="https://github.com/staqapp/extractors/blob/446f605a3c6e4ffc7330e1db8e9534ed8e8d1aa0/Rakefile#L151-L156">
                            these lines
                          </a>{" "}
                          in the Rakefile, and also
                          <a href="https://github.com/staqapp/extractors/tree/446f605a3c6e4ffc7330e1db8e9534ed8e8d1aa0/TribalFusionSikuli/assets/TribalFusionSikuli.sikuli">
                            TribalFusionSikui.
                          </a>
                        </small>
                        </div>
                      </div>
                     {this.props.match.params.id!=0 && <div className="col-md-4">
                        <div className="form-group shadow-sm p-3 ">
                          <label>Schema</label>
                          <p>
                            <Link
                              to={"/schema/detail/" + this.state.schema_id}
                            >
                              Extractor {this.state.schema_id} {this.state.type}
                            </Link>
                          </p>
                          <small>
                            This gets created automatically for new extractors,
                            and cannot be changed for existing extractors. See{" "}
                            <a href="https://www.pivotaltracker.com/n/projects/804865/stories/107725144">
                              #107725144
                            </a>{" "}
                            for more.
                          </small>
                        </div>
                      </div>}
                      <div className="col-md-4">
                        <div className="form-group  shadow-sm p-3 ">
                          <label>
                            Extraction scheduling recipe
                            <span className=" text-danger">*</span>
                          </label>
                          <div className="input-group ">
                            <input
                              autoComplete="off"
                              id="extractor_recipe_name"
                              name="extractor_recipe_name"
                              type="text"
                              onChange={this.handleChange}
                              value={this.state.extractor_recipe_name}
                              className={
                                !this.state.extraction_recipe_error
                                  ? "form-control"
                                  : "form-control  is-invalid"
                              }
                            />
                            {this.renderExtractorRecipe()}
                            {IsAuthorize([Role.TAM, Role.Dev, Role.Support, Role.Manager]) &&<div className="input-group-append">
                            <button type="button" className="btn btn-success" onClick={this.handleShowExtractionSchedulingRecipi}><i className="fas fa-plus" style={{ color: "white" }}></i></button>
                             <button type="button"  className={`btn btn-primary `} disabled={this.state.extraction_scheduling_recipe_id==0 || this.state.extraction_scheduling_recipe_id==null}  onClick={this.handleShowEditExtractionSchedulingRecipi}><i className="far fa-edit"></i></button>
                                                      </div>}
                            <div className="invalid-feedback">
                              {this.state.extraction_recipe_error}
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div className="col-md-4">
                        <div className="form-group shadow-sm p-3 mb-4 ">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="schedule_locked"
                              checked={this.state.schedule_locked}
                              onChange={this.handleChange}
                              className="custom-control-input"
                              id="schedule_locked"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="schedule_locked"
                            >
                              Schedule locked
                            </label>
                          </div>
                          <small>
                            If this is true, this extractor can not be used with
                            any other scheduling recipe
                          </small>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group shadow-sm p-3 mb-4 ">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="exclude_from_last_month_collection"
                              checked={
                                this.state.exclude_from_last_month_collection
                              }
                              onChange={this.handleChange}
                              className="custom-control-input"
                              id="exclude_from_last_month_collection"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="exclude_from_last_month_collection"
                            >
                              Exclude from last month collection
                            </label>
                          </div>
                          <small>
                            If this is true, we will not try to re-collect the
                            previous month of data periodically
                          </small>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group shadow-sm p-3 mb-4 ">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="exclude_from_last_seven_collection"
                              checked={
                                this.state.exclude_from_last_seven_collection
                              }
                              onChange={this.handleChange}
                              className="custom-control-input"
                              id="exclude_from_last_seven_collection"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="exclude_from_last_seven_collection"
                            >
                              Exclude from last seven collection
                            </label>
                          </div>
                          <small>
                            If this is true, we will not try to re-collect the
                            previous week of data periodically
                          </small>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group shadow-sm p-3 mb-4 ">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="exclude_from_automatic_scheduling"
                              checked={
                                this.state.exclude_from_automatic_scheduling
                              }
                              onChange={this.handleChange}
                              className="custom-control-input"
                              id="exclude_from_automatic_scheduling"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="exclude_from_automatic_scheduling"
                            >
                              Exclude from automatic scheduling
                            </label>
                          </div>
                          <small>
                            If this is true, we will never schedule missions to
                            refresh connections that use this extractor.
                            Typically you'd do this for custom extractors that
                            use email-based or SFTP-based data sources.
                          </small>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group shadow-sm p-3 mb-4 ">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="exclude_from_multiple_daily_collection"
                              checked={
                                this.state
                                  .exclude_from_multiple_daily_collection
                              }
                              onChange={this.handleChange}
                              className="custom-control-input"
                              id="exclude_from_multiple_daily_collection"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="exclude_from_multiple_daily_collection"
                            >
                              Exclude from multiple daily collection
                            </label>
                          </div>
                          <small>
                            Talk to Kate before touching. If this is true, we
                            don't schedule a second daily mission to collect
                            data later in the day.
                          </small>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group shadow-sm p-3 mb-4 ">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="use_date_for_reporting"
                              checked={this.state.use_date_for_reporting}
                              onChange={this.handleChange}
                              className="custom-control-input"
                              id="use_date_for_reporting"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="use_date_for_reporting"
                            >
                              Use date for reporting
                            </label>
                          </div>
                          <small>
                            If this is true, we will apply the time range
                            constraint to the 'Date' column rather than the
                            'staq_start_at'/'staq_end_at' columns during
                            reporting.
                          </small>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group shadow-sm p-3 mb-4 ">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="dateless"
                              checked={this.state.dateless}
                              onChange={this.handleChange}
                              className="custom-control-input"
                              id="dateless"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="dateless"
                            >
                              Dateless
                            </label>
                          </div>
                          <small>
                            Some extractors bulk collect old data each time they
                            run, this can cause them to report out-of-date data
                            incorrectly. Set this to true if latest_data_at
                            won't be accurate
                          </small>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group shadow-sm p-3">
                          <label>Auth provider</label>
                          <input
                            type="text"
                            maxLength={255}
                            name="auth_provider"
                            className="form-control"
                            value={this.state.auth_provider}
                            onChange={this.handleChange}
                          />
                          <small>
                            Typically this gets set by the programmer. This is
                            what triggers OAuth authentication vs. prompting for
                            user name and password
                          </small>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group shadow-sm p-3 mb-0">
                          <label>Instructions</label>
                          <textarea
                            rows={10}
                            name="instructions"
                            value={this.state.instructions}
                            onChange={this.handleChange}
                            className="form-control"
                            style={{ height: "160px" }}
                          ></textarea>
                          <small>
                            Setup instructions to show the user; any email
                            addresses or URLs you enter here will be converted
                            into HTML links
                          </small>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group shadow-sm p-3 mb-0">
                          <label>Tags</label>
                          <textarea
                            rows={10}
                            name="tags"
                            value={this.state.tags}
                            onChange={this.handleChange}
                            className="form-control"
                            style={{ height: "160px" }}
                          ></textarea>
                          <small>
                            Separate each tag with a comma (example:
                            'casper,unstable,additional setup required')
                          </small>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-3 mb-4">
              {!this.state.isSaving && (
                <Fragment>
                  <Link
                    type="button"
                    className="btn btn-lg btn-default"
                    to="/extractor/list"
                  >
                    {ButtonType.Cancel}
                  </Link>
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    onClick={this.handleClick}
                    value={ButtonType.Save}
                  />
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    value={ButtonType.SaveAndAddAnother}
                    onClick={this.handleClick}
                  />
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    value={ButtonType.SaveAndEdit}
                    onClick={this.handleClick}
                  />
                </Fragment>
              )}
              <Loader
                loading={this.state.isSaving}
                marginBottom="0px"
                marginTop="8px"
                float="center"
                width="368px"
              ></Loader>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}
