import { HttpWrapper, AppConfig } from "../../../../../core";
import { PostExportModel } from "../../../../../shared/export/post.export.model";
import { EmailLayoutsListResponseModel, EmailLayoutsDetailModel, AddUpdateEmailLayoutsModel, EmailLayoutsDeleteResponseModel, EmailLayoutsDeleteModel } from "../models";



export class EmailLayoutsService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getEmailLayoutsList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
    return this.wrapper.post<EmailLayoutsListResponseModel>(AppConfig.apiEndpoint + '/v1/email_layouts/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
  }

  getEmailLayoutsById(id: number) {
    return this.wrapper.get<EmailLayoutsDetailModel>(AppConfig.apiEndpoint + '/v1/email_layouts/' + id);
  }  

  postEmailLayouts(EmailLayouts: AddUpdateEmailLayoutsModel) {
    return this.wrapper.post<AddUpdateEmailLayoutsModel>(AppConfig.apiEndpoint + '/v1/email_layouts', EmailLayouts);
}

editEmailLayouts(id?: number) {
    return this.wrapper.get<AddUpdateEmailLayoutsModel>(AppConfig.apiEndpoint + '/v1/email_layouts/edit/' + id);
}

updateEmailLayouts(EmailLayouts: AddUpdateEmailLayoutsModel) {
    return this.wrapper.put<AddUpdateEmailLayoutsModel>(AppConfig.apiEndpoint + '/v1/email_layouts/' + EmailLayouts.id, EmailLayouts);
}

getLinkedResourcesById(id: number) {
  return this.wrapper.get<EmailLayoutsDeleteModel>(AppConfig.apiEndpoint + '/v1/email_layouts/' + id+'/linked_resources');
}

deleteRecordById(id: number) {
  return this.wrapper.delete<EmailLayoutsDeleteResponseModel>(AppConfig.apiEndpoint + '/v1/email_layouts/' + id);
}

  exportEmailLayouts(exportData?: PostExportModel) {
    return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/email_layouts/export', exportData);
  }


} 