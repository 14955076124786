

export interface IAppConfig {
    apiEndpoint: string;        
    phoneMask: any,
    GranType: string;
    ClientId: string;
    ClientSecret: string;
    Redirect_Uri: string;
    AuthUrl: string;
    ResponseType: string;
    Scope: string;
    AccessType: string;
    TokenAPI: string;
    DeleteConnectionUrl:string;
    DeleteCustomConnectionUrl:string;
    DeleteApplicationsUrl:string;
    ConnectionVerificationUrl:string;
    CollectionMissionUrl:string;
    ImpersonationUrl:string;
    CustomConnectionFileUrl:string;
    FilePickerUrl:string;

    TokenKey:string;
    UserInfoKey:string;
    CryptoKey:string;
    RefreshTokenKey:string,
    RefreshTokenExpiry: number,
    RefreshTokenGrantType: string,

    FileStackUrl:string;
    FileStackKey:string;

    PapertrailUrl:string;
    AdminStagingUrl:string;
}


// const apiRootUrl = 'https://admin-api.test.staq.com/api';
const apiRootUrl = 'https://admin-api.staq.com/api';
//const apiRootUrl = 'production server';
//const apiRootUrl = 'testing server';


const fileStackUrl = "https://www.filestackapi.com/api/file/"
const fileStackKey = "A2sUouA4TlertEOzrgZDhz";
const filePickerUrl = "https://www.filepicker.io/api/file/";

const impersonation_url="https://sso.app.staq.com/admin?identifier=";
// const impersonation_url="https://staging.app.staq.com/admin?identifier=";
const custom_connection_file_url = "https://www.filepicker.io/api/file/";


const papertrailurl = "https://papertrailapp.com/groups/1285144/events?q=";
const adminStagingUrl = "https://admin-staging.gke.staq-infra.com/tps/connections/";

const client_Id = "600422881376-0vp107g56c3d907jdcgfnjf0eh7s056n.apps.googleusercontent.com";
const Client_secret = "aq-ehY21TxLjAvLsPTy0COo0";
// const client_Id = "159559558347-f5q1tplf9fsg3brp718pvqbnft3frbn5.apps.googleusercontent.com";
// const Client_secret = "gbsxUbsAv4aAgaZdHEtSItHM";
const GRANT_TYPE = "authorization_code";
const redirect_uri = window.location.origin + "/login-callback";
//  const redirect_uri = "http://192.168.111.226.xip.io:8081/login-callback";

const auth_url = "https://accounts.google.com/o/oauth2/auth?";
const response_type = "code";
const scope = "openid email profile";
const token_api = "https://accounts.google.com/o/oauth2/token";
const access_type = "offline";
const refresh_token_expiry_in_day = 0.5;
const refresh_token_grant_type = 'refresh_token';


const delete_connection_url = "/job_manager/connections/";
const delete_custom_connection_url = "/job_manager/custom_connections/";
const connection_verification_url = "/job_manager/connection_verifications";
const collection_mission_url = "/job_manager/user_defined_collection_missions";
const applications_url = "v1/applications/";
const token_key='tk';
const user_info_key='ui';
const crypto_key='w9y$B&E)H@McQfTjWnZr4u7x!A%C*F-J';
const refresh_token_key="rtk";

export const AppConfig: IAppConfig = {        
    apiEndpoint: apiRootUrl,
    phoneMask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    ClientId: client_Id,
    ClientSecret: Client_secret,
    GranType: GRANT_TYPE,
    Redirect_Uri: redirect_uri,
    AuthUrl: auth_url,
    ResponseType: response_type,
    Scope: scope,
    AccessType: access_type,
    TokenAPI: token_api,
    DeleteConnectionUrl:delete_connection_url,
    DeleteCustomConnectionUrl:delete_custom_connection_url,
    ConnectionVerificationUrl:connection_verification_url,
    DeleteApplicationsUrl:applications_url,
    CollectionMissionUrl:collection_mission_url,
    ImpersonationUrl:impersonation_url,
    CustomConnectionFileUrl:custom_connection_file_url,

    UserInfoKey:user_info_key,
    TokenKey:token_key,
    CryptoKey:crypto_key,
    RefreshTokenKey: refresh_token_key,
    RefreshTokenExpiry: refresh_token_expiry_in_day,
    RefreshTokenGrantType: refresh_token_grant_type,
    

    FileStackUrl:fileStackUrl,
    FileStackKey:fileStackKey,
    FilePickerUrl:filePickerUrl,
    PapertrailUrl:papertrailurl,
    AdminStagingUrl:adminStagingUrl
};