import React, { Component, Fragment } from 'react'
import { ExtractionSchedulingRecipesService } from './services';
import { HandleNotFoundResponse, ConvertDateTime, PrependZero } from '../../../shared/helpers';
import { NotFoundResponseArea } from '../../../shared/enums';
import { HttpResponse } from '../../../core';
import { NavLink } from "react-router-dom";
import { ExtractionSchedulingRecipesTabs } from '.';
import { ExtractionSchedulingRecipesDetailModel, RecentChanges } from './models';
import { Loader } from '../../../shared/loaders';

interface IState {
    result?: ExtractionSchedulingRecipesDetailModel;
    parsedData?: string;
    isLoading?: boolean;
}
export class ExtractionSchedulingRecipesDetail extends Component<any, IState> {
    private service: ExtractionSchedulingRecipesService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new ExtractionSchedulingRecipesService();
    }

    initialState: Partial<IState> = {
        result: { 
            id: 0,
            connection:{
                text:"",
                value:0
            },
            created_at:null,
            custom_connection:{
                text:"",
                value:0
            },
            earliest_available_data:"",
            extra_duration:"",
            extraction_engine:{
                text:"",
                value:0
            },
            extractor:{
                text:"",
                value:0
            },
            includes_today:false,
            latest_available_data:"",
            max_duration:"",
            notes:"",
            recent_changes:[],
            run_at_hour:0,
             spread_mission_period:0,
             strategy:"",
             updated_at:null
        },
        isLoading: false,
    }

    componentDidMount() { 
        this.loadData();
    }

    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getExtractionSchedulingRecipesById(this.props.match.params.id)
            .then((res: HttpResponse<ExtractionSchedulingRecipesDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {                                      
                    this.setState({
                        result: res.result                        
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.ExtractionSchedulingRecipes,this.props)
            });
    }
    render() {
        return (
            <Fragment>               
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Details for Extraction Scheduling Recipe 'ExtractionSchedulingRecipe #{this.state.result?.id}'</h5>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <ExtractionSchedulingRecipesTabs id={this.props.match.params.id} url="/extraction-scheduling-recipe/detail/" />
                            {!this.state.isLoading &&  <>
                            <h5 className="mb-3">Basic Info</h5>
                            <div className="row">
                                {this.state.result?.strategy &&    <div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Strategy</b>
                                        </div>
                                        <div className="card-body">
                                        {this.state.result?.strategy}
                                        </div>
                                    </div>
                                    </div>}
                                    {this.state.result?.custom_connection && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Custom Connection</b>
                                            </div>
                                            <div className="card-body">
                                                <NavLink to={"/custom-connection/detail/" + this.state.result?.custom_connection.value}>{this.state.result?.custom_connection.text}</NavLink>
                                            </div>
                                        </div>
                                    </div>}
                                    {this.state.result?.connection && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Connection</b>
                                            </div>
                                            <div className="card-body">
                                                <NavLink to={"/connection/detail/" + this.state.result?.connection.value}>{this.state.result?.connection.text}</NavLink>
                                            </div>
                                        </div>
                                    </div>}
                                    {this.state.result?.extraction_engine && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Extraction Engine</b>
                                            </div>
                                            <div className="card-body">
                                                <NavLink to={"/extraction-engine/detail/" + this.state.result?.extraction_engine.value}>{this.state.result?.extraction_engine.text}</NavLink>
                                            </div>
                                        </div>
                                    </div>}
                               
                                            
                                    {this.state.result?.extractor && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Extractor</b>
                                            </div>
                                            <div className="card-body">
                                                <NavLink to={"/extractor/detail/" + this.state.result?.extractor.value}>{this.state.result?.extractor.text}</NavLink>
                                            </div>
                                        </div>
                                    </div>}

                                    <div className="col-3">
                                    <div className="card card-silver shadow-sm">
                                        <div className="card-header">
                                            <b>Run at Hour</b>
                                        </div>
                                        <div className="card-body">
                                        {PrependZero(this.state.result?.run_at_hour)}&nbsp;UTC
                                        </div>
                                    </div>
                                    </div>
                                    {this.state.result?.max_duration &&    <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Max Duration</b>
                                            </div>
                                            <div className="card-body">
                                            {this.state.result?.max_duration}
                                            </div>
                                        </div>
                                    </div>}

                                    {this.state.result?.extra_duration &&    <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Extra Duration</b>
                                            </div>
                                            <div className="card-body">
                                            {this.state.result?.extra_duration}
                                            </div>
                                        </div>
                                    </div>}
                               
                                                                        
                                    {this.state.result?.spread_mission_period!=0 &&    <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                            <b>Spread Mission Period</b>
                                            </div>
                                            <div className="card-body">
                                            {this.state.result?.spread_mission_period}
                                            </div>
                                        </div>
                                    </div>}
                                    {this.state.result?.notes &&    <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Notes</b>
                                            </div>
                                            <div className="card-body">
                                            {this.state.result?.notes}
                                            </div>
                                        </div>
                                    </div>}

                                    {this.state.result?.earliest_available_data &&    <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Earliest Available Data</b>
                                            </div>
                                            <div className="card-body">
                                            {this.state.result?.earliest_available_data}
                                            </div>
                                        </div>
                                    </div>}

                                    {this.state.result?.latest_available_data &&    <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                            <b>Latest Available Data</b>
                                            </div>
                                            <div className="card-body">
                                            {this.state.result?.latest_available_data}
                                            </div>
                                        </div>
                                    </div>}
                               
                           
                                    {this.state.result?.includes_today && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Includes Today</b>
                                            </div>
                                            <div className="card-body"> <i className="fas fa-check text-success"></i>   Yes</div>
                                        </div>
                                    </div>
                                    } 
                                  <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Created At</b>
                                            </div>
                                            <div className="card-body">{ConvertDateTime(this.state.result?.created_at, 'LLL')}</div>
                                        </div>
                                    </div>
                                    {this.state.result?.updated_at && <div className="col-3">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Updated At</b>
                                            </div>
                                            <div className="card-body">{ConvertDateTime(this.state.result?.updated_at, 'LLL')}</div>
                                        </div>
                                    </div>}
                            </div>

                            {this.state.result?.recent_changes.length != 0 && <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="mt-4">Recent Changes</h5>                                            
                                            <div className="table-responsive">
                                                <table className="table table-sm table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Admin</th>                                                                                                                   
                                                            <th scope="col">Created At</th>
                                                            <th scope="col">Summary</th>                                                            
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.result?.recent_changes.map((item: RecentChanges, index) => {
                                                            return (
                                                                <tr key={index}>                                                                   
                                                                    <td>{item.admin.email}</td>
                                                                    <td>{ConvertDateTime(item.created_at, 'LLL')}</td>  
                                                                    <td>{item.summary}</td>
                                                                </tr>

                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                            </div>}
                        </>}                 
                        {this.state.isLoading && <Loader loading={this.state.isLoading} />}
                        </div>
                    </div>
                </div>
            </div>            
        </Fragment>
        )
    }
}
 