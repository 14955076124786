import React, { Component, Fragment } from 'react'
import Breadcrumbs from '../../shared/Breadcrumbs'
import { ReportColumnsRoute } from '../../routing/'

export  class ReportColumnsPage extends Component {
    render() {
        return (
            <Fragment>
                <Breadcrumbs />
                <ReportColumnsRoute />
            </Fragment>
        )
    }
}
