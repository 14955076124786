import { HttpWrapper, AppConfig } from "../../../../../core";
import { PostExportModel } from "../../../../../shared/export/post.export.model";
import { InboundFilesListResponseModel, InboundFilesDetailModel, InboundFilesPostModel, CollectionMissionResponseListModel } from "../models";
import { CancelCollectionMission } from "../../../connections/models";
import { FileUploadResponseModel } from "../../../../../shared/models";


export class InboundFilesService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getInboundFlesList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
    return this.wrapper.post<InboundFilesListResponseModel>(AppConfig.apiEndpoint + '/v1/inbound_files/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
  }

  getInboundFilesById(id: number) {
    return this.wrapper.get<InboundFilesDetailModel>(AppConfig.apiEndpoint + '/v1/inbound_files/' + id);
  }


  // postInboundFiles(accountDomain: InboundFilesPostModel) {
  //   return this.wrapper.post<InboundFilesPostModel>(AppConfig.apiEndpoint + '/v1/inbound_files', accountDomain);
  // }

  
  exportInboundFiles(exportData?: PostExportModel) {
    return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/inbound_files/export', exportData);
  }

  
  reprocessRunCollectionMission(id: number) {
    return this.wrapper.post<FileUploadResponseModel>(AppConfig.apiEndpoint + '/v1/inbound_files/'+ id +'/run_collection_mission',{});
  }

  getCollectionMissionList(id: number, currentPage: number, pageSize: number) {
    return this.wrapper.get<CollectionMissionResponseListModel>(AppConfig.apiEndpoint + '/v1/inbound_files/' + id + '/collection_missions?current_page=' + currentPage + '&page_size=' + pageSize);
  }
  
  cancelCollectionMissionById(id: number, missionId?:number) {
    return this.wrapper.put<CancelCollectionMission>(AppConfig.apiEndpoint + '/v1/custom_connections/'+ id +'/cancel_collection_missions?mission_id='+missionId,{});
  }
 

}