import React, { Component, Fragment } from 'react'
import { HttpResponse } from '../../../../../core';
import { Loader } from '../../../../../shared/loaders';
import { DataEngineJobStateTransitionResponseModel } from './models';
import { DataEgineJobService } from './services/data.engines.job.service';
import { NavLink } from "react-router-dom";
import { ConvertDateTime } from '../../../../../shared/helpers';

interface IState {
    result?: DataEngineJobStateTransitionResponseModel;
    isLoading?: boolean;

}
export class DataEngineJobStateTransition extends Component<any, IState> {
    private service: DataEgineJobService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new DataEgineJobService();
    }
    initialState: Partial<IState> = {
        result: {
            backtrace: "",
            data_engine_job: {
                text: "",
                value: 0
            },
            sidekiq_job_id: "",
            user_message: "",
            created_at: null,
            event: "",
            from_: "",
            id: 0,
            message: "",
            to: ""
        },
        isLoading: false,
    }
    componentDidMount() {
        this.loadData();
    }
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getDataEgineJobStateTransition(this.props.match.params.id)
            .then((res: HttpResponse<DataEngineJobStateTransitionResponseModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            });

    }

    render() {
        return (
            <Fragment>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5>Details for Data Engine Job State Transition  'DataEngineJobStateTransition #{this.state.result?.id}'</h5>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                {!this.state.isLoading && <>
                                    <h5 className="mb-3">Basic Info</h5>
                                    <div className="row">
                                        {this.state.result?.data_engine_job != null &&
                                            <div className="col-3">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header">
                                                        <b>Data Engine Job</b>
                                                    </div>
                                                    <div className="card-body">
                                                        <NavLink to={"/data-engine-job/detail/" + this.state.result?.data_engine_job.value}>DataEngineJob #{this.state.result?.data_engine_job.value}</NavLink>
                                                    </div>
                                                </div>
                                            </div>}
                                        {this.state.result?.event && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Event</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.event}
                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.result?.created_at && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Created At</b>
                                                </div>
                                                <div className="card-body">
                                                    {ConvertDateTime(this.state.result?.created_at, 'LLL')}
                                                </div>
                                            </div>
                                        </div>}

                                    </div>
                                    <div className="row">
                                        {this.state.result?.from_ && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>From</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.from_}
                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.result?.to && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>To</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.to}
                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.result?.user_message && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>User message</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.user_message}
                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.result?.message && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Message</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.message}
                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.result?.backtrace && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Backtrace</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.backtrace}
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                </>}
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
