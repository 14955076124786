import { Route } from "react-router-dom";
import { UserForm, UserDetail } from '../components/user'
import { DashboardDetail } from '../components/user/dashboard'
import { ReportForm, ReportDetail, ReportDefinition, ViewReportData, ReportChangeHistory, ReportTableStorageSettings, ReportDataEngineJobs, ReportReadiness, ReportDataEngineReportJobs, ReportBackups } from "../components/user/reports";
import {  InstalledApplicationsDetail, InstalledApplicationsForm, InstalledApplicationDelete } from "../components/user/reports/installed-application";
import { ReportChangeHistoryDetail } from "../components/user/reports/report-change-histories";
import { ReportColumnDetail } from "../components/user/reports/report-columns";
import {  ReportColumnFieldsDetail } from "../components/user/reports/report-columns/report-column-fields";
import { ReportColumnFiltersDetail } from "../components/user/reports/report-columns/report-column-filters";
import { ReportRunSchedulesDetail, ReportRunScheduleForm } from "../components/user/reports/report-run-schedules";
import {  ReportViewsDetail } from '../components/user/reports/report-views'
import { AppletsDetail } from "../components/user/reports/report-views/applets/AppletsDetail";
import { SourceReportDetail, SourceReportEditForm } from '../components/user/reports/source-reports'
import { AppletsDelete } from "../components/user/reports/report-views/applets/AppletsDelete";
import ReportRunSchedulesDelete from "../components/user/reports/report-run-schedules/ReportRunSchedulesDelete";
import { ReportFiltersDetail } from "../components/user/reports/report-filters";
import React from "react";
import {PrivateRoute} from "./auth-gaurd";
import UserList from "../components/user/UserList";
import DashboardList from "../components/user/dashboard/DashboardList";
import ReportList from "../components/user/reports/ReportList";
import  InstalledApplications  from "../components/user/reports/installed-application/InstalledApplicationsList";
import ReportChangeHistoriesList from "../components/user/reports/report-change-histories/ReportChangeHistoriesList";
import ReportColumnsList from '../components/user/reports/report-columns/ReportColumnsList'
import ReportColumnFieldsList from "../components/user/reports/report-columns/report-column-fields/ReportColumnFieldsList";
import ReportColumnFiltersList from "../components/user/reports/report-columns/report-column-filters/ReportColumnFiltersList";
import ReportFiltersList from "../components/user/reports/report-filters/ReportFiltersList";
import ReportDataSourceFilterList from "../components/user/reports/report-filters/report-data-source/ReportDataSourceFilterList"
import {ReportDataSourceFilterDetail} from "../components/user/reports/report-filters/report-data-source/ReportDataSourceFilterDetail"
import ReportRunSchedulesList from "../components/user/reports/report-run-schedules/ReportRunSchedulesList";
import ReportViewsList from "../components/user/reports/report-views/ReportViewsList";
import AppletsList from "../components/user/reports/report-views/applets/AppletsList";
import SourceReportsList from "../components/user/reports/source-reports/SourceReportsList";



export function UserRoute() {  
  return (
    <div className="compLoader">
      <PrivateRoute path="/user/list" exact component={UserList} />
      <PrivateRoute path="/user/user-form/:id" exact component={UserForm} />
      <PrivateRoute path="/user/detail/:id" exact component={UserDetail} />
    </div>
  )
}

export function UserDashboardRoute() {
  return (
    <div className="compLoader">
      <PrivateRoute path="/dashboard/list" exact component={DashboardList} />
      <PrivateRoute path="/dashboard/detail/:id" exact component={DashboardDetail} />
    </div>
  )
}

export function UserReportRoute() {
  return (
    <div className="compLoader">
      <PrivateRoute path="/report/list" exact component={ReportList} />
      <PrivateRoute path="/report/report-form/:id" exact component={ReportForm} />
      <PrivateRoute path="/report/detail/:id" exact component={ReportDetail} />
      <PrivateRoute path="/report/report_definition/:id" exact component={ReportDefinition} />
      <PrivateRoute path="/report/report_data/:id" exact component={ViewReportData} />
      <PrivateRoute path="/report/report_change_history/:id" exact component={ReportChangeHistory} />
      <PrivateRoute path="/report/data_engine_jobs/:id" exact component={ReportDataEngineJobs} />
      <PrivateRoute path="/report/data_engine_report_jobs/:id" exact component={ReportDataEngineReportJobs} />
      <PrivateRoute path="/report/report_table_storage/:id" exact component={ReportTableStorageSettings} />
      <PrivateRoute path="/report/reportreadiness/:id" exact component={ReportReadiness} />
      <PrivateRoute path="/report/backups/:id" exact component={ReportBackups} />
    </div>
  )
}

export function InstalledApplicationsRoute() {
  return (
    <div className="compLoader">
      <Route path="/installed-report-application/list" exact component={InstalledApplications} />
      <Route path="/installed-report-application/detail/:id" exact component={InstalledApplicationsDetail} />
      <Route path="/installed-report-application/installed-report-application-form/:id" exact component={InstalledApplicationsForm} />
      <Route path="/installed-report-application/delete/:id" exact component={InstalledApplicationDelete} />
    </div>
  )

}



export function ReportChangeHistoriesRoute() {
  return (
    <div className="compLoader">
      <Route path="/report-change-history/list" exact component={ReportChangeHistoriesList} />
      <Route path="/report-change-history/detail/:id" exact component={ReportChangeHistoryDetail} />
    </div>
  )

}

export function ReportColumnsRoute() {
  return (
    <div className="compLoader">
      <Route path="/report-column/list" exact component={ReportColumnsList} />
      <Route path="/report-column/detail/:id" exact component={ReportColumnDetail} />
    </div>
  )

}

export function ReportColumnFieldsRoute() {
  return (
    <div className="compLoader">
      <Route path="/report-column-field/list" exact component={ReportColumnFieldsList} />
      <Route path="/report-column-field/detail/:id" exact component={ReportColumnFieldsDetail} />
    </div>
  )

}

export function ReportColumnFiltersRoute() {
  return (
    <div className="compLoader">
      <Route path="/report-column-filter/list" exact component={ReportColumnFiltersList} />
      <Route path="/report-column-filter/detail/:id" exact component={ReportColumnFiltersDetail} />
    </div>
  )

}
export function ReportFiltersRoute() {
  return (
    <div>
      <Route path="/report-filter/list" exact component={ReportFiltersList} />
      <Route path="/report-filter/detail/:id" exact component={ReportFiltersDetail} />
    </div>
  )

}

export function ReportDataSourceFiltersRoute() {
  return (
    <div>
      <Route path="/report-data-source-filter/list" exact component={ReportDataSourceFilterList} />
      <Route path="/report-data-source-filter/detail/:id" exact component={ReportDataSourceFilterDetail} />
    </div>
  )

}

export function ReportRunSchedulesRoute() {
  return (
    <div className="compLoader">
      <Route path="/report-run-schedule/list" exact component={ReportRunSchedulesList} />
      <Route path="/report-run-schedule/report-run-schedule-form/:id" exact component={ReportRunScheduleForm} />
      <Route path="/report-run-schedule/detail/:id" exact component={ReportRunSchedulesDetail} />
      <Route path="/report-run-schedule/delete/:id" exact component={ReportRunSchedulesDelete} />
    </div>
  )

}


export function ReportViewsRoute() {
  return (
    <div className="compLoader">
      <Route path="/report-view/list" exact component={ReportViewsList} />
      <Route path="/report-view/detail/:id" exact component={ReportViewsDetail} />
    </div>
  )

}

export function AppletsRoute() {
  return (
    <div className="compLoader">
      <Route path="/applet/list" exact component={AppletsList} />
      <Route path="/applet/detail/:id" exact component={AppletsDetail} />
      <Route path="/applet/delete/:id" exact component={AppletsDelete} />
    </div>
  )

}

export function SourceReportsRoute() {
  return (
    <div className="compLoader">
      <Route path="/source-report/list" exact component={SourceReportsList} />
      <Route path="/source-report/detail/:id" exact component={SourceReportDetail} />
      <Route path="/source-report/edit/:id" exact component={SourceReportEditForm} />
    </div>
  )

}
