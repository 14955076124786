import * as yaml from 'js-yaml'
const DEFAULT_LINT_OPTION = {
    schema: 'DEFAULT_SAFE_SCHEMA'  
  };

export  const  ValidateYaml=((data:any)=>{
    const options = Object.assign({}, DEFAULT_LINT_OPTION);
   try{
    yaml.safeLoad(data, {
        schema: yaml[options.schema]
      });
    return true;

   }catch(error)  {
    return false;
}
});