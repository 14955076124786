import React, { Component, Fragment } from 'react'
import { ChangeModel, change_type, ScopeSchemaChangeModel } from './models/scope.schema.manager.model'
import { HandleNotFoundResponse } from '../../../../shared/helpers';
import { NotFoundResponseArea } from '../../../../shared/enums';
import { ScopesService } from './services';
import { ScopesTabs } from '.';
import { GetChangeTypeList, GetFieldTypeList } from '../../../../shared/helpers/change.type.list.helper';
import { ChangeType } from '../../../../shared/enums/change.type.enum';
import { GetUserInfo, ShowSuccessMessage } from '../../../../shared/helpers';
import { HttpResponse } from '../../../../core';
import { ScopesDetailModel, FieldModel } from './models';
import { Loader } from '../../../../shared/loaders';

interface IState {
    data?:ScopeSchemaChangeModel;
    jid?:string;
    change_type?:string;
    field_id?:number;
    name?:string;
    type?:string;
    scope_id?:number;
    triggered_by?:string;  
    changes?:Array<ChangeModel>;
    changeList?:Array<change_type>;
    result?: ScopesDetailModel;
    parsedData?: string;
    isLoading?: boolean;
    
}
export  class SchemaManager extends Component<any, IState> {
    private service: ScopesService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new ScopesService();
        this.setChangeList = this.setChangeList.bind(this);
    }

    initialState: Partial<IState> = {
        changeList:[],
        change_type:"",
        changes:[],
        field_id:0,
        name:"",
        scope_id:0,
        triggered_by:"",
        type:"string",
        jid:"",
        isLoading: false,
    }

    setChangeList()
    {          
        let name = "";    
        let fieldName = this.state.result?.fields_.find(p=>p.id==this.state.field_id);  
        if(this.state.change_type== ChangeType.add)
        {
            name=this.state.change_type+" "+this.state.name+" ("+this.state.type+")";
        }
        if(this.state.change_type== ChangeType.delete)
        {            
            name=this.state.change_type+" "+fieldName?.name+" ("+this.state.type+")";
        }
        if(this.state.change_type== ChangeType.change_type)
        {            
            name="Change type of "+fieldName?.name+" to "+this.state.type;
        }
        if(this.state.change_type== ChangeType.rename)
        {            
            name="Rename "+fieldName?.name+" to "+this.state.name;            
        }
        this.state.changeList?.push({  
          id:this.state.changeList.length+1,          
           name:name
      }); 
      if(this.state.changeList)
      {
        this.state.changes?.push({
          change_type:this.state.change_type,
          field_id:this.state.field_id,
          name:this.state.name,
          type:this.state.type
        })
      }
      this.setState(this.initialState); 
           
    }

    handleSubmit ()
      {
        let email =GetUserInfo().email;        
        let data: ScopeSchemaChangeModel = {
          changes:this.state.changes,
          scope_id:this.props.match.params.id,
          triggered_by: email
        };       
        this.postData(data);
      };

      handleChange = (event: any) => {
        const isCheckbox = event.target.type === "checkbox";
        this.setState({
          [event.target.name]: isCheckbox
            ? event.target.checked
            : event.target.value,
        }); 
      };

      removeRecord(id) {
        let index = this.state.changeList?.findIndex(p => p.id === id)
        if (index! > -1) {
            if (index! > -1) {
                this.state.changeList?.splice(index!, 1);
            }
            this.setState({changeList:this.state.changeList})
        }
    }

    postData(data: ScopeSchemaChangeModel) {
      // this.setSavingFlag(true);
      this.service.postSchemaChanges(data)
          .then((res: HttpResponse<ScopeSchemaChangeModel>) => {
              // this.setSavingFlag(false);
              if (res && res.result) {
                  this.setState({
                     jid:res.result.jid
                  },()=>{
                    ShowSuccessMessage("Schema change has been initiated. This may take a while depending on the size of the data involved.");                      
                    this.props.history.push('/scopes/detail/'+ this.props.match.params.id);                    
                  });                

              }
          }
          );
        }
        
  
      componentDidMount() { 
          this.loadData();
      }
      private setLoading(loading: boolean) {
          this.setState({ isLoading: loading });
      }
      loadData() {
          this.setLoading(true);
          this.service.getScopesById(this.props.match.params.id)
              .then((res: HttpResponse<ScopesDetailModel>) => {
                  this.setLoading(false);
                  if (res && res.result) {                                      
                      this.setState({
                          result: res.result                        
                      },()=>{
                          this.setState({
                          parsedData: JSON.stringify(this.state.result?.table_names, null, '\t')
                          });                      
                      });
                  }
              })
              .catch(ex => {
                  HandleNotFoundResponse(ex,NotFoundResponseArea.Scopes,this.props)
              });
    }
    renderLoading() {
      return (
        <Fragment>               
          <h5>Schema Manager</h5>
          <div className="d-flex justify-content-between align-items-center mb-2">
              <h5>Modify Fields for  &nbsp;'Scope {this.props.match.params.id}&nbsp;-&nbsp;{this.props.match.params.name}'</h5>
          </div>
          <div className="row">
              <div className="col-md-12">
                  <div className="card">
                      <div className="card-body">  
                        <ScopesTabs id={this.props.match.params.id} name={this.props.match.params.name} schemaName={this.props.match.params.schemaName}  isSchema= {true}  url="/scope/schema-manager/" />                         
                          <Loader loading={this.state.isLoading} />
                      </div>
                  </div>
              </div>
          </div>            
        </Fragment>
      )
    }
    render() {
      if (this.state.isLoading) {
        return this.renderLoading();  
      }
        return (
            <Fragment>               
              <h5>Schema Manager</h5>
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Modify Fields for  &nbsp;'Scope {this.props.match.params.id}&nbsp;-&nbsp;{this.props.match.params.name}'</h5>
                
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">  
                        <form> 
                        <ScopesTabs id={this.props.match.params.id} name={this.props.match.params.name} schemaName={this.props.match.params.schemaName}  isSchema= {true}  url="/scope/schema-manager/" />                         
                        <p className="mt-4 text-danger"><i className="fas fa-exclamation-triangle text1"></i> Do not modify existing extractor scopes before 2pm on weekdays and make sure John is aware of the change</p>
                           
                        <div className="row">
                      <div className="col">
                          <div className="card card-silver shadow-sm">
                            <div className="card-header">                              
                            </div>
                            <div className="card-body">                            
                              <div className="row">
                              <div className="col-6 form-group">
                                <label>Change Type</label>
                                <select
                                  className="form-control "
                                  id="change_type"
                                  name="change_type"
                                  value={this.state.change_type}
                                  onChange={this.handleChange}
                                >
                                  {GetChangeTypeList.map((item, index) => {
                              return (
                                <option key={index} value={item.value}>
                                  {item.value}
                                </option>
                              );
                            })}
                                </select>
                              </div>
                              {(this.state.change_type!="" && this.state.change_type!= ChangeType.add) && <div className="col-6 form-group ">
                                <label>* Field List</label>
                                <select
                                  className="form-control "
                                  id="field_id"
                                  name="field_id"
                                  value={this.state.field_id}
                                  onChange={this.handleChange}
                                >
                                  <option key="0" value="0"/>                                  
                                  { this.state.result?.fields_.map((item: FieldModel, index) => {  
                              return (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                                </select>
                              </div>}
                              </div>

                             {this.state.change_type!="" && <div className="row">                              
                             {(this.state.change_type== ChangeType.add || this.state.change_type== ChangeType.rename) && <div className="col-6 form-group">
                                <label>* Name Value</label>
                                <input
                                  type="text"
                                  maxLength={255}
                                  name="name"
                                  value={this.state.name}
                                  onChange={this.handleChange}
                                  className="form-control"
                                />
                              </div>}
                              {(this.state.change_type== ChangeType.add || this.state.change_type== ChangeType.change_type) &&<div className="col-6 form-group">
                                <label>* Field Type</label>
                                <select
                                  className="form-control "
                                  id="type"
                                  name="type"
                                  value={this.state.type}
                                  onChange={this.handleChange}
                                >
                                  {GetFieldTypeList.map((item, index) => {
                              return (
                                <option key={index} value={item.value}>
                                  {item.text}
                                </option>
                              );
                            })}
                                </select>
                              </div>}
                              </div>}

                              
                              {this.state.change_type!="" &&  <button onClick={this.setChangeList}  type="button"
                                className="btn btn-success">
                                Add to Queue
                              </button>}
                              
                            </div>
                          </div>
                      </div>
                      
                    </div>

                    

                                     <div className="row">
                                        <div className="col-md-12">                                                                                      
                                            <div className="table-responsive">
                                                <table className="table table-sm table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Change Action</th>                                                                                                                   
                                                            <th scope="col">Remove</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td colSpan={2}><button onClick={() => {if(window.confirm("Are you sure? This change can't be stopped. Data loss may occur.")){this.handleSubmit()};}} type="button" className="btn btn-danger">Submit</button></td>
                                                    </tr>
                                                        {this.state.changeList?.map((item: change_type, index) => {
                                                            return (
                                                                
                                                                <tr key={index}>                                                                                                                                   
                                                                   <td>{item.name}</td>
                                                                   <td>  <button type="button" className="btn  btn-sm btn-outline-danger far fa-trash-alt" onClick={() => this.removeRecord(item.id)}></button></td>
                                                                </tr>

                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                            
                                    </form>
                        </div>
                    </div>
                </div>
            </div>            
        </Fragment>
        )
    }
}
