import React from "react";
import { SourceType } from "../enums";
import { NavLink } from "react-router-dom";

export function RedirectBySourceType(type, name, id) {
  switch (type) {
    case SourceType.CONNECTION.toLocaleLowerCase():
      return <NavLink to={"/connection/detail/" + id}>{name}</NavLink>;
    case SourceType.PLATFORM.toLocaleLowerCase():
      return <a href="#">{name}</a>;
    case SourceType.CUSTOM_CONNECTION.toLocaleLowerCase():
      return <NavLink to={"/custom-connection/detail/" + id}>{name}</NavLink>;
    case SourceType.REPORT.toLocaleLowerCase():
      return <NavLink to={"/source-report/detail/" + id}>{name}</NavLink>;
      case SourceType.EXTRACTOR.toLocaleLowerCase():
        return <NavLink to={"/extractor/detail/" + id}>{name}</NavLink>;
  }
}
