import React, { Component, Fragment } from 'react'
import { SourceReportListResponseModel } from './models/source.report.list.response.model'
import ExportComponent from '../../../../shared/export/ExportComponent';
import { Columns, FilterModel, ColumnType } from '../../../../shared/filter/models';
import { PostExportModel } from '../../../../shared/export/post.export.model';
import { SourceReportService } from '../source-reports/services';
import { SourceReportsColumnList, SourceReportsListModel } from './models';
import { FileDownloadService } from '../../../../shared/services/file-download.service';
import { ShowErrorMessage } from '../../../../shared/helpers';
import { HttpResponse } from '../../../../core';
import Modal from 'react-bootstrap/Modal';
import { ButtonType } from '../../../../shared/enums/button.enum';
import { StickyTable, Row, Cell, Wrapper } from 'react-sticky-table';
import { Sorting, PaginationRecordInfo, Pagination } from '../../../../shared';
import { Loader } from '../../../../shared/loaders';
import Filter from '../../../../shared/filter/Filter';
import { Link, NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../../../redux/navigation';
import { IsAuthorize } from '../../../../shared/authorization/check-access';
import { Role } from '../../../../shared/authorization/enums';

interface IState {
    result: SourceReportListResponseModel;
    currentPage: number;
    pageSize: number;
    sortBy: string;
    sortOrder: boolean;
    currentColumn: string;
    pageCount: number;
    totalItems: number;
    Columns: Columns[];
    showExportModal?: boolean;
    filters?: FilterModel[];
    isShowFilter: boolean;
    export?: PostExportModel;
    isLoading: boolean;
    Ids: number[];
    selectedIds: FilterModel;
    isAllChecked: boolean;
    isExporting: boolean;
    isSelectedAll?: boolean;
    encodeToName?: string;
    exportTo?: string;
    columnSeparator?: string;
    isNoHeader?: boolean;
}
interface IProps {    
    current_route: string;
    ChangePage: Function;    
    currentPage: number;    
    ChangeFilters: Function;
    filters:[];
}
export class SourceReportsList extends Component<IProps, IState>{
    private service: SourceReportService;
    private currentColumn: string = '';
    columnList = SourceReportsColumnList;
    constructor(props: IProps) {
        super(props);
        this.state = {
            isSelectedAll: true,
            isNoHeader: false,
            columnSeparator: ",",
            encodeToName: "utf-8",
            exportTo: "csv",
            result: {
                paging: {
                    current_page: this.props.currentPage,
                    page_count: 0,
                    page_size: 0,
                    total_items: 0
                },
                records: []
            },
            currentPage: this.props.currentPage,
            pageSize: 20,
            sortBy: "id",
            sortOrder: true,
            currentColumn: "",
            pageCount: 0,
            totalItems: 0,
            Columns: [
                { id: 1, displayText: "Id", columnName: "id", value: '', type: ColumnType.Number },
                { id: 2, displayText: "Report", columnName: "report_name", value: '', type: ColumnType.String },
                { id: 3, displayText: "Account", columnName: "account_name", value: '', type: ColumnType.String }

            ],
            isShowFilter: false,
            export: {
                csv_setting: { encoding: "", header: false, separator: "" },
                filters: [],
                file_type: "",
                included_properties: {},
                is_desc: false,
                sort_by: ""
            },
            isLoading: false,
            Ids: [],
            selectedIds: { name: "", op: "", value: [] },
            filters:this.props.current_route.includes("/source-report/")?this.props.filters: [],
            isAllChecked: false,
            isExporting: false

        };

        this.service = new SourceReportService();

        this.sortList = this.sortList.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this.exportTo = this.exportTo.bind(this);
        this.clearExportModal = this.clearExportModal.bind(this);
    }

    applyFilter = (filter) => {
        this.setState({ filters: filter, currentPage: 1 }, () => {
            this.props.ChangeFilters(filter);
            this.props.ChangePage(1);
            this.loadData();
        });
    }


    clearFilter = () => {
        this.setState({ filters: [], currentPage: 1, isShowFilter: false, selectedIds: { name: "", op: "", value: [] } }, () => {
            this.props.ChangeFilters([]);
            this.props.ChangePage(1);
            this.loadData();
        });
    }

    componentDidMount() {
        this.loadData();
    }

    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }

    private loadData() {
        this.setLoading(true);
        this.service.getSourceReportsList(this.state.currentPage, this.state.pageSize, this.state.sortBy, this.state.sortOrder, this.state.filters)
            .then((res: HttpResponse<SourceReportListResponseModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result,
                        currentPage: res.result.paging.current_page,
                        pageSize: res.result.paging.page_size
                    });

                }
            });
    }

    pageChange(page: number) {
        if (this.state.currentPage == page) return;
        this.setState(
            {
                currentPage: page,
                isAllChecked: false,
                selectedIds: { name: "", op: "", value: [] }
            },
            () => {
                this.props.ChangePage(page);
                this.loadData();
            }
        )
    }

    sortList(sortColumn: string, sortOrder: boolean) {
        this.currentColumn = sortColumn;
        this.setState(
            { sortBy: sortColumn, sortOrder: sortOrder, isAllChecked: false }, () => {
                this.loadData();
            }
        )
    }

    handleClose = () => {
        this.setState({ showExportModal: false }, () => {
        });
    }

    handleShow = () => {
        this.setState({ showExportModal: true }, () => {

        });
    }

    toggleFilter = () => {
        this.setState({ isShowFilter: !this.state.isShowFilter })
    }

    private setIsExporting(exporting: boolean) {
        this.setState({ isExporting: exporting });
    }

    clearExportModal(data) {
        this.setState({
            isSelectedAll: data.isSelectedAll,
            isNoHeader: data.isNoHeader,
            columnSeparator: data.columnSeparator,
            encodeToName: data.encodeToName,
            exportTo: data.exportTo,
        })
    }

    export() {
        this.setIsExporting(true);
        this.service.exportSourceReports(this.state.export)
            .then((res) => {
                this.setIsExporting(false);
                if (res) {
                    new FileDownloadService().downloadFileByStream(res, this.state.export?.exportFileName || 'Applets.csv');
                    this.handleClose();
                }
            }, () => {
                this.setIsExporting(false);
                ShowErrorMessage();
            });
    }

    exportTo = data => {
        let filterdata = this.state.filters?.slice();
        if (this.state.selectedIds.value.length != 0) {
            filterdata?.push(this.state.selectedIds);
        }
        this.setState({
            export: {
                filters: filterdata,
                file_type: data.file_type.toLowerCase(),
                csv_setting: { encoding: data.csv_setting.encoding.toLowerCase(), header: !data.csv_setting.header, separator: data.csv_setting.separator.toLowerCase() },
                included_properties: data.included_properties,
                exportFileName: data.exportFileName,
                is_desc: this.state.sortOrder,
                sort_by: this.state.sortBy
            },
            isSelectedAll: data.isSelectedAll            
        }, () => {
            // this.export();
        });
    }

    onSelectionChange(e: any) {
        // current array of ids
        let ids = this.state.Ids
        if (e.target.name === "all") {
            ids = []
            if (e.target.checked) {
                this.state.result.records.forEach(p => { p.isChecked = true })
                this.setState({ result: this.state.result, isAllChecked: true, Ids: ids }, () => {
                    this.state.result.records.forEach((p) => { ids.push(p.id) })
                })
            }
            else {
                this.state.result.records.forEach(p => { p.isChecked = false })
                this.setState({ result: this.state.result, isAllChecked: false, Ids: ids })
            }
        }
        else {
            let isAll;
            let index
            let checkedIndex = this.state.result.records.findIndex(p => p.id == e.target.value);
            this.state.result.records[checkedIndex]["isChecked"] = e.target.checked;
            if (e.target.checked) {
                ids.push(+e.target.value)
                if (this.state.result.records.filter(p => p.isChecked == true).length === this.state.result.records.length) {
                    isAll = true;
                }
            } else {
                // remove the value from the unchecked checkbox from the array
                index = ids.indexOf(+e.target.value)
                ids.splice(index, 1)
                isAll = false;
            }
            this.setState({ result: this.state.result, isAllChecked: isAll })
        }
        // update the state with the new array of ids
        let selectedIds = { name: "id", op: "in", value: ids }
        this.setState({ selectedIds: selectedIds })
    }
    render() {
        return (
            <Fragment>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="flex-fill">
                        <div className="row">
                            <div className="col-md-8"> <h4>List of Source Reports</h4></div>
                            <div className="col-md-4 text-right">
                                 {/* <button type="button" className="btn btn-secondary ml-3" onClick={this.handleShow}><i className="fas fa-download"></i> {ButtonType.Export}</button> */}
                                <button type="button" className="btn btn-primary ml-3" onClick={this.toggleFilter}><i className="fas fa-filter"></i> {ButtonType.AddFilter}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal backdrop='static' keyboard={false} size="lg" show={this.state.showExportModal} onHide={this.handleClose}>
                    <ExportComponent isSelectedAll={this.state.isSelectedAll} isNoHeader={this.state.isNoHeader} columnList={this.columnList} handleClose={this.handleClose} exportTo={this.exportTo} isExporting={this.state.isExporting} fileNamePrefix='reporViews_list_' clearExportModal={this.clearExportModal} />
                </Modal>
                <div className="d-flex">
                    <div className="flex-fill">
                        <div className="card" >
                            <div>
                                <div className="card-body p-0">
                                    <div className="sticky-table-wrapper" >
                                        <StickyTable borderWidth={0} rightStickyColumnCount={1} wrapperRef={Wrapper}>
                                            <Row className="sticky-table-header">
                                                <Cell className="text-center"><div className="custom-checkbox custom-control">
                                                    <input type="checkbox" className="custom-control-input"
                                                        value="all" onChange={this.onSelectionChange.bind(this)} id="all" name="all" checked={this.state.isAllChecked} />
                                                    <label htmlFor="all" className="custom-control-label">&nbsp;</label>
                                                </div></Cell>
                                                <Sorting columnName="id" displayName="Id" currentColumn={this.currentColumn} sortOrder={this.state.sortOrder} onClick={this.sortList} />
                                                <Cell>Source Report</Cell>
                                                <Sorting columnName="report_name" displayName="Report" currentColumn={this.currentColumn} sortOrder={this.state.sortOrder} onClick={this.sortList} />
                                                <Sorting columnName="account_name" displayName="Account" currentColumn={this.currentColumn} sortOrder={this.state.sortOrder} onClick={this.sortList} />
                                                <Cell>Action</Cell>
                                            </Row>
                                            {!this.state.isLoading && this.state.result.records.length > 0 && (
                                                this.state.result.records?.map((item: SourceReportsListModel) => {
                                                    return (
                                                        <Row key={item.id} >
                                                            <Cell className="text-center">
                                                                <div className="custom-checkbox custom-control">
                                                                    <input type="checkbox" className="custom-control-input"
                                                                        value={item.id || ''} checked={item.isChecked || false} onChange={this.onSelectionChange.bind(this)} id={"checkbox" + item.id} name={"checkbox" + item.id} />
                                                                    <label htmlFor={"checkbox" + item.id} className="custom-control-label">&nbsp;</label>
                                                                </div>
                                                            </Cell>
                                                            <Cell title={item.id.toString()}>{item.id}</Cell>
                                                            <Cell><Link to={"/source-report/detail/" + item.id}><span className="sortlength" title={item.name}>{item.name}</span></Link></Cell>
                                                            {item.report && <Cell><Link to={"/report/detail/" + item.report.value}><span className="sortlength" title={item.report.text}>{item.report.text}</span></Link></Cell>}
                                                            {!item.report && <Cell title=""></Cell>}
                                                            {item.account && <Cell><Link to={"/account/detail/" + item.account.value}><span title={item.account.text} className="sortlength"> {item.account.text}</span></Link></Cell>}
                                                           {!item.account && <Cell title="New Account">New Account</Cell>}
                                                            <Cell>
                                                            {IsAuthorize([Role.TAM,Role.Support,Role.Dev]) &&<Link to={"/source-report/edit/" + item.id} className="far fa-edit  m-2" title="Edit"></Link>}
                                                            <Link to={"/source-report/detail/" + item.id} className="fas fa-info-circle m-2"  title="Show"></Link>
                                                            </Cell>
                                                        </Row>
                                                    );
                                                })
                                            )}
                                        </StickyTable>
                                        {!this.state.isLoading && this.state.result.records.length === 0 && <div className="text-danger text-center"> <img src="../../no-data.jpg" className="img-fluid" alt="No Data Found" /></div>}
                                        {this.state.isLoading && <Loader loading={this.state.isLoading} />}
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-between">
                                <PaginationRecordInfo currentPage={this.state.currentPage} totalRecords={this.state.result?.paging.total_items} pageSize={this.state.pageSize} />
                                <Pagination currentPage={this.state.currentPage} pageCount={this.state.result?.paging.page_count} onChangePage={this.pageChange} />
                            </div>
                        </div>
                    </div>
                    {this.state.isShowFilter &&

                        <div className="page-filter">
                            <div className="d-flex justify-content-between">
                                <h6>Filter by</h6>
                                {/* <div onClick={this.toggleFilter} className="closebtn">
                                    <i className="fa fa-times"></i>
                                </div> */}
                                <button className="btn btn-default btn-sm" onClick={this.toggleFilter}> <i className="fa fa-times"></i></button>
                            </div>
                            <hr />
                            <Filter columnList={this.state.Columns} applyFilter={this.applyFilter} clearFilter={this.clearFilter} />
                        </div>
                    }
                </div>
            </Fragment>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SourceReportsList);