import { HttpWrapper, AppConfig } from "../../../../../core";
import { PostExportModel } from "../../../../../shared/export/post.export.model";
import { LinkResourcesResponseModel } from "../../../connections/models/link.resources.response.model";
import { InboundEmailAddressesListResponseModel, InboundEmailAddressesDetailsModel, InboundEmailAddressesPostModel, InboundEmailAddressesEditModel, InboundEmailAddressesLinkResourcesResponseModel } from "../models";
import { FileUploadResponseModel, FileUploadModel } from "../../../../../shared/models";



export class InboundEmailAddressesService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getInboudEmailAddressesList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
    return this.wrapper.post<InboundEmailAddressesListResponseModel>(AppConfig.apiEndpoint + '/v1/inbound_email_addresses/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
  }

  getInboudEmailAddressesById(id: number) {
    return this.wrapper.get<InboundEmailAddressesDetailsModel>(AppConfig.apiEndpoint + '/v1/inbound_email_addresses/' + id);
  }


  postInboudEmailAddresses(InboudEmailAddresses: InboundEmailAddressesPostModel) {
      return this.wrapper.post<InboundEmailAddressesPostModel>(AppConfig.apiEndpoint + '/v1/inbound_email_addresses', InboudEmailAddresses);
    }

    editInboudEmailAddresses(id?: number) {
      return this.wrapper.get<InboundEmailAddressesEditModel>(AppConfig.apiEndpoint + '/v1/inbound_email_addresses/edit/' + id);
    }

    updateInboudEmailAddresses(InboudEmailAddresses: InboundEmailAddressesPostModel) {
      return this.wrapper.put<InboundEmailAddressesPostModel>(AppConfig.apiEndpoint + '/v1/inbound_email_addresses/' + InboudEmailAddresses.id, InboudEmailAddresses);
    }

    getLinkedResourcesById(id: number) {
      return this.wrapper.get<InboundEmailAddressesLinkResourcesResponseModel>(AppConfig.apiEndpoint + '/v1/inbound_email_addresses/' + id + '/linked_resources');
    }
  
    deleteRecordById(id: number) {
      return this.wrapper.delete<any>(AppConfig.apiEndpoint + '/v1/inbound_email_addresses/' + id);      
    }

    connectionSearch(type){
      return this.wrapper.delete<any>(AppConfig.apiEndpoint + '/v1/custom_connections/search/' + type);
    }
 
//     getLinkCustomConnectionMaintainById(id: number) {
//       return this.wrapper.get<LinkResponseCustomConnectionMaintain>(AppConfig.apiEndpoint + '/v1/custom_connections/' + id + '/maintain');
//     }
    
//     cancelCollectionMissionById(id: number, missionId?:number) {
//       return this.wrapper.put<CancelCollectionMission>(AppConfig.apiEndpoint + '/v1/custom_connections/'+ id +'/cancel_collection_missions?mission_id='+missionId,{});
//     }
   

//     verifyCustomConnectionById(id: number) {
//       return this.wrapper.post<any>(AppConfig.ConnectionVerificationUrl, id);
//     }

//     postCollectionMission(data: CollectionMissionPostModel) {
//       return this.wrapper.post<any>(AppConfig.CollectionMissionUrl, data);
//     }    

//     getUploadedFileList(id: number, currentPage: number, pageSize: number) {
//       return this.wrapper.get<FileUploadListResponseModel>(AppConfig.apiEndpoint + '/v1/custom_connections/' + id + '/uploaded_files?current_page=' + currentPage + '&page_size=' + pageSize);
//     }

//     uploadFile(data:FileUploadModel) {
//       return this.wrapper.post<FileUploadResponseModel>(AppConfig.apiEndpoint + '/v1/uploaded_files',data);
//     }

//     runCollectionMission(id: number) {
//       return this.wrapper.post<FileUploadResponseModel>(AppConfig.apiEndpoint + '/v1/uploaded_files/'+ id +'/run_collection_mission',{});
//     }
//     reprocessRunCollectionMission(id: number) {
//       return this.wrapper.post<FileUploadResponseModel>(AppConfig.apiEndpoint + '/v1/inbound_files/'+ id +'/run_collection_mission',{});
//     }

//     getFileLinkedResourcesById(id: number) {
//       return this.wrapper.get<FileLinkResourceModel>(AppConfig.apiEndpoint + '/v1/custom_connections/' + id + '/info');
//     }
  

exportInboundEmailAddresses(exportData?: PostExportModel) {
    return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/inbound-email-addresses/export', exportData);
  }

}