


import { AppConfig, HttpWrapper } from "../../../core";
import { AuthResponseModel } from "../models/auth.response.model";
import { AuthModel, UserDetailResponseModel } from "../models";

export class AuthService {
    private wrapper: HttpWrapper;
    constructor() {
      this.wrapper = new HttpWrapper();
    } 
  
    getAccessToken(data: AuthModel) {
      return this.wrapper.post<AuthResponseModel>(AppConfig.TokenAPI, data);
    }


    getGetUserInfo() {
      return this.wrapper.get<UserDetailResponseModel>(AppConfig.apiEndpoint + '/v1/auth/userinfo');
    }

    getAccessTokenByRefreshToken(data: AuthModel) {
      return this.wrapper.post<AuthResponseModel>(AppConfig.TokenAPI, data);
    }
  
  } 