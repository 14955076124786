import React, { Component, Fragment } from 'react'
import { PlanCategoriesService } from './services';
import { HttpResponse } from '../../../../core';
import { NavLink } from "react-router-dom";
import { PlanCategoriesTabs } from './PlanCategoriesTabs'
import { DropdownItemModel } from '../../../../shared/models';
import { ButtonType } from '../../../../shared/enums/button.enum';
import { ShowSuccessMessage, HandleNotFoundResponse } from '../../../../shared/helpers';
import { NotFoundResponseArea } from '../../../../shared/enums';
import { PlanCategoriesDeleteModel, PlanCategoriesDeleteResponseModel } from './models';

interface IState {
    result?: PlanCategoriesDeleteModel;
    deleteResult?: PlanCategoriesDeleteResponseModel;
    isDoubleleClick?: boolean;
}
export class DeletePlanCategories extends Component<any, IState> {
    private service: PlanCategoriesService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new PlanCategoriesService();
        this.deleteRecord = this.deleteRecord.bind(this);
    }

    initialState: Partial<IState> = {
        result: {
            id: 0 ,  
            name: "",
        },
        deleteResult: { isdeleted: false },
        isDoubleleClick: false
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.service.getLinkedResourcesById(this.props.match.params.id)
            .then((res: HttpResponse<PlanCategoriesDeleteModel>) => {
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.PlanCategories,this.props) 
            });
    }

    deleteRecord() {
        if (!this.state.isDoubleleClick) {
            this.setState({ isDoubleleClick: true })
            this.service.deleteRecordById(this.props.match.params.id)
                .then((res: HttpResponse<PlanCategoriesDeleteResponseModel>) => {
                    if (res && res.result) {
                        this.setState({
                            deleteResult: res.result
                        }, () => {
                            if (this.state.deleteResult?.isdeleted) {
                                ShowSuccessMessage("Plan Category Successfully deleted.");
                                this.props.history.push('/plan-category/list');
                            }
                        });
                    }
                });
        }
    }

    render() {
        return (
            <Fragment>
                {/* {this.state.result?.scheduled_reports.value == this.props.match.params.id && <div> */}
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5>Delete Plan Category '{this.state.result?.name}'</h5>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <PlanCategoriesTabs id={this.props.match.params.id} url="/plan-category/delete/" />
                                <div className="alert font-weight-bold  alert-light"> <i className="fas fa-exclamation-circle text1"></i> Are you sure you want to delete this Plan Category <strong>“ {this.state.result?.name}”</strong> ?</div>
                                <p className="mt-4 text-danger"><i className="fas fa-exclamation-triangle text1"></i> The following related items may be deleted or orphaned:</p>
                                <div className="row">
                                    <div className="col">
                                        <div className="card card-primary shadow-sm">
                                            <div className="card-header">
                                                <h4>Plan Category</h4>
                                            </div>
                                            <div className="card-body">
                                                <NavLink className="badge badge-light text-link" to={"/plan-category/detail/" + this.state.result?.id}>{this.state.result?.name} </NavLink>
                                            </div>
                                        </div>
                                    </div>
                  
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="mx-auto">
                                <button className="btn btn-lg btn-danger" disabled={this.state.isDoubleleClick} onClick={this.deleteRecord}> {ButtonType.Delete}</button>
                                <NavLink type="button" className="btn btn-lg btn-default ml-3" to="/plan-category/list">{ButtonType.Cancel}</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div>} */}
            </Fragment>
        )
    }
}
