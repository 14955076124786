import { HttpWrapper, AppConfig } from "../../../../core";
import { PostExportModel } from "../../../../shared/export/post.export.model";
import { ProtectedOperationsListResponseModel, ProtectedOperationsDetailModel, AddUpdateProtectedOperationsModel, ProtectedOperationsDeleteResponseModel, ProtectedOperationsDeleteModel } from "../models";



export class ProtectedOperationsService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getProtectedOperationsList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
    return this.wrapper.post<ProtectedOperationsListResponseModel>(AppConfig.apiEndpoint + '/v1/protected_operations/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
  }

  getProtectedOperationsById(id: number) {
    return this.wrapper.get<ProtectedOperationsDetailModel>(AppConfig.apiEndpoint + '/v1/protected_operations/' + id);
  }  

  postProtectedOperations(ProtectedOperations: AddUpdateProtectedOperationsModel) {
    return this.wrapper.post<AddUpdateProtectedOperationsModel>(AppConfig.apiEndpoint + '/v1/protected_operations', ProtectedOperations);
}

editProtectedOperations(id?: number) {
    return this.wrapper.get<AddUpdateProtectedOperationsModel>(AppConfig.apiEndpoint + '/v1/protected_operations/edit/' + id);
}

updateProtectedOperations(ProtectedOperations: AddUpdateProtectedOperationsModel) {
    return this.wrapper.put<AddUpdateProtectedOperationsModel>(AppConfig.apiEndpoint + '/v1/protected_operations/' + ProtectedOperations.id, ProtectedOperations);
}

getLinkedResourcesById(id: number) {
  return this.wrapper.get<ProtectedOperationsDeleteModel>(AppConfig.apiEndpoint + '/v1/protected_operations/' + id+'/linked_resources');
}

deleteRecordById(id: number) {
  return this.wrapper.delete<ProtectedOperationsDeleteResponseModel>(AppConfig.apiEndpoint + '/v1/protected_operations/' + id);
}

  exportProtectedOperations(exportData?: PostExportModel) {
    return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/protected_operations/export', exportData);
  }


} 