import React, { Component, Fragment } from "react";
import { SubscriptionsDetailModel } from "./models";
import { SubscriptionsService } from "./services/subscriptions.service";
import { HttpResponse } from "../../../../core";
import {
  HandleNotFoundResponse
} from "../../../../shared/helpers";
import { NotFoundResponseArea } from "../../../../shared/enums";
import { SubscriptionsTabs } from "./SubscriptionsTabs";
import {Link} from 'react-router-dom'
import { Loader } from "../../../../shared/loaders";

interface IState {
  result?: SubscriptionsDetailModel;
  isLoading?: boolean;
}

export class SubscriptionsDetail extends Component<any, IState> {
  private service: SubscriptionsService;
  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.service = new SubscriptionsService();
  }

  // Defines the initial state for the values coming from API.
  initialState: Partial<IState> = {
    result: {
      id: 0,
      name: "",
      description: "",
      subscription_category_id: 0,
      email_template_id: 0,
      category: {
        text: "",
        value: 0
      },
      email_template: {
        text: "",
        value: 0
      },
    },
    isLoading: false,
  };

  //Get the detail from the API whenever the page loads.
  componentDidMount() {
    this.loadData();
  }

  //function to load the initial view data coming from the API.
  private setLoading(loading: boolean) {
    this.setState({ isLoading: loading });
}
loadData() {
    this.setLoading(true);
    this.service
      .getSubscriptionById(this.props.match.params.id)
      .then((res: HttpResponse<SubscriptionsDetailModel>) => {
        this.setLoading(false);
        if (res && res.result) {
          this.setState({
            result: res.result,
          });
        }
      })
      .catch((ex) => {
        HandleNotFoundResponse(ex, NotFoundResponseArea.Subscriptions, this.props);
      });
  }

  render() {
    return (
      <Fragment>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h5>Details for Subscriptions '{this.state.result?.name}'</h5>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <SubscriptionsTabs
                  id={this.props.match.params.id}
                  url="/subscription/detail/"
                />
                 {!this.state.isLoading &&  <>     
                <h5 className="mb-3">Basic Info</h5>
                <div className="row">
                  {this.state.result?.name && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Name</b>
                        </div>
                        <div className="card-body">
                          {this.state.result?.name}
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.category?.text && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Subscription Category</b>
                        </div>
                        <div className="card-body">
                          <Link to={"/subscription-category/detail/" + this.state.result?.subscription_category_id}>{this.state.result?.category?.text}</Link>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.email_template?.text && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Email template</b>
                        </div>
                        <div className="card-body">
                        <Link to={"/email-template/detail/" + this.state.result?.email_template_id}>{this.state.result?.email_template?.text}</Link>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.visible && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Visible</b>
                        </div>
                        <div className="card-body">
                          <i className="fas fa-check text-success text2"></i>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.description && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Description</b>
                        </div>
                        <div className="card-body">
                          {this.state.result?.description}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />} 
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
