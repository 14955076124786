import React, { Component, Fragment } from 'react'
import { CollectionMissionTaskRoute } from '../../routing'
import Breadcrumbs from '../../shared/Breadcrumbs'

export class CollectionMissionTaskPage extends Component {
    componentDidMount() {
        // setTimeout(() => {
        //   this.reloadPage()
        // }, 1500)        
      }
    reloadPage()
    {
      if( window.localStorage )
      {
        if(!localStorage.getItem('firstLoad') )
        {
          localStorage['firstLoad'] = true;
          window.location.reload(false);
        }
        else
          localStorage.removeItem('firstLoad');
      }
    }
    render() {
        return (
            <Fragment>
                <Breadcrumbs />
                <CollectionMissionTaskRoute />
            </Fragment>
        )
    }
}
