import React, { Component, Fragment } from 'react'
import { DeleteResponseModel, LinkResourcesResponseModel } from '../../../shared/models'
import { HttpResponse } from '../../../core';
import { ShowSuccessMessage, HandleNotFoundResponse } from '../../../shared/helpers';
import { NavLink } from "react-router-dom";
import { ButtonType, NotFoundResponseArea } from '../../../shared/enums';
import { ExtractionSchedulingRecipesService } from './services';
import { ExtractionSchedulingRecipesTabs } from './ExtractionSchedulingRecipesTabs';
import { DeleteLinkResource } from './models';

interface IState {
    result?: DeleteLinkResource;
    deleteResult?: DeleteResponseModel;
    isDoubleleClick?: boolean;
}
export class DeleteExtractionSchedulingRecipes extends Component<any, IState> {
    private service: ExtractionSchedulingRecipesService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new ExtractionSchedulingRecipesService();
        this.deleteRecord = this.deleteRecord.bind(this);
    }

    initialState: Partial<IState> = {
        result: {
            id: 0,
            recent_changes:[],
            connection:{
                text:"",
                value:0
            },
            custom_connection:{
                text:"",
                value:0
            },
            extraction_engine:{
                text:"",
                value:0
            },
            extractor:{
                text:"",
                value:0
            }
        },
        deleteResult: { isdeleted: false },
        isDoubleleClick: false
    }
    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.service.getLinkedResourcesById(this.props.match.params.id)
            .then((res: HttpResponse<DeleteLinkResource>) => {
                if (res && res.result) {                    
                    this.setState({
                        result: res.result
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.ExtractionSchedulingRecipes,this.props)
            });
    }


    deleteRecord() {
        if (!this.state.isDoubleleClick) {
            this.setState({ isDoubleleClick: true })
            this.service.deleteRecordById(this.props.match.params.id)
                .then((res: HttpResponse<DeleteResponseModel>) => {
                    if (res && res.result) {
                        this.setState({
                            deleteResult: res.result
                        }, () => {
                            if (this.state.deleteResult?.isdeleted) {
                                ShowSuccessMessage("Extraction engine successfully deleted.");
                                this.props.history.push('/extraction-engine/list');
                            }
                        });
                    }
                });

        }
    }
    render() {
        return (
            <Fragment> 
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5>Delete Extraction Scheduling Recipe 'ExtractionSchedulingRecipe #{this.props.match.params.id}'</h5>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <ExtractionSchedulingRecipesTabs id={this.props.match.params.id} url="/extraction-engine/delete/" />
                                <div className="alert font-weight-bold  alert-light"> <i className="fas fa-exclamation-circle text1"></i> Are you sure you want to delete this extraction scheduling recipe  <strong>“ExtractionSchedulingRecipe #{this.props.match.params.id}”</strong> ?</div>

                                <p className="mt-4 text-danger"><i className="fas fa-exclamation-triangle text1"></i> The following related items may be deleted or orphaned:</p>
                                <div className="row ">
                                    <div className="col">
                                        <div className="card card-primary shadow-sm">
                                            <div className="card-header">
                                                <h4>Extraction Scheduling Recipe</h4>
                                            </div>
                                            <div className="card-body">
                                                <NavLink className="badge badge-light text-link" to={"/extraction-scheduling-recipe/detail/" + this.state.result?.id}>ExtractionSchedulingRecipe #{this.props.match.params.id} </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.result?.connection && <div className="col">
                                    <div className="card-header">
                                                <h4>Connection </h4>
                                            </div>
                                    <div className="card-body">
                                                <NavLink className="badge badge-light text-link" to={"/connection/detail/" + this.state.result?.connection.value}>{this.state.result?.connection.text} </NavLink>
                                            </div>
                                    </div>}
                                    {this.state.result?.custom_connection && <div className="col">
                                    <div className="card-header">
                                                <h4>Custom Connection </h4>
                                            </div>
                                    <div className="card-body">
                                                <NavLink className="badge badge-light text-link" to={"/custom-connection/detail/" + this.state.result?.custom_connection.value}>{this.state.result?.custom_connection.text} </NavLink>
                                            </div>
                                    </div>}

                                    {this.state.result?.extractor && <div className="col">
                                    <div className="card-header">
                                                <h4>Extractor </h4>
                                            </div>
                                    <div className="card-body">
                                                <a className="badge badge-light text-link">{this.state.result?.extractor.text} </a>
                                            </div>
                                    </div>}
                                   
                                   
                                </div>
                                <div className="row">
                                {this.state.result?.extraction_engine && <div className="col">
                                    <div className="card-header">
                                                <h4>Extraction Engine </h4>
                                            </div>
                                    <div className="card-body">
                                                <NavLink className="badge badge-light text-link" to={"/extraction-engine/detail/" + this.state.result?.extraction_engine.value}>{this.state.result?.extraction_engine.text} </NavLink>
                                            </div>
                                    </div>}
                                {this.state.result?.recent_changes.length != 0 && <div className="col">
                                        {this.state.result?.recent_changes.map((item: any) => {
                                            return (
                                                <div className="card card-success shadow-sm" key={item.value}>
                                                    <div className="card-header"><h4>Extraction Scheduling Recipe Changes </h4> </div><div className="card-body"> <span className="badge badge-light">Extraction scheduling recipe changes #{item}</span></div>
                                                </div>
                                            );
                                        })}
                                    </div>}
                                </div>

                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="mx-auto">
                                <button className="btn btn-lg btn-danger" disabled={this.state.isDoubleleClick} onClick={this.deleteRecord}> {ButtonType.Delete}</button>
                                <NavLink type="button" className="btn btn-lg btn-default ml-3" to="/extraction-scheduling-recipe/list">{ButtonType.Cancel}</NavLink>
                            </div>
                        </div>
                    </div>

                </div>
            </Fragment>
        )
    }
}
