import React, { Component, Fragment } from 'react'
import { ReportFiltersDetailModel } from '../report-filters/models'
import { ReportFiltersService } from './services/report.filters.service';
import { HttpResponse } from '../../../../core';
import { ReportFiltersTabs } from './ReportFiltersTabs';
import { HandleNotFoundResponse } from '../../../../shared/helpers';
import { NotFoundResponseArea } from '../../../../shared/enums';
import { Loader } from '../../../../shared/loaders';

interface IState {
    result?: ReportFiltersDetailModel;
    isLoading?: boolean;
}
export class ReportFiltersDetail extends Component<any, IState>  {
    private service: ReportFiltersService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new ReportFiltersService();
    }

    initialState: Partial<IState> = {
        result: {
            values: [],
            comparator: "",
            filter_type: "",
            id: 0,
            logical_operator: "",
            operator: ""
        },
        isLoading: false,
    }

    componentDidMount() {
        this.loadData();
    }
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getReportFiltersById(this.props.match.params.id)
            .then((res: HttpResponse<ReportFiltersDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.ReportFilters,this.props) 
            });
    }
    render() {
        return (
            <Fragment>
               <div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <h5>Details for Report Filters '#{this.state.result?.id}'</h5>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <ReportFiltersTabs id={this.props.match.params.id} url="/report-filter/detail/" />
                                    {!this.state.isLoading &&  <>  
                                    <h5 className="mb-3">Basic Info</h5>
                                    <div className="row">
                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Filter Type</b>
                                                </div>
                                                <div className="card-body">{this.state.result?.filter_type}</div>
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Comparator</b>
                                                </div>
                                                <div className="card-body">{this.state.result?.comparator}</div>
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Operator</b>
                                                </div>
                                                <div className="card-body">{this.state.result?.operator}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-4">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Logical Operator</b>
                                                </div>
                                                <div className="card-body">{this.state.result?.logical_operator}</div>
                                            </div>
                                        </div>
                                        {this.state.result?.values.length != 0 && 
                                        <div className="col-4">
                                             <div className="card card-silver shadow-sm">
                                             <div className="card-header font-weight-bold"> Values</div>
                                             <div className="card-body">
                                                    {this.state.result?.values.map((item: any) => {
                                                        return (
                                                            <span  key={item.value}>
                                                                    ["{item}"]
                                                            </span>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    </div>
                                    </>}                 
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
