import { IExportColumnModel } from "../../../../../shared/models";

export const ChangeHistoryColumnList: IExportColumnModel[] = [
    {
        isAllChecked: true,
        name: "Fields from report change histories",
        value: "report_change_history",
        columns: [
            {
                name: "Id",
                value: "Id",
                checked: true,
            },
            {
                name: "Description",
                value: "Description",
                checked: true,
            },
            {
                name: "Last definition",
                value: "Last definition",
                checked: true,
            },
            {
                name: "Ip",
                value: "Ip",
                checked: true,
            },
            {
                name: "Created at",
                value: "Created at",
                checked: true,
            },
            {
                name: "Updated at",
                value: "Updated at",
                checked: true,
            }            
        ]

    },
    {
        isAllChecked: true,
        name: "Fields from associated report",
        value: "reports",
        columns: [
            {
                name: "Id",
                value: "Id",
                checked: true,
            },
            {
                name: "Name",
                value: "Name",
                checked: true,
            },
            {
                name: "Favicon url",
                value: "Favicon url",
                checked: true,
            },
            {
                name: "Created at",
                value: "Created at",
                checked: true,
            },
            {
                name: "Updated at",
                value: "Updated at",
                checked: true,
            }, 
            {
                name: "Custom sql",
                value: "Custom sql",
                checked: true,
            },
            {
                name: "Checksum",
                value: "Checksum",
                checked: true,
            },
            {
                name: "Last generated at",
                value: "Last generated at",
                checked: true,
            },
            {
                name: "Total row count",
                value: "Total row count",
                checked: true,
            },
            {
                name: "Has row matching",
                value: "Has row matching",
                checked: true,
            },
            {
                name: "Time range",
                value: "Time range",
                checked: true,
            },
            {
                name: "Enabled",
                value: "Enabled",
                checked: true,
            },
            {
                name: "Edited at",
                value: "Edited at",
                checked: true,
            },
            {
                name: "Viewed at",
                value: "Viewed at",
                checked: true,
            },
            {
                name: "Query strategy",
                value: "Query strategy",
                checked: true,
            },
            {
                name: "Always rebuild",
                value: "Always rebuild",
                checked: true,
            },
            {
                name: "Runs in redshift",
                value: "Runs in redshift",
                checked: true,
            },
            {
                name: "Alert on failure",
                value: "Alert on failure",
                checked: true,
            },
            {
                name: "Skip when priority dependent job",
                value: "Skip when priority dependent job",
                checked: true,
            },
                      
        ]

    },
    {
        isAllChecked: true,
        name: "Fields from associated user",
        value: "users",
        columns: [
            {
                name: "Id",
                value: "Id",
                checked: true,
            },
            {
                name: "Name",
                value: "Name",
                checked: true,
            },
            {
                name: "Email",
                value: "Email",
                checked: true,
            },
            {
                name: "Title",
                value: "Title",
                checked: true,
            },
            {
                name: "Phone",
                value: "Phone",
                checked: true,
            },
            {
                name: "Remember created at",
                value: "Remember created at",
                checked: true,
            },
            {
                name: "Sign in count",
                value: "Sign in count",
                checked: true,
            },
            {
                name: "Current sign in at",
                value: "Current sign in at",
                checked: true,
            },
            {
                name: "Last sign in at",
                value: "Last sign in at",
                checked: true,
            },
            {
                name: "Current sign in ip",
                value: "Current sign in ip",
                checked: true,
            },
            {
                name: "Last sign in ip",
                value: "Last sign in ip",
                checked: true,
            },
            {
                name: "Created at",
                value: "Created at",
                checked: true,
            },
            {
                name: "Updated at",
                value: "Updated at",
                checked: true,
            },
            {
                name: "Account",
                value: "account_id",
                checked: true,
            },
            {
                name: "Welcomed at",
                value: "Welcomed at",
                checked: true,
            },
            {
                name: "Demo",
                value: "Demo",
                checked: true,
            },
            {
                name: "Needs setup",
                value: "Needs setup",
                checked: true,
            },
            {
                name: "Failed attempts",
                value: "Failed attempts",
                checked: true,
            },
            {
                name: "Locked at",
                value: "Locked at",
                checked: true,
            },
            {
                name: "Invited by user",
                value: "Invited by user id",
                checked: true,
            },
            {
                name: "Billable",
                value: "Billable",
                checked: true,
            },
            {
                name: "Staq admin",
                value: "Staq admin",
                checked: true,
            }            
        ]

    },
    {
        isAllChecked: true,
        name: "Fields from associated admin",
        value: "admins",
        columns: [            
            {
                name: "Name",
                value: "Name",
                checked: true,
            },
            {
                name: "Email",
                value: "Email",
                checked: true,
            },
            {
                name: "Role",
                value: "Role",
                checked: true,
            },
            {
                name: "Team",
                value: "Team",
                checked: true,
            }                    
        ]

    },
]