import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import ModalComp from "./ModalComp"
import { CustomerFaviconService } from "./services/customer.favicons.service"
import { AppConfig, HttpResponse } from "../../../../core"
import {
  FileUploadResponseModel,
  FileUploadModel,
} from "../../../../shared/models"
import {
  ShowSuccessMessage,
  ShowErrorMessage,
} from "../../../../shared/helpers"

function Profile({ th }: any) {
  const [isLoading, setIsLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [crop, setCrop] = useState<any>({
    unit: "%", // Can be 'px' or '%'
    x: 0,
    y: 0,
    width: 50,
    height: 50,
  })
  const [image, setImage] = useState<any>(null)
  const [imgSrc, setImgSrc] = useState<any>(null)
  const [err, setErr] = useState<string>("")
  const sizeOfImg = 55
  const [fileObj, setFileObj] = useState<any>(null)

  const handleClose = () => {
    setShow(false)
    setErr("")
    setImage(null)
    setImgSrc(null)
    setFileObj(null)
  }
  const handleShow = () => {
    setShow(true)
  }
  const handleCorpComplete = (crop: any, pixalCrop: any) => {
    console.log(crop, pixalCrop, "++")
  }
  const handleOnCrop = (crop: any) => {
    setCrop(crop)
  }
  const handleImageLoaded = (e: any) => {
    setImage(e.currentTarget)
  }
  const onSelectFile = (e: any) => {
    const file = e.target.files?.[0]
    const validImageTypes = ["image/jpg", "image/jpeg", "image/png"];
    setFileObj(file)
    let sizeInKb = file?.size / 1000
    let fileType = file?.type
    if (!file) return
    if (!validImageTypes.includes(fileType)){
      setErr(`Please upload an image file.`)
      setImage(null)
      setImgSrc(null)
      e.target.value = ""
      setFileObj(null)
      return
    }
    if (sizeInKb < sizeOfImg) {
      const reader = new FileReader()
      reader.addEventListener("load", () => {
        const imageElement = new Image()
        const imageUrl = reader.result?.toString() || ""
        imageElement.src = imageUrl
        setImgSrc(imageUrl)
      })
      reader.readAsDataURL(file)
    } else {
      setErr(`image file size should be less than ${sizeOfImg} Kb`)
      setImage(null)
      setImgSrc(null)
      e.target.value = ""
      setFileObj(null)
    }
  }
  const getCroppedImg = async () => {
    setIsLoading(true)
    const canvas = document.createElement("canvas")
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext("2d")
    ctx?.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )
    const base64Image = canvas.toDataURL(fileObj.type)
    let imageCropFile = dataURLtoFile(base64Image, fileObj.name)
    let formData = new FormData()
    formData.append("file", imageCropFile)

    // remote request
    // service.postCustomerFaviconGCS(formData)

    await th.service
      .postCustomerFaviconGCS(formData)
      .then((res: HttpResponse<FileUploadResponseModel>) => {
        if (res && res.result) {
          if (res.result.id > 0) {
            ShowSuccessMessage("File Uploaded successfully.")
            th.props.history.push("/customer-favicon/list")
          }
        } else {
          ShowErrorMessage()
        }
      })

    setImgSrc(null)
    setImage(null)
    setErr("")
    handleClose()
  }
  function dataURLtoFile(dataurl: any, filename: string) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }
  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
      >
        Select Favicon File to Upload
      </Button>
      {show ? (
        <ModalComp
          show={show}
          handleClose={handleClose}
          handleCorpComplete={handleCorpComplete}
          handleOnCrop={handleOnCrop}
          crop={crop}
          handleImageLoaded={handleImageLoaded}
          onSelectFile={onSelectFile}
          getCroppedImg={getCroppedImg}
          err={err}
          imgSrc={imgSrc}
          setIsLoading={isLoading}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default Profile
