import React, { Component, Fragment } from 'react'
import { NavLink } from "react-router-dom";

interface IProps {
    id: number,
    url: string,
    name?:string
}
export class AccountDomainTabs extends Component<IProps> {
    render() {
        return (
            <Fragment>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                        <NavLink to={"/account-domain/account-domain-form/" + this.props.id} className={this.props.url == "/account-domain/account-domain-form/" ? "nav-link active" : "nav-link"} id="edit-tab" data-toggle="tab" href="#edit" role="tab"
                            aria-controls="edit" aria-selected={this.props.url == "/account-domain/account-domain-form/" ? "true" : "false"}><i className="far fa-edit"></i>&nbsp;Edit</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to={"/account-domain/delete/" + this.props.id + "/" + this.props.name} className={this.props.url == "/account-domain/delete/" ? "nav-link active" : "nav-link"} id="show-tab" data-toggle="tab" href="#delete" role="tab"
                            aria-controls="delete" aria-selected={this.props.url == "/account-domain/delete/" ? "true" : "false"}><i className="far fa-trash-alt"></i>&nbsp;Delete</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to={"/account-domain/detail/" + this.props.id} className={this.props.url == "/account-domain/detail/" ? "nav-link active" : "nav-link"} id="show-tab" data-toggle="tab" href="#show" role="tab"
                            aria-controls="show" aria-selected={this.props.url == "/account-domain/detail/" ? "true" : "false"}><i className="fas fa-info-circle"></i>&nbsp;Show</NavLink>
                    </li>
                </ul>
                <br />
            </Fragment>
        )
    }
}
