import React, { Component, Fragment } from 'react'
import { NavLink } from "react-router-dom";
import { IsAuthorize } from '../../../../shared/authorization/check-access';
import { Role } from '../../../../shared/authorization/enums';


interface IProps {
    id: number,
    url: string,    
    schemaName?:string,
    isSchema?:boolean,
    scopeId?:number 
    
}
export  class FieldsTabs extends Component<IProps> {
    render() {
        return (
            <Fragment>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
                <NavLink to={"/field/field-form/" + this.props.id+"/"+this.props.schemaName+"/"+this.props.scopeId} className={this.props.url=="/field/field-form/"?"nav-link active":"nav-link"} id="show-tab" data-toggle="tab" href="#edit" role="tab"
                    aria-controls="edit" aria-selected={this.props.url=="/field/field-form/"?"true":"false"}><i className="far fa-edit"></i>&nbsp;Edit</NavLink>
            </li>
                <li className="nav-item">
                    <NavLink to={"/field/detail/" + this.props.id+"/"+this.props.schemaName+"/"+this.props.scopeId} className={this.props.url == "/field/detail/" ? "nav-link active" : "nav-link"} id="detail-tab" data-toggle="tab" href="#edit" role="tab"
                        aria-controls="detail" aria-selected={this.props.url == "/field/detail/" ? "true" : "false"}><i className="fas fa-info-circle"></i>&nbsp;Show</NavLink>
                </li>
              {this.props.isSchema && IsAuthorize([Role.TAM, Role.Dev]) &&   <li className="nav-item">
                            <NavLink to={"/field/schema-manager/" + this.props.id+"/"+this.props.schemaName+"/"+this.props.scopeId} className={this.props.url == "/field/schema-manager/" ? "nav-link active" : "nav-link"} id="connection-maintain-tab" data-toggle="tab" href="#connection-maintain" role="tab"
                                aria-controls="connection-maintain" aria-selected={this.props.url == "/field/schema-manager/" ? "true" : "false"}><i className="fas fa-check"></i>&nbsp;Schema Manager</NavLink>
                        </li>}
            </ul>
            <br />
        </Fragment> 
        )
    }
}
