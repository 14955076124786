import React, { Component, Fragment } from "react";
import { InvocationScheduleDetailModel } from "./models";
import { InvocationSchedulesService } from "./services/invocation-schedule.service";
import { HttpResponse } from "../../../../core";
import {
  HandleNotFoundResponse,
  ConvertDateTime,
} from "../../../../shared/helpers";
import { NotFoundResponseArea } from "../../../../shared/enums";
import { InvocationSchedulesTabs } from "./InvocationSchedulesTabs";
import { Link } from "react-router-dom";
import { DropdownItemModel } from "../../../../shared/models";
import { Loader } from "../../../../shared/loaders";

interface IState {
  result?: InvocationScheduleDetailModel;
  isLoading?: boolean;
}

export class InvocationSchedulesDetail extends Component<any, IState> {
  private service: InvocationSchedulesService;
  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.service = new InvocationSchedulesService();
  }

  // Defines the initial state for the values coming from API.
  initialState: Partial<IState> = {
    result: {
      id: 0,
      run_at_time: "",
      installed_report_application_name: "",
      installed_report_application_id: 0,
      created_at : new Date(),
      updated_at: new Date()
    },
    isLoading: false,
  };

  //Get the detail from the API whenever the page loads.
  componentDidMount() {
    this.loadData();
  }
  private setLoading(loading: boolean) {
    this.setState({ isLoading: loading });
}
  //function to load the initial view data coming from the API.
  loadData() {
    this.setLoading(true);
    this.service
      .getInvocationScheduleById(this.props.match.params.id)
      .then((res: HttpResponse<InvocationScheduleDetailModel>) => {
        this.setLoading(false);
        if (res && res.result) {
          this.setState({
            result: res.result,
          });
        }
      })
      .catch((ex) => {
        HandleNotFoundResponse(ex, NotFoundResponseArea.InvocationSchedules, this.props);
      });
  }

  render() {
    return (
      <Fragment>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h5>Details for Invocation Schedule 'InvocationSchedule #{this.state.result?.id}'</h5>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <InvocationSchedulesTabs
                  id={this.props.match.params.id}
                  name={this.state.result?.installed_report_application_name}
                  url="/invocation-schedule/detail/"
                />
                {!this.state.isLoading &&  <>  
                <h5 className="mb-3">Basic Info</h5>
                <div className="row">
                  {this.state.result?.run_at_time && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Run at time</b>
                        </div>
                        <div className="card-body">
                          {this.state.result?.run_at_time}
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.installed_report_application_name && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Installed report application</b>
                        </div>
                        <div className="card-body">
                        <Link to={"/installed-report-application/detail/" + this.state.result?.installed_report_application_id}>{this.state.result?.installed_report_application_name}</Link>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.created_at && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Created At</b>
                        </div>
                        <div className="card-body">
                          {ConvertDateTime(this.state.result?.created_at, 'LLL')}
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.updated_at && (
                    <div className="col-4">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Updated At</b>
                        </div>
                        <div className="card-body">
                          {ConvertDateTime(this.state.result?.updated_at,'LLL')}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                </>}                 
                 {this.state.isLoading && <Loader loading={this.state.isLoading} />}    
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
