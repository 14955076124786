import React, { Component, Fragment } from 'react'
import Breadcrumbs from '../../shared/Breadcrumbs'
import { TagLocationCategoryRoute } from '../../routing'

export class TagLocationCategoriesPage extends Component {
    render() {
        return (
            <Fragment>
            <Breadcrumbs />
            <TagLocationCategoryRoute />
          </Fragment>
        )
    }
}
