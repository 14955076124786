import { ChangeType, SchemaChangeFieldTypesEnum } from "../enums/change.type.enum";

export const GetChangeTypeList = [   
        {text:"", value:""}, 
        { text: ChangeType.add, value:  ChangeType.add },
        { text: ChangeType.delete, value:  ChangeType.delete },
        { text: ChangeType.rename, value:  ChangeType.rename },
        { text: ChangeType.change_type, value:  ChangeType.change_type }
    
]

export const GetFieldChangeTypeList = [   
    {text:"", value:""},     
    { text: ChangeType.delete, value:  ChangeType.delete },
    { text: ChangeType.rename, value:  ChangeType.rename },
    { text: ChangeType.change_type, value:  ChangeType.change_type }

]


export const GetFieldTypeList = [  
    { text: "String (Less than 255 characters)", value:  SchemaChangeFieldTypesEnum.string },  
    { text: "Text (More than 255 characters)", value:  SchemaChangeFieldTypesEnum.text },
    { text: "Date", value:  SchemaChangeFieldTypesEnum.date },
    { text: "Time", value:  SchemaChangeFieldTypesEnum.time },
    { text: "Integer (A whole number less than 2,147,483,647)", value: SchemaChangeFieldTypesEnum.integer},
    { text: "Big Integer (A whole number larger than 2,147,483,647)", value: SchemaChangeFieldTypesEnum.big_integer },    
    { text: "Float (A number with a decimal)", value:  SchemaChangeFieldTypesEnum.float },
    { text: "Currency (Money)", value: SchemaChangeFieldTypesEnum.currency },
    { text: "Boolean (true and false)", value: SchemaChangeFieldTypesEnum.boolean },
    { text: "URL", value:  SchemaChangeFieldTypesEnum.url}
    

]

export const GetFieldList = [    
    {text:"", value:0}, 
    { text: "Date", value:  "703705" },
    { text: "Ad unit", value:  "703706" },
    { text: "Country", value:  "703707" },
    { text: "Line item", value:  "703708" },
    { text: "Line item type", value:  "703709" },
    { text: "Creative size", value:  "703710" },
    { text: "Device category", value:  "703711" },
    { text: "Advertiser ID", value:  "703712" },
    { text: "Advertiser", value:  "703713" },
    { text: "Order", value:  "703714" },
    { text: "Order ID", value:  "703715" },
    { text: "Programmatic channel", value:  "703716" },
    { text: "Programmatic buyer", value:  "703717" },
    { text: "Demand channel", value:  "703718" },
    { text: "Advertiser (classified)", value:  "703719" },
    { text: "Brand (classified)", value:  "703720" },
    { text: "Ad unit ID", value:  "703721" },
    { text: "Country ID", value:  "703722" },
    { text: "Line item ID", value:  "703723" },
    { text: "Device category ID", value:  "703724" },
    { text: "Programmatic buyer ID", value:  "703725" },
    { text: "Demand channel ID", value:  "703726" },
    { text: "Programmatic order", value:  "703727" },
    { text: "Total impressions", value:  "703728" },
    { text: "Total clicks", value:  "703729" },
    { text: "Total CPM and CPC revenue", value:  "703730" },
    { text: "Total CPM, CPC, CPD, and vCPM revenue", value:  "703731" },
    { text: "Total Active View viewable impressions", value:  "703732" },
    { text: "Total Active View measurable impressions", value:  "703733" }
]