import { RegexOptionsEnum } from "../enums";

export const GetRejexOptionsList = [
    { text: RegexOptionsEnum.Extended, value: RegexOptionsEnum.Extended },
    { text: RegexOptionsEnum["Ignore Case"], value: RegexOptionsEnum["Ignore Case"] },
    { text: RegexOptionsEnum["Ignore Case and Extended"], value: RegexOptionsEnum["Ignore Case and Extended"] },
    { text: RegexOptionsEnum["Ignore Case and Multiline"], value: RegexOptionsEnum["Ignore Case and Multiline"] },
    { text: RegexOptionsEnum["Ignore Case, Multiline, and Extended"], value: RegexOptionsEnum["Ignore Case, Multiline, and Extended"] },
    { text: RegexOptionsEnum.Multiline, value: RegexOptionsEnum.Multiline },
    { text: RegexOptionsEnum["Multiline and Extended"], value: RegexOptionsEnum["Multiline and Extended"] }
    
]