import React, { Component, Fragment } from "react";
import Breadcrumbs from "../../shared/Breadcrumbs";
import { CustomerLogoRoute } from "../../routing";

export class CustomerLogoPage extends Component {
  render() {
    return (
      <Fragment>
        <Breadcrumbs />
        <CustomerLogoRoute />
      </Fragment>
    );
  }
}
