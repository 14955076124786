import { HttpWrapper, AppConfig } from "../../../../../core";
import { InstalledApplicationListResponseModel } from "../models/installed.application.list.response.model";
import { PostExportModel } from "../../../../../shared/export/post.export.model";
import { InstalledApplicationModel, EditInstalledApplicationModel, InstalledApplicationDetailModel, InstalledApplicationDeleteModel, InstalledApplicationDeleteResponseModel, ControlMissionPostModel } from "../models";
import { FileUploadResponseModel } from "../../../../../shared/models";

export class InstalledApplicationService {
    private wrapper: HttpWrapper;
    constructor() {
        this.wrapper = new HttpWrapper();
    }

    getInstalledApplicationList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
        return this.wrapper.post<InstalledApplicationListResponseModel>(AppConfig.apiEndpoint + '/v1/installed_report_application/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }

    postInstalledApplication(installedApplication: InstalledApplicationModel) {
        return this.wrapper.post<InstalledApplicationModel>(AppConfig.apiEndpoint + '/v1/installed_report_application', installedApplication);
    }

    editInstalledApplication(id?: number) {
        return this.wrapper.get<EditInstalledApplicationModel>(AppConfig.apiEndpoint + '/v1/installed_report_application/edit/' + id);
    }

    updateInstalledApplication(installedApplication: InstalledApplicationModel) {
        return this.wrapper.put<InstalledApplicationModel>(AppConfig.apiEndpoint + '/v1/installed_report_application/' + installedApplication.id, installedApplication);
    }

    getInstalledApplicationById(id: number) {
        return this.wrapper.get<InstalledApplicationDetailModel>(AppConfig.apiEndpoint + '/v1/installed_report_application/' + id);
    }

    getLinkedResourcesById(id: number) {
        return this.wrapper.get<InstalledApplicationDeleteModel>(AppConfig.apiEndpoint + '/v1/installed_report_application/' + id+'/linked_resources');
    }

    deleteRecordById(id: number) {
        return this.wrapper.delete<InstalledApplicationDeleteResponseModel>(AppConfig.apiEndpoint + '/v1/installed_report_application/' + id);
    }

    createControlMission(data: ControlMissionPostModel) {
        return this.wrapper.post<FileUploadResponseModel>(AppConfig.apiEndpoint + '/v1/installed_report_application/control_mission', data);
    }


    exportInstalledApplication(exportData?: PostExportModel) {
        return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/installed_report_application/export', exportData);
    }

}