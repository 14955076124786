import React, { Component, Fragment } from 'react'
import { AccountDomainDetailModel } from './models/account.domain.detail.model'
import { AccountDomainService } from './services/account.domain.service';
import { HttpResponse } from '../../../core';
import { AccountDomainTabs } from './AccountDomainTabs';
import { NavLink } from "react-router-dom";
import { HandleNotFoundResponse } from '../../../shared/helpers';
import { NotFoundResponseArea } from '../../../shared/enums';
import { Loader } from '../../../shared/loaders';

interface IState {
    result?: AccountDomainDetailModel;
    isLoading?: boolean;
}
export class AccountDomainDetail extends Component<any, IState> {
    private service: AccountDomainService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new AccountDomainService();
    }
    initialState: Partial<IState> = {
        result: {
            account: {
                text: "",
                value: 0
            },
            admin: {
                text: "",
                value: 0
            },
            id: 0,
            name: ""
        },
        isLoading: false,
    }

    componentDidMount() {
        this.loadData();
    }
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getAccountDomainById(this.props.match.params.id)
            .then((res: HttpResponse<AccountDomainDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.AccountDomain,this.props)
            });
    }
    render() {
        return (
            <Fragment>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5>Details for Account Domain '{this.state.result?.name}'</h5>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <AccountDomainTabs id={this.props.match.params.id} name={this.state.result?.name} url="/account-domain/detail/" />
                                {!this.state.isLoading && <>  
                                <h5 className="mb-3">Basic Info</h5>
                                <div className="row">
                                    <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Name</b>
                                            </div>
                                            <div className="card-body">
                                               {this.state.result?.name}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Account</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.account && <NavLink to={"/account/detail/" + this.state.result?.account.value}>{this.state.result?.account.text}</NavLink>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Last Updated By</b>
                                            </div>
                                            <div className="card-body">
                                            <NavLink to={"/admin/detail/" +this.state.result?.admin.value}>{this.state.result?.admin.text}</NavLink>                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </>}                 
                        {this.state.isLoading && <Loader loading={this.state.isLoading} />}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
