import React, { Component, Fragment } from 'react'
import { BrowserRouter } from "react-router-dom";
import { UserReportRoute } from '../../routing/UserRoutes'
import Breadcrumbs from '../../shared/Breadcrumbs';

export  class UserReportPage extends Component {
    render() {
        return (
           
                <Fragment>
                    <Breadcrumbs />
                    <UserReportRoute />
                </Fragment>
        )
    }
}
