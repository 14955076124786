import React, { Component, Fragment } from "react";
import {
  ConnectionPostModel,
  EditConnectionModel,
  ExtractorTimeZoneSpecification,
} from "./models";
import { DropdownItemModel, ParamaResponseModel, ParamsVersionPostModel, ParamsVersionsModel } from "../../../shared/models";
import { CommonService } from "../../../shared/services/common.service";
import { ConnectionsService } from "./services";
import {
  ConnectionState,
  ButtonType,
  NotFoundResponseArea,
} from "../../../shared/enums";
import { HttpResponse } from "../../../core";
import {
  ShowSuccessMessage,
  GetConnectionStateList,
  GetAllTimeZone,
  Capitalize,
  HandleNotFoundResponse, ConvertDateTime
} from "../../../shared/helpers";
import { ValidateYaml } from "../../../shared/helpers/yaml.helper";
import { Link } from "react-router-dom";
import { Loader } from "../../../shared/loaders";
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { Editor } from "../../../shared";

interface IProps {
  handleClose: Function,
  id?:number;
  isNew?:boolean;    
  updateConnection:Function
} 
interface IState {  
  connection?: ConnectionPostModel;
  editConnecion?: EditConnectionModel;
  id?: number;
  name?: string;
  available_to_external_users?: boolean;
  billable?: boolean;
  state?: string;
  state_message?: string;
  ignore_health?: boolean;
  data_retention_days?: number;
  maintenance_mode?: boolean;
  authorization_unstable_at?: Date | null;
  authorization_unstable?: boolean;

  params?: string;
  paramsError?: string;
  time_zone?: string;
  internal_notes?: string;

  isShowtext?: string;
  isSave?: string;
  isSaving?: boolean;

  platform_id?: number;
  platformList?: DropdownItemModel[];
  platformName?: string;
  platformError?: string;

  account_id?: number;
  accountList?: DropdownItemModel[];
  accountName?: string;
  accountError?: string;

  custom_extraction_scheduling_recipe_id?: number | null;
  customExtractionSchedulingRecipeList?: DropdownItemModel[];
  customExtractionSchedulingRecipeName?: string;

  data_source?: DropdownItemModel;
  data_source_id?: number;
  dataSourceName?: string;
  dataSourceList?: DropdownItemModel[];

  inbound_email_addresses?: Array<DropdownItemModel>;
  extractor_time_zone_specification?: ExtractorTimeZoneSpecification | null;
  paramsList?: Array<any>;

  isNew?:boolean;
  isChildUpdate?:boolean;

  paramsVersionsList?: Array<ParamsVersionsModel>;
  paramsVersion?:number;
}
export class ConnectionFormPopup extends Component<IProps, IState> {
  //declaring service type
  private service: ConnectionsService;
  private commonService: CommonService;
  private networkCall: any;

  constructor(props:IProps) {
    super(props);
    this.service = new ConnectionsService();
    this.commonService = new CommonService();
    this.state = this.initialState;
    this.editorhandleParams = this.editorhandleParams.bind(this);
    this.handleClose = this.handleClose.bind(this);    
  }

  initialState: Partial<IState> = {
    connection: {
      account_id: 0,
      authorization_unstable_at: null,
      available_to_external_users: false,
      billable: true,
      custom_extraction_scheduling_recipe_id: 0,
      // data_retention_days: 0,
      data_source_id: 0,
      id: 0,
      ignore_health: false,
      internal_notes: "",
      maintenance_mode: false,
      name: "",
      params: "",
      platform_id: 0,
      state: ConnectionState.NEW,
      state_message: "",
      time_zone: "",
    },
    account_id: 0,
    authorization_unstable_at: null,
    available_to_external_users: false,
    billable: true,
    custom_extraction_scheduling_recipe_id: null,
    data_retention_days: 0,
    data_source_id: 0,
    dataSourceName: "",
    id: 0,
    ignore_health: false,
    internal_notes: "",
    maintenance_mode: false,
    name: "",
    params: "",
    platform_id: 0,
    state: ConnectionState.NEW,
    state_message: "",
    time_zone: "UTC",
    accountError: "",
    accountList: [],
    accountName: "",
    customExtractionSchedulingRecipeList: [],
    dataSourceList: [],
    platformList: [],
    platformError: "",
    platformName: "",
    paramsError: "",
    authorization_unstable: false,

    isShowtext: "New",
    isSave: "",
    isSaving: false,
    inbound_email_addresses: [],
    data_source: {
      text: "",
      value: 0,
    },
    paramsVersionsList:[],
    paramsVersion:0
  };

  handleClose = event => {
    event.preventDefault();
    this.props.handleClose();        
}

  componentDidMount() {
    if(this.props.isNew)
    {
      this.setState({id:0})
    }
    if (!this.props.isNew) {      
      this.setState({ id: this.props.id, isShowtext: "Edit" }, () => {
          this.loadData()
      });
  }
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  // componentWillReceiveProps(nextprops) {
  //   if (nextprops.match.params.id > 0) {
  //     this.loadData(nextprops.match.params.id);
  //   }
  // }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (event.path[0].id !== "accountName" && event.path[0].id !== "account" && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight) {
      this.setState(
        {
          accountList: [],
        },
        () => {}
      );
    }

    if (
      event.path[0].id !== "platformName" &&
      event.path[0].id !== "platform" && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight
    ) {
      this.setState(
        {
          platformList: [],
        },
        () => {}
      );
    }

    if (
      event.path[0].id !== "customExtractionSchedulingRecipename" &&
      event.path[0].id !== "cesr" && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight
    ) {
      this.setState(
        {
          customExtractionSchedulingRecipeList: [],
        },
        () => {}
      );
    }

    if (
      event.path[0].id !== "dataSourceName" &&
      event.path[0].id !== "dataSource" && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight
    ) {
      this.setState(
        {
          dataSourceList: [],
        },
        () => {}
      );
    }
  };

  loadData() {
    this.service
      .editConnection(this.props.id)
      .then((res: HttpResponse<EditConnectionModel>) => {
        if (res && res.result) {
          this.setState({
            id: res.result?.id,
            accountName:
              res.result?.account === null ? "" : res.result?.account.text,
            account_id:
              res.result?.account === null ? 0 : res.result?.account.value,
            platformName:
              res.result?.platform === null ? "" : res.result?.platform.text,
            platform_id:
              res.result?.platform === null ? 0 : res.result?.platform.value,
            custom_extraction_scheduling_recipe_id:
              res.result?.custom_extraction_scheduling_recipe_id,
            customExtractionSchedulingRecipeName:
              res.result?.custom_extraction_scheduling_recipe_id != null
                ? "ExtractionSchedulingRecipe #" +
                  res.result?.custom_extraction_scheduling_recipe_id
                : "",
            data_source_id: res.result?.data_source_id,
            data_source: res.result?.data_source,
            authorization_unstable:
              res.result?.authorization_unstable_at == null ? false : true,
            available_to_external_users:
              res.result?.available_to_external_users,
            billable: res.result?.billable,
            data_retention_days: res.result?.data_retention_days,
            ignore_health: res.result?.ignore_health,
            internal_notes: res.result?.internal_notes,
            maintenance_mode: res.result?.maintenance_mode,
            name: res.result?.name,
            params: res.result?.params == null ? "" : res.result?.params,
            state: res.result?.state,
            state_message: res.result?.state_message,
            time_zone: res.result?.time_zone==""?"UTC":res.result?.time_zone,
            inbound_email_addresses: res.result?.inbound_email_addresses,
            extractor_time_zone_specification:
              res.result?.extractor_time_zone_specification,
              paramsVersionsList:res.result?.params_versions
          });
        } 
      })
      .catch((ex) => {
        HandleNotFoundResponse(ex, NotFoundResponseArea.Connection, this.props);
      });
  }

  onParamsChange = (e) => {
    const value = e.target.value;  
    let data:ParamsVersionPostModel = {
      connection_id:this.state?.id || 0,
      connection_type:"connection",
      id:value
    }

    this.commonService
      .changeParamsByVersion(data)
      .then((res: HttpResponse<ParamaResponseModel>) => {
        if (res && res.result) {
          this.setState({params:res.result.params})
        }
      });

  }

  private getAccounts(text: string) {
    this.commonService
      .getAccountsByName(text)
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res) {
          this.setState({
            accountList: res.result,
          });
        }
      });
  }

  private getPlatforms(text: string) {
    this.commonService
      .getPlatformByName(text)
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res) {
          this.setState({
            platformList: res.result,
          });
        }
      });
  }

  private getCustomExtractionSchedulingRecipes(id?: number) {
    this.commonService
      .getCustomExtractionSchedulingRecipeByName(id)
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res) {
          this.setState({
            customExtractionSchedulingRecipeList: res.result,
          });
        }
      });
  }

  private getDataSources(id?: number) {
    this.commonService
      .getDataSourceById(id)
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res) {
          this.setState({
            dataSourceList: res.result,
          });
        }
      });
  }

  //*************** * Accounts************************* //

  onAccountChange = (e) => {
    const value = e.target.value;
    if (value.length > 0) {
      if (this.networkCall) {
        clearTimeout(this.networkCall);
      }
      this.networkCall = setTimeout(() => {
        this.getAccounts(value);
      }, 600);
    }
    this.setState({
      accountName: value,
      account_id: value === "" ? 0 : value,
      accountError: "",
    });
  };

  selectedAccount(item) {
    this.setState({
      account_id: item.value,
      accountName: item.text,
      accountList: [],
    });
  }

  renderAccount = () => {
    if (this.state.accountList?.length === 0) {
      return null;
    }
    return (
      <ul className="list-unstyled auto-suggest">
        {this.state.accountList?.map((item, index) => (
          <li
            id="account"
            key={index}
            onClick={() => this.selectedAccount(item)}
          >
            {item.text}
          </li>
        ))}
      </ul>
    );
  };
  //*************** * Accounts************************* //

  //*************** * Platform************************* //

  onPlatformChange = (e) => {
    const value = e.target.value;
    if (value.length > 0) {
      if (this.networkCall) {
        clearTimeout(this.networkCall);
      }
      this.networkCall = setTimeout(() => {
        this.getPlatforms(value);
      }, 600);
    }
    this.setState({
      platformName: value,
      platform_id: value === "" ? 0 : value,
      platformError: "",
    });
  };

  selectedPlatform(item) {
    this.setState({
      platform_id: item.value,
      platformName: item.text,
      platformList: [],
    });
  }

  renderPlatform = () => {
    if (this.state.platformList?.length === 0) {
      return null;
    }
    return (
      <ul className="list-unstyled auto-suggest">
        {this.state.platformList?.map((item, index) => (
          <li
            id="platform"
            key={index}
            onClick={() => this.selectedPlatform(item)}
          >
            {item.text}
          </li>
        ))}
      </ul>
    );
  };
  //*************** *Platform************************* //

  //*************** * Custom Extraction Scheduling Recipe************************* //

  oncustomExtractionSchedulingRecipeChange = (e) => {
    const value = e.target.value;
    if (value.length > 0) {
      if (this.networkCall) {
        clearTimeout(this.networkCall);
      }
      this.networkCall = setTimeout(() => {
        this.getCustomExtractionSchedulingRecipes(value);
      }, 600);
    }
    this.setState({
      customExtractionSchedulingRecipeName: value,
      custom_extraction_scheduling_recipe_id: value === "" ? 0 : value,
    });
  };

  selectedExtractionSchedulingRecipe(item) {
    this.setState({
      custom_extraction_scheduling_recipe_id: item.value,
      customExtractionSchedulingRecipeName:
        "ExtractionSchedulingRecipe #" + item.value,
      customExtractionSchedulingRecipeList: [],
    });
  }

  renderExtractionSchedulingRecipe = () => {
    if (this.state.customExtractionSchedulingRecipeList?.length === 0) {
      return null;
    }
    return (
      <ul className="list-unstyled auto-suggest">
        {this.state.customExtractionSchedulingRecipeList?.map((item, index) => (
          <li
            id="cesr"
            key={index}
            onClick={() => this.selectedExtractionSchedulingRecipe(item)}
          >
            ExtractionSchedulingRecipe #{item.value}
          </li>
        ))}
      </ul>
    );
  };
  //*************** *Extraction Scheduling Recipe************************* //

  //*************** * Data Source************************* //

  onDataSourceChange = (e) => {
    const value = e.target.value;
    if (value.length > 0) {
      if (this.networkCall) {
        clearTimeout(this.networkCall);
      }
      this.networkCall = setTimeout(() => {
        this.getDataSources(value);
      }, 600);
    }
    this.setState({
      dataSourceName: value,
      data_source_id: value === "" ? 0 : value,
    });
  };

  selectedDataSource(item) {
    this.setState({
      data_source_id: item.value,
      dataSourceName: item.text + " Source #" + item.value,
      dataSourceList: [],
    });
  }

  renderDataSource = () => {
    if (this.state.dataSourceList?.length === 0) {
      return null;
    }
    return (
      <ul className="list-unstyled auto-suggest">
        {this.state.dataSourceList?.map((item, index) => (
          <li
            id="dataSource"
            key={index}
            onClick={() => this.selectedDataSource(item)}
          >
            {item.text} Source #{item.value}
          </li>
        ))}
      </ul>
    );
  };
  //*************** *Data Source************************* //

  handleChange = (event: any) => {
    if (event.target.name == "paramsVersion") {
      this.onParamsChange(event);
    }
    if (event.target.name == "accountName") {
      this.onAccountChange(event);
    }
    if (event.target.name == "platformName") {
      this.onPlatformChange(event);
    }
    if (event.target.name == "customExtractionSchedulingRecipename") {
      this.oncustomExtractionSchedulingRecipeChange(event);
    }
    if (event.target.name == "dataSourceName") {
      this.onDataSourceChange(event);
    }
    const isCheckbox = event.target.type === "checkbox";
    let name = event.target.name;
    let value = event.target.value;
    this.setState({
      [event.target.name]: isCheckbox
        ? event.target.checked
        : event.target.value,
    });
  };

  editorhandleParams(data) {
    this.setState({
      params: data,
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if(!this.state.isChildUpdate)
    { 
    const isValid = this.validate();
    const isParamsValid = this.validateParams();
    let accountData: ConnectionPostModel = {
      name: this.state.name,
      id: this.state.id,
      account_id: this.state.account_id,
      available_to_external_users: this.state.available_to_external_users,
      billable: this.state.billable,
      custom_extraction_scheduling_recipe_id: this.state
        .custom_extraction_scheduling_recipe_id,
      // data_retention_days: this.state.data_retention_days,
      data_source_id: this.state.data_source_id,
      ignore_health: this.state.ignore_health,
      internal_notes: this.state.internal_notes,
      maintenance_mode: this.state.maintenance_mode,
      params: this.state.params,
      platform_id: this.state.platform_id,
      state: this.state.state,
      state_message: this.state.state_message,
      time_zone: this.state.time_zone,
      authorization_unstable: this.state.authorization_unstable,
    };

    if (isValid && isParamsValid) {
      if (accountData.id === 0) {
        this.postData(accountData);
      } else {
        this.updateData(accountData);
      }
    }
  }
  };

  validate = () => {
    let accountError = "";
    let platformError = "";

    if (!this.state.account_id) {
      accountError = "Account can't be blank";
    }
    if (!this.state.platform_id) {
      platformError = "Platform can't be blank";

      if (platformError || accountError) {
        this.setState({
          platformError: platformError,
          accountError: accountError,
        });
        return false;
      }
    } else {
      platformError = "";
      accountError = "";
      this.setState({
        platformError: platformError,
        accountError: accountError,
      });
      return true;
    }
  };

  validateParams() {
    let isValid = ValidateYaml(this.state.params);
    if (isValid) {
      this.setState({ paramsError: "" });
      return true;
    }
    if (!isValid) {
      this.setState({
        paramsError: "Parameters is not valid please type valid YAML",
      });
      return false;
    }
  }

  postData(account: ConnectionPostModel) {
    this.setSavingFlag(true);
    this.service.postConnection(account).then(
      (res: HttpResponse<ConnectionPostModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              id: res.result.id,
            },
            () => {
              ShowSuccessMessage("Connection successfully created.");
              if (this.state.isSave === ButtonType.Save) {
                this.setState(this.initialState);
                this.props.updateConnection(true,this.state.name,this.state.id);
                this.props.handleClose();
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  updateData(account: ConnectionPostModel) {
    this.setSavingFlag(true);
    this.service.updateConnection(account).then(
      (res: HttpResponse<ConnectionPostModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              id: res.result.id,
            },
            () => {
              ShowSuccessMessage("Connection successfully updated.");
              if (this.state.isSave === ButtonType.Save) {
                this.setState(this.initialState);
                this.props.updateConnection(true,this.state.name,this.state.id);
                this.props.handleClose();
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  handleClick = (event) => {
    this.setState({ isSave: event.target.value },()=>{
      this.props.updateConnection(true,this.state.name,this.state.id);
  })
  };
  private setSavingFlag(saving: boolean) {
    this.setState({ isSaving: saving });
  }

  render() {
    return (
      <Fragment>
        <form onSubmit={this.handleSubmit}>
        <Modal.Header closeButton onClick={this.handleClose}>
                    </Modal.Header>
                    <Modal.Body>
          <input type="hidden" value={this.state.id} />
          <div>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h5>
                {this.state.isShowtext} Connection
                {this.state.name && <span> '{this.state.name}'</span>}
              </h5>
            </div>            
            <div className="row">
              <div className="col-md-12 col-xl-12">
               
                    <div className="row">
                      <div className="col-xl-4 col-md-4">
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            type="text"
                            maxLength={255}
                            name="name"
                            value={this.state.name}
                            placeholder="Enter Name"
                            onChange={this.handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mb-2 position-relative">
                          <label>
                            Platform<span className=" text-danger">*</span>
                          </label>
                          {this.state.id == 0 && (
                            <div className="input-group ">
                              <input
                                autoComplete="off"
                                id="platformName"
                                name="platformName"
                                type="text"
                                onChange={this.handleChange}
                                value={this.state.platformName}
                                className={
                                  !this.state.platformError
                                    ? "form-control"
                                    : "form-control  is-invalid"
                                }
                              />
                              {this.renderPlatform()}
                             
                              <div className="invalid-feedback">
                                {this.state.platformError}
                              </div>
                            </div>
                          )}
                          {this.state.id != 0 && (
                            <div>
                            {" "}
                            <Link
                              to={"/platform/detail/" + this.state.platform_id}
                              className="badge badge-light"
                            >
                              {this.state.platformName}
                            </Link>
                          </div>
                          )}
                          <small>
                            Cannot be changed for existing connections. See
                            <a href="https://www.pivotaltracker.com/n/projects/804865/stories/107725144">
                              #107725144
                            </a>
                            for more.
                          </small>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mb-2 position-relative">
                          <label>
                            Account<span className=" text-danger">*</span>
                          </label>
                          {this.state.id == 0 && (
                            <div className="input-group ">
                              <input
                                autoComplete="off"
                                id="accountName"
                                name="accountName"
                                type="text"
                                onChange={this.handleChange}
                                value={this.state.accountName}
                                className={
                                  !this.state.accountError
                                    ? "form-control"
                                    : "form-control  is-invalid"
                                }
                              />
                              {this.renderAccount()}
                              <div className="invalid-feedback">
                                {this.state.accountError}
                              </div>
                            </div>
                          )}
                          {this.state.id != 0 && (
                            <div>
                              {" "}
                              <Link
                                to={"/account/detail/" + this.state.account_id}
                                className="badge badge-light"
                              >
                                {this.state.accountName}
                              </Link>
                            </div>
                          )}
                          <small>Once this is set, it cannot be changed.</small>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group shadow-sm p-3 mb-4 ">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="available_to_external_users"
                              checked={this.state.available_to_external_users}
                              onChange={this.handleChange}
                              className="custom-control-input"
                              id="available_to_external_users"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="available_to_external_users"
                            >
                              Available Externally?
                            </label>
                          </div>
                          <small>
                            {" "}
                            External users can see all details of the connection
                            if this is checked{" "}
                          </small>
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4">
                        <div className="form-group shadow-sm p-3 mb-4 ">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="billable"
                              checked={this.state.billable}
                              onChange={this.handleChange}
                              className="custom-control-input"
                              id="billable"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="billable"
                            >
                              Billable
                              {/* <OverlayTrigger
                              overlay={
                                <Tooltip id="button-tooltip">
                                  If this flag is set, this connection is
                                  included in the count on account management
                                  page in staqweb.
                                </Tooltip>
                              }
                            >
                              <a className="btn btn-outline-dark border-0 btn-sm ml-1">
                                <i className="fas fa-info-circle"></i>
                              </a>
                            </OverlayTrigger> */}
                            </label>
                          </div>
                          <small>
                            If active, this connection is included in the count
                            on account management page in staqweb.
                          </small>
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4 ">
                        <div className="form-group form-group-sm shadow-sm p-3 mb-4 ">
                          <label>State </label>
                          <select
                            className="form-control form-control-sm "
                            name="state"
                            value={this.state.state}
                            onChange={this.handleChange}
                          >
                            {GetConnectionStateList.map((item, index) => {
                              return (
                                <option key={index} value={item.value}>
                                  {item.value}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group shadow-sm p-3 mb-4 ">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="ignore_health"
                              checked={this.state.ignore_health}
                              onChange={this.handleChange}
                              className="custom-control-input"
                              id="ignore_health"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="ignore_health"
                            >
                              Ignore Health
                            </label>
                          </div>
                          <small>
                            Check it to activate Ignore Health Option <br />
                            <br />
                          </small>
                        </div>
                      </div>
                      <div className="col-md-4 ">
                        <div className="form-group shadow-sm p-3 mb-4 ">
                          <label>
                            Data Retention Days
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="button-tooltip">
                                  The number of days to retain data in
                                  connections and custom connections. Default
                                  for an account is 1095 (3 years). A value of
                                  zero or less disables purging of old data for
                                  this connection entirely. No value specified
                                  uses the account default.
                                </Tooltip>
                              }
                            >
                              <a className="btn btn-outline-dark border-0 btn-sm ml-1">
                                <i className="fas fa-info-circle"></i>
                              </a>
                            </OverlayTrigger>
                          </label>
                          <input
                            type="number"
                            name="data_retention_days"
                            value={this.state.data_retention_days}
                            onChange={this.handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4">
                        <div className="form-group shadow-sm p-3 mb-4">
                          <label>State Message</label>
                          <input
                            type="text"
                            maxLength={255}
                            name="state_message"
                            value={this.state.state_message}
                            placeholder="Enter State Message"
                            onChange={this.handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group shadow-sm p-3 mb-4 ">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="maintenance_mode"
                              checked={this.state.maintenance_mode}
                              onChange={this.handleChange}
                              className="custom-control-input"
                              id="maintenance_mode"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="maintenance_mode"
                            >
                              Maintenance Mode
                            </label>
                          </div>
                          <small>
                            If this flag is set, a maintenance mode message is
                            shown to users in connection manager.
                          </small>
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4">
                        <div className="form-group shadow-sm p-3 mb-4 ">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="authorization_unstable"
                              checked={this.state.authorization_unstable}
                              onChange={this.handleChange}
                              className="custom-control-input"
                              id="authorization_unstable"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="authorization_unstable"
                            >
                              Authorization Unstable At
                            </label>
                          </div>
                          <small>
                            {" "}
                            If this flag is set, once a day, a single Connection
                            Verifier mission for this connection is run.
                          </small>
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-4">
                        <div className="form-group">
                          <label>Time Zone </label>
                          {this.state.id == 0 && (
                            <small>
                              Please set the connection's time zone after you
                              save it.
                            </small>
                          )}
                          {this.state.id != 0 &&
                            this.state.extractor_time_zone_specification ==
                              null && (
                              <small>
                                Please set the connection's time zone after the
                                extractor is created.
                              </small>
                            )}
                          <div>
                            {this.state.extractor_time_zone_specification
                              ?.style == 0 && (
                              <div>
                                <small>
                                  Platform &nbsp;{this.state.platform_id}&nbsp;
                                  is configured to use a global time zone (
                                  {
                                    this.state.extractor_time_zone_specification
                                      .global_default
                                  }
                                  ) , so individual connections cannot have
                                  their own time zones.
                                </small>
                                <a href="#" className="text-small">
                                  Extractor Time Zone Specification:{" "}
                                  {this.state.time_zone}&nbsp;{" "}
                                  {this.state.extractor_time_zone_specification
                                    .id > 0 && (
                                    <span>
                                      (ID{" "}
                                      {
                                        this.state
                                          .extractor_time_zone_specification.id
                                      }
                                      ){" "}
                                    </span>
                                  )}
                                </a>
                              </div>
                            )}

                            {this.state.extractor_time_zone_specification
                              ?.style == 1 && (
                              <div>
                                {this.state.extractor_time_zone_specification
                                  .configurable_choices.length > 0 && (
                                  <select
                                    className="form-control form-control-sm"
                                    name="time_zone"
                                    value={this.state.time_zone}
                                    onChange={this.handleChange}
                                  >
                                    {this.state.extractor_time_zone_specification.configurable_choices?.map(
                                      (item, index) => {
                                        return (
                                          <option key={index} value={item}>
                                            {item}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                )}
                                {this.state.extractor_time_zone_specification
                                  .configurable_choices.length == 0 && (
                                  <select
                                    className="form-control form-control-sm"
                                    name="time_zone"
                                    value={this.state.time_zone}
                                    onChange={this.handleChange}
                                  >
                                    {GetAllTimeZone.map((item, index) => {
                                      return (
                                        <option key={index} value={item.value}>
                                          {item.text}
                                        </option>
                                      );
                                    })}
                                  </select>
                                )}
                              </div>
                            )}
                          </div>
                          <small>
                            See{" "}
                            <a href="https://sites.google.com/a/staq.com/staqnowledged/home/infrastructure/staq-repos/extractors/time-zones">
                              time zone info
                            </a>
                          </small>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4 ">
                        <div className="form-group shadow-sm p-3 mb-4 ">
                          <label>
                            Custom Extraction Scheduling Recipe
                            <span className=" text-danger"></span>
                          </label>
                          <div className="input-group ">
                            <input
                              autoComplete="off"
                              id="customExtractionSchedulingRecipename"
                              name="customExtractionSchedulingRecipename"
                              type="text"
                              onChange={this.handleChange}
                              value={
                                this.state.customExtractionSchedulingRecipeName
                              }
                              className="form-control"
                            />
                            {this.renderExtractionSchedulingRecipe()}
                            
                          </div>
                          <small>
                            If a recipe isn't specified here, it will default to
                            the extractor's recipe
                          </small>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group shadow-sm p-3 mb-4">
                          <label>
                            Data Source<span className=" text-danger"></span>
                          </label>
                          {this.state.id != 0 && (
                            <p>
                              {Capitalize(this.state.data_source?.text)}
                              &nbsp;Source #{this.state.data_source?.value}
                            </p>
                          )}
                          {this.state.id == 0 && (
                            <div className="input-group ">
                              <input
                                autoComplete="off"
                                id="dataSourceName"
                                name="dataSourceName"
                                type="text"
                                onChange={this.handleChange}
                                value={this.state.dataSourceName}
                                className="form-control"
                              />
                              {this.renderDataSource()}
                            </div>
                          )}

                          <small>
                            This is automatically created. Once it is set, it
                            can't be changed.
                          </small>
                        </div>
                      </div>
                      {/* <div className="col-xl-4 col-md-4">
                        <div className="form-group shadow-sm p-3 mb-4">
                          <label>Inbound Email Addresses</label> &nbsp;
                          {this.props.id != 0 && (
                              <Link to="/inbound-email-address/inbound-email-address-form/0" className={`btn btn-success`}>
                              <i
                                className="fas fa-plus"
                                style={{ color: "white" }}
                              ></i>
                            </Link>
                          )}
                         
                            <div>
                            {this.state.inbound_email_addresses?.map(
                                (item, i, arr) => {
                                  return (
                                    <span key={item.value}>
                                      {item && (
                                        <a href="#">
                                          {item.text}
                                          {i != arr.length - 1 ? ", " : ""}
                                        </a>
                                      )}
                                    </span>
                                  );
                                }
                              )}
                            </div>
                          
                        </div>
                      </div> */}
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group shadow-sm p-3 mb-0">
                        <h6>Parameters </h6>
                          <Editor
                            StringData={this.state.params}
                            FormateType="yaml"
                            onChange={this.editorhandleParams}
                          />
                          {this.state.paramsError != "" && (
                            <div style={{ color: "red" }}>
                              {this.state.paramsError}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group shadow-sm p-3 ">
                        <h6>Internal notes</h6>
                          <textarea
                            rows={15}
                            name="internal_notes"
                            value={this.state.internal_notes}
                            onChange={this.handleChange}
                            className="form-control"
                            style={{ height: "230px" }}
                          ></textarea>
                          <small>
                            <a href="https://sites.google.com/a/staq.com/staqnowledged/home/5---our-processes/quality-assurance/internal-notes">
                              Instructions on what to add to this field
                            </a>
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group shadow-sm p-3 mb-0">
                          <h6>Load params from a previous state</h6>
                          <select
                            className="form-control  col-md-4"
                            name="paramsVersion"
                            value={this.state.paramsVersion}
                            onChange={this.handleChange}
                          >
                            <option value="0">
                              Current Version
                            </option>
                            {this.state.paramsVersionsList?.map((item:ParamsVersionsModel, index) => {
                              return (                                
                                <option key={index} value={item.id}>                                  
                                  {ConvertDateTime(item.created_at, "LLL")}&nbsp;UTC
                                </option>
                              );
                            })}
                          </select>

                          <small>
                            Provides authentication details and other control
                            options for our extractor code
                          </small>
                        </div>
                      </div>
                    </div>
                 
              </div>
            </div>

            <div className="text-center mt-3 mb-4">
            <button type="button" className="btn btn-lg btn-default" onClick={this.handleClose}>{ButtonType.Cancel}</button>
                                {!this.state.isSaving && <Fragment>
                                    <input type="submit" className="btn btn-lg btn-primary  ml-3" onClick={this.handleClick} value={ButtonType.Save} />                                    
                                </Fragment>
                                }
                                <Loader loading={this.state.isSaving} marginBottom="0px" marginTop="8px" width="368px" ></Loader>
            </div>
          </div>
          </Modal.Body>
        </form>
      </Fragment>
    );
  }
}
