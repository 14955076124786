import React, { Component, Fragment } from 'react'
import Breadcrumbs from '../../shared/Breadcrumbs'
import { ReportChangeHistoriesRoute } from '../../routing'

export  class ReportChangeHistoriesPage extends Component {
    render() {
        return (
            <Fragment>
            <Breadcrumbs />
            <ReportChangeHistoriesRoute />
        </Fragment>
        )
    }
}
