import React, { Component, Fragment } from 'react'
import { NavLink } from "react-router-dom";
import { TabScrolling } from '../../../../shared'
import { IsAuthorize } from '../../../../shared/authorization/check-access';
import { Role } from '../../../../shared/authorization/enums';

interface IProps {
    id: number,
    url: string
}
export class InboundEmailAddressesTabs extends Component<IProps> {
    render() {
        return (
            <Fragment> 
                {/* <TabScrolling /> */}
                    <ul className="nav nav-tabs" id="myTab largetab" role="tablist" >
                    {IsAuthorize([Role.TAM, Role.Dev, Role.Support]) && <li className="nav-item">
                            <NavLink to={"/inbound-email-address/inbound-email-address-form/" + this.props.id} className={this.props.url == "/inbound-email-address/inbound-email-address-form/" + this.props.id ? "nav-link active" : "nav-link"} id="edit-tab" data-toggle="tab" href="#edit" role="tab"
                                aria-controls="edit" aria-selected={this.props.url == "/inbound-email-address/inbound-email-address-form/" + this.props.id ? "true" : "false"}><i className="far fa-edit"></i>&nbsp;Edit</NavLink>
                        </li>}
                        {IsAuthorize([Role.TAM, Role.Dev, Role.Support]) &&  <li className="nav-item">
                            <NavLink to={"/inbound-email-address/delete/" + this.props.id} className={this.props.url == "/inbound-email-address/delete/" ? "nav-link active" : "nav-link"} id="storage-tab" data-toggle="tab" href="#storage" role="tab"
                                aria-controls="storage" aria-selected={this.props.url == "/inbound-email-address/delete/" ? "true" : "false"}><i className="far fa-trash-alt"></i>&nbsp;Delete</NavLink>
                        </li>}
                        {IsAuthorize([Role.TAM, Role.Dev,  Role.AM, Role.Support ,Role.Manager]) &&    <li className="nav-item">
                            <NavLink to={"/inbound-email-address/detail/" + this.props.id} className={this.props.url == "/inbound-email-address/detail/" ? "nav-link active" : "nav-link"} id="show-tab" data-toggle="tab" href="#show" role="tab"
                                aria-controls="show" aria-selected={this.props.url == "/inbound-email-address/detail/" ? "true" : "false"}><i className="fas fa-info-circle"></i>&nbsp;Show</NavLink>
                        </li>}
                    </ul> 
                <br />
            </Fragment>
        )
    }
}
