import { HttpWrapper, AppConfig } from '../../../../../core'
import { SubscriptionsListResponseModel,SubscriptionsDetailModel,PostSubscriptionsModel,EditSubscriptionsModel } from '../models';
import { PostExportModel } from "../../../../../shared/export/post.export.model";
import { DeleteResponseModel } from '../../../../../shared/models';
import { SubscriptionsLinkResourcesResponseModel } from '../models/subscriptions.link.resources.response.model';

export class SubscriptionsService {
    private wrapper: HttpWrapper;

    constructor() {
      this.wrapper = new HttpWrapper();
    }
  
    getList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
      return this.wrapper.post<SubscriptionsListResponseModel>(AppConfig.apiEndpoint + '/v1/subscriptions/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }
  
    getSubscriptionById(id: number) {
      return this.wrapper.get<SubscriptionsDetailModel>(AppConfig.apiEndpoint + '/v1/subscriptions/' + id);
    }
  
    postSubscription(payload: PostSubscriptionsModel) {
      return this.wrapper.post<PostSubscriptionsModel>(AppConfig.apiEndpoint + '/v1/subscriptions', payload);
    }
  
    editSubscription(id: number) {
      return this.wrapper.get<EditSubscriptionsModel>(AppConfig.apiEndpoint + '/v1/subscriptions/edit/' + id);
    }

    getLinkedResourcesById(id: number) {
        return this.wrapper.get<SubscriptionsLinkResourcesResponseModel>(AppConfig.apiEndpoint + '/v1/subscriptions/' + id + '/linked_resources');
      }
  
    deleteSubscriptionById(id: number) {
      return this.wrapper.delete<DeleteResponseModel>(AppConfig.apiEndpoint + '/v1/subscriptions/' + id);
    }
  
    updateSubscription(subscription: PostSubscriptionsModel) {
      return this.wrapper.put<PostSubscriptionsModel>(AppConfig.apiEndpoint + '/v1/subscriptions/' + subscription.id, subscription);
    }
    
    exportSubscriptions(exportData?: PostExportModel) {
      return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/subscriptions/export', exportData);
    }
  
  
  }