import React, { Component, Fragment } from 'react'
import { NavLink } from "react-router-dom";
import { TabScrolling } from '../../../shared'
import { IsAuthorize } from '../../../shared/authorization/check-access';
import { Role } from '../../../shared/authorization/enums';

interface IProps {
    id: number,
    url: string
}

export class ReportTabs extends Component<IProps> {
    render() {
        return (
            <Fragment>
                <TabScrolling />
                <div className="tabscroll" >
                    <ul className="nav nav-tabs" id="myTab largetab" role="tablist" >
                    {IsAuthorize([Role.TAM, Role.Dev, Role.Support])  &&<li className="nav-item">
                            <NavLink to={"/report/report-form/" + this.props.id} className={this.props.url == "/report/report-form/" ? "nav-link active" : "nav-link"} id="edit-tab" data-toggle="tab" href="#edit" role="tab"
                                aria-controls="edit" aria-selected={this.props.url == "/report/report-form/" ? "true" : "false"}><i className="far fa-edit"></i>&nbsp;Edit</NavLink>
                        </li>}
                        {IsAuthorize([Role.Sales, Role.AM, Role.TAM, Role.Dev, Role.Support ,Role.Manager]) &&  <li className="nav-item">
                            <NavLink to={"/report/detail/" + this.props.id} className={this.props.url == "/report/detail/" ? "nav-link active" : "nav-link"} id="show-tab" data-toggle="tab" href="#show" role="tab"
                                aria-controls="show" aria-selected={this.props.url == "/report/detail/" ? "true" : "false"}><i className="fas fa-info-circle"></i>&nbsp;Show</NavLink>
                        </li>}
                        <li className="nav-item">
                            <NavLink to={"/report/report_definition/" + this.props.id} className={this.props.url == "/report/report_definition/" ? "nav-link active" : "nav-link"} id="report_definition-tab" data-toggle="tab" href="#report_definition" role="tab"
                                aria-controls="report_definition" aria-selected={this.props.url == "/report/report_definition/" ? "true" : "false"}><i className="fas fa-align-justify"></i>&nbsp;Report Definition</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={"/report/report_data/" + this.props.id} className={this.props.url == "/report/report_data/" ? "nav-link active" : "nav-link"} id="data-tab" data-toggle="tab" href="#data" role="tab"
                                aria-controls="data" aria-selected={this.props.url == "/report/report_data/" ? "true" : "false"}><i className="fas fa-eye"></i>&nbsp; View Report data</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={"/report/report_table_storage/" + this.props.id} className={this.props.url == "/report/report_table_storage/" ? "nav-link active" : "nav-link"} id="storage-tab" data-toggle="tab" href="#storage" role="tab"
                                aria-controls="storage" aria-selected={this.props.url == "/report/report_table_storage/" ? "true" : "false"}><i className="fas fa-database"></i>&nbsp;Table Storage Settings</NavLink>
                        </li>
                        {/* <li className="nav-item">
                            <NavLink to={"/report/data_engine_jobs/" + this.props.id} className={this.props.url == "/report/data_engine_jobs/" ? "nav-link active" : "nav-link"} id="jobs-tab" data-toggle="tab" href="#jobs" role="tab"
                                aria-controls="jobs" aria-selected={this.props.url == "/report/data_engine_jobs/" ? "true" : "false"}><i className="fas fa-cog"></i>&nbsp;Data Engine Jobs</NavLink>
                        </li> */}
                        <li className="nav-item">
                            <NavLink to={"/report/data_engine_report_jobs/" + this.props.id} className={this.props.url == "/report/data_engine_report_jobs/" ? "nav-link active" : "nav-link"} id="jobs-tab" data-toggle="tab" href="#jobs" role="tab"
                                aria-controls="jobs" aria-selected={this.props.url == "/report/data_engine_report_jobs/" ? "true" : "false"}><i className="fas fa-cogs"></i>&nbsp;Data Engine Jobs</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={"/report/report_change_history/" + this.props.id} className={this.props.url == "/report/report_change_history/" ? "nav-link active" : "nav-link"} id="history-tab" data-toggle="tab" href="#history" role="tab"
                                aria-controls="history" aria-selected={this.props.url == "/report/report_change_history/" ? "true" : "false"}><i className="fas fa-history"></i>&nbsp;Change History</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={"/report/backups/" + this.props.id} className={this.props.url == "/report/backups/" ? "nav-link active" : "nav-link"} id="backups-tab" data-toggle="tab" href="#backups" role="tab"
                                aria-controls="backups" aria-selected={this.props.url == "/report/backups/" ? "true" : "false"}><i className="fas fa-archive"></i>&nbsp;Backups</NavLink>
                        </li>
                        {/* <li className="nav-item">
                    <NavLink to={"/report/reportreadiness/" + this.props.id} className={this.props.url=="/report/reportreadiness/"?"nav-link active":"nav-link"} id="readiness-tab" data-toggle="tab" href="#eadiness" role="tab"
                        aria-controls="eadiness" aria-selected={this.props.url=="/report/reportreadiness/"?"true":"false"}><i className="fas fa-bars"></i>&nbsp;Report Readiness</NavLink>
                </li> */}
                    </ul></div>
                <br />
            </Fragment>
        )
    }


}
