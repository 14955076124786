import React from "react";
import { Route } from "react-router-dom";
import {
  StaqContactForm,
  StaqContactDetail,
} from "../components/individual/staq-contacts";
import StaqContactList from "../components/individual/staq-contacts/StaqContactList";

import { StaqEventsDetail} from "../components/individual/staq-events";
import StaqEventsList from "../components/individual/staq-events/StaqEventsList";

import {ApplicationsDetail, ApplicationsForm, ApplicationsDelete} from "../components/individual/applications";
import CustomerFaviconList from "../components/individual/images/customer-favicons/CustomerFaviconList";
import {
  CustomerFaviconDetail,
  CustomerFaviconForm,
} from "../components/individual/images/customer-favicons/";
import CustomerLogoList from "../components/individual/images/customer-logos/CustomerLogoList";
import {
  CustomerLogoDetail,
  CustomerLogoForm,
} from "../components/individual/images/customer-logos/";
import DatabaseEgineList from "../components/individual/database-engines/DatabaseEgineList";
import { DatabaseEgineDetail } from "../components/individual/database-engines";
import { DataEgineDetail } from "../components/individual/database-engines/data-engines";
import DataEgineList from "../components/individual/database-engines/data-engines/DataEgineList";
import DataEgineJobList from "../components/individual/database-engines/data-engines/data-engine-jobs/DataEgineJobList";
import { DataEgineJobDetail } from "../components/individual/database-engines/data-engines/data-engine-jobs/DataEgineJobDetail";
import { DataEgineJobForm } from "../components/individual/database-engines/data-engines/data-engine-jobs";
import PlatformList from "../components/individual/platforms/PlatformList";
import InvocationSchedulesList from "../components/individual/applications/invocation-schedules/InvocationSchedulesList"
import {InvocationSchedulesForm} from "../components/individual/applications/invocation-schedules/InvocationSchedulesForm"
import {DeleteInvocationSchedules} from "../components/individual/applications/invocation-schedules/InvocationScheduleDelete"
import {InvocationSchedulesDetail} from "../components/individual/applications/invocation-schedules/InvocationSchedulesDetail"
import RoleList from "../components/individual/roles/RoleList"
import {RoleForm}  from "../components/individual/roles/RoleForm"
import {RoleDetail} from "../components/individual/roles/RoleDetail"
import {PlatformForm} from "../components/individual/platforms/PlatformForm"
import {PlatformDetail} from "../components/individual/platforms/PlatformDetail"
import {DeletePlatform} from "../components/individual/platforms/DeletePlatform"
import ExtractionEnginesList from "../components/accounts/custom-connection/extraction-engines/ExtractionEnginesList";
import { ExtractionSchedulingRecipesDetail, ExtractionSchedulingRecipesForm, DeleteExtractionSchedulingRecipes } from "../components/individual/extraction-scheduling-recipes";
import ExtractionSchedulingRecipesList from "../components/individual/extraction-scheduling-recipes/ExtractionSchedulingRecipesList";
import { ExtractionEnginesDetail, DeleteExtractionEngines, ExtractionEnginesForm } from "../components/accounts/custom-connection/extraction-engines";
import ApplicationsList from "../components/individual/applications/ApplicationsList";

import ExtractionSchedulingRecipesChangesList from "../components/individual/extraction-scheduling-recipes/extraction-scheduling-recipes-changes/ExtractionSchedulingRecipesChangesList";
import { ExtractionSchedulingRecipesChangesDetail } from '../components/individual/extraction-scheduling-recipes/extraction-scheduling-recipes-changes'
import ScopesList from "../components/individual/schema/scopes/ScopesList";
import { SchemaManager, ScopesDetail, ScopesEditForm } from '../components/individual/schema/scopes'
import { SchemasDetail } from '../components/individual/schema'
import SchemasList from '../components/individual/schema/SchemasList'
import FieldsList from "../components/individual/schema/fields/FieldsList";
import { FieldSchemaManager, FieldsDetail,FieldsEditForm } from "../components/individual/schema/fields";
import ScopeSchemaChangesList from '../components/individual/schema/scope-schema-changes/ScopeSchemaChangesList'
import {ScopeSchemaChangesDetail} from '../components/individual/schema/scope-schema-changes/ScopeSchemaChangesDetail'
import ScopeFieldSchemaChangesList from '../components/individual/schema/scope-schema-changes/scope-field-schema-changes/ScopeFieldSchemaChangesList'
import {ScopeFieldSchemaChangesDetail} from '../components/individual/schema/scope-schema-changes/scope-field-schema-changes/ScopeFieldSchemaChangesDetail'
import FieldClassificationList from "../components/individual/schema/field-classification/FieldClassificationList";
import { FieldClassificationDetail } from "../components/individual/schema/field-classification/FieldClassificationDetail";
import { FieldClassificationForm } from "../components/individual/schema/field-classification/FieldClassificationForm";
import TagLocationCategoriesList from "../components/individual/tag-matcher/tag-location-categories/TagLocationCategoriesList";
import { TagLocationCategoriesDetail } from "../components/individual/tag-matcher/tag-location-categories/TagLocationCategoriesDetail";
import { TagLocationCategoriesForm } from "../components/individual/tag-matcher/tag-location-categories/TagLocationCategoriesForm";
import { DeleteTagLocationCategories } from "../components/individual/tag-matcher/tag-location-categories";
import TagLocationList from "../components/individual/tag-matcher/tag-location/TagLocationList";
import { TagLocationDetail } from "../components/individual/tag-matcher/tag-location/TagLocationDetail";
import { TagLocationForm } from "../components/individual/tag-matcher/tag-location/TagLocationForm";
import { DeleteTagLocation } from "../components/individual/tag-matcher/tag-location/DeleteTagLocation";
import TagMatchersList from "../components/individual/tag-matcher/tag-matchers/TagMatchersList";
import { TagMatchersDetail } from "../components/individual/tag-matcher/tag-matchers/TagMatchersDetail";
import { TagMatchersForm } from "../components/individual/tag-matcher/tag-matchers/TagMatchersForm";
import { DeleteTagMatchers } from "../components/individual/tag-matcher/tag-matchers/DeleteTagMatchers";
import SubscriptionsList from "../components/individual/subscriptions/subscriptions/SubscriptionsList"
import {SubscriptionsDetail} from "../components/individual/subscriptions/subscriptions/SubscriptionsDetail";
import {SubscriptionsForm} from "../components/individual/subscriptions/subscriptions/SubscriptionsForm"
import {DeleteSubscriptions} from "../components/individual/subscriptions/subscriptions/DeleteSubscriptions"
import { DeleteSubscriptionCategory } from "../components/individual/subscriptions/subscription-categories/DeleteSubscriptionCategory";
import { SubscriptionCategoryDetail } from "../components/individual/subscriptions/subscription-categories/SubscriptionCategoryDetail";
import { SubscriptionCategoryForm } from "../components/individual/subscriptions/subscription-categories/SubscriptionCategoryForm";
import  SubscriptionCategoryList  from "../components/individual/subscriptions/subscription-categories/SubscriptionCategoryList";
import { CategoryForm, CategoryDetail, DeleteCategory } from "../components/individual/categories";
import CategoryList from "../components/individual/categories/CategoryList";
import FeatureFlagsList from "../components/individual/external-roles-and-communication/feature-flags/FeatureFlagsList";
import { FeatureFlagsDetail } from "../components/individual/external-roles-and-communication/feature-flags/FeatureFlagsDetail";
import { FeatureFlagsForm } from "../components/individual/external-roles-and-communication/feature-flags/FeatureFlagsForm";
import { DeleteFeatureFlags } from "../components/individual/external-roles-and-communication/feature-flags/DeleteFeatureFlags";
import EmailLayoutsList from "../components/individual/external-roles-and-communication/email-layouts/EmailLayoutsList";
import { EmailLayoutsDetail } from "../components/individual/external-roles-and-communication/email-layouts/EmailLayoutsDetail";
import { EmailLayoutsForm } from "../components/individual/external-roles-and-communication/email-layouts/EmailLayoutsForm";
import { DeleteEmailLayouts } from "../components/individual/external-roles-and-communication/email-layouts/DeleteEmailLayouts";
import EmailTemplatesList from "../components/individual/external-roles-communications/email-templates/EmailTemplatesList";
import { DeleteEmailTemplates } from "../components/individual/external-roles-communications/email-templates/DeleteEmailTemplates";
import { EmailTemplatesDetail } from "../components/individual/external-roles-communications/email-templates/EmailTemplatesDetail";
import { EmailTemplatesForm } from "../components/individual/external-roles-communications/email-templates/EmailTemplatesForm";
import ProtectedOperationsList from "../components/individual/protected-operations/ProtectedOperationsList";
import { ProtectedOperationsDetail } from "../components/individual/protected-operations/ProtectedOperationsDetail";
import { ProtectedOperationsForm } from "../components/individual/protected-operations/ProtectedOperationsForm";
import { DeleteProtectedOperations } from "../components/individual/protected-operations/DeleteProtectedOperations";
import PlanCategoriesList from "../components/individual/external-roles-and-communication/plan-categories/PlanCategoriesList";
import { PlanCategoriesDetail } from "../components/individual/external-roles-and-communication/plan-categories/PlanCategoriesDetail";
import { PlanCategoriesForm } from "../components/individual/external-roles-and-communication/plan-categories/PlanCategoriesForm";
import { DeletePlanCategories } from "../components/individual/external-roles-and-communication/plan-categories/DeletePlanCategories";
import ConfigurationsList from "../components/individual/configurations/ConfigurationsList";
import { ConfigurationsDetail } from "../components/individual/configurations/ConfigurationsDetail";
import { ConfigurationsForm } from "../components/individual/configurations/ConfigurationsForm";
import { DeleteConfigurations } from "../components/individual/configurations/DeleteConfigurations";
import { DataEngineJobStateTransition } from "../components/individual/database-engines/data-engines/data-engine-jobs/DataEngineJobStateTransition";
import { DeleteStaqContact } from "../components/individual/staq-contacts/DeleteStaqContact";

export function StaqContactRoute() {
  return (
    <div className="compLoader">
      <Route path="/admin/list" exact component={StaqContactList} />
      <Route
        path="/admin/admin-form/:id"
        exact
        component={StaqContactForm}
      />
      <Route
        path="/admin/detail/:id"
        exact
        component={StaqContactDetail}
      />
       <Route path="/admin/delete/:id" exact component={DeleteStaqContact} />
    </div>
  );
}

export function StaqEventsRoute() {
  return (
    <div className="compLoader">
      <Route path="/staq-event/list" exact component={StaqEventsList} />
      <Route  path="/staq-event/detail/:id" exact component={StaqEventsDetail} />
    </div>
  );
}


export function ApplicationsRoute() {
  return (
    <div className="compLoader">
      <Route path="/application/list" exact component={ApplicationsList} />
      <Route path="/application/detail/:id" exact component={ApplicationsDetail} />
      <Route path="/application/application-form/:id" exact component={ApplicationsForm} />
      <Route path="/application/delete/:id" exact component={ApplicationsDelete} />
    </div>
  );
}

export function CustomerFaviconRoute() {
  return (
    <div className="compLoader">
      <Route
        path="/customer-favicon/list"
        exact
        component={CustomerFaviconList}
      />
      <Route
        path="/customer-favicon/detail/:id"
        exact
        component={CustomerFaviconDetail}
      />
      <Route
        path="/customer-favicon/customer-favicon-form/:id"
        exact
        component={CustomerFaviconForm}
      />
    </div>
  );
}

export function CustomerLogoRoute() {
  return (
    <div className="compLoader">
      <Route path="/customer-logo/list" exact component={CustomerLogoList} />
      <Route
        path="/customer-logo/detail/:id"
        exact
        component={CustomerLogoDetail}
      />
      <Route
        path="/customer-logo/customer-Logo-form/:id"
        exact
        component={CustomerLogoForm}
      />
    </div>
  );
}

export function DatabaseEgineRoute() {
  return (
    <div className="compLoader">
      <Route
        path="/database-engine/list"
        exact
        component={DatabaseEgineList}
      />
      <Route
        path="/database-engine/detail/:id"
        exact
        component={DatabaseEgineDetail}
      />      
    </div>    
  );
}

export function DataEgineRoute() {
  return (
    <div className="compLoader">
      <Route path="/data-engine/list" exact component={DataEgineList} />
      <Route
        path="/data-engine/detail/:id"
        exact
        component={DataEgineDetail}
      />
    </div>
  );
}

export function DataEgineJobRoute() {
  return (
    <div className="compLoader">
      <Route path="/data-engine-job/list" exact component={DataEgineJobList} />
      <Route
        path="/data-engine-job/detail/:id"
        exact
        component={DataEgineJobDetail}
      />
       <Route
        path="/data-engine-job/data-engine-job-form/:id"
        exact
        component={DataEgineJobForm}
      />
      <Route
        path="/data-engine-job/state-transition/:id"
        exact
        component={DataEngineJobStateTransition}
      />
    </div>
  );
}

export function PlatformRoute() {
  return (
    <div className="compLoader">
      <Route path="/platform/list" exact component={PlatformList} />
      <Route path="/platform/platform-form/:id" exact component={PlatformForm} />
      <Route path="/platform/detail/:id" exact component={PlatformDetail} />
      <Route path="/platform/delete/:id" exact component={DeletePlatform} />
    </div>
  )
}


export function InvocationSchedulesRoute() {
  return (
    <div className="compLoader">
      <Route path="/invocation-schedule/list" exact component={InvocationSchedulesList} />
      <Route path="/invocation-schedule/invocation-schedule-form/:id" exact component={InvocationSchedulesForm} />
      <Route path="/invocation-schedule/detail/:id" exact component={InvocationSchedulesDetail} />
      <Route path="/invocation-schedule/delete/:id" exact component={DeleteInvocationSchedules} />
    </div>
  )
}

export function SubscriptionsRoute() {
  return (
    <div className="compLoader">
      <Route path="/subscription/list" exact component={SubscriptionsList} />
      <Route path="/subscription/subscription-form/:id" exact component={SubscriptionsForm} />
      <Route path="/subscription/detail/:id" exact component={SubscriptionsDetail} />
      <Route path="/subscription/delete/:id" exact component={DeleteSubscriptions} />
    </div>
  )
}

export function SubscriptionCategoryRoute() {
  return (
    <div className="compLoader">
      <Route path="/subscription-category/list" exact component={SubscriptionCategoryList} />
      <Route path="/subscription-category/subscription-category-form/:id" exact component={SubscriptionCategoryForm} />
      <Route path="/subscription-category/detail/:id" exact component={SubscriptionCategoryDetail} />
      <Route path="/subscription-category/delete/:id" exact component={DeleteSubscriptionCategory} />
    </div>
  )
}

export function CategoryRoute() {
  return (
    <div className="compLoader">
      <Route path="/category/list" exact component={CategoryList} />
      <Route path="/category/category-form/:id" exact component={CategoryForm} />
      <Route path="/category/detail/:id" exact component={CategoryDetail} />
      <Route path="/category/delete/:id" exact component={DeleteCategory} />
    </div>
  )
}

export function RolesRoute() {
  return (
    <div className="compLoader">
      <Route path="/role/list" exact component={RoleList} />
      <Route path="/role/role-form/:id" exact component={RoleForm} />
      <Route path="/role/detail/:id" exact component={RoleDetail} />
    </div>
  )
}

export function ExtractionEnginesRoute() {
  return (
    <div className="compLoader">
      <Route path="/extraction-engine/list" exact component={ExtractionEnginesList} />
       <Route path="/extraction-engine/extraction-engine-form/:id" exact component={ExtractionEnginesForm} />
      <Route path="/extraction-engine/detail/:id" exact component={ExtractionEnginesDetail} />
      <Route path="/extraction-engine/delete/:id" exact component={DeleteExtractionEngines} />
    </div>
  );
}
 
export function ExtractionSchedulingRecipesChangesRoute() {
  return (
    <div className="compLoader">
      <Route path="/extraction-scheduling-recipe-change/list" exact component={ExtractionSchedulingRecipesChangesList} />       
      <Route path="/extraction-scheduling-recipe-change/detail/:id" exact component={ExtractionSchedulingRecipesChangesDetail} />      
    </div>
  );
}

export function ExtractionSchedulingRecipesRoute() {
  return (
    <div className="compLoader">
      <Route path="/extraction-scheduling-recipe/list" exact component={ExtractionSchedulingRecipesList} />
       <Route path="/extraction-scheduling-recipe/extraction-scheduling-recipe-form/:id" exact component={ExtractionSchedulingRecipesForm} />
      <Route path="/extraction-scheduling-recipe/detail/:id" exact component={ExtractionSchedulingRecipesDetail} />
      <Route path="/extraction-scheduling-recipe/delete/:id" exact component={DeleteExtractionSchedulingRecipes} />
    </div>
  );
}

export function SchemasRoute() {
  return (
    <div className="compLoader">
      <Route path="/schema/list" exact component={SchemasList} />       
      <Route path="/schema/detail/:id" exact component={SchemasDetail} />      
    </div>
  );
}

export function FieldsRoute() {
  return (
    <div className="compLoader">
      <Route path="/field/list" exact component={FieldsList} />       
      <Route path="/field/detail/:id/:schemaName?/:scopeId?" exact component={FieldsDetail} />      
      <Route path="/field/field-form/:id/:schemaName/:scopeId" exact component={FieldsEditForm} />
      <Route path="/field/schema-manager/:id/:schemaName/:scopeId" exact component={FieldSchemaManager} /> 
    </div>
  );
}
 

export function ScopesRoute() {
  return (
    <div className="compLoader">
      <Route path="/scope/list" exact component={ScopesList} />       
      <Route path="/scope/detail/:id" exact component={ScopesDetail} />      
      <Route path="/scope/scope-form/:id/:schemaName" exact component={ScopesEditForm} />
      <Route path="/scope/schema-manager/:id/:name/:schemaName" exact component={SchemaManager} /> 
    </div> 
  );
}
 
export function ScopeSchemaChangesRoute() {
  return (
    <div className="compLoader">
      <Route path="/scope-schema-change/list" exact component={ScopeSchemaChangesList} />       
      <Route path="/scope-schema-change/detail/:id" exact component={ScopeSchemaChangesDetail} />      
    </div>
  );
}


export function FieldsClassificationRoute() {
  return (
    <div className="compLoader">
      <Route path="/field-classification/list" exact component={FieldClassificationList} />       
      <Route path="/field-classification/detail/:id" exact component={FieldClassificationDetail} />      
      <Route path="/field-classification/field-classification-form/:id" exact component={FieldClassificationForm} />
    </div>
  );
}
 
export function ScopeFieldSchemaChangesRoute() {
  return (
    <div className="compLoader">
      <Route path="/scope-field-schema-change/list" exact component={ScopeFieldSchemaChangesList} />       
      <Route path="/scope-field-schema-change/detail/:id" exact component={ScopeFieldSchemaChangesDetail} />      
    </div>
  );
}

export function TagLocationCategoryRoute() {
  return (
    <div className="compLoader">
      <Route path="/tag-location-category/list" exact component={TagLocationCategoriesList} />       
      <Route path="/tag-location-category/detail/:id" exact component={TagLocationCategoriesDetail} />      
      <Route path="/tag-location-category/tag-location-category-form/:id" exact component={TagLocationCategoriesForm} />
      <Route path="/tag-location-category/delete/:id" exact component={DeleteTagLocationCategories} />
    </div>
  );
}
export function TagLocationRoute() {
  return (
    <div className="compLoader">
      <Route path="/tag-location/list" exact component={TagLocationList} />       
      <Route path="/tag-location/detail/:id" exact component={TagLocationDetail} />      
      <Route path="/tag-location/tag-location-form/:id" exact component={TagLocationForm} />
      <Route path="/tag-location/delete/:id" exact component={DeleteTagLocation} />
    </div>
  );
}

export function TagMatchersRoute() {
  return (
    <div className="compLoader">
      <Route path="/tag-matcher/list" exact component={TagMatchersList} />       
      <Route path="/tag-matcher/detail/:id" exact component={TagMatchersDetail} />      
      <Route path="/tag-matcher/tag-matcher-form/:id" exact component={TagMatchersForm} />
      <Route path="/tag-matcher/delete/:id" exact component={DeleteTagMatchers} />
    </div>
  );
}
export function FeatureFlagsRoute() {
  return (
    <div className="compLoader">
      <Route path="/feature-flag/list" exact component={FeatureFlagsList} />       
      <Route path="/feature-flag/detail/:id" exact component={FeatureFlagsDetail} />      
      <Route path="/feature-flag/feature-flag-form/:id" exact component={FeatureFlagsForm} />
      <Route path="/feature-flag/delete/:id" exact component={DeleteFeatureFlags} />
    </div>
  );
}
export function EmailLayoutsRoute() {
  return (
    <div className="compLoader">
      <Route path="/email-layout/list" exact component={EmailLayoutsList} />       
      <Route path="/email-layout/detail/:id" exact component={EmailLayoutsDetail} />      
      <Route path="/email-layout/email-layout-form/:id" exact component={EmailLayoutsForm} />
      <Route path="/email-layout/delete/:id" exact component={DeleteEmailLayouts} />
    </div>
  );
}

export function EmailTemplatesRoute() {
  return (
    <div className="compLoader">
      <Route path="/email-template/list" exact component={EmailTemplatesList} />       
      <Route path="/email-template/detail/:id" exact component={EmailTemplatesDetail} />      
      <Route path="/email-template/email-template-form/:id" exact component={EmailTemplatesForm} />
      <Route path="/email-template/delete/:id" exact component={DeleteEmailTemplates} />
    </div>
  );
}

export function ProtectedOperationsRoute() {
  return (
    <div className="compLoader">
      <Route path="/protected-operation/list" exact component={ProtectedOperationsList} />       
      <Route path="/protected-operation/detail/:id" exact component={ProtectedOperationsDetail} />      
      <Route path="/protected-operation/protected-operation-form/:id" exact component={ProtectedOperationsForm} />
      <Route path="/protected-operation/delete/:id" exact component={DeleteProtectedOperations} />
    </div>
  );
}

export function PlanCategoriesRoute() {
  return (
    <div className="compLoader">
      <Route path="/plan-category/list" exact component={PlanCategoriesList} />       
      <Route path="/plan-category/detail/:id" exact component={PlanCategoriesDetail} />      
      <Route path="/plan-category/plan-category-form/:id" exact component={PlanCategoriesForm} />
      <Route path="/plan-category/delete/:id" exact component={DeletePlanCategories} />
    </div>
  );
}
export function ConfigurationsRoute() {
  return (
    <div className="compLoader">
      <Route path="/configuration/list" exact component={ConfigurationsList} />       
      <Route path="/configuration/detail/:id" exact component={ConfigurationsDetail} />      
      <Route path="/configuration/configuration-form/:id" exact component={ConfigurationsForm} />
      <Route path="/configuration/delete/:id" exact component={DeleteConfigurations} />
    </div>
  );
}