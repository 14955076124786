import { HttpWrapper, AppConfig } from '../../../../core'
import { PlatformListResponseModel,PlatformDetailModel,PostPlatformModel,EditPlatformModel } from '../models';
import { PlatformLinkResourcesResponseModel, DeleteResponseModel } from '../../../../shared/models';
import { PostExportModel } from "../../../../shared/export/post.export.model";
export class PlatformService {
    private wrapper: HttpWrapper;

    constructor() {
      this.wrapper = new HttpWrapper();
    }
  
    getPlatformList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
      return this.wrapper.post<PlatformListResponseModel>(AppConfig.apiEndpoint + '/v1/platforms/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }
  
    getPlatformById(id: number) {
      return this.wrapper.get<PlatformDetailModel>(AppConfig.apiEndpoint + '/v1/platforms/' + id);
    }
  
    postPlatform(platform: PostPlatformModel) {
      return this.wrapper.post<PostPlatformModel>(AppConfig.apiEndpoint + '/v1/platforms', platform);
    }
  
    editPlatform(id: number) {
      return this.wrapper.get<EditPlatformModel>(AppConfig.apiEndpoint + '/v1/platforms/edit/' + id);
    }
  
    updatePlatform(platform: PostPlatformModel) {
      return this.wrapper.put<PostPlatformModel>(AppConfig.apiEndpoint + '/v1/platforms/' + platform.id, platform);
    }
  
    getLinkedResourcesById(id: number) {
      return this.wrapper.get<PlatformLinkResourcesResponseModel>(AppConfig.apiEndpoint + '/v1/platforms/' + id + '/linked_resources');
    } 

    deleteRecordById(id: number) {
      return this.wrapper.delete<DeleteResponseModel>(AppConfig.apiEndpoint + '/v1/platforms/' + id);
    }
    
    exportPlatform(exportData?: PostExportModel) {
      return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/platforms/export', exportData);
    }
  
  
  }