import React, { Component, Fragment } from "react";
import { SubscriptionCategoryService } from "./services/subscription.category.service";
import { EditSubscriptionCategoryModel } from "./models/edit.subscription.category.model";
import { HttpResponse } from "../../../../core";
import {
  HandleNotFoundResponse,
  ShowSuccessMessage,
} from "../../../../shared/helpers";
import { NotFoundResponseArea, ButtonType } from "../../../../shared/enums";
import { PostSubscriptionCategoryModel } from "./models";
import { Loader } from "../../../../shared/loaders";
import Modal from "react-bootstrap/Modal";

interface IState {
  id?: number;
  name?: string;
  label?: string;
  isShowtext?: string;
  isSave?: string;
  isSaving?: boolean;
  editCatgeories?: EditSubscriptionCategoryModel;
}

interface IProps {
  handleClose: Function;
  id?: number;
  isNew?: boolean;
  updateCategories: Function;
}

export class SubscriptionCategoryFormPopup extends Component<IProps, IState> {
  private service: SubscriptionCategoryService;

  constructor(props: IProps) {
    super(props);
    this.state = this.initialState;
    this.service = new SubscriptionCategoryService();
  }

  //Initial State to get the data for the edit functionality from the API
  initialState: Partial<IState> = {
    id: 0,
    name: "",
    label: "",
    isShowtext: "New",
    isSave: "",
    isSaving: false,
    editCatgeories: {
      id: 0,
      name: "",
      label: "",
    },
  };

  handleClose = (event) => {
    event.preventDefault();
    this.props.handleClose();
  };

  // To the load the initial data coming from API for the Edit functionality.
  componentDidMount() {
    if (!this.props.isNew) {
      this.setState({ id: this.props.id, isShowtext: "Edit" }, () => {
        this.loadData();
      });
    }
  }

  // Function for loading the data in the intial phase.
  loadData() {
    this.setSavingFlag(true);
    this.service
      .editSubscriptionCategory(Number(this.props.id))
      .then((res: HttpResponse<EditSubscriptionCategoryModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState({
            id: res.result?.id,
            name: res.result?.name === null ? "" : res.result?.name,
            label: res.result?.label === null ? "" : res.result?.label,
          });
        }
      })
      .catch((ex) => {
        HandleNotFoundResponse(
          ex,
          NotFoundResponseArea.SubscriptionCategory,
          this.props
        );
      });
  }

  handleChange = (event: any) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {}
    );
  };

  handleCategorySubmit = (event) => {
    event.preventDefault();
    let payload = {
      id: this.state.id,
      name: this.state.name,
      label: this.state.label,
    };
    if (payload.id === 0) {
      this.postData(payload);
    } else {
      this.updateData(payload);
    }
  };

  postData(subscription: PostSubscriptionCategoryModel) {
    this.setSavingFlag(true);
    this.service.postSubscriptionCategory(subscription).then(
      (res: HttpResponse<PostSubscriptionCategoryModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              id: res.result.id,
            },
            () => {
              ShowSuccessMessage("Subscription Category successfully created.");
              if (this.state.isSave === ButtonType.Save) {
                this.setState(this.initialState);
                this.props.updateCategories(
                  true,
                  this.state.name,
                  this.state.id
                );
                this.props.handleClose();
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  //Function to update the data
  updateData(subscription: PostSubscriptionCategoryModel) {
    this.setSavingFlag(true);
    this.service.updateSubscriptionCategory(subscription).then(
      (res: HttpResponse<PostSubscriptionCategoryModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              id: res.result.id,
            },
            () => {
              //Showing the message along with changing the route according to the button clicked.
              ShowSuccessMessage("Subscription category successfully updated.");
              if (this.state.isSave === ButtonType.Save) {
                this.setState(this.initialState);
                this.props.updateCategories(
                  true,
                  this.state.name,
                  this.state.id
                );
                this.props.handleClose();
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  // Function to check the type of save functionality , in our case save and save & Edit.
  handleClick = (event) => {
    this.setState({ isSave: event.target.value }, () => {
      this.props.updateCategories(true, this.state.name, this.state.id);
    });
  };

  private setSavingFlag(saving: boolean) {
    this.setState({ isSaving: saving });
  }

  render() {
    return (
      <Fragment>
        <form onSubmit={this.handleCategorySubmit}>
          <Modal.Header closeButton onClick={this.handleClose}></Modal.Header>
          <Modal.Body>
            <input type="hidden" value={this.state.id} />
            <div>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h5>
                  {this.state.isShowtext} Subscription Category
                  {this.state.isShowtext === "Edit" && (
                    <span> '{this.state.name}'</span>
                  )}
                </h5>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Name</label>
                            <input
                              type="text"
                              maxLength={255}
                              name="name"
                              className="form-control"
                              value={this.state.name}
                              onChange={this.handleChange}
                            />
                            <small>
                              Must be a unique and will be show to users if a
                              label is not present.
                            </small>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Label</label>
                            <input
                              type="text"
                              maxLength={255}
                              name="label"
                              className="form-control"
                              value={this.state.label}
                              onChange={this.handleChange}
                            />
                            <small>
                              The friendly name displayed to users in web on
                              account notification settings page
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center mt-3 mb-4">
                <button
                  type="button"
                  className="btn btn-lg btn-default"
                  onClick={this.handleClose}
                >
                  {ButtonType.Cancel}
                </button>
                {!this.state.isSaving && (
                  <Fragment>
                    <input
                      type="submit"
                      className="btn btn-lg btn-primary  ml-3"
                      onClick={this.handleClick}
                      value={ButtonType.Save}
                    />
                  </Fragment>
                )}
                <Loader
                  loading={this.state.isSaving}
                  marginBottom="0px"
                  marginTop="8px"
                  width="368px"
                ></Loader>
              </div>
            </div>
          </Modal.Body>
        </form>
      </Fragment>
    );
  }
}
