import React, { Component, Fragment } from "react";
import { CategoryService } from "./services/category.service";
import { EditCategoryModel } from "./models/edit.category.model";
import { HttpResponse } from "../../../core";
import {
  HandleNotFoundResponse,
  ShowSuccessMessage,
} from "../../../shared/helpers";
import { NotFoundResponseArea, ButtonType } from "../../../shared/enums";
import { PostCategoryModel } from "./models";
import { Loader } from "../../../shared/loaders";
import { DropdownItemModel, CategoryTypeModel } from "../../../shared/models";
import { CommonService } from "../../../shared/services/common.service";
import { Modal } from "react-bootstrap";

interface IState {
  id?: number;
  name?: string;
  platforms_list?: DropdownItemModel[];
  platforms?: Array<CategoryTypeModel>;
  unassigned_platforms?: Array<CategoryTypeModel>;
  isShowtext?: string;
  searchText?: string;
  isSave?: string;
  isSaving?: boolean;
  name_error?: string;
  searchList?: DropdownItemModel[];
}

interface IProps {
  handleClose: Function;
  id?: number;
  isNew?: boolean;
  updateCategories: Function;
}

export class CategoryFormPopup extends Component<IProps, IState> {
  private service: CategoryService;
  private commonService: CommonService;

  constructor(props: IProps) {
    super(props);
    this.state = this.initialState;
    this.service = new CategoryService();
    this.commonService = new CommonService();
  }

  //Initial State to get the data for the edit functionality from the API
  initialState: Partial<IState> = {
    id: 0,
    name: "",
    platforms_list: [],
    platforms: [],
    unassigned_platforms: [],
    isShowtext: "New",
    searchText: "",
    isSave: "",
    isSaving: false,
    name_error: "",
    searchList: [],
  };

  handleClose = (event) => {
    event.preventDefault();
    this.props.handleClose();
  };

  // To the load the initial data coming from API for the Edit functionality.
  componentDidMount() {
    if (!this.props.isNew) {
      this.setState({ id: this.props.id, isShowtext: "Edit" }, () => {
        this.loadData();
      });
    }
    if (this.props.isNew) {
      this.getAllPlatforms();
    }
  }

  getAllPlatforms() {
    this.commonService
      .getAllPlatforms()
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res) {
          this.setState({
            unassigned_platforms: res.result,
            searchList: res.result,
          });
        }
      });
  }

  // Function for loading the data in the intial phase.
  loadData() {
    this.setSavingFlag(true);
    this.service
      .editCategory(Number(this.props.id))
      .then((res: HttpResponse<EditCategoryModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState({
            id: res.result?.id,
            name: res.result?.name === null ? "" : res.result?.name,
            platforms: res.result?.platforms,
            unassigned_platforms: res.result?.unassigned_platforms,
            searchList: res.result.unassigned_platforms,
          });
        }
      })
      .catch((ex) => {
        HandleNotFoundResponse(ex, NotFoundResponseArea.Category, this.props);
      });
  }

  handleChange = (event: any) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        this.handleSearch(name, value);
        this.validate();
      }
    );
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const isValid = this.validate();
    let payload = {
      id: this.state.id,
      name: this.state.name,
      platform_ids: this.state.platforms,
    };
    if (isValid) {
      if (payload.id === 0) {
        this.postData(payload);
      } else {
        this.updateData(payload);
      }
    }
  };

  validate = () => {
    let name_error = "";

    if (!this.state.name && this.state.isSave) {
      name_error = "Name cannot be blank";
    }

    if (name_error) {
      this.setState({
        name_error: name_error,
      });
      return false;
    }

    if (!name_error) {
      name_error = "";
      this.setState({
        name_error: name_error,
      });
      return true;
    }
  };

  postData(category: PostCategoryModel) {
    this.setSavingFlag(true);
    this.service.postCategory(category).then(
      (res: HttpResponse<PostCategoryModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              id: res.result.id,
            },
            () => {
              ShowSuccessMessage("Category successfully created.");
              if (this.state.isSave === ButtonType.Save) {                
                this.props.updateCategories(
                  true,
                  this.state.name,
                  this.state.id
                );
                this.setState(this.initialState);
                this.props.handleClose();
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  //Function to update the data
  updateData(category: PostCategoryModel) {
    this.setSavingFlag(true);
    this.service.updateCategory(category).then(
      (res: HttpResponse<PostCategoryModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              id: res.result.id,
            },
            () => {
              //Showing the message along with changing the route according to the button clicked.
              ShowSuccessMessage("Category successfully updated.");
              if (this.state.isSave === ButtonType.Save) {                
                this.props.updateCategories(
                  true,
                  this.state.name,
                  this.state.id
                );
                this.setState(this.initialState);
                this.props.handleClose();
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  // Function to check the type of save functionality , in our case save and save & Edit.
  handleClick = (event) => {
    this.setState({ isSave: event.target.value }, () => {
      this.props.updateCategories(true, this.state.name, this.state.id);
    });
  };

  private setSavingFlag(saving: boolean) {
    this.setState({ isSaving: saving });
  }

  handleSearch(name, value) {
    if (name === "searchText") {
      let list = this.state.unassigned_platforms;
      list = list?.filter((p) =>
        p.text?.toLowerCase().startsWith(value.toLowerCase())
      );

      if (value) {
        this.setState({ unassigned_platforms: list });
      } else {
        this.setState({ unassigned_platforms: this.state.searchList });
      }
    }
  }

  onLeftCategorySelectionChange(e: any) {
    let data = this.state.unassigned_platforms?.find(
      (p) => p.value === e.target.value
    );
    this.state.platforms?.push({
      text: data?.text,
      value: data?.value,
      isChecked: data?.isChecked,
    });
    this.setState({
      unassigned_platforms: this.state.unassigned_platforms?.filter(
        (p) => p.value !== e.target.value
      ),
      searchList: this.state.searchList?.filter(
        (p) => p.value !== e.target.value
      ),
      platforms: this.state.platforms,
    });
  }

  selectAll() {
    this.state.unassigned_platforms?.forEach((p) => {
      this.state.platforms?.push(p);
    });
    this.setState({
      unassigned_platforms: [],
      platforms: this.state.platforms,
    });
  }

  onRightCategorySelectionChange(e: any) {
    let data = this.state.platforms?.find((p) => p.value === e.target.value);
    this.state.unassigned_platforms?.push({
      text: data?.text,
      value: data?.value,
      isChecked: data?.isChecked,
    });
    this.setState({
      platforms: this.state.platforms?.filter(
        (p) => p.value !== e.target.value
      ),
      unassigned_platforms: this.state.unassigned_platforms,
    });
  }

  clearAll() {
    this.state.platforms?.forEach((p) => {
      this.state.unassigned_platforms?.push(p);
    });
    this.setState({
      platforms: [],
      unassigned_platforms: this.state.unassigned_platforms,
    });
  }

  render() {
    return (
      <Fragment>
        <form onSubmit={this.handleSubmit}>
        <Modal.Header closeButton onClick={this.handleClose}></Modal.Header>
          <Modal.Body>
          <input type="hidden" value={this.state.id} />
          <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5>
                {this.state.isShowtext} Category
                {this.state.isShowtext === "Edit" && (
                  <span> '{this.state.name}'</span>
                )}
              </h5>
            </div>
            <div className="row">
              <div className="col-md-12">
                
                   
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group shadow-sm p-3">
                          <label>
                            Name<span className=" text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              maxLength={255}
                              name="name"
                              className={
                                !this.state.name_error
                                  ? "form-control"
                                  : "form-control  is-invalid"
                              }
                              value={this.state.name}
                              onChange={this.handleChange}
                            />
                            <div className="invalid-feedback">
                              {this.state.name_error}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group  shadow-sm p-3 mb-0">
                        <div className="d-flex justify-content-between mb-2">
                          <label>Platforms</label>
                          {/* <button type="button" className="btn btn-success" onClick={this.handleShowPlatform}><i className="fas fa-plus" style={{ color: "white" }}></i></button> */}
                        </div>
                        <input
                          type="text"
                          name="searchText"
                          value={this.state.searchText}
                          placeholder="Search"
                          onChange={this.handleChange}
                          className="form-control form-control-sm"
                        />
                      </div>
                      <div className="row">
                        <div
                          className="col-md-6"
                          style={{ paddingRight: "0px" }}
                        >
                          <div className="form-group customScrollBar shadow-sm p-3 mb-2">
                            {this.state.unassigned_platforms?.map(
                              (item, index) => {
                                return (
                                  <div key={index}>
                                    <div>
                                      <input
                                        type="checkbox"
                                        style={{ display: "none" }}
                                        value={item.value || ""}
                                        checked={item.isChecked || false}
                                        onChange={this.onLeftCategorySelectionChange.bind(
                                          this
                                        )}
                                        id={"category" + item.value}
                                        name={"category" + item.value}
                                      />
                                      <label htmlFor={"category" + item.value}>
                                        {item.text}
                                      </label>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <button
                            type="button"
                            onClick={this.selectAll.bind(this)}
                            className="btn btn-default btn-sm mr-2"
                          >
                            Choose All{" "}
                            <i className="fas fa-chevron-circle-right"></i>
                          </button>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group customScrollBar  shadow-sm p-3 mb-2">
                            {this.state.platforms?.map((item, index) => {
                              return (
                                <div key={index}>
                                  <div>
                                    <input
                                      style={{ display: "none" }}
                                      type="checkbox"
                                      value={item.value || ""}
                                      checked={item.isChecked || false}
                                      onChange={this.onRightCategorySelectionChange.bind(
                                        this
                                      )}
                                      id={"category" + item.value}
                                      name={"category" + item.value}
                                    />
                                    <label htmlFor={"category" + item.value}>
                                      {item.text}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <button
                            type="button"
                            onClick={this.clearAll.bind(this)}
                            className="btn btn-default btn-sm"
                          >
                            <i className="fas fa-chevron-circle-left"></i> Clear
                            All
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                
                 
              </div>
            </div>
          </div>
          <hr/>
          <div className="text-center  mt-4">
            <button
              type="button"
              className="btn btn-md btn-default"
              onClick={this.handleClose}
            >
              {ButtonType.Cancel}
            </button>
            {!this.state.isSaving && (
              <input
                type="submit"
                className="btn btn-md btn-primary  ml-3"
                onClick={this.handleClick}
                value={ButtonType.Save}
              />
            )}
            <Loader
              loading={this.state.isSaving}
              marginBottom="0px"
              marginTop="8px"
              float="center"
              width="368px"
            ></Loader>
          </div>
          </Modal.Body>
        </form>
      </Fragment>
    );
  }
}
