import React, { Component, Fragment } from 'react'
import { InstalledApplicationDetailModel, InvocationSchedulesModel, InstalledApplicationControlMissionsModel, ControlMissionPostModel } from './models'
import { InstalledApplicationService } from './services/installed.application.service';
import { HttpResponse } from '../../../../core';
import { NavLink } from "react-router-dom";
import { ConvertDateTime, GetUserInfo, HandleNotFoundResponse, ShowSuccessMessage } from '../../../../shared/helpers';
import { Editor } from '../../../../shared';
import { InstalledApplicationTabs } from './InstalledApplicationTabs'
import { NotFoundResponseArea } from '../../../../shared/enums';
import { FileUploadResponseModel } from '../../../../shared/models';
import { Loader } from '../../../../shared/loaders';

interface IState {
    result?: InstalledApplicationDetailModel;
    options?: object;
    parsedData?: string;
    optionsError?: string;
    isLoading?: boolean;
    isOptions?: boolean;
}
export class InstalledApplicationsDetail extends Component<any, IState>{
    private service: InstalledApplicationService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new InstalledApplicationService();
        this.submitMission = this.submitMission.bind(this);
        this.editorhandleOptions = this.editorhandleOptions.bind(this);
        this.showOptions = this.showOptions.bind(this);
        this.hideOptions = this.hideOptions.bind(this);
    }

    initialState: Partial<IState> = {
        result: {
            revealable_options: "",
            application: {
                text: "",
                value: 0
            },
            application_enabled: false,
            application_id: 0,
            billable: false,
            control_missions: [],
            enabled: false,
            id: 0,
            invocation_schedules: [],
            name: "",
            options: "",
            report: {
                text: "",
                value: 0
            },
            report_id: 0,
            run_on_report_change: false,
            created_at: new Date(),
            updated_at: new Date(),

        },
        options: {},
        parsedData: "",
        optionsError: "",
        isLoading: false,
        isOptions: false
    }

    componentDidMount() {
        this.loadData();
    }
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getInstalledApplicationById(this.props.match.params.id)
            .then((res: HttpResponse<InstalledApplicationDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex, NotFoundResponseArea.InstalledApplications, this.props)
            });
    }


    showOptions() {
        this.setState({ isOptions: true })
    }

    hideOptions() {
        this.setState({ isOptions: false })
    }

    editorhandleOptions(data) {
        this.setState({
            parsedData: data,
        }, () => {
            this.isJson(data)
        });
    }

    submitMission() {
        let email = GetUserInfo().email;
        let data: ControlMissionPostModel = {
            installed_report_application_id: this.props.match.params.id,
            options: this.state.parsedData == "" ? {} : JSON.parse(this.state.parsedData || ''),
            triggered_by: email
        };
        this.service.createControlMission(data)
            .then((res: HttpResponse<FileUploadResponseModel>) => {
                if (res && res.result) {
                    if (res.result.jid) {
                        ShowSuccessMessage("Mission created for Scheduled Report");
                        this.loadData();
                    }
                }
            });

    }



    isJson(str) {
        try {
            JSON.parse(str);
            this.setState({ optionsError: "" });
            return true;
        } catch (e) {
            this.setState({
                optionsError: "Options is not valid please type valid JSON",
            });
            return false;
        }
    }

    render() {
        return (
            <Fragment>
                <div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <h5>Details for Installed Application '{this.state.result?.name}'</h5>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <InstalledApplicationTabs id={this.props.match.params.id} url="/installedApplication/detail/" />
                                    {!this.state.isLoading && <>
                                        <h5 className="mb-3">Basic Info</h5>
                                        <div className="row">
                                            <div className="col">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header">
                                                        <b>Name</b>
                                                    </div>
                                                    <div className="card-body">{this.state.result?.name}</div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header">
                                                        <b>Report</b>
                                                    </div>
                                                    <div className="card-body">
                                                        <NavLink to={"/report/detail/" + this.state.result?.report.value}>{this.state.result?.report.text}</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header">
                                                        <b>Application</b>
                                                    </div>
                                                    <div className="card-body">
                                                        <NavLink to={"/application/detail/" + this.state.result?.application.value}>{this.state.result?.application.text}</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.result?.enabled && <div className="col">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header">
                                                        <b>Enabled</b>
                                                    </div>
                                                    <div className="card-body"> <i className="fas fa-check text-success"></i> Yes</div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                        <div className="row">
                                            {this.state.result?.run_on_report_change && <div className="col">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header">
                                                        <b>Run On Report Change</b>
                                                    </div>
                                                    <div className="card-body"> <i className="fas fa-check text-success"></i>   Yes</div>
                                                </div>
                                            </div>
                                            }
                                            {this.state.result?.billable && <div className="col">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header">
                                                        <b>Billable</b>
                                                    </div>
                                                    <div className="card-body"> <i className="fas fa-check text-success"></i>   Yes</div>
                                                </div>
                                            </div>
                                            }
                                            <div className="col">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header">
                                                        <b>Created At</b>
                                                    </div>
                                                    <div className="card-body">{ConvertDateTime(this.state.result?.created_at, 'LLL')}</div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header">
                                                        <b>Updated At</b>
                                                    </div>
                                                    <div className="card-body">{ConvertDateTime(this.state.result?.updated_at, 'LLL')}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group shadow-sm p-3 mb-0">
                                                    <h5>Actions</h5>
                                                    <label>New Control Mission Options</label>
                                                    <Editor
                                                        StringData={this.state.parsedData}
                                                        FormateType="json"
                                                        onChange={this.editorhandleOptions}
                                                    />
                                                    {this.state.optionsError != "" && (
                                                        <div style={{ color: "red" }}>
                                                            {this.state.optionsError}
                                                        </div>
                                                    )}
                                                    <button onClick={this.submitMission} className="btn btn-success mt-2">Run Mission</button>
                                                </div>

                                            </div>



                                        </div>
                                        {this.state.result?.invocation_schedules.length != 0 && <div className="row">
                                            <div className="col-md-12">
                                                <h5 className="mt-4">Invocation Schedules</h5>
                                                <div className="table-responsive">
                                                    <table className="table table-sm table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">#</th>
                                                                <th scope="col">Time Scheduled (Eastern Time)</th>
                                                                <th scope="col">Created At</th>
                                                                <th scope="col">Updated At</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.result?.invocation_schedules.map((item: InvocationSchedulesModel, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{item.id}</td>
                                                                        <td>{item.run_at_time}	</td>
                                                                        <td>{ConvertDateTime(item.created_at, 'LLL')}</td>
                                                                        <td>{ConvertDateTime(item.updated_at, 'LLL')}</td>
                                                                    </tr>

                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.result?.control_missions.length != 0 && <div className="row">
                                            <div className="col-md-12 mt-4">
                                                <h5>Control Missions</h5>
                                                <div className="table-responsive">
                                                    <table className="table table table-sm table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Action</th>
                                                                <th scope="col">#</th>
                                                                <th scope="col">State</th>
                                                                <th scope="col">Created At</th>
                                                                <th scope="col">Updated At</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.result?.control_missions.map((item: InstalledApplicationControlMissionsModel, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td><button className="btn btn-sm  btn-info">Run Again </button></td>
                                                                        <td><NavLink to={"/control_mission/" + item.id}>{item.id}</NavLink></td>
                                                                        <td><NavLink to={"/control_mission/" + item.id}>{item.state}</NavLink></td>
                                                                        <td>{ConvertDateTime(item.created_at, 'LLL')}</td>
                                                                        <td>{ConvertDateTime(item.updated_at, 'LLL')}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.result?.options != "" && <div className="row">
                                            {this.state.result?.id == this.props.match.params.id && this.state.result?.options != null && <div className="col-md-6 mt-4    ">
                                                <h5>Options</h5>
                                                {!this.state.isOptions && <button onClick={this.showOptions} className="btn btn-sm  btn-info"> Click to Reveal </button>}
                                                {this.state.isOptions && <button onClick={this.hideOptions} className="btn btn-sm rounded-0 pull-right  btn-danger"> Click to Close </button>}
                                                {this.state.isOptions && <Editor StringData={this.state.result?.options} FormateType="yaml" />}
                                            </div>}
                                            {this.state.result?.id == this.props.match.params.id && this.state.result?.revealable_options != null && <div className="col-md-6 mt-4">
                                                <div className="form-group">
                                                    <h5>Revealable Option</h5>
                                                    <Editor StringData={this.state.result?.revealable_options} FormateType="yaml" />
                                                </div>
                                            </div>}
                                        </div>}
                                    </>}
                                    {this.state.isLoading && <Loader loading={this.state.isLoading} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
