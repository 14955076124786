import { HttpWrapper, AppConfig } from "../../../../../../core";
import { ReportColumnFiltersListResponseModel, ReportColumnFiltersDetailModel } from "../models";
import { PostExportModel } from "../../../../../../shared/export/post.export.model";

export class ReportColumnFiltersService {
    private wrapper: HttpWrapper;
    constructor() {
        this.wrapper = new HttpWrapper();
    }

    getReportColumnFiltersList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
        return this.wrapper.post<ReportColumnFiltersListResponseModel>(AppConfig.apiEndpoint + '/v1/report_column_filter/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }   

    getReportColumnFiltersById(id: number) {
        return this.wrapper.get<ReportColumnFiltersDetailModel>(AppConfig.apiEndpoint + '/v1/report_column_filter/' + id);
    }
    
    exportReportColumnFilters(exportData?: PostExportModel) {
        return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/report_column_filter/export', exportData);
    }

}