import React, { Component, Fragment } from "react";
import Breadcrumbs from "../../shared/Breadcrumbs";
import { ConfigurationsRoute } from "../../routing/IndividualRoutes";

export class ConfigurationsPage extends Component {
  render() {
    return (
      <Fragment>
        <Breadcrumbs />
        <ConfigurationsRoute />
      </Fragment>
    );
  }
}
