import React, { Component, Fragment } from "react";
import { IsAuthorize } from "../../../shared/authorization/check-access";
import { Role } from "../../../shared/authorization/enums";
import { NavLink } from "react-router-dom";

interface IProps {
  id: number;
  url: string;
  name?: string;
}
export class PlatformTabs extends Component<IProps> {
  render() {
    return (
      <Fragment>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          {IsAuthorize([Role.Dev]) && (
            <li className="nav-item">
              <NavLink
                to={"/platform/platform-form/" + this.props.id}
                className={
                  this.props.url === "/platform/platform-form/"
                    ? "nav-link active"
                    : "nav-link"
                }
                id="edit-tab"
                data-toggle="tab"
                href="#edit"
                role="tab"
                aria-controls="edit"
                aria-selected={
                  this.props.url === "/platform/platform-form/"
                    ? "true"
                    : "false"
                }
              >
                <i className="far fa-edit"></i>&nbsp;Edit
              </NavLink>
            </li>
          )}
          {IsAuthorize([Role.Dev]) && (
            <li className="nav-item">
              <NavLink
                to={
                  "/platform/delete/" + this.props.id
                }
                className={
                  this.props.url === "/platform/delete/"
                    ? "nav-link active"
                    : "nav-link"
                }
                id="show-tab"
                data-toggle="tab"
                href="#delete"
                role="tab"
                aria-controls="delete"
                aria-selected={
                  this.props.url === "/platform/delete/" ? "true" : "false"
                }
              >
                <i className="far fa-trash-alt"></i>&nbsp;Delete
              </NavLink>
            </li>
          )}
          {IsAuthorize([Role.Sales, Role.AM, Role.TAM, Role.Dev, Role.Support ,Role.Manager]) && (
            <li className="nav-item">
              <NavLink
                to={"/platform/detail/" + this.props.id}
                className={
                  this.props.url === "/platform/detail/"
                    ? "nav-link active"
                    : "nav-link"
                }
                id="show-tab"
                data-toggle="tab"
                href="#show"
                role="tab"
                aria-controls="show"
                aria-selected={
                  this.props.url === "/platform/detail/" ? "true" : "false"
                }
              >
                <i className="fas fa-info-circle"></i>&nbsp;Show
              </NavLink>
            </li>
          )}
        </ul>
        <br />
      </Fragment>
    );
  }
}
