import React, { Component, Fragment } from "react";
import { ConnectionsSemaphoresDetailModel } from "./models/connections.semaphores.detail.model";
import { ConnectionsSemaphoresService } from "./services/connection.semaphores.service";
import { HttpResponse } from "../../../../core";
import { NavLink } from "react-router-dom";
import { StatusListModel } from "./models/status.list.model";
import { ConnectionSemaphoresTabs } from ".";
import { Loader } from '../../../../shared/loaders';

interface IState {
  result?: ConnectionsSemaphoresDetailModel;
  isLoading?: boolean;
}
export class ConnectionSemaphoresDetail extends Component<any, IState> {
  private service: ConnectionsSemaphoresService;
  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.service = new ConnectionsSemaphoresService();
  }
  initialState: Partial<IState> = {
    result: {
      connection: {
        text: "",
        value: 0,
      },
      id: 0,
      size: 0,
      statuses: [],
      timeout_seconds: 0,
    },
    isLoading: false,
  };

  componentDidMount() {
    this.loadData();
  }
  private setLoading(loading: boolean) {
    this.setState({ isLoading: loading });
}
  loadData() {
    this.setLoading(true);
    this.service
      .getConnectionSemaphoresById(this.props.match.params.id)
      .then((res: HttpResponse<ConnectionsSemaphoresDetailModel>) => {
        this.setLoading(false);
        if (res && res.result) {
          this.setState({
            result: res.result,
          });
        }
      })
      .catch((ex) => {
        if (ex.status == 404) {
          this.props.history.push("/connection-semaphore/list");
        }
      });
  }
  render() {
    return (
      <Fragment>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h5>
            Details for Connection Semaphore 'ConnectionSemaphore #
            {this.props.match.params.id}'
          </h5>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <ConnectionSemaphoresTabs id={this.props.match.params.id} url="/connection-semaphore/detail/" />
                {!this.state.isLoading &&  <>  
                <h5 className="mb-3">Basic Info</h5>
                <div className="row">
                  {this.state.result?.connection && (
                    <div className="col">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Connection</b>
                        </div>
                        <div className="card-body">
                          <NavLink to={"/connection/detail/" +this.state.result?.connection.value}>{this.state.result?.connection.text}</NavLink>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col">
                    <div className="card card-silver shadow-sm">
                      <div className="card-header">
                        <b>Timeout Seconds</b>
                      </div>
                      <div className="card-body">
                        {this.state.result?.timeout_seconds}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card card-silver shadow-sm">
                      <div className="card-header">
                        <b>Size</b>
                      </div>
                      <div className="card-body">{this.state.result?.size}</div>
                    </div>
                  </div>
                </div>

               
                  <div className="row">
                    <div className="col-md-12 mt-4">
                      <h5>Status</h5>
                      <div className="table-responsive">
                        <table className="table table table-sm table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Key</th>
                              <th scope="col">Status</th>
                              <th scope="col">Thread ID</th>
                            </tr>
                          </thead>
                          {this.state.result?.statuses && (<tbody>
                            {this.state.result?.statuses.map(
                              (item: StatusListModel, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{item.key}</td>
                                    {item.available &&<td style={{color: "#468847"}}>Available</td>}
                                    <td>{item.thread_id}</td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>)}
                        </table>
                        <a href="https://sites.google.com/a/staq.com/staqnowledged/infrastructure/production/connection-semaphore-protection">
                          What is this?
                        </a>
                      </div>
                    </div>
                  </div>
                  </>}                 
                        {this.state.isLoading && <Loader loading={this.state.isLoading} />}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
