import React, { Component, Fragment } from "react";
import Breadcrumbs from "../../shared/Breadcrumbs";
import { CustomerFaviconRoute } from "../../routing";

export class CustomerFaviconPage extends Component {
  render() {
    return (
      <Fragment>
        <Breadcrumbs />
        <CustomerFaviconRoute />
      </Fragment>
    );
  }
}
