import React, { Component, Fragment } from "react";
import { HttpResponse, AppConfig } from "../../../core";
import { NavLink, Link } from "react-router-dom";
import {
  ConvertDateTime,
  HandleNotFoundResponse,
  formatBytes,
  ShowSuccessMessage,
  PrependZero, GetUserInfo
} from "../../../shared/helpers";
import { Editor, PaginationRecordInfo, Pagination } from "../../../shared";
import { Accordion, Card, Button, Modal } from "react-bootstrap";
import { ClearSemaphoreModel, ClearSemaphoreResponseModel, DropdownItemModel, FileUploadResponseModel, RerunCollectionMissionPostModel, RerunCollectionMissionResponseModel } from "../../../shared/models";
import {
  CollectionMissionsResponseListModel,
  ConnectionDetailModel,
  SemaphoreListModel,
  IssuesListModel,
  CollectionMissionsModel,
} from "../connections/models";
import { CustomConnectionTabs } from "./CustomConnectionTabs";
import { CustomConnectionsService } from "./services";
import { CustomConnectionDetailModel, Uploaded_File, InboundFiles, CancelCustomCollectionMission } from "./models";
import { StatusListModel } from "../connections/connections-semaphores/models/status.list.model";
import { NotFoundResponseArea, ConnectionState } from "../../../shared/enums";
import { Loader } from "../../../shared/loaders";
import { CommonService } from "../../../shared/services/common.service";
import { InboundEmailAddressesFormPopup } from "./inbound-email-addresses";
import { IsAuthorize } from "../../../shared/authorization/check-access";
import { Role } from "../../../shared/authorization/enums";
// import moment from "moment";
import moment from "moment-timezone";

interface IState {
  showInboundEmailAddressesForm?: boolean;
  isNew?: boolean;
  isChildUpdate?: boolean;
  result?: CustomConnectionDetailModel;
  collectionMissionsResponse: CollectionMissionsResponseListModel;
  currentPage: number;
  pageSize: number;
  pageCount: number;
  totalItems: number;
  isLoading: boolean;
  rerunMissionDate:Date;
  isParam?:boolean;
}
export class CustomConnectionDetails extends Component<any, IState> {
  private service: CustomConnectionsService;
  private commonService: CommonService;
  constructor(props) {
    super(props);
    this.state = {
      collectionMissionsResponse: {
        paging: {
          current_page: 0,
          page_count: 0,
          page_size: 0,
          total_items: 0,
        },
        records: [],
      },
      result: {
        id: 0,
        cc_schema: {
          id: 0,
          name: "",
          scopes: [],
        },
        download_link_matcher: "",
        extraction_engine: {
          text: "",
          value: 0,
          type:""
        },
        extraction_scheduling_recipe: {
          id: 0,
          run_at_hour: 0,
        },
        lookup_code: "",
        rate_limit: "",

        account: {
          text: "",
          value: 0,
        },
        rate_limit_summary: {
          id: 0,
          text: "",
          status: "",
        },
        recent_inbound_files: [],
        recent_uploaded_file: [],
        subdirectory_name: "",
        extractor_time_zone_specification: {
          id: 0,
          global_default: "",
        },
        authorization_unstable_at: null,
        billable: false,
        created_at: new Date(),
        all_dependent_reports: [],
        earliest_data_at: new Date(),
        edited_scopes: [],
        internal_notes: "",
        latest_data_at: new Date(),
        name: "",
        params: "",
        purge_data_before: new Date(),
        resource_protection_summary: {
          locked: false,
          message: "",
        },
        run_at_hour: 0,
        semaphore: {
          key: "",
          statuses: [],
          text: "",
          id: 0,
        },
        state: "",
        time_zone: "",
        updated_at: new Date(),
        created_by_user: {
          text: "",
          value: 0,
        },
        available_to_external_users: false,
        custom_extraction_scheduling_recipe_id: 0,
        data_retention_days: 0,
        deleted: false,
        extractor: {
          text: "",
          value: 0,
        },
        ignore_health: false,
        inbound_email_addresses: [],
        issues: [],
        report_priority_override: 0,
        state_message: "",
        time_zone_spec: {
          text: "", 
          value: 0,
        },
      },
      currentPage: 1,
      pageSize: 10,
      pageCount: 0,
      totalItems: 0,
      isLoading: false,
      rerunMissionDate: new Date(),
      isNew: false,
      isChildUpdate: false,
      showInboundEmailAddressesForm: false,
      isParam:false 
    };
    this.service = new CustomConnectionsService();
    this.commonService = new CommonService();
    this.pageChange = this.pageChange.bind(this);
    this.processFile = this.processFile.bind(this);
    this.reProcessFile = this.reProcessFile.bind(this);
    this.cancelMission = this.cancelMission.bind(this);
    this.rerunMission = this.rerunMission.bind(this);
    this.clearSemaphore = this.clearSemaphore.bind(this);
    this.updateInboundEmailAddresses = this.updateInboundEmailAddresses.bind(this);
    this.showParam  = this.showParam.bind(this);
    this.hideParam  = this.hideParam.bind(this);
  }

  updateInboundEmailAddresses(isChildUpdate, name, id, connectionId) {
    if (isChildUpdate) {
      if (id != 0 && connectionId == this.props.match.params.id) {
        this.state.result?.inbound_email_addresses?.push({ text: name, value: id })
      }
      this.setState({
        isChildUpdate: isChildUpdate
      }, () => {

      });
    }
  }

  showParam()
  {
    this.setState({isParam:true})
  }

 hideParam()
  {
    this.setState({isParam:false})
  }
  

  handleShowInboundEmailAddresses = () => {
    this.setState({ showInboundEmailAddressesForm: true, isNew: false }, () => { });
  };
  handleInboundEmailAddressesClose = () => {
    this.setState({ showInboundEmailAddressesForm: false }, () => { });
  };

  processFile(id)
  {
    this.service
    .runCollectionMission(id)
    .then((res: HttpResponse<FileUploadResponseModel>) => {
      if (res && res.result) {
        if (res.result.jid) {
          ShowSuccessMessage("Created UploadedFile "+id+ " for Sidekiq Job ID "+ res.result.jid+", check for a collection mission to be assigned");
          this.loadData();
        }
      }
    })
    .catch(ex => {
      HandleNotFoundResponse(ex,NotFoundResponseArea.CustomConnection,null)
  });
  }
  reProcessFile(id)
  {
    this.service
    .reprocessRunCollectionMission(id)
    .then((res: HttpResponse<FileUploadResponseModel>) => {
      if (res && res.result) {
        if (res.result.jid) {
          ShowSuccessMessage("Created Sidekiq Job ID  "+res.result.jid+ "  to process InboundFile "+id+ ". Watch for a new collection mission to be created shortly.");
          this.loadData();
        }
      }
    })
    .catch(ex => {
     HandleNotFoundResponse(ex,NotFoundResponseArea.CustomConnection,null)
  });
  }

  cancelMission(id, index)
  {
    this.service
    .cancelCollectionMissionById(this.props.match.params.id, id)
    .then((res: HttpResponse<CancelCustomCollectionMission>) => {
      if (res && res.result) {
        ShowSuccessMessage(res.result.mission_count +" missions and "+res.result.task_count+" tasks for this connection have been canceled.");
        let data = this.state.collectionMissionsResponse;
        data.records[index]['state']="failed";
        this.setState({collectionMissionsResponse:data})
      }
    })  
    .catch(ex => {
     HandleNotFoundResponse(ex,NotFoundResponseArea.CustomConnection,null)
  });  
  }

  rerunMission(id)
  {
    let email =GetUserInfo().email;
    let data:RerunCollectionMissionPostModel={                  
      collection_mission_id:id,
      rerun:true,
      triggered_by:email      
    };
    this.service
    .rerunCollectionMissionById(data)
    .then((res: HttpResponse<RerunCollectionMissionResponseModel>) => {
      if (res && res.result) {
        ShowSuccessMessage("Mission created for "+ConvertDateTime(this.state.rerunMissionDate,"LLL")+" UTC");    
        this.getControlMission()          
      }
    })    
    .catch(ex => {
     HandleNotFoundResponse(ex,NotFoundResponseArea.CustomConnection,null)
  }); 
  }

  clearSemaphore()
  {
   let data:ClearSemaphoreModel={
     id:this.props.match.params.id,
     type:"CustomConnection"
   } 
    this.commonService
    .clearSemaphore(data)
    .then((res: HttpResponse<ClearSemaphoreResponseModel>) => {
      if (res && res.result) {
        ShowSuccessMessage(res.result.message);   
      }
    })   
    .catch(ex => {
      HandleNotFoundResponse(ex,NotFoundResponseArea.CustomConnection,null)
  });  
  }

  componentDidMount() {
    this.loadData();
    this.getControlMission();
  }
  private setLoading(loading: boolean) {
    this.setState({ isLoading: loading });
}
loadData() {
    this.setLoading(true);
    this.service
      .getCustomConnectionById(this.props.match.params.id)
      .then((res: HttpResponse<CustomConnectionDetailModel>) => {
        this.setLoading(false);
        if (res && res.result) {
          this.setState({
            result: res.result,
          });
        }
      })
      .catch((ex) => {
        HandleNotFoundResponse(
          ex,
          NotFoundResponseArea.CustomConnection,
          this.props
        );
      });
  }

  pageChange(page: number) {
    if (this.state.currentPage == page) return;
    this.setState({ currentPage: page }, () => {
      this.getControlMission();
    });
  }
  getControlMission() {
    this.service
      .getCollectionMissionList(
        this.props.match.params.id,
        this.state.currentPage,
        this.state.pageSize
      )
      .then((res: HttpResponse<CollectionMissionsResponseListModel>) => {
        if (res && res.result) {
          this.setState(
            {
              collectionMissionsResponse: res.result,
            },
            () => {
            
            }
          );
        }
      })
      .catch((ex) => {
        HandleNotFoundResponse(
          ex,
          NotFoundResponseArea.CustomConnection,
          this.props
        );
      });
  }

  render() {
    return (
      <Fragment>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h5>
            Details for Custom Connection '
            {this.state.result?.name == "" ? "-" : this.state.result?.name}'
          </h5>
        </div>
        <Modal backdrop='static' keyboard={false} size="lg" show={this.state.showInboundEmailAddressesForm} onHide={this.handleInboundEmailAddressesClose}>
              <InboundEmailAddressesFormPopup updateInboundEmailAddresses={this.updateInboundEmailAddresses} handleClose={this.handleInboundEmailAddressesClose} isNew={this.state.isNew} id={0} connectionId={this.props.match.params.id} connectionType="CustomConnection" connectionName={this.state.result?.name} />
            </Modal>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <CustomConnectionTabs
                  id={this.props.match.params.id}
                  url="/custom-connection/detail/"
                />
                {!this.state.isLoading &&  <>  
                <h5 className="mb-3">Basic Info</h5>
                <div className="row">
                  <div className="col">
                    <div className="card card-silver shadow-sm">
                      <div className="card-header">
                        <b>Name</b>
                      </div>
                      {this.state.result?.deleted && (
                        <div className="card-body">
                          <span className=" text-danger">
                            Scheduled to be deleted:&nbsp;
                          </span>
                          <br />
                          (see{" "}
                          <a href="https://sites.google.com/a/staq.com/staqnowledged/home/4---our-product/staq-admin/connection-deleting">
                            {" "}
                            wiki
                          </a>{" "}
                          for details)&nbsp;
                          {this.state.result?.name}
                        </div>
                      )}
                      {!this.state.result?.deleted && (
                        <div className="card-body">
                          {this.state.result?.name}
                        </div>
                      )}
                    </div>
                  </div>

                  {this.state.result?.extraction_engine && (
                    <div className="col">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Extraction Engine</b>
                        </div>
                        <div className="card-body">
                        <NavLink to={"/extraction-engine/detail/" + this.state.result?.extraction_engine.value}> {this.state.result?.extraction_engine.text}</NavLink>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.account && (
                    <div className="col">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Account</b>
                        </div>
                        <div className="card-body">
                          <NavLink
                            to={
                              "/account/detail/" +
                              this.state.result?.account.value
                            }
                          >
                            {this.state.result?.account.text}
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.account.active && (
                    <div className="col">
                      <div className="card card-info shadow-sm">
                        <div className="card-header font-weight-bold">
                          Account Active?
                        </div>
                        <div className="card-body">
                          <i className="fas fa-check text-success text2"></i>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.billable && (
                    <div className="col">
                      <div className="card card-info shadow-sm">
                        <div className="card-header font-weight-bold">
                          Billable
                        </div>
                        <div className="card-body">
                          <i className="fas fa-check text-success text2"></i>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row">
                  {this.state.result?.report_priority_override && (
                    <div className="col">
                    <div className="card card-silver shadow-sm">
                      <div className="card-header">
                        <b>Report Priority Override</b>
                      </div>
                      <div className="card-body">
                        {this.state.result?.report_priority_override}
                      </div>
                    </div>
                    </div>
                  )}
                  {this.state.result?.ignore_health && (
                    <div className="col">
                      <div className="card card-info shadow-sm">
                        <div className="card-header font-weight-bold">
                          Ignore Health
                        </div>
                        <div className="card-body">
                          <i className="fas fa-check text-success text2"></i>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col">
                    <div className="card card-silver shadow-sm">
                      <div className="card-header">
                        <b>Purge Data Before</b>
                      </div>
                      <div className="card-body">
                        <span>
                           
                          {ConvertDateTime(
                            this.state.result?.purge_data_before,
                            "LLL"
                          )}
                        </span>
                        {Number(this.state.result?.data_retention_days) < 0 && <span>Disabled - old data will not be removed</span>}
                        {Number(this.state.result?.data_retention_days) > 0 &&  <p>This value is controlled at the account level and
                        overridden at the connection level by adjusting data
                        retention days</p>  }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="card card-silver shadow-sm">
                      <div className="card-header">
                        <b>Internal Notes</b>
                      </div>
                      <div className="card-body">
                        {this.state.result?.internal_notes}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="card card-silver shadow-sm">
                      <div className="card-header">
                        <b>State</b>
                      </div>
                      <div className="card-body">
                        {this.state.result?.state}:&nbsp;
                        {this.state.result?.state_message}
                      </div>
                    </div>
                  </div>
                  {this.state.result?.cc_schema != null && (
                    <div className="col">
                      <div className="card card-silver shadow-sm">
                      <div className="card-header font-weight-bold">
                          Schema
                        </div>
                        <div className="card-body">                          
                  <NavLink to={"/schema/detail/" + this.state.result?.cc_schema.id}>Custom Connection Schema&nbsp;{this.state.result?.cc_schema.id}</NavLink>
                          <br />
                          {this.state.result?.cc_schema.scopes.map(
                            (item: DropdownItemModel, i, arr) => {
                              return (
                                 <span key={item.value}>
                             <NavLink to={"/scope/detail/" + item.value} className="badge badge-light m-1">
                                   <strong>{i + 1}:</strong> {item.text}
                                   {i != arr.length - 1 ? ", " : ""} 
                                   </NavLink>
                               </span>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {this.state.result?.authorization_unstable_at && (
                    <div className="col">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Authorization Unstable?</b>
                        </div>
                        <div className="card-body">
                          <span className=" text-danger">
                            {" "}
                            {ConvertDateTime(
                              this.state.result?.authorization_unstable_at,
                              "LLL"
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col">
                    <div className="card card-silver shadow-sm">
                      <div className="card-header">
                        <b>Time zone</b>
                      </div>

                      {!this.state.result?.time_zone_spec && (
                        <div className="card-body">
                          {this.state.result?.time_zone}
                          <br />
                          <small>
                            See{" "}
                            <a
                              href="https://sites.google.com/a/staq.com/staqnowledged/home/infrastructure/staq-repos/extractors/time-zones-specification"
                              target="_blank"
                            >
                              &nbsp;time zone info{" "}
                            </a>
                            for more information.
                          </small>
                        </div>
                      )}

                      {this.state.result?.time_zone_spec && (
                        <div className="card-body">
                          The extractor for
                          <a href="#">
                            {this.state.result.extraction_engine.text}
                          </a>
                          uses a global time zone. Individual connections cannot
                          have their own time zones.
                          <br />
                          More details for
                          <a href="#">
                            {this.state.result.time_zone_spec.text}
                          </a>
                          <br />
                          <small>
                            {" "}
                            See{" "}
                            <a
                              href="https://sites.google.com/a/staq.com/staqnowledged/home/infrastructure/staq-repos/extractors/time-zones-specification"
                              target="_blank"
                            >
                              &nbsp;time zone info{" "}
                            </a>
                            for more information.
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8 ">
                    <div className="form-group">
                      <h5>Params</h5>
                      {!this.state.isParam && <button onClick={this.showParam} className="btn btn-sm  btn-info"> Click to Reveal </button>}
                      {this.state.isParam && <button onClick={this.hideParam} className="btn btn-sm rounded-0 pull-right  btn-danger"> Click to Close </button>}
                      {this.state.isParam && <Editor
                        StringData={
                          this.state.result?.params == null
                            ? ""
                            : this.state.result?.params
                        }
                        FormateType="yaml"
                      />}
                      {/* <br/> */}
                       {/* {this.state.isParam && <button onClick={this.hideParam} className="btn btn-sm  btn-info"> Click to Conceal </button>} */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="card card-silver shadow-sm">
                      <div className="card-header">
                        <b>Extraction Scheduling Recipe</b>
                      </div>
                      <div className="card-body">
                        {this.state.result
                          ?.extraction_scheduling_recipe.id && (
                           
                            <NavLink to={"/extraction-scheduling-recipe/detail/" + this.state.result?.extraction_scheduling_recipe.id}>  Extraction Scheduling Recipe { this.state.result?.extraction_scheduling_recipe.id}</NavLink> 
                         
                        )}
                     

                      {this.state.result
                        ?.extraction_engine && (
                       <>
                         <br/>(<NavLink to={"/extraction-engine/detail/" + this.state.result?.extraction_engine.value}> 
                            {this.state.result?.extraction_engine.type}
                          </NavLink> engine default)
                       </>
                      )}
                      </div>
                    </div>
                  </div>

                  {this.state.result?.run_at_hour != null && (
                    <div className="col">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Run At Hour</b>
                        </div>
                        <div className="card-body">
                          {PrependZero(this.state.result?.run_at_hour)}
                          &nbsp; UTC (
                          <a href="https://sites.google.com/a/staq.com/staqnowledged/home/4---our-product/staq-admin/how_to/how-to-change-schedules">
                            {" "}
                            details
                          </a>
                          )
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.lookup_code && (
                    <div className="col">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Lookup Code</b>
                        </div>
                        <div className="card-body">
                          {this.state.result?.lookup_code}
                        </div>
                      </div>
                    </div>
                  )}
                   

                  {this.state.result?.resource_protection_summary != null && (
                    <div className="col">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Resource Protection Summary</b>
                        </div>
                        {this.state.result?.resource_protection_summary
                          .locked && (
                          <div className="card-body">
                            <span className=" text-danger">
                              {" "}
                              {
                                this.state.result?.resource_protection_summary
                                  .message
                              }
                            </span>
                          </div>
                        )}
                        {!this.state.result?.resource_protection_summary
                          .locked && (
                          <div className="card-body">
                            {
                              this.state.result?.resource_protection_summary
                                .message
                            }
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                
                {this.state.result?.inbound_email_addresses.length!=0 &&  (
                    <div className="row">
                  {IsAuthorize([Role.AM, Role.TAM, Role.Dev, Role.Support])  && <div className="col">
                      <div className="card card-silver shadow-sm">
                     
                        <div className="card-header font-weight-bold">
                          Inbound Email Addresses 
                          {IsAuthorize([Role.TAM, Role.Dev, Role.Support]) && <button className="btn btn-sm ml-4 note-btn btn-success" onClick={this.handleShowInboundEmailAddresses}>
                           <i className="fas fa-plus"></i>
                          </button>}
                        </div>
                        <div className="card-body">
                          {this.state.result?.inbound_email_addresses.map(
                            (item: DropdownItemModel, i,arr) => {
                              return (
                                <span key={item.value}>
                                  <NavLink className="badge badge-light m-1" to={"/inbound-email-address/detail/" + item.value}> 
                                    <strong>{i + 1}:</strong> {item.text}
                                    {i != arr.length - 1 ? ", " : ""}
                                  </NavLink>
                                </span>
                              );
                            }
                          )}
                          </div>
                         
                          
                      </div>
                    </div>}
                    </div>
                  )}

                {this.state.result?.rate_limit_summary != null && (
                  <div className="row">
                    <div className="col">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Rate Limit</b>
                        </div>
                        {this.state.result?.rate_limit_summary.text ==
                          "ExtractorRateLimit" && (
                          <div className="card-body">
                            <a href="#">
                              {this.state.result?.rate_limit_summary.status}
                            </a>
                          </div>
                        )}
                        {this.state.result?.rate_limit_summary.text ==
                          "DefaultRateLimit" && (
                          <div className="card-body">Default Rate Limit</div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div className="row">
                  {this.state.result?.semaphore != null && (
                    <div className="col-md-12 ">
                      <h5>Semaphore</h5>
                      {this.state.result?.semaphore.text ==
                        "ConnectionSemaphore" && (
                        <NavLink
                          to={
                            "/connection-semaphore/detail/" +
                            this.state.result?.semaphore.id
                          }
                        >
                          {this.state.result?.semaphore.key}
                        </NavLink>
                      )}

                      {this.state.result?.semaphore.text ==
                        "ExtractorSemaphore" && (
                        <p>
                          <a href="#">{this.state.result?.semaphore.key}</a>
                        </p>
                      )}

                      {this.state.result?.semaphore.text !=
                        "ExtractorSemaphore" &&
                        this.state.result?.semaphore.text !=
                          "ConnectionSemaphore" && (
                          <p>
                            Default Semaphore{" "}
                            {this.state.result?.semaphore.key && (
                              <span>({this.state.result?.semaphore.key})</span>
                            )}
                          </p>
                        )}

              <button onClick={()=>this.clearSemaphore()} className="btn btn-sm btn-danger ">
                    Clear Semaphores
                                      </button>
                    </div>
                  )}

                  <div className="col-md-12 m-t-10">
                    <div className="table-responsive" style={{ maxHeight: "550px" }}>
                      <table className="table table-md table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Key</th>
                            <th scope="col">Status</th>
                            <th scope="col">Thread ID</th>
                          </tr>
                        </thead>
                        {this.state.result?.semaphore != null && (
                          <tbody>
                            {this.state.result?.semaphore.statuses.map(
                              (item: StatusListModel, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{item.key}</td>
                                    {item.available && (
                                      <td style={{ color: "#468847" }}>
                                        {item.description}
                                      </td>
                                    )}
                                    {!item.available && (
                                      <td style={{ color: "red" }}>
                                        {item.description}
                                      </td>
                                    )}
                                    <td>{item.thread_id}</td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                  <div className="col-md-12  mt-3">
                    <p>
                      <a
                        href="https://sites.google.com/a/staq.com/staqnowledged/infrastructure/production/connection-semaphore-protection"
                        target="blank"
                      >
                        What is this?
                      </a>
                    </p>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-md-12  mt-3">
                    <h5>Pivotal Tracker issues</h5>
                  </div>
                  <div className="col-md-12">
                    <Accordion>
                      <Card className="accoridan-ss">
                        <Card.Header className="accoridan-ss--header">
                          <Accordion.Toggle as={Button} variant="link" eventKey="0"             >
                            Create New Pivotal Tracker Issue
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <div className="row">
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <label>Name</label>
                                  <input type="text" className="form-control" />
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <label>Story type</label>
                                  <select className="form-control">
                                    <option>Bug</option>
                                    <option>Feature</option>
                                    <option>Chore</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <label>Label</label>
                                  <input type="text" className="form-control" />
                                  <small>Separate label values by spaces</small>
                                </div>
                              </div>

                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>Description</label>
                                  <textarea className="form-control"></textarea>
                                </div>
                              </div>
                              <div className="col-sm-12 ">
                                <button className="btn btn-sm btn-info">
                                  Make Issue
                                </button>
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card className="accoridan-ss">
                        <Card.Header className="accoridan-ss--header">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="1"
                          >
                            Link to an Existing Pivotal Tracker Issue
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                          <Card.Body>
                            <div className="row">
                              <div className="col-sm-5">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control" placeholder="Enter Story ID"
                                  />
                                  <small>
                                    A number from the Platform project in
                                    PivotalTracker, like '71497028'
                                  </small>


                                </div>
                                <button className="btn btn-sm  btn-info">
                                  Link  Issue
                                  </button>
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                </div> */}

                <div className="row ">
                  {this.state.result?.edited_scopes && (
                    <div className="col">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header font-weight-bold">
                          Edited Scopes
                        </div>
                        <div className="card-body">
                          {this.state.result?.edited_scopes.map(
                            (item: DropdownItemModel, i, arr) => {
                              return (
                                <span key={item.value}>
                                  <NavLink
                                    className="badge badge-light m-1"
                                    to={"/report/detail/" + item.value}
                                  >
                                    <strong>{i + 1}:</strong> {item.text}
                                    {i != arr.length - 1 ? ", " : ""}
                                  </NavLink>
                                </span>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {this.state.result?.all_dependent_reports && (
                    <div className="col">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header font-weight-bold">
                          Dependent Reports
                        </div>
                        <div className="card-body">
                          {this.state.result?.all_dependent_reports.map(
                            (item: DropdownItemModel, i, arr) => {
                              return (
                                <span key={item.value}>
                                  <NavLink
                                    className="badge badge-light m-1"
                                    to={"/report/detail/" + item.value}
                                  >
                                    <strong>{i + 1}:</strong> {item.text}
                                    {i != arr.length - 1 ? ", " : ""}
                                  </NavLink>
                                </span>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {this.state.result?.recent_uploaded_file && (
                  <div className="row">
                     <h5>Last 50 Uploaded Files </h5>
                    <div className="col-md-12 m-t-10">
                      <div className="table-responsive" style={{ maxHeight: "550px" }}>
                        <table className="table table-md table-striped">
                          <thead> 
                            <tr>
                              <th scope="col">File</th>
                              <th scope="col">Size</th>
                              <th scope="col">Created at</th>
                              <th scope="col">Actions</th>
                            </tr>
                          </thead>

                          <tbody>
                            {this.state.result?.recent_uploaded_file.map(
                              (item: Uploaded_File, index) => {                                
                                return (
                                  <tr key={index}>
                                    <td><a href={item.url}>Save </a> {item.filename}</td>                                    
                                    <td>{item.size!=null?formatBytes(item.size):"0 Bytes"}</td>
                                    <td>{ConvertDateTime(item.created_at, "LLL")}</td>
                                    <td> <button onClick={()=>this.processFile(item.id)} className="btn btn-sm  btn-info">
                                        Process
                                      </button></td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}

                
{this.state.result?.recent_inbound_files && (
                  <div className="row">
                    <div className="col  mt-3">
                      <div className="row mt-5">
                      <div className="col-md-12">
                      <h5>Last 50 Inbound Files - &nbsp;
                        <small>
                        {this.state.result?.inbound_email_addresses.map(
                            (item: DropdownItemModel, i,arr) => {
                              return (
                                <span key={item.value}> 
                                    {item.text}
                                    {i != arr.length - 1 ? ", " : ""}
                                </span>
                              );
                            }
                          )}
                        </small>
                      </h5>
                      </div>
                      
                      </div>
                     
                    </div>
                    <div className="col-md-12 mb-3 mt-2">
                      <div className="table-responsive" style={{ maxHeight: "550px" }}>
                        <table className="table table-md table-striped">
                          <thead>
                            <tr>
                            <th scope="col">Created at</th>
                              <th scope="col">File</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {this.state.result?.recent_inbound_files.map(
                              (item: InboundFiles, index) => {                                
                                return (
                                  <tr key={index}>
                                    <td>{ConvertDateTime(item.created_at, "LLL")}&nbsp;({item.interface})</td>  
                                    <td> <NavLink to={"/inbound-file/detail/" + item.id}> {item.path}</NavLink></td>   
                                    <td> <button  onClick={()=>this.reProcessFile(item.id)} className="btn btn-sm  btn-info">
                                        Reprocess
                                      </button></td>                                  
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}

                <div className="row">
                  {this.state.result?.download_link_matcher && (
                    <div className="col">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Download Link Matcher</b>
                        </div>
                        <div className="card-body">
                          {" "}
                          {this.state.result?.download_link_matcher}
                        </div>
                      </div>
                    </div>
                  )}
                   {this.state.result?.subdirectory_name && (
                    <div className="col">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Subdirectory Name</b>
                        </div>
                        <div className="card-body">
                          {" "}
                          {this.state.result?.subdirectory_name}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row">
                  {this.state.result?.created_by_user && (
                    <div className="col">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Created By User</b>
                        </div>
                        <div className="card-body">
                          <NavLink
                            to={
                              "/user/detail/" +
                              this.state.result?.created_by_user.value
                            }
                          >
                            {this.state.result?.created_by_user.text}
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col">
                    <div className="card card-silver shadow-sm">
                      <div className="card-header">
                        <b>Created At</b>
                      </div>
                      <div className="card-body">
                        {" "}
                        {ConvertDateTime(this.state.result?.created_at, "LLL")}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card card-silver shadow-sm">
                      <div className="card-header">
                        <b>Updated At</b>
                      </div>
                      <div className="card-body">
                        {" "}
                        {ConvertDateTime(this.state.result?.updated_at, "LLL")}
                      </div>
                    </div>
                  </div>
                  {this.state.result?.earliest_data_at && (
                    <div className="col">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Earliest Data At</b>
                        </div>
                        <div className="card-body">
                          {" "}
                          {ConvertDateTime(
                            this.state.result?.earliest_data_at,
                            "LLL"
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.result?.latest_data_at && (
                    <div className="col">
                      <div className="card card-silver shadow-sm">
                        <div className="card-header">
                          <b>Latest Data At</b>
                        </div>
                        <div className="card-body">
                          {" "}
                          {ConvertDateTime(
                            this.state.result?.latest_data_at,
                            "LLL"
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                

                {this.state.collectionMissionsResponse.records.length > 0 && (
                  <div className="row">
                    <div className="col-md-12  mt-3">
                      <h6>Recent Collection Missions</h6>
                    </div> 
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table table-md table-striped">
                          <thead>
                            <tr>
                              <th>Rerun</th>
                              <th>Cancel</th>
                              <th>#</th>
                              <th>Created At</th>
                              <th>Interval of Interest</th>
                              <th>Scheduled At</th>
                              <th>State</th>
                              <th># Datapoints</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.collectionMissionsResponse.records?.map(
                              (item: CollectionMissionsModel,index) => {
                                let diff =  moment.utc(item.scheduled_at).tz("UTC").local();
                                return (
                                  <tr key={item.id}>
                                    <td>
                                      <button onClick={()=>this.rerunMission(item.id)} className="btn btn-sm  btn-info">
                                        Run Again 
                                      </button>
                                    </td>
                                    {(item.state=="new" || item.state=="in_progress") &&  <td> <button onClick={()=>this.cancelMission(item.id,index)}
                                className="btn btn-sm   btn-danger">
                                Cancel
                              </button></td>}
                              {(item.state!="new" && item.state!="in_progress") &&  <td></td>}
                                    <td><NavLink to={ "/custom-connection/collection-mission/" + item.id}>{item.id}</NavLink> </td>
                                    <td>
                                      {ConvertDateTime(item.created_at, "LLL")}
                                    </td>
                                    <td>{ConvertDateTime(item.mission_start_at, "l")}-{ConvertDateTime(item.mission_end_at, "l")}(UTC)</td>
                                    <td>
                                      {/* {item.scheduled_at.toString()<= Date.now().toString()?"ago":"from now"} */}
                                      {ConvertDateTime(item.scheduled_at, "LLL")}<br/>
                                       {diff.fromNow()}
                                     
                                    </td>
                                    <td>
                                    <NavLink to={ "/custom-connection/collection-mission/" + item.id} style={{color:item.state=="failure"?"red":""}}>{item.state}</NavLink>
                                    </td>
                                    <td>{item.state=="success"?item.datapoint_count:"-"}</td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
                
                <div className="row">
                <div className="col-md-6">
                    <PaginationRecordInfo
                      currentPage={this.state.currentPage}
                      totalRecords={
                        this.state.collectionMissionsResponse?.paging
                          .total_items
                      }
                      pageSize={this.state.pageSize}
                    />
                    </div>
                    <div className="col-md-6">
                    <div className="pull-right"><Pagination
                      currentPage={this.state.currentPage}
                      pageCount={
                        this.state.collectionMissionsResponse?.paging.page_count
                      }
                      onChangePage={this.pageChange}
                    /></div>
                  </div>
                </div>
                </>}                 
                        {this.state.isLoading && <Loader loading={this.state.isLoading} />}     
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
